import React from 'react';
import ProfileInfo from './ProfileInfo';
import './ProfileContainer.scss';
import { Form } from 'reactstrap';
import ProfileEmails from './ProfileEmails';
import EmergencyContacts from './EmergencyContacts';

const ProfileContainer = () => {
  return (
    <div>
      <Form>
        <ProfileInfo />

        <hr className='my-4 profile-seperator' />

        <ProfileEmails />

        <hr className='my-4 profile-seperator' />

        <EmergencyContacts />
      </Form>
    </div>
  );
};

export default ProfileContainer;
