import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import './CustomModal.scss';
const CustomModal = ({
  isOpen,
  toggle,
  headerText,
  cancelButtonText,
  confirmButtonText,
  confirmFunction,
  children,
  customClass,
  customSize,
  buttonIconClass,
  roleModal,
  noButtons,
}) => (
  <Modal
    className={customClass ?? ''}
    isOpen={isOpen}
    toggle={toggle}
    size={customSize || 'md'}
    centered
  >
    <ModalHeader toggle={toggle}>
      <h3 className={`text-dark ${noButtons ? 'pl-2' : ''}`}>{headerText}</h3>
    </ModalHeader>
    <ModalBody
      className={`${roleModal ? 'pb-1' : ''}${noButtons ? 'pl-0 pr-0' : ''}`}
    >
      {children}
    </ModalBody>

    <ModalFooter>
      {noButtons ? (
        ''
      ) : (
        <>
          <Button
            className='admin-theme-button custom-btn-size2 btn-width'
            color='secondary'
            onClick={toggle}
          >
            {cancelButtonText}
          </Button>
          <Button
            color='primary'
            onClick={(e) => confirmFunction(e)}
            className={`custom-btn-size2 admin-theme-button
         ${confirmButtonText === 'Add' ? 'btn-width' : ''}`}
          >
            {buttonIconClass && <i className={buttonIconClass} />}{' '}
            {confirmButtonText}
          </Button>
        </>
      )}
    </ModalFooter>
  </Modal>
);

export default CustomModal;
