import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UncontrolledTooltip, Button, Spinner } from 'reactstrap';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import {
  changeRole,
  deleteUserFromProject,
  getTeam,
} from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import { setProjectTeamArray } from 'redux/actions/projects';
import { useParams } from 'react-router-dom';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import defaultImage from 'assets/img/theme/no-user-1.jpeg';
import { getImageUrl } from 'utils/helper-functions';

const UserRoleRow = ({
  name,
  email,
  role,
  userId,
  jobTitle,
  managerFlag,
  projectName,
  imageUrl,
  rate,
}) => {
  const dispatch = useDispatch();
  const [removeModal, setRemoveModal] = useState(false);
  const [forceRemoveFlag, setForceRemoveFlag] = useState(false);
  const [managerModal, setManagerModal] = useState(false);
  const [roleValue, setRoleValue] = useState(role);
  const [previousRole, setPreviousRole] = useState(role);
  const [saveFlag, setSaveFlag] = useState(false);
  const [spinnerFlag, setSpinnerFlag] = useState(false);
  const { projectId } = useParams();

  const [rateValue, setRateValue] = useState(rate);
  const [jobDesc, setJobDesc] = useState(jobTitle);
  const [editRate, setEditRate] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);

  const setSelectRoleHandler = (selectedRole) => {
    setPreviousRole(roleValue);
    setRoleValue(selectedRole);
    setSaveFlag(true);
  };

  const setRateHandler = (updatedRate) => {
    setRateValue(parseFloat(updatedRate));
    setSaveFlag(true);
  };
  const editHandler = () => {
    setEditRate(true);
    setSaveFlag(true);
  };

  const roleUpdateUserRoleHandler = (e) => {
    setEditRate(false);
    e?.preventDefault();
    setSaveFlag(false);
    setSpinnerFlag(true);

    changeRole(projectId, userId, {
      role: roleValue.toUpperCase(),
      user_per_hour_rate: rateValue,
      job_title: jobDesc,
    })
      .then(() => {
        getTeam(projectId).then(({ data }) => {
          dispatch(setProjectTeamArray(data));
        });
        setManagerModal(false);

        setTimeout(() => {
          setSpinnerFlag(false);
          toast.success('Update successfull');
        }, 1000);
      })
      .catch(() => {
        getTeam(projectId).then(({ data }) =>
          dispatch(setProjectTeamArray(data))
        );

        setManagerModal(false);
        setTimeout(() => {
          setSpinnerFlag(false);
          toast.error('Failed to update');
        }, 1000);
      });
  };

  const deleteUserFromProjectHandler = (e) => {
    e?.preventDefault();
    deleteUserFromProject(projectId, userId, forceRemoveFlag)
      .then(() => {
        toast.success('User removed successfully');
        setRemoveModal(false);
        getTeam(projectId).then(({ data }) =>
          dispatch(setProjectTeamArray(data))
        );
      })
      .catch((errorMessage) => {
        if (errorMessage === 'user is currently logging time in this project') {
          // toast.error(errorMessage);
          setForceRemoveFlag(true);
        } else {
          toast.error('Failed to remove user');
        }
      });
  };
  const roleUpdateManagerHandler = (e) => {
    e.preventDefault();
    if (previousRole === roleValue) roleUpdateUserRoleHandler(e);
    else setManagerModal(true);
  };
  const toggleManager = () => {
    setManagerModal(false);
    setRoleValue(previousRole);
  };

  const employeeImageSrc = getImageUrl(imageUrl);

  return (
    <>
      <td className='table-user'>
        <div className='d-flex'>
          <img
            alt='...'
            className='avatar no-user-img rounded-circle mr-3'
            height='600'
            src={employeeImageSrc}
            onError={(e) => {
              e.target.onError = null;
              e.target.src = defaultImage;
            }}
            width='40'
          />
          <div>
            <b>{name}</b>
            <br />
            <i className='text-primary cursor-normal'>{email}</i>
          </div>
        </div>
      </td>

      <td className='table-actions'>
        <div>
          <CustomFormGroup
            type='select'
            name='selectViewUserRole'
            id='selectViewUserRole'
            value={roleValue}
            setValue={setSelectRoleHandler}
            iconClassName='fas fa-user-edit'
            selectBoxText='Select view'
            position='tableCell'
            selectBoxArray={[
              { uid: 'DEVELOPER', name: 'Developer' },
              { uid: 'MANAGER', name: 'Manager' },
              { uid: 'QA', name: 'QA' },
              { uid: 'DESIGNER', name: 'Designer' },
            ]}
            disabled={roleValue === 'MANAGER' && !saveFlag && !managerModal}
          />
          <UncontrolledTooltip delay={0} target='selectViewUserRole'>
            Change Role
          </UncontrolledTooltip>
        </div>
      </td>

      <td className='table-actions'>
        <div>
          <CustomFormGroup
            type='number'
            value={rateValue}
            iconClassName='fas fa-dollar-sign'
            name='userRate'
            id='userRate'
            setValue={setRateHandler}
            min={1}
            position='tableCell'
            disabled={!editRate}
            editHandler={editHandler}
            didSubmitButtonClick={saveClicked}
          />
        </div>
      </td>
      <td className='table-actions'>
        <div>
          <CustomFormGroup
            type='text'
            value={jobDesc}
            name='jobDesc'
            id='jobDesc'
            setValue={setJobDesc}
            position='tableCell'
            disabled={!editRate}
            editHandler={editHandler}
            didSubmitButtonClick={saveClicked}
          />
        </div>
      </td>

      <td>
        <div className='client-edit-button-child'>
          {saveFlag ? (
            <Button
              color='success'
              className={`save-btn ${!saveFlag ? 'hidden-class' : ''}`}
              size='custom-size'
              onClick={(e) => {
                setSaveClicked(true);
                if (rateValue >= 0 && rateValue) {
                  setSaveFlag(false);
                  if (roleValue === 'MANAGER' && managerFlag) {
                    roleUpdateManagerHandler(e);
                  } else {
                    roleUpdateUserRoleHandler(e);
                  }
                }
              }}
            >
              Save
            </Button>
          ) : (
            <div className='spinner-container'>
              <Spinner
                size='sm'
                color='primary'
                className={` ${!spinnerFlag ? 'hidden-class' : ''}`}
              />
            </div>
          )}
          {!saveFlag && (
            <i
              className='fas fa-pencil-alt fa-lg text-lg ml-3'
              style={{ color: '#fdc13f' }}
              onClick={editHandler}
            />
          )}
          <button
            className='icons-wrapper-button'
            disabled={roleValue === 'MANAGER' && !saveFlag && !managerModal}
            onClick={() => setRemoveModal(true)}
          >
            <i
              className='fas fa-user-times text-lg ml-3'
              id='tooltip601065234'
            />
          </button>
          <UncontrolledTooltip delay={0} target='tooltip601065234'>
            Remove User
          </UncontrolledTooltip>
        </div>
      </td>

      <CustomModal
        isOpen={removeModal}
        toggle={() => setRemoveModal(!removeModal)}
        headerText='Remove user from project'
        cancelButtonText='Cancel'
        confirmButtonText='Remove'
        confirmFunction={deleteUserFromProjectHandler}
      >
        {forceRemoveFlag ? (
          <p className='mb-0'>
            {name}
            <i> is currently logging time in this project.</i>
          </p>
        ) : (
          ''
        )}
        <p className='mb-0'>
          {'Are you sure you want to remove '}
          <i>
            <b>{name}</b>
          </i>
          {' from '}
          <i>
            <b>{projectName}</b>
          </i>
          ?
        </p>
      </CustomModal>
      <CustomModal
        isOpen={managerModal}
        toggle={toggleManager}
        headerText='Change Manager'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={roleUpdateUserRoleHandler}
      >
        <p>
          {
            'There is already a manager of this project. Are you sure you want to change manager?'
          }
        </p>
      </CustomModal>
    </>
  );
};

export default UserRoleRow;
