import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  PopoverBody,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import { formatLoggedTime } from 'utils/helper-functions';

const MultipleUsers = ({ status, position, users, number }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const handleShow = () => setShowAll(true);

  const today = moment().startOf('day');

  useEffect(() => {
    if (showAll) {
      setShowAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverOpen]);

  const UserList = ({
    uid,
    image,
    name,
    checkin,
    checkout,
    working,
    index,
  }) => (
    <>
      {index % 9 === 0 && index > 1 && <div className='break' />}
      <span
        className='avatar avatar-sm rounded-circle cursor-pointer'
        id={`employee-${uid}`}
      >
        <img
          alt={`-alt`}
          src={image}
          className={moment(checkin).isBefore(today, 'day') && 'black-n-white'}
        />
      </span>
      <UncontrolledTooltip
        target={`employee-${uid}`}
        placement={status === 'in' ? 'top' : 'bottom'}
      >
        {name}
      </UncontrolledTooltip>
      <UncontrolledPopover
        target={`employee-${uid}`}
        trigger='legacy'
        className='popover-users info'
        placement={status === 'in' ? 'left-start' : 'left-end'}
      >
        <PopoverBody className='users-popover'>
          <div className='checkin-wrapper-single-card'>
            <div>
              <img
                alt={name?.split(' ')[0] + image}
                src={image}
                width='40'
                className={
                  moment(checkin).isBefore(today, 'day') && 'black-n-white'
                }
              />
            </div>
            <div className='user-data'>
              <div className='name'>{name}</div>
              <p>
                In-out time: &nbsp; <i>{moment(checkin).format('hh:mm a')}</i>
                {' - '}
                {checkout ? (
                  <i>{moment(checkout).format('hh:mm a')}</i>
                ) : (
                  <i className='working'>*</i>
                )}
              </p>
              <p>
                {checkout ? (
                  <span>
                    (
                    {formatLoggedTime({
                      hours: parseInt(
                        moment
                          .duration(moment(checkout).diff(checkin))
                          .asHours()
                      ),
                      minutes: parseInt(
                        moment
                          .duration(moment(checkout).diff(checkin))
                          .asMinutes() % 60
                      ),
                    })}
                    ){' '}
                  </span>
                ) : (
                  <span>(in progress)</span>
                )}
                {working ? (
                  <i className='fas fa-laptop-house work-from-icon text-success' />
                ) : (
                  ''
                )}
              </p>
            </div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );

  return (
    <div
      className={`timeline-check ${status}`}
      style={{ left: `${position}px` }}
    >
      <span
        className={`avatar avatar-sm rounded-circle text-avatar background-${status}`}
        id={`employee-${position}-${status}`}
        onClick={() => setPopoverOpen(!popoverOpen)}
      >
        <span>&#43;</span>
        <span>{number}</span>
      </span>

      <UncontrolledPopover
        target={`employee-${position}-${status}`}
        trigger='legacy'
        popperClassName={`popover-users multiple ${showAll && 'not-round'}`}
        defaultOpen={popoverOpen}
        hideArrow={true}
        placement={status === 'in' ? 'top-end' : 'bottom-end'}
      >
        <PopoverBody className='users-popover'>
          <div className='avatar-group d-flex align-items-center'>
            {users?.map((value, index) =>
              index < 4 ? (
                <UserList
                  uid={value?.uid}
                  image={value?.image}
                  name={value?.name}
                  checkin={value?.checkin}
                  checkout={value?.checkout}
                  working={value?.working}
                  index={index}
                  status={status}
                />
              ) : showAll ? (
                <UserList
                  uid={value?.uid}
                  image={value?.image}
                  name={value?.name}
                  checkin={value?.checkin}
                  checkout={value?.checkout}
                  working={value?.working}
                  index={index}
                />
              ) : (
                index < 5 && (
                  <>
                    <span className='avatar avatar-sm rounded-circle'>
                      +{users.length - 4}
                    </span>
                    <div>
                      <Button
                        className='admin-theme-button custom-btn-sm ml-3 mr-2'
                        color='primary'
                        onClick={() => handleShow()}
                      >
                        <span>View all</span>
                      </Button>
                    </div>
                  </>
                )
              )
            )}
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};
export default MultipleUsers;
