import React, { useEffect, useState } from 'react';
import { getProjects } from 'utils/api/api-requests/admin';
import { Card, CardHeader, Col, Container, Button, Row } from 'reactstrap';
import AdminHeader from 'Components/Headers/Admin/Admin';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ProjectTable from './Table/Table';
import DeleteModal from 'Components/Admin/Project/Modals/DeleteModal';
import AddModal from 'Components/Admin/Project/Modals/AddModal';
import EditModal from 'Components/Admin/Project/Modals/EditModal';
import { setProjectAddModal } from 'redux/actions/modals';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import ArchiveProjectTable from './Table/ArchiveTable';
import { useHistory } from 'react-router-dom';
import ExoprtIcon from 'assets/img/active-export.png';

const ProjectContainer = () => {
  const history = useHistory();
  const { projectsList } = useSelector((state) => state);
  const [areProjectsBeingLoaded, setAreProjectsBeingLoaded] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [archivedProjects, setArchivedProjects] = useState([]);
  const [inActiveProjectsModal, setInActiveProjectsModal] = useState(false);
  const [unArchiveModal, setUnArchiveModal] = useState(false);
  const [selectedArchivedProjects, setSelectedArchivedProjects] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const updateProjectsStatus = (data) => {
    setProjects(data?.filter(({ deleted_at }) => !deleted_at));
    setArchivedProjects(data?.filter(({ deleted_at }) => deleted_at));
  };
  useEffect(() => {
    setAreProjectsBeingLoaded(true);
    getProjects()
      .then(({ data }) => {
        updateProjectsStatus(data);
        setAreProjectsBeingLoaded(false);
      })
      .catch((error) => {
        setAreProjectsBeingLoaded(false);
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast?.error('Unable to fetch project list.');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsList]);

  return (
    <>
      <div className='main-content'>
        <AdminHeader />

        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col d-flex align-items-center'>
                      <div className='workspace-heading'>
                        <h3 className='mb-0 text-dark'>Projects</h3>
                      </div>

                      <div
                        className={`custom-action-button ${
                          selectedProjects?.length <= 0 ? 'disabled' : ''
                        }`}
                        onClick={() => setArchiveModal(true)}
                      >
                        <img
                          className='export-icon'
                          alt='...'
                          src={ExoprtIcon}
                        />
                        <div>Archive</div>
                      </div>
                    </div>

                    <div className='col text-right'>
                      <Button
                        color='primary'
                        onClick={(e) => {
                          e?.preventDefault();
                          dispatch(setProjectAddModal(true));
                        }}
                        className='admin-theme-button custom-btn-size add-button'
                      >
                        <span>
                          <i className='fas fa-plus' /> Add a new project
                        </span>
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <ProjectTable
                  projects={projects}
                  areProjectsBeingLoaded={areProjectsBeingLoaded}
                  setSelectedProjects={setSelectedProjects}
                  selectedProjects={selectedProjects}
                  archiveModal={archiveModal}
                  setArchiveModal={setArchiveModal}
                  updateProjectsStatus={updateProjectsStatus}
                />
                <div className='d-flex flex-row justify-content-center '>
                  <button
                    className={`view-archived-button admin-theme-button ${
                      archivedProjects?.length === 0 ? 'disabled' : ''
                    }`}
                    onClick={() => setInActiveProjectsModal(true)}
                  >
                    View archived projects
                  </button>
                </div>
              </Card>
            </Col>
          </Row>
          <DeleteModal />
          <AddModal />
          <EditModal />
        </Container>
      </div>
      <CustomModal
        isOpen={inActiveProjectsModal}
        toggle={() => {
          setInActiveProjectsModal(!inActiveProjectsModal);
          setSelectedArchivedProjects([]);
        }}
        headerText='Archived projects'
        noButtons={true}
        customSize='lg'
        customClass='archived-user-modal'
      >
        <ArchiveProjectTable
          projects={archivedProjects}
          updateProjectsStatus={updateProjectsStatus}
          selectedArchivedProjects={selectedArchivedProjects}
          setSelectedArchivedProjects={setSelectedArchivedProjects}
          setUnArchiveModal={setUnArchiveModal}
          unArchiveModal={unArchiveModal}
          setDeleteModal={setDeleteModal}
          deleteModal={deleteModal}
        />
        <div className='d-flex modal-action-button'>
          <div
            className={`custom-action-button ${
              selectedArchivedProjects?.length <= 0 ? 'disabled' : ''
            }`}
            onClick={() => setUnArchiveModal(true)}
          >
            <img className='export-icon' alt='...' src={ExoprtIcon} />
            <div>Unarchive</div>
          </div>
          <div
            className={`custom-action-button align-items-baseline ${
              selectedArchivedProjects?.length <= 0 ? 'disabled' : ''
            }`}
            onClick={() => setDeleteModal(true)}
          >
            <i className='fa fa-trash-alt delete-icon' />
            <div>Delete</div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ProjectContainer;
