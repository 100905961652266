import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdminHeader from 'Components/Headers/Admin/Admin';
import ClientTable from './Table/Table';
import { Card, CardHeader, Col, Container, Button, Row } from 'reactstrap';
import { getClients } from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import ArchivedClientTable from './Table/ArchiveTable';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { useHistory } from 'react-router-dom';
import ExoprtIcon from 'assets/img/active-export.png';

const Client = () => {
  const history = useHistory();
  const { clientList } = useSelector((state) => state);
  const [areClientsBeingLoaded, setAreClientsBeingLoaded] = useState(false);

  const [selectedClients, setSelectedClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [archiveModal, setArchiveModal] = useState(false);

  const [inActiveClientsModal, setInActiveClientsModal] = useState(false);
  const [archivedClients, setArchivedClients] = useState([]);
  const [unArchiveModal, setUnArchiveModal] = useState(false);
  const [selectedArchivedClients, setSelectedArchivedClients] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const updateClientsStatus = (data) => {
    setClients(data?.filter(({ deleted_at }) => !deleted_at));
    setArchivedClients(data?.filter(({ deleted_at }) => deleted_at));
  };

  useEffect(() => {
    setAreClientsBeingLoaded(true);
    getClients()
      .then(({ data }) => {
        updateClientsStatus(data);
        setAreClientsBeingLoaded(false);
      })
      .catch((error) => {
        setAreClientsBeingLoaded(false);
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientList]);
  return (
    <>
      <div className='main-content'>
        <AdminHeader />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col d-flex align-items-center'>
                      <div className='workspace-heading'>
                        <h3 className='mb-0 text-dark'>Clients</h3>
                      </div>

                      <div
                        className={`custom-action-button ${
                          selectedClients?.length <= 0 ? 'disabled' : ''
                        }`}
                        onClick={() => setArchiveModal(true)}
                      >
                        <img
                          className='export-icon'
                          alt='...'
                          src={ExoprtIcon}
                        />
                        <div>Archive</div>
                      </div>
                    </div>

                    <div className='col text-right'>
                      <Button
                        className='admin-theme-button custom-btn-size add-button'
                        color='primary'
                        onClick={() =>
                          history.push(`/datumbrain/admin/clients/new`)
                        }
                      >
                        <span>
                          <i className='fas fa-plus' /> Add a new client
                        </span>
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <ClientTable
                  clients={clients}
                  areClientsBeingLoaded={areClientsBeingLoaded}
                  setAreClientsBeingLoaded={setAreClientsBeingLoaded}
                  selectedClients={selectedClients}
                  setSelectedClients={setSelectedClients}
                  archiveModal={archiveModal}
                  setArchiveModal={setArchiveModal}
                  updateClientsStatus={updateClientsStatus}
                />{' '}
                <div className='d-flex flex-row justify-content-center '>
                  <button
                    className={`view-archived-button admin-theme-button ${
                      archivedClients?.length === 0 ? 'disabled' : ''
                    }`}
                    onClick={() => setInActiveClientsModal(true)}
                  >
                    View archived clients
                  </button>
                </div>
              </Card>
            </Col>
          </Row>

          <CustomModal
            isOpen={inActiveClientsModal}
            toggle={() => {
              setInActiveClientsModal(!inActiveClientsModal);
              setSelectedArchivedClients([]);
            }}
            headerText='Archived Clients'
            noButtons={true}
            customSize='lg'
            customClass='archived-user-modal'
          >
            <ArchivedClientTable
              clients={archivedClients}
              areClientsBeingLoaded={areClientsBeingLoaded}
              setAreClientsBeingLoaded={setAreClientsBeingLoaded}
              selectedArchivedClients={selectedArchivedClients}
              setSelectedArchivedClients={setSelectedArchivedClients}
              setUnArchiveModal={setUnArchiveModal}
              unArchiveModal={unArchiveModal}
              setDeleteModal={setDeleteModal}
              deleteModal={deleteModal}
              updateClientsStatus={updateClientsStatus}
            />
            <div className='d-flex modal-action-button'>
              <div
                className={`custom-action-button ${
                  selectedArchivedClients?.length <= 0 ? 'disabled' : ''
                }`}
                onClick={() => setUnArchiveModal(true)}
              >
                <img className='export-icon' alt='...' src={ExoprtIcon} />
                <div>Unarchive</div>
              </div>
              <div
                className={`custom-action-button align-items-baseline ${
                  selectedArchivedClients?.length <= 0 ? 'disabled' : ''
                }`}
                onClick={() => setDeleteModal(true)}
              >
                <i className='fa fa-trash-alt delete-icon' />
                <div>Delete</div>
              </div>
            </div>
          </CustomModal>
        </Container>
      </div>
    </>
  );
};

export default Client;
