import './styles.scss';

import forward from '../../../../assets/svg/Foward.svg';
// import plus from '../../../../assets/svg/plus.svg';

import { getErpDetails } from 'utils/api/api-requests/admin';

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

const ErpDetailCard = () => {
  const [erpDetails, setErpDetails] = useState('');

  useEffect(() => {
    getErpDetails()
      .then(({ data }) => {
        setErpDetails(data);
      })
      .catch((err) => toast.error('Failed to get ERP details'));
  }, []);

  const { clients, projects, employees } = erpDetails;

  return (
    <div className='erpDetail'>
      <h3 className='erpDetail__title'>ERP for you</h3>

      <div className='erpDetail__cards'>
        <Link
          to={`/datumbrain/admin/projects`}
          className='erpDetail__cards--card'
        >
          <span className='card__header'>
            Projects <img src={forward} alt='icon' />
          </span>
          <span className='card__stats'>
            {projects?.toString()?.padStart(2, '0')}
          </span>
        </Link>
        <Link to={`/datumbrain/admin/users`} className='erpDetail__cards--card'>
          <span className='card__header'>
            Employees <img src={forward} alt='icon' />
          </span>
          <span className='card__stats'>
            {' '}
            {employees?.toString()?.padStart(2, '0')}
          </span>
        </Link>
        <Link to='/datumbrain/admin/clients' className='erpDetail__cards--card'>
          <span className='card__header'>
            Clients <img src={forward} alt='icon' />
          </span>
          <span className='card__stats'>
            {clients?.toString()?.padStart(2, '0')}
          </span>
        </Link>
        {/* <div className='erpDetail__cards--card erpDetail__cards--addLink'>
          <span>Add quick link</span>
          <img src={plus} alt='icon' />
        </div> */}
      </div>
    </div>
  );
};
export default ErpDetailCard;
