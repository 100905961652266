import moment from 'moment';
import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';
import {
  formatLoggedTime,
  getZonedTime,
  updateStateArray,
} from 'utils/helper-functions';
import './LogCard.scss';
import Parser from 'html-react-parser';
import { parseMarkdown } from 'utils/helper-functions';
const LogCard = ({
  uid,
  date,
  timeStart,
  timeEnd,
  hours,
  worked,
  lateSitting,
  tracking,
  loggedBy,
  selectedLogs,
  setSelectedLogs,
  editLogHandler,
  calendarView,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [markDownTooglerFlag, setMarkDownTooglerFlag] = useState(false);
  const mouseEnterMarkDownHandler = (uid) => {
    if (!markDownTooglerFlag) setPopoverOpen(uid);
  };
  return (
    <Card className='log-card'>
      {calendarView ? (
        ''
      ) : (
        <CardHeader className='log-card-header'>
          <div>
            <div>
              <div>
                <div className='styled-checkbox-container'>
                  <label className='styled-checkbox user-card'>
                    <input
                      type='checkbox'
                      checked={selectedLogs?.includes(uid)}
                      onClick={() =>
                        updateStateArray({
                          array: selectedLogs,
                          setArray: setSelectedLogs,
                          value: uid,
                        })
                      }
                    />
                    <span
                      className={`checkbox-text ${
                        localStorage
                          .getItem('currentUserRole')
                          ?.toLowerCase() === 'admin'
                          ? 'user-theme-color'
                          : ''
                      }`}
                    >
                      {date}
                    </span>
                    <span className='checkmark'></span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <i
                className={`fas fa-pencil-alt  ${
                  moment()
                    .subtract(6, 'days')
                    .isBefore(moment(date, 'YYYY-MM-DD'))
                    ? 'logs-edit-btn'
                    : 'disabled-icon'
                }`}
                title='edit'
                onClick={() => {
                  setPopoverOpen(false);
                  editLogHandler({
                    uid,
                    date,
                    timeStart,
                    timeEnd,
                    hours,
                    worked,
                    lateSitting,
                    tracking,
                  });
                }}
              />
            </div>
          </div>
          <div className='log-card-day'>{moment(date).format('dddd')}</div>
        </CardHeader>
      )}
      <CardBody className='log-card-body'>
        {loggedBy ? (
          <>
            <div className='log-card-heading'>
              <div className='log-card-value'>{loggedBy}</div>
            </div>
          </>
        ) : (
          ''
        )}
        <div className='log-card-time'>
          <div>
            <div className='log-card-sub-heading'>START TIME</div>
            <div className='log-card-value'>{timeStart}</div>
          </div>
          <div>
            <div className='log-card-sub-heading'>END TIME</div>
            <div className='log-card-value'>
              {tracking ? (
                <span className='in-progress'>in progress</span>
              ) : (
                timeEnd
              )}
            </div>
          </div>
          <div>
            <div className='log-card-sub-heading'>HOURS LOGGED</div>
            <div className='log-card-value'>{`${
              isNaN(hours)
                ? '-'
                : formatLoggedTime({
                    hours: getZonedTime({
                      type: 'hours',
                      value: hours,
                    }),
                    minutes: getZonedTime({
                      type: 'minutes',
                      value: hours,
                    }),
                  })
            }`}</div>
          </div>
        </div>
        <div className='log-card-details '>
          <div>
            <div className='log-card-sub-heading'>DETAILS</div>
            <div>
              <span
                className='log-card-value td-details'
                onMouseEnter={() => mouseEnterMarkDownHandler(uid)}
                id={`details-${uid}`}
              >
                {worked?.replace(/[^a-zA-Z ]/g, ' ')}
              </span>
              <UncontrolledPopover
                trigger={markDownTooglerFlag === uid ? 'focus' : 'legacy'}
                onMouseLeave={() => setPopoverOpen(false)}
                placement='left'
                isOpen={popoverOpen === uid}
                toggle={() =>
                  setPopoverOpen(markDownTooglerFlag ? true : !popoverOpen)
                }
                target={`details-${uid}`}
              >
                <PopoverBody className='p-3'>
                  <div>
                    <div className='d-flex flex-row justify-content-end'>
                      <label className='mr-2 mb-0 custom-styled-label'>
                        Hold
                      </label>
                      <div>
                        <label className='mb-0 custom-toggle markdown-toogle custom-parent-toggler'>
                          <input
                            type='checkbox'
                            onClick={() =>
                              setMarkDownTooglerFlag(
                                !markDownTooglerFlag ? uid : false
                              )
                            }
                            checked={markDownTooglerFlag === uid}
                          />
                          <span
                            className={`custom-toggle-slider markdown-toogle-slider rounded-circle custom-child-toggler ${
                              markDownTooglerFlag === uid ? 'right' : 'left'
                            }`}
                          />
                        </label>
                      </div>
                    </div>
                    <div>{Parser(parseMarkdown(worked))}</div>
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter className='log-card-footer'>
        <div className='log-card-off-hours'>
          <div className='log-card-sub-heading'>OFF HOURS</div>
          <div>{lateSitting}</div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default LogCard;
