import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AdminHeader from 'Components/Headers/Admin/Admin';
import UserHeader from 'Components/Headers/User/User';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Spinner,
} from 'reactstrap';
import { getUserSingleLog } from 'utils/api/api-requests/admin';
import moment from 'moment';
import { getTimerValue } from 'utils/helper-functions';

const LogDetails = () => {
  const { logId } = useParams();
  const { push } = useHistory();
  const [log, setLog] = useState({});
  const [didLogLoad, setDidLogLoad] = useState(false);

  useEffect(() => {
    getUserSingleLog(logId)
      .then(({ data }) => {
        setDidLogLoad(true);
        setLog(data);
      })
      .catch(() => {
        setDidLogLoad(false);
        setLog(null);
      });
  }, [logId]);
  const renderClientAttribute = (key, value) => (
    <div className='client-attribute'>
      <div className='client-key'>{key}</div>
      <div className='client-value'>{value}</div>
    </div>
  );
  const getFormattedTime = (time) => {
    const hours = parseInt(time?.split(':')?.[0]);
    const minutes = parseInt(time?.split(':')?.[1]);
    const seconds = parseInt(time?.split(':')?.[2]);
    return `${hours === 0 ? '' : `${hours} hr${hours === 1 ? '' : 's'}`} ${
      minutes === 0 ? '' : `${minutes} minute${minutes === 1 ? '' : 's'}`
    } ${seconds === 0 ? '' : `${seconds} second${seconds === 1 ? '' : 's'}`}`;
  };
  return (
    <div>
      {localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin' ? (
        <AdminHeader />
      ) : (
        <UserHeader />
      )}
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              {!didLogLoad && !log ? (
                <div className='not-found-message'>Log not found.</div>
              ) : !didLogLoad ? (
                <div className='logs-spinner'>
                  <Spinner color='admin' />
                </div>
              ) : (
                <>
                  <CardHeader className='border-0 details-card-header'>
                    <Row className='align-items-center'>
                      <div className='col custom-breadcrumb-admin'>
                        <span
                          onClick={() =>
                            push(
                              `/datumbrain/admin/project/${log?.project_uid}`
                            )
                          }
                        >
                          {log?.project_name}
                        </span>{' '}
                        /{' '}
                        <span
                          onClick={() =>
                            push(
                              `/datumbrain/admin/project/${log?.project_uid}/logs`
                            )
                          }
                        >
                          Logs
                        </span>{' '}
                        /{' '}
                        <>
                          {moment(log?.time_start).format('hh:mm A')} -{' '}
                          {log?.time_end ? (
                            `${moment(log?.time_end).format('hh:mm A')}`
                          ) : (
                            <em>In progress</em>
                          )}
                        </>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className='details-card-body'>
                    <div className='client-details'>
                      {renderClientAttribute('project', log?.project_name)}

                      {log?.user_name &&
                        renderClientAttribute('user', log?.user_name)}
                      {log?.time_start &&
                        renderClientAttribute(
                          'start time',
                          moment(log?.time_start).format('hh:mm A')
                        )}
                      {log?.time_end && !log?.tracking
                        ? renderClientAttribute(
                            'end time',
                            moment(log?.time_end).format('hh:mm A')
                          )
                        : log?.tracking &&
                          renderClientAttribute(
                            'end time',
                            <em>In progress</em>
                          )}
                      {log?.time_end && !log?.tracking
                        ? renderClientAttribute(
                            'total logged time',
                            getFormattedTime(
                              getTimerValue(
                                moment(log?.time_end).diff(
                                  moment(log?.time_start),
                                  'seconds'
                                )
                              )
                            )
                          )
                        : log?.tracking &&
                          renderClientAttribute(
                            'total logged time',
                            <em>In progress</em>
                          )}
                      {log?.worked_on_what &&
                        renderClientAttribute('worked on', log?.worked_on_what)}
                      {log?.reason_for_late_sitting &&
                        log?.reason_for_late_sitting !== '-' &&
                        renderClientAttribute(
                          'reason for late sitting',
                          log?.reason_for_late_sitting
                        )}
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LogDetails;
