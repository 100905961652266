import { combineReducers } from 'redux';
import {
  projectsList,
  projectObject,
  selectedProjectId,
  projectTeamArray,
  isProjectEditButtonClicked,
  selectedProjectObject,
  timeLogStartTime,
  timeLogEndTime,
  customSliderArray,
  workedOnWhat,
  reasonForLateSitting,
  selectedProjectName,
  fromDateFilter,
  toDateFilter,
  validateFilterDate,
  projectStartDate,
  projectEndDate,
  projectTeamCustomSelectArray,
  endDateFlag,
} from './projects';
import {
  currentUserRole,
  hasForgotPassword,
  mploUser,
  workspaceAlias,
  newWorkspaceObject,
  workspaceModal,
  editUserInfoFlag,
  employeeLeaveModal,
} from './general';
import {
  employeeDataHr,
  employeeData,
  employeeEmergencyContactInfo,
  employeeBankInfo,
  employeeRoleCustomSelectArray,
  userList,
  archivedUsersList,
  welcomeStatus,
  profileImage,
  leaveEditModal,
  leaveDeleteModal,
  remainingMedicalLeaves,
  remainingCasualLeaves,
} from './employee';
import {
  projectAddModal,
  projectEditModal,
  projectUserModal,
  projectDeleteModal,
  isMissingModalOpen,
  employeeRoleModal,
} from './modals';
import {
  projectLogsList,
  logsViewMode,
  areLogsBeingLoaded,
  logAddModal,
  logEditModal,
  logDeleteModal,
  logID,
  logDate,
  loggingProjects,
  trackingLogs,
  logsPageSize,
  activeView,
  isEditingTrackingLog,
  userIdForLogs,
} from './logs';
import {
  clientObject,
  clientList,
  clientAddModal,
  clientEditModal,
  clientDeleteModal,
} from './client';
import { slackChannelsArrays, integrationFlag } from './integration/slack';
import {
  checkinFromFilter,
  checkinToFilter,
  checkinViewMode,
  checkinTime,
  checkoutTime,
} from './checkin/index';
import {
  yesterdayGoals,
  todayGoals,
  blockers,
  selectedMood,
  metYesterdayGoals,
  userStatus,
  isCheckedIn,
  currentPagesChunk,
} from './userStatus';

import { pageNumber } from './pagination';

export default combineReducers({
  projectsList,
  projectLogsList,
  logsViewMode,
  areLogsBeingLoaded,
  logAddModal,
  logEditModal,
  logDeleteModal,
  logID,
  logDate,
  loggingProjects,
  trackingLogs,
  logsPageSize,
  mploUser,
  workspaceAlias,
  activeView,
  isEditingTrackingLog,
  newWorkspaceObject,
  workspaceModal,
  editUserInfoFlag,
  userIdForLogs,

  projectObject,
  selectedProjectId,
  projectAddModal,
  projectEditModal,
  projectUserModal,
  projectDeleteModal,
  isMissingModalOpen,
  employeeRoleModal,
  clientAddModal,
  clientEditModal,
  clientDeleteModal,
  projectTeamArray,
  isProjectEditButtonClicked,
  selectedProjectObject,
  currentUserRole,
  hasForgotPassword,
  timeLogStartTime,
  timeLogEndTime,
  customSliderArray,
  workedOnWhat,
  reasonForLateSitting,
  selectedProjectName,
  fromDateFilter,
  toDateFilter,
  validateFilterDate,
  endDateFlag,

  projectStartDate,
  projectEndDate,
  employeeDataHr,
  employeeData,
  employeeEmergencyContactInfo,
  employeeBankInfo,
  employeeRoleCustomSelectArray,
  welcomeStatus,
  profileImage,

  projectTeamCustomSelectArray,
  userList,
  archivedUsersList,
  clientObject,
  clientList,

  slackChannelsArrays,
  integrationFlag,

  checkinFromFilter,
  checkinToFilter,
  checkinViewMode,
  checkinTime,
  checkoutTime,
  employeeLeaveModal,

  leaveEditModal,
  leaveDeleteModal,

  remainingMedicalLeaves,
  remainingCasualLeaves,

  yesterdayGoals,
  todayGoals,
  blockers,
  selectedMood,
  metYesterdayGoals,
  userStatus,
  isCheckedIn,

  pageNumber,

  currentPagesChunk,
});
