import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserList, setEmployeeData } from 'redux/actions/employee';
import { archiveMultipleUsers } from 'utils/api/api-requests/admin';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { Table, Spinner } from 'reactstrap';
import { updateStateArray } from 'utils/helper-functions';
import moment from 'moment';
import { toast } from 'react-toastify';
import './Table.scss';
import { setEmployeeRoleModal } from 'redux/actions/modals';
import RolesModal from '../Modals/RolesModal';
import { useHistory } from 'react-router-dom';
import { setArchivedUsersList } from '../../../../redux/actions/employee';
import { getExperience } from '../../../../utils/helper-functions';

const UserTable = ({
  archiveModal,
  setArchiveModal,
  selectedActiveUsers,
  setSelectedActiveUsers,
}) => {
  const mploUser = useSelector((state) => state.mploUser);
  const userList = useSelector((state) => state.userList);
  const history = useHistory();

  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [areEmployeesBeingLoaded, setAreEmployeesBeingLoaded] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setAreEmployeesBeingLoaded(true);
    dispatch(
      setUserList({
        success: (listData) => {
          setAreEmployeesBeingLoaded(false);
        },
        failure: (error) => {
          setAreEmployeesBeingLoaded(false);
          if (error === 'token expired, please login again') {
            toast.error(error);
            localStorage.clear();
          } else toast.error(error);
          history.push('/auth/login');
        },
      })
    );

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refreshData = () => {
    const success = () => {
      setAreEmployeesBeingLoaded(false);
    };
    const failure = (error) => {
      setAreEmployeesBeingLoaded(false);
      if (error === 'token expired, please login again') {
        toast.error(error);
        localStorage.clear();
      } else toast.error(error);
      history.push('/auth/login');
    };
    dispatch(
      setUserList({
        success: (data) => success(data),
        failure: (error) => failure(error),
      })
    );
    dispatch(
      setArchivedUsersList({
        success: (data) => success(data),
        failure: (error) => failure(error),
      })
    );
  };

  const archiveMultipleUsersHandler = () => {
    if (selectedActiveUsers?.length > 0) {
      archiveMultipleUsers(selectedActiveUsers)
        .then(() => {
          setSelectedActiveUsers([]);
          setArchiveModal(false);
          toast.success('Users are successfully archived');
          refreshData();
        })
        .catch(() => {
          toast.success('Unable to archive users');
        });
    }
  };

  return (
    <>
      {areEmployeesBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : userList?.length > 0 ? (
        <>
          <Table
            className='align-items-center table-flush employee-table '
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='customCheck'
                      type='checkbox'
                      checked={
                        selectedActiveUsers?.length ===
                          userList?.filter(
                            ({ roles }) => !roles?.includes('OWNER')
                          )?.length && selectedActiveUsers?.length !== 0
                      }
                      onClick={(e) => {
                        setSelectedActiveUsers(
                          e?.target?.checked
                            ? userList
                                ?.filter(
                                  ({ roles }) => !roles?.includes('OWNER')
                                )
                                ?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                      disabled={
                        userList?.filter(({ roles }) =>
                          roles?.includes('OWNER')
                        )?.length > 0 && userList?.length === 1
                      }
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='customCheck'
                    ></label>
                  </div>
                </th>
                <th scope='col'>Name</th>
                <th scope='col'>Email</th>
                <th scope='col'>Roles</th>
                <th scope='col'>Joined</th>
                <th scope='col'>Experience</th>

                {mploUser?.roles?.includes('OWNER') ? <th scope='col' /> : ''}
              </tr>
            </thead>
            <tbody>
              {userList
                ?.filter(({ roles }) =>
                  mploUser?.roles?.includes('OWNER')
                    ? true
                    : !roles?.includes('OWNER')
                )
                ?.map(({ uid, name, roles, email, joined_on }) => (
                  <tr key={uid}>
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedActiveUsers?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedActiveUsers,
                              setArray: setSelectedActiveUsers,
                              value: uid,
                            })
                          }
                          disabled={roles?.includes('OWNER')}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>
                      {roles?.map((role, index) => (
                        <>
                          <span
                            className={`font-italic ${
                              roles?.includes('OWNER') && role !== 'EMPLOYEE'
                                ? 'disabled-text'
                                : ''
                            }`}
                          >
                            {`${role?.[0]?.toUpperCase()}${role
                              ?.substring(1)
                              ?.toLowerCase()}${
                              index + 1 < roles?.length ? ', ' : ''
                            }`}
                          </span>
                        </>
                      ))}
                    </td>

                    <td>{moment(joined_on).from(moment(currentTime))}</td>
                    <td>{getExperience(joined_on)}</td>
                    {mploUser?.roles?.includes('OWNER') ? (
                      <td>
                        <i
                          className='fas fa-users fa-lg user-theme-color'
                          onClick={() => {
                            dispatch(setEmployeeData({ uid, roles, name }));
                            dispatch(setEmployeeRoleModal(true));
                          }}
                        />
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div button'>There are no users yet</div>
      )}

      <CustomModal
        isOpen={archiveModal}
        toggle={() => setArchiveModal(!archiveModal)}
        headerText='Archive Users'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={archiveMultipleUsersHandler}
      >
        <p>Are you sure you want to archive selected users?</p>
      </CustomModal>

      <RolesModal />
    </>
  );
};

export default UserTable;
