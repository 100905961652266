import React, { useEffect } from 'react';
import { Form, Input } from 'reactstrap';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCheckinFromFilter,
  setCheckinToFilter,
  setCheckinViewMode,
} from 'redux/actions/checkin';
import {
  setFilterDatesInverse,
  setValidatedDateRange,
} from 'utils/helper-functions';
import { setFilterDates } from 'utils/helper-functions';

const CheckinFilter = () => {
  const dispatch = useDispatch();
  const { checkinFromFilter, checkinToFilter } = useSelector((state) => state);
  useEffect(() => {
    dispatch(setCheckinViewMode('THIS_WEEK'));
    setFilterDates(
      'THIS_WEEK',
      dispatch,
      setCheckinFromFilter,
      setCheckinToFilter
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Form className='needs-validation' noValidate>
        <div className='date-input-container right'>
          <div className='date-text'>From</div>
          <Input
            className='user-select-none'
            type='date'
            onChange={({ target: { value } }) => {
              dispatch(setCheckinFromFilter(value));
              setFilterDatesInverse(
                value,
                checkinToFilter,
                dispatch,
                setCheckinViewMode
              );
              setValidatedDateRange({
                date1: value,
                date2: checkinToFilter,
                setDate2: (date) => dispatch(setCheckinToFilter(date)),
              });
            }}
            name='date'
            id='fromDate'
            value={checkinFromFilter}
            placeholder='date placeholder'
          />
        </div>

        <div className='date-input-container right'>
          <div className='date-text'>To</div>
          <Input
            className='user-select-none'
            onChange={({ target: { value } }) => {
              dispatch(setCheckinToFilter(value));
              setFilterDatesInverse(
                checkinFromFilter,
                value,
                dispatch,
                setCheckinViewMode
              );
            }}
            type='date'
            name='date'
            value={checkinToFilter}
            min={checkinFromFilter}
            max={moment().format('YYYY-MM-DD')}
            id='toDate'
            placeholder='date placeholder'
          />
        </div>
      </Form>
    </>
  );
};

export default CheckinFilter;
