import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  unarchiveMultipleUsers,
  deleteMultipleUsers,
} from 'utils/api/api-requests/admin';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { Table, Spinner } from 'reactstrap';
import { updateStateArray } from 'utils/helper-functions';
import moment from 'moment';
import { toast } from 'react-toastify';
import './Table.scss';
import { useHistory } from 'react-router-dom';
import {
  setArchivedUsersList,
  setUserList,
} from '../../../../redux/actions/employee';
import { getExperience } from '../../../../utils/helper-functions';

const UserArchiveTable = ({
  selectedArchivedUsers,
  setSelectedArchivedUsers,
  setUnArchiveModal,
  unArchiveModal,
  setDeleteModal,
  deleteModal,
}) => {
  const { archivedUsersList } = useSelector((state) => state);

  const dispatch = useDispatch();
  const history = useHistory();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [areEmployeesBeingLoaded, setAreEmployeesBeingLoaded] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setAreEmployeesBeingLoaded(true);
    dispatch(
      setArchivedUsersList({
        success: (data) => {
          setAreEmployeesBeingLoaded(false);
        },
        failure: (error) => {
          setAreEmployeesBeingLoaded(false);
          if (error === 'token expired, please login again') {
            toast.error(error);
            localStorage.clear();
            history.push('/auth/login');
          } else toast.error('Unable to fetch user list');
        },
      })
    );

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refreshData = (dataType) => {
    const success = (data) => {
      setAreEmployeesBeingLoaded(false);
    };
    const failure = (error) => {
      setAreEmployeesBeingLoaded(false);
      if (error === 'token expired, please login again') {
        toast.error(error);
        localStorage.clear();
      } else toast.error(error);
      history.push('/auth/login');
    };
    if (dataType === 'archived_users') {
      dispatch(
        setArchivedUsersList({
          success: (data) => success(data),
          failure: (error) => failure(error),
        })
      );
    } else {
      dispatch(
        setUserList({
          success: (data) => success(data),
          failure: (error) => failure(error),
        })
      );
      dispatch(
        setArchivedUsersList({
          success: (data) => success(data),
          failure: (error) => failure(error),
        })
      );
    }
  };
  const unarchiveMultipleUsersHandler = () => {
    if (selectedArchivedUsers?.length > 0) {
      unarchiveMultipleUsers(selectedArchivedUsers)
        .then(() => {
          setSelectedArchivedUsers([]);
          setUnArchiveModal(false);
          toast.success('Users are successfully unarchived');
          refreshData();
        })
        .catch(() => {
          toast.success('Unable to unarchive users');
        });
    }
  };

  const deleteMultipleUsersHandler = () => {
    if (selectedArchivedUsers?.length > 0) {
      deleteMultipleUsers(selectedArchivedUsers)
        .then(() => {
          setSelectedArchivedUsers([]);
          setDeleteModal(false);
          toast.success('Users are successfully deleted');
          refreshData('archived_users');
        })
        .catch(() => {
          toast.success('Unable to delete users');
        });
    }
  };

  return (
    <>
      {areEmployeesBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : archivedUsersList?.length > 0 ? (
        <>
          <Table
            className='align-items-center table-flush employee-table archived'
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='archivedCustomCheck'
                      type='checkbox'
                      checked={
                        selectedArchivedUsers?.length ===
                        archivedUsersList?.length
                      }
                      onClick={() =>
                        setSelectedArchivedUsers(
                          selectedArchivedUsers?.length >= 0 &&
                            selectedArchivedUsers?.length <
                              archivedUsersList?.length
                            ? archivedUsersList?.map(({ uid }) => uid)
                            : []
                        )
                      }
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='archivedCustomCheck'
                    ></label>
                  </div>
                </th>
                <th scope='col'>Name</th>
                <th scope='col'>Email</th>
                <th scope='col'>Roles</th>
                <th scope='col'>Joined</th>
                <th scope='col'>Experience</th>
              </tr>
            </thead>
            <tbody>
              {archivedUsersList?.map(
                ({ uid, name, roles, email, joined_on }) => (
                  <tr key={uid}>
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedArchivedUsers?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedArchivedUsers,
                              setArray: setSelectedArchivedUsers,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>
                      {roles?.map((role, index) => (
                        <>
                          <span
                            className={`font-italic ${
                              roles?.includes('OWNER') && role !== 'EMPLOYEE'
                                ? 'disabled-text'
                                : ''
                            }`}
                          >
                            {`${role?.[0]?.toUpperCase()}${role
                              ?.substring(1)
                              ?.toLowerCase()}${
                              index + 1 < roles?.length ? ', ' : ''
                            }`}
                          </span>
                        </>
                      ))}
                    </td>
                    <td>{moment(joined_on).from(moment(currentTime))}</td>
                    <td>{getExperience(joined_on)}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div'>There are no archived users</div>
      )}

      <CustomModal
        isOpen={unArchiveModal}
        toggle={() => setUnArchiveModal(!unArchiveModal)}
        headerText='Unarchive Users'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={unarchiveMultipleUsersHandler}
      >
        <p>Are you sure you want to unarchive selected users?</p>
      </CustomModal>
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Users'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleUsersHandler}
      >
        <p>
          Deleting account will result in permanent deletion of all the user's
          data also, are you sure you want to delete selected users?
        </p>
      </CustomModal>
    </>
  );
};

export default UserArchiveTable;
