import React, { useEffect, useState } from 'react';
import './styles.scss';
const RetainerForm = ({
  retainerRows,
  setRetainerRows,
  removeRow,
  getProjectInfo,
}) => {
  const [currency, setCurrency] = useState();
  useEffect(() => {
    getProjectInfo().then((data) => setCurrency(data.currency));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <table className='retainer-table'>
        <thead>
          <tr>
            <th className='col-8'>Description</th>
            <th className='col-1'>Quantity</th>
            <th className='col-2'>Amount ({currency})</th>
            <th className='col-1'></th>
          </tr>
        </thead>
        <tbody>
          {retainerRows.map((row, index) => (
            <tr key={index}>
              <td className='col-8 px-2 py-1'>
                <input
                  className='form-control'
                  value={row.description}
                  onChange={(e) => {
                    const updatedRows = [...retainerRows];
                    updatedRows[index].description = e.target.value;
                    setRetainerRows(updatedRows);
                  }}
                />
                {!row.description && (
                  <span className='custom-invalid-feedback-text'>
                    Description is empty
                  </span>
                )}
                &nbsp;
              </td>
              <td className='col-1 px-2 py-1'>
                <input
                  className='form-control'
                  type='number'
                  value={row.quantity}
                  onChange={(e) => {
                    const updatedRows = [...retainerRows];
                    updatedRows[index].quantity = parseInt(e.target.value);
                    setRetainerRows(updatedRows);
                  }}
                />
                &nbsp;
              </td>
              <td className='col-2 px-2 py-1'>
                <input
                  className='form-control'
                  type='number'
                  value={row.amount}
                  onChange={(e) => {
                    const updatedRows = [...retainerRows];
                    updatedRows[index].amount = parseFloat(e.target.value);
                    setRetainerRows(updatedRows);
                  }}
                />
                {!row.amount && row.amount === 0 ? (
                  <span className='custom-invalid-feedback-text'>
                    Amount shouldn't be 0
                  </span>
                ) : !row.amount ? (
                  <span className='custom-invalid-feedback-text'>
                    Enter Amount
                  </span>
                ) : (
                  ''
                )}
                &nbsp;
              </td>
              <td className='col-1'>
                {index > 0 && (
                  <h6
                    className='heading-small text-muted user-theme-color cursor-pointer mb-0'
                    onClick={() => removeRow(index)}
                  >
                    Remove
                  </h6>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default RetainerForm;
