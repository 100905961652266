import { Field, FieldArray, Formik, getIn } from 'formik';
import React, { useState, useCallback } from 'react';
import {
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  Row,
  Table,
} from 'reactstrap';
import ErrorPage from '../../../../../../Common/ErrorPage/ErrorPage';
import TableHeader from './Header';
import revertActionIcon from 'assets/svg/revert-action.svg';
import revertActionDisabledIcon from 'assets/svg/revert-action-disabled.svg';
import { validationSchema, getInitialValues } from './Schema';
import TableFooter from './Footer';
import RetainerForm from '../RetainerForm/RetainerForm';
import { DEFAULT_DESCRIPTION } from '../../../../../../../constants';
import ErrorMessage from 'Components/Auth/Client/ErrorMessage';
const getSubTotal = (values, index, setFieldValue) => {
  const perHourRate = Number.isNaN(
    values?.associatedUsers?.[index]?.per_hour_rate
  )
    ? 0
    : values?.associatedUsers?.[index]?.per_hour_rate;
  const loggedHours = Number.isNaN(
    values?.associatedUsers[index]?.time_logged.hours
  )
    ? 0
    : values?.associatedUsers[index]?.time_logged.hours;
  const loggedMinutes = Number.isNaN(
    values?.associatedUsers[index]?.time_logged.minutes
  )
    ? 0
    : values?.associatedUsers[index]?.time_logged.minutes;
  const subTotal = (perHourRate * (loggedHours + loggedMinutes / 60)).toFixed(
    2
  );
  return subTotal;
};

const TableContent = ({
  serverError,
  users,
  projectName,
  projectHoursAllocated,
  getProjectInfo,
  retainer,
  retainerAmount,
}) => {
  const [didDownloadBtnClick, setDidDownloadBtnClick] = useState(false);
  const [grandTotal, setGrandTotal] = useState('');
  const [fixedPrice, setFixedPrice] = useState(retainerAmount);
  const [description, setDescription] = useState(DEFAULT_DESCRIPTION);
  const [rowCount, setRowCount] = useState(1);
  const [retainerRows, setRetainerRows] = useState([
    {
      description: DEFAULT_DESCRIPTION,
      quantity: 1,
      amount: retainerAmount ? retainerAmount : 0,
    },
  ]);

  const [haveSetDefaultHours, setHaveSetDefaultHours] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(() =>
    users?.map(({ uid }) => uid)
  );

  const toggleUser = (userId) => {
    if (selectedUsers?.includes(userId)) {
      setSelectedUsers(selectedUsers?.filter((uid) => uid !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const resetValue = (callback, key, index) => {
    const initialValues = getInitialValues(users);
    callback(
      `associatedUsers.${index}.${key}`,
      getIn(initialValues, `associatedUsers.${index}.${key}`, true)
    );
  };
  const resetSingleEmployee = (setFieldValue, index) => {
    resetValue(setFieldValue, 'name', index);
    resetValue(setFieldValue, 'per_hour_rate', index);
    resetValue(setFieldValue, 'time_logged.hours', index);
    resetValue(setFieldValue, 'time_logged.minutes', index);
  };

  const resetHoursToDefault = (setFieldValue, hours) => {
    setHaveSetDefaultHours(true);
    for (let index = 0; index < users?.length; index++) {
      setFieldValue(`associatedUsers.${index}.time_logged.hours`, hours);
      setFieldValue(`associatedUsers.${index}.time_logged.minutes`, 0);
    }
  };

  const resetRetainerInfo = () => {
    setRowCount(1);
    setRetainerRows([
      {
        description: DEFAULT_DESCRIPTION,
        quantity: 1,
        amount: retainerAmount ? retainerAmount : 0,
      },
    ]);
  };

  const setInitialValue = ({ key, position, setFieldValue }) => {
    setFieldValue(
      `associatedUsers.${position}.${key}`,
      getIn(getInitialValues(users), `associatedUsers.${position}.${key}`, true)
    );
  };

  const resetSelectedEmployees = (setFieldValue) => {
    const usersList = users?.map(({ uid }) => uid);
    setSelectedUsers(usersList);
    for (let index = 0; index < usersList?.length; index++) {
      let position = users?.map(({ uid }) => uid)?.indexOf(usersList[index]);
      setInitialValue({ key: 'name', position, setFieldValue });
      setInitialValue({ key: 'per_hour_rate', position, setFieldValue });
      setInitialValue({
        key: 'time_logged.hours',
        position,
        setFieldValue,
      });
      setInitialValue({
        key: 'time_logged.minutes',
        position,
        setFieldValue,
      });
      setInitialValue({ key: 'isChecked', position, setFieldValue });
    }
  };

  const computeGrandTotal = useCallback(
    (values) => {
      const filteredUsers = values?.associatedUsers?.filter(({ uid }) =>
        selectedUsers?.includes(uid)
      );
      return filteredUsers?.reduce(
        (a, b) => {
          const rate = Number.isNaN(b?.per_hour_rate) ? 0 : b?.per_hour_rate;
          const hours = Number.isNaN(b?.time_logged?.hours)
            ? 0
            : b?.time_logged?.hours;
          const minutes = Number.isNaN(b?.time_logged?.minutes)
            ? 0
            : b?.time_logged?.minutes;
          return a + Math.round(rate * (hours + minutes / 60), 2);
        },

        0
      );
    },
    [selectedUsers]
  );
  //
  const addNewRow = () => {
    setRetainerRows([
      ...retainerRows,
      { description: '', quantity: 1, amount: '' },
    ]);
    setRowCount(rowCount + 1);
  };
  const removeRow = (index) => {
    const updatedRows = [...retainerRows];
    updatedRows.splice(index, 1);
    setRetainerRows(updatedRows);
    rowCount !== 1 && setRowCount(rowCount - 1);
  };
  const computeGrandTotalRetainer = useCallback((retainerRows) => {
    let grandTotalRetainer = 0;

    for (const row of retainerRows) {
      if (row.amount && row.quantity) {
        grandTotalRetainer += parseFloat(row.amount) * parseInt(row.quantity);
      }
    }

    return grandTotalRetainer.toFixed(2);
  }, []);
  const areAllRetainerRowsFilled = () => {
    return retainerRows.every(
      (row) => row.description && row.quantity && row.amount
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={getInitialValues(users)}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, errors, touched, dirty, setFieldValue }) => {
        const getSelectedUsers = () =>
          values.associatedUsers.filter(({ isChecked }) => isChecked);

        const isAllSelected = () =>
          users?.length && getSelectedUsers().length === users?.length;

        const toggleAllSelection = () => {
          users.forEach((_, index) => {
            setFieldValue(
              `associatedUsers.${index}.isChecked`,
              users?.length !== getSelectedUsers()?.length
            );
            setSelectedUsers(
              selectedUsers?.length < users?.length
                ? users?.map(({ uid }) => uid)
                : []
            );
          });
        };
        const minutesToHour = ({ target: { value } }, index, setFieldValue) => {
          const regexNum = /^(\s*|\d+)$/;
          if (regexNum?.test(value?.toString())) {
            let minutes = parseInt(value);
            if (minutes >= 60) {
              const additionalHours = Math.floor(minutes / 60);
              minutes %= 60;
              setFieldValue(
                `associatedUsers.${index}.time_logged.hours`,
                (values?.associatedUsers[index]?.time_logged?.hours || 0) +
                  additionalHours
              );
            }
            setFieldValue(
              `associatedUsers.${index}.time_logged.minutes`,
              minutes
            );
          }
        };
        const tableHeaderProps = {
          serverError: serverError,
          hoursAllocated: projectHoursAllocated,
          resetHoursToDefault: (projectHoursAllocated) =>
            resetHoursToDefault(setFieldValue, projectHoursAllocated),
          getProjectInfo: getProjectInfo,
          isAllSelected: isAllSelected(),
          toggleAllSelection: toggleAllSelection,
        };
        return (
          <>
            <CardBody className='logs-card-body-container review-card-body'>
              {retainer ? (
                <RetainerForm
                  removeRow={removeRow}
                  retainerRows={retainerRows}
                  setRetainerRows={setRetainerRows}
                  getProjectInfo={getProjectInfo}
                />
              ) : (
                <FieldArray
                  name='associatedUsers'
                  render={() => (
                    <Table
                      className='align-items-center review-invoice-table'
                      responsive
                    >
                      <>
                        {serverError ? (
                          <div>
                            <TableHeader {...tableHeaderProps} />
                            <ErrorPage />
                          </div>
                        ) : (
                          <>
                            <TableHeader {...tableHeaderProps} />
                            <tbody>
                              {users?.map((user, index) => (
                                <tr key={user?.uid}>
                                  <td className='pl-pt-0'>
                                    <div className='custom-control custom-checkbox'>
                                      <Field
                                        className='custom-control-input '
                                        id={`clientCheckbox_${user?.uid}`}
                                        name={`associatedUsers.${index}.isChecked`}
                                        type='checkbox'
                                        checked={
                                          values.associatedUsers[index]
                                            .isChecked
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `associatedUsers.${index}.isChecked`,
                                            e.target.checked
                                          );
                                          toggleUser(user?.uid);
                                        }}
                                      />
                                      <label
                                        className='custom-control-label'
                                        htmlFor={`clientCheckbox_${user?.uid}`}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='mb--2'>
                                      <Field
                                        type='text'
                                        name={`associatedUsers.${index}.name`}
                                        className='form-control'
                                        disabled={
                                          !selectedUsers?.includes(user?.uid)
                                        }
                                      />
                                      <Field
                                        type='hidden'
                                        name={`associatedUsers.${index}.job_title`}
                                      />
                                      <div
                                        className={`custom-invalid-feedback-text
                      ${
                        (didDownloadBtnClick ||
                          touched?.associatedUsers?.[index]?.name) &&
                        errors?.associatedUsers?.[index]?.name
                          ? ''
                          : ' hidden-class'
                      }`}
                                      >
                                        <i className='fas fa-exclamation-circle' />{' '}
                                        <span>
                                          {
                                            errors?.associatedUsers?.[index]
                                              ?.name
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='mb--2'>
                                      <Field
                                        onWheel={(e) => e.target.blur()}
                                        type='number'
                                        min={0}
                                        name={`associatedUsers.${index}.per_hour_rate`}
                                        className='form-control'
                                        disabled={
                                          !selectedUsers?.includes(user?.uid)
                                        }
                                        onChange={({ target: { value } }) => {
                                          const regex = /^(\s*|\d+)$/;

                                          if (regex.test(value.toString())) {
                                            setFieldValue(
                                              `associatedUsers.${index}.per_hour_rate`,
                                              parseInt(value)
                                            );
                                          }
                                        }}
                                      />

                                      <div
                                        className={`custom-invalid-feedback-text
                      ${
                        (didDownloadBtnClick ||
                          touched?.associatedUsers?.[index]?.per_hour_rate) &&
                        errors?.associatedUsers?.[index]?.per_hour_rate
                          ? ''
                          : ' hidden-class'
                      }`}
                                      >
                                        <i className='fas fa-exclamation-circle' />{' '}
                                        <span>
                                          {
                                            errors?.associatedUsers?.[index]
                                              ?.per_hour_rate
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='mb--2'>
                                      <Row className='review-input'>
                                        <Col className='hours-logged-input'>
                                          <InputGroup>
                                            <Field
                                              onWheel={(e) => e.target.blur()}
                                              name={`associatedUsers.${index}.time_logged.hours`}
                                              className='form-control'
                                              type='number'
                                              min={0}
                                              disabled={
                                                !selectedUsers?.includes(
                                                  user?.uid
                                                )
                                              }
                                              onChange={({
                                                target: { value },
                                              }) => {
                                                const regex = /^(\s*|\d+)$/;

                                                if (
                                                  regex.test(value.toString())
                                                ) {
                                                  setFieldValue(
                                                    `associatedUsers.${index}.time_logged.hours`,
                                                    parseInt(value)
                                                  );
                                                }
                                              }}
                                            />
                                            <InputGroupAddon
                                              addonType='append'
                                              className={
                                                !selectedUsers?.includes(
                                                  user?.uid
                                                )
                                                  ? 'disabled-input-group-text'
                                                  : ''
                                              }
                                            >
                                              hrs
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </Col>
                                        <Col className='hours-logged-input'>
                                          <InputGroup>
                                            <Field
                                              onWheel={(e) => e.target.blur()}
                                              name={`associatedUsers.${index}.time_logged.minutes`}
                                              className='form-control'
                                              type='number'
                                              min={0}
                                              max={59}
                                              disabled={
                                                !selectedUsers?.includes(
                                                  user?.uid
                                                )
                                              }
                                              onBlur={(e) =>
                                                minutesToHour(
                                                  e,
                                                  index,
                                                  setFieldValue
                                                )
                                              }
                                            />
                                            <InputGroupAddon
                                              addonType='append'
                                              className={
                                                !selectedUsers?.includes(
                                                  user?.uid
                                                )
                                                  ? 'disabled-input-group-text'
                                                  : ''
                                              }
                                            >
                                              mins
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg='6' style={{ padding: 0 }}>
                                          <div
                                            className={`custom-invalid-feedback-text
                      ${
                        (didDownloadBtnClick ||
                          touched?.associatedUsers?.[index]?.time_logged
                            ?.hours) &&
                        errors?.associatedUsers?.[index]?.time_logged?.hours
                          ? ''
                          : ' hidden-class'
                      }`}
                                          >
                                            <i className='fas fa-exclamation-circle' />{' '}
                                            <span>
                                              {
                                                errors?.associatedUsers?.[index]
                                                  ?.time_logged?.hours
                                              }
                                            </span>
                                          </div>
                                        </Col>
                                        <Col lg='6'>
                                          <div
                                            className={`custom-invalid-feedback-text
                      ${
                        (didDownloadBtnClick ||
                          touched?.associatedUsers?.[index]?.time_logged
                            ?.minutes) &&
                        errors?.associatedUsers?.[index]?.time_logged?.minutes
                          ? ''
                          : ' hidden-class'
                      }`}
                                          >
                                            <i className='fas fa-exclamation-circle' />{' '}
                                            <span>
                                              {
                                                errors?.associatedUsers?.[index]
                                                  ?.time_logged?.minutes
                                              }
                                            </span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                  <td
                                    className={
                                      !selectedUsers?.includes(user?.uid)
                                        ? 'disabled-td'
                                        : ''
                                    }
                                  >
                                    {getSubTotal(values, index, setFieldValue)}
                                  </td>
                                  <td>
                                    <img
                                      onClick={() => {
                                        if (
                                          selectedUsers?.includes(user?.uid)
                                        ) {
                                          resetSingleEmployee(
                                            setFieldValue,
                                            index
                                          );
                                        }
                                      }}
                                      className='cursor-pointer'
                                      src={
                                        !selectedUsers?.includes(user?.uid)
                                          ? revertActionDisabledIcon
                                          : revertActionIcon
                                      }
                                      alt='revert'
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </>
                        )}
                      </>
                    </Table>
                  )}
                />
              )}
            </CardBody>
            {!serverError && (
              <TableFooter
                rowCount={rowCount}
                addNewRow={addNewRow}
                retainerRows={retainerRows}
                areAllRetainerRowsFilled={areAllRetainerRowsFilled}
                dirty={dirty}
                setFieldValue={setFieldValue}
                selectedUsers={selectedUsers}
                values={values}
                errors={errors}
                setDidDownloadBtnClick={setDidDownloadBtnClick}
                users={users}
                projectName={projectName}
                haveSetDefaultHours={haveSetDefaultHours}
                setHaveSetDefaultHours={setHaveSetDefaultHours}
                retainer={retainer}
                fixedPrice={fixedPrice}
                resetRetainerInfo={resetRetainerInfo}
                description={description}
                grandTotal={grandTotal}
                setGrandTotal={setGrandTotal}
                retainerAmount={retainerAmount}
                resetSelectedEmployees={resetSelectedEmployees}
                computeGrandTotal={computeGrandTotal}
                computeGrandTotalRetainer={computeGrandTotalRetainer}
                getProjectInfo={getProjectInfo}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default TableContent;
