import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardHeader, Col, Container, Row, Spinner } from 'reactstrap';
import { getManagerProjects } from '../../../../utils/api/api-requests/user';
import Header from '../../../Headers/User/User';
import './Manage.scss';

const Manage = () => {
  const [managerProjects, setManagerProjects] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setIsDataLoading(true);

    getManagerProjects()
      .then(({ data }) => {
        setManagerProjects(data);
        setIsDataLoading(false);
      })
      .catch(() => {
        setManagerProjects([]);
        setIsDataLoading(false);
      });
  }, []);
  return (
    <div>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              <CardHeader className='border-0 projects-header'>
                <Row className='align-items-center'>
                  <div className='col'>
                    <h3 className='mb-0 title project__mange--title'>
                      Projects
                    </h3>
                  </div>
                </Row>
              </CardHeader>
              <div className='project-container'>
                {isDataLoading ? (
                  <div className='text-center data-loading-spinner'>
                    <Spinner color='user' size='lg' />
                  </div>
                ) : managerProjects?.length > 0 ? (
                  <div className='project-list user'>
                    {managerProjects?.map(({ uid, name }) => (
                      <NavLink
                        key={uid}
                        to={`/datumbrain/user/projects/${uid}/activity`}
                      >
                        {name}
                      </NavLink>
                    ))}
                  </div>
                ) : (
                  <div className='no-data-card'>
                    You are currently not managing any project
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Manage;
