import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
const ProjectsDropdown = ({ setSelectedProject, projects }) => {
  const [isOpen, setIsOpen] = useState(true);
  const onProjectSelect = (project) => {
    setSelectedProject(project);
    setIsOpen(false);
  };

  const selectProject = (project) => {
    onProjectSelect({
      id: project?.uid,
      name: project?.name,
      tag: project?.tag,
    });
  };

  return (
    <div>
      <Dropdown
        className='projects-dropdown'
        isOpen={isOpen}
        toggle={() => setIsOpen((prev) => !prev)}
      >
        <DropdownToggle />
        <DropdownMenu className='projects-dropdown-menu'>
          {projects?.map((project) => (
            <DropdownItem
              key={project?.uid}
              onClick={() => selectProject(project)}
            >
              #{project?.name}{' '}
              <span className='projects-dropdown-item'>({project?.tag})</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default ProjectsDropdown;
