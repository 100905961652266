import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { getAdminUsersActivity } from 'utils/api/api-requests/admin';
import ActivityCard from '../ActivityCard/ActivityCard';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const AllUsersActivity = ({ fromDate, toDate }) => {
  const [usersActivityLogs, setUsersActivityLogs] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setIsDataLoading(true);
    getAdminUsersActivity(fromDate, toDate)
      .then(({ data }) => {
        setUsersActivityLogs(data);
        setIsDataLoading(false);
      })
      .catch((error) => {
        setIsDataLoading(false);
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast?.error('Unable to fetch activity list.');
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);
  return isDataLoading ? (
    <div className='p-5 text-center'>
      <Spinner color='success' size='lg' />
    </div>
  ) : (
    <div className='activity-list'>
      {usersActivityLogs?.length > 0 ? (
        usersActivityLogs?.map((log) => <ActivityCard log={log} />)
      ) : (
        <div className='no-activity-data'>No activity found</div>
      )}
    </div>
  );
};

export default AllUsersActivity;
