import './styles.scss';

import dropdown from '../../../../assets/svg/dropdown.svg';
import dropArrow from '../../../../assets/svg/dropArrow.svg';
import search from '../../../../assets/svg/search.svg';

import { useState, useEffect } from 'react';

import { getProjects } from 'utils/api/api-requests/admin';

import { useDispatch, useSelector } from 'react-redux';
import { setPageNumber } from 'redux/actions/pagination';
import { setSelectedProjectId } from 'redux/actions/projects';
import { setCurrentPagesChunk } from 'redux/actions/userStatus';

import { toast } from 'react-toastify';

const DropDown = ({ paginationCount }) => {
  const selectedProjectId = useSelector((state) => state?.selectedProjectId);
  const dispatch = useDispatch();

  const [showContent, setShowContent] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState('All');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getProjects()
      .then(({ data }) => {
        setProjects(data);
        if (selectedProjectId) {
          setProjectName(
            data?.find((project) => project?.uid === selectedProjectId)?.name
          );
        }
      })
      .catch((err) => {
        toast.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  const handleMenu = () => setShowContent((current) => !current);
  let projectNamesArr = [
    { uid: null, name: 'All ' },
    ...projects?.filter((project) =>
      project?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
    ),
  ];

  const selectProject = (project) => {
    dispatch(setPageNumber(1));
    dispatch(setCurrentPagesChunk(paginationCount >= 3 ? [1, 2, 3] : [1, 2]));
    dispatch(setSelectedProjectId(project?.uid));
    setProjectName(project?.name);

    handleMenu();
  };

  return (
    <>
      <div className='dropdown'>
        <button className='dropdown__btn' onClick={handleMenu}>
          <img src={dropdown} alt='dropdown' />
          <span>{projectName}</span>
          <img src={dropArrow} alt='dropdown arrow' />
        </button>
        <div
          className={
            showContent ? 'dropdown__content show' : 'dropdown__content'
          }
        >
          <div className='dropdown__search'>
            <img src={search} alt='search' />
            <input
              type='text'
              placeholder='Search project'
              onChange={(e) => setSearchValue(e?.target?.value)}
            />
          </div>
          <div className='dropdown__projects'>
            {projectNamesArr?.map((project) => (
              <div
                onClick={() => selectProject(project)}
                className='dropdown__projects--project'
              >
                {project?.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default DropDown;
