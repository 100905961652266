import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

const ReviewInvoice = () => {
  const history = useHistory();
  const { projectId } = useParams();
  const { fromDateFilter, toDateFilter } = useSelector((state) => state);

  const reviewInvoice = () => {
    history.push(`/datumbrain/admin/projects/${projectId}/invoice/review`);
  };
  return (
    <div
      className={`custom-action-card ${
        fromDateFilter && toDateFilter ? '' : 'disabled-button'
      } `}
      onClick={reviewInvoice}
    >
      <img
        className='cursor-pointer'
        alt='...'
        src={require('assets/svg/download.svg').default}
      />
      <span>Review</span>
    </div>
  );
};

export default ReviewInvoice;
