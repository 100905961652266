import { API_URL } from '../api-constants';
export const IS_OWNER_URL = `${API_URL}/owner/is_owner`;
export const DOWNLOAD_INVOICE_URL = ({
  projectId,
  from,
  to,
  projectType,
  approved,
}) =>
  `${API_URL}/owner/project/${projectId}/invoice/download?from=${from}&to=${to}&approved=${approved}&project_type=${projectType}`;
export const REVIEW_INVOICE_URL = ({ projectId, from, to }) =>
  `${API_URL}/owner/project/${projectId}/invoice/review?from=${from}&to=${to}`;
export const DOWNLOAD_REVIEWED_INVOICE = ({
  projectId,
  from,
  to,
  projectType,
}) => `${API_URL}/owner/project/${projectId}/invoice/review/download?from=${from}&to=${to}&project_type=${projectType}
`;
