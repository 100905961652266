export const currentUserRole = (userRole = '', { type, payload }) =>
  type === 'SET_CURRENT_USER_ROLE' ? payload : userRole;
export const hasForgotPassword = (password = true, { type, payload }) =>
  type === 'SET_HAS_FORGOT_PASSWORD' ? payload : password;
export const mploUser = (mploUser = {}, { type, payload }) =>
  type === 'SET_MPLO_USER' ? payload : mploUser;
export const workspaceAlias = (
  workspaceAlias = localStorage.getItem('workspace'),
  { type, payload }
) => (type === 'SET_WORKSPACE_ALIAS' ? payload : workspaceAlias);
export const newWorkspaceObject = (
  workspaceObject = { name: '', alias: '' },
  { type, payload }
) => (type === 'SET_WORKSPACE_OBJECT' ? payload : workspaceObject);
export const workspaceModal = (modal = false, { type, payload }) =>
  type === 'SET_WORKSPACE_MODAL' ? payload : modal;
export const editUserInfoFlag = (userInfoFlag = false, { type, payload }) =>
  type === 'SET_USER_INFO_FLAG' ? payload : userInfoFlag;
export const employeeLeaveModal = (leaveModal = false, { type, payload }) =>
  type === 'SET_EMPLOYEE_LEAVE_MODAL' ? payload : leaveModal;
