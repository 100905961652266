export const employeeDataHr = (employeeData = '', { type, payload }) =>
  type === 'SET_EMPLOYEE_DATA_HR' ? payload : employeeData;

export const employeeEmergencyContactInfo = (
  employeeEmergencyContactInfo = '',
  { type, payload }
) =>
  type === 'SET_EMPLOYEE_EMERGENCY_CONTACT_INFO'
    ? payload
    : employeeEmergencyContactInfo;
export const employeeBankInfo = (employeeBankInfo = '', { type, payload }) =>
  type === 'SET_EMPLOYEE_BANK_INFO' ? payload : employeeBankInfo;
export const employeeRoleCustomSelectArray = (
  employeeRole = [],
  { type, payload }
) =>
  type === 'SET_EMPLOYEE_ROLE_CUSTOM_SELECT_ARRAY' ? payload : employeeRole;

export const userList = (userList = [], { type, payload }) =>
  type === 'SET_USER_LIST' ? payload : userList;

export const archivedUsersList = (userList = [], { type, payload }) =>
  type === 'SET_ARCHIVED_USERS_LIST' ? payload : userList;
export const welcomeStatus = (status = false, { type, payload }) =>
  type === 'SET_WELCOME_STATUS' ? payload : status;

export const employeeData = (employeeData = '', { type, payload }) =>
  type === 'SET_EMPLOYEE_DATA' ? payload : employeeData;

export const profileImage = (profileImage = '', { type, payload }) =>
  type === 'SET_PROFILE_IMAGE' ? payload : profileImage;

export const leaveEditModal = (modal = '', { type, payload }) =>
  type === 'SET_LEAVE_EDIT_MODAL' ? payload : modal;

export const leaveDeleteModal = (modal = '', { type, payload }) =>
  type === 'SET_LEAVE_DELETE_MODAL' ? payload : modal;

export const remainingMedicalLeaves = (flag = '', { type, payload }) =>
  type === 'SET_REMAINING_MEDICAL_LEAVES' ? payload : flag;

export const remainingCasualLeaves = (flag = '', { type, payload }) =>
  type === 'SET_CASUAL_LEAVE_LIMIT' ? payload : flag;
