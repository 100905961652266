import React, { useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import '../styles.scss';
import { validate } from 'email-validator';
import { toast } from 'react-toastify';
import ErrorMessage from '../ErrorMessage';
import { clientLogin } from '../../../../utils/api/api-requests/auth';
import { useHistory } from 'react-router-dom';

const Login = () => {
  const history = useHistory();
  const [clientEmail, setClientEmail] = useState('');
  const [clientPassword, setClientPassword] = useState('');
  const [didSignInBtnClick, setDidSignInBtnClick] = useState(false);
  useEffect(() => {
    setClientEmail(localStorage.getItem('email'));
  }, []);
  const handleEnterPress = (e) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      onLogin();
    }
  };
  const onLogin = () => {
    setDidSignInBtnClick(true);
    if (validate(clientEmail) && clientPassword) {
      setDidSignInBtnClick(false);
      clientLogin({
        email: clientEmail,
        password: clientPassword,
      })
        .then(({ data, headers }) => {
          localStorage.clear();
          localStorage.setItem('jwt-token', headers?.['jwt-token']);
          localStorage.setItem('client', data?.uid);
          localStorage.setItem('client-name', data?.name);
          localStorage.setItem('currentUserRole', 'client');
          toast.success('Client logged in successfully.');
          history.push('/client/dashboard');
        })
        .catch(() => {
          toast.error('Unable to log in.');
        });
    }
  };
  return (
    <Form autoComplete='off'>
      <CustomFormGroup
        type='text'
        name='clientEmail'
        id='clientEmail'
        placeholder='Email'
        value={clientEmail}
        setValue={setClientEmail}
        iconClassName='far fa-envelope fa-lg'
        layout='admin'
        handleEnterPress={handleEnterPress}
      />
      <ErrorMessage
        isInvalid={didSignInBtnClick && !validate(clientEmail)}
        message='Enter a valid email address'
      />
      <CustomFormGroup
        type='password'
        name='clientPassword'
        id='clientPassword'
        placeholder='Password'
        value={clientPassword}
        setValue={setClientPassword}
        iconClassName='fas fa-key fa-lock'
        layout='admin'
        handleEnterPress={handleEnterPress}
      />
      <ErrorMessage
        isInvalid={didSignInBtnClick && !clientPassword}
        message='Password is empty'
      />

      <div onClick={onLogin} className='log-time-btn client-signup-btn'>
        Login
      </div>
      <div
        className='client-forgot-password'
        onClick={() => history.push('/auth/client/forgot-password')}
      >
        Forgot Password?
      </div>
    </Form>
  );
};

export default Login;
