export const setCheckinFromFilter = (checkinFromFilter) => ({
  type: 'SET_CHECKIN_FROM_FILTER',
  payload: checkinFromFilter,
});

export const setCheckinToFilter = (checkinToFilter) => ({
  type: 'SET_CHECKIN_TO_FILTER',
  payload: checkinToFilter,
});

export const setCheckinViewMode = (checkinViewMode) => ({
  type: 'SET_CHECKIN_VIEW_MODE',
  payload: checkinViewMode,
});

export const setCheckinTime = (checkinTime = '') => ({
  type: 'SET_CHECKIN_TIME',
  payload: checkinTime,
});

export const setCheckoutTime = (checkoutTime = '') => ({
  type: 'SET_CHECKOUT_TIME',
  payload: checkoutTime,
});
