import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AdminHeader from 'Components/Headers/Admin/Admin';
import UserHeader from '../../Headers/User/User';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Spinner,
} from 'reactstrap';
import { getUser } from 'utils/api/api-requests/admin';
const UserDetails = () => {
  const { userId } = useParams();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [didUserLoad, setDidUserLoad] = useState(false);
  useEffect(() => {
    getUser(userId)
      .then(({ data }) => {
        setDidUserLoad(true);
        setUser(data);
      })
      .catch(() => {
        setDidUserLoad(false);
        setUser(null);
      });
  }, [userId]);
  const renderClientAttribute = (key, value) => (
    <div className='client-attribute'>
      <div className='client-key'>{key}</div>
      <div className='client-value'>{value}</div>
    </div>
  );
  return (
    <div>
      {localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin' ? (
        <AdminHeader />
      ) : (
        <UserHeader />
      )}{' '}
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              {!didUserLoad && !user ? (
                <div className='not-found-message'>User not found.</div>
              ) : !didUserLoad ? (
                <div className='logs-spinner no-header'>
                  <Spinner color='admin' />
                </div>
              ) : (
                <>
                  <CardHeader className='border-0 details-card-header'>
                    <Row className='align-items-center'>
                      <div className='col custom-breadcrumb-admin'>
                        <span
                          onClick={() =>
                            history.push(`/datumbrain/admin/users`)
                          }
                        >
                          Employees
                        </span>
                        {' / '}
                        {user?.name}
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className='details-card-body'>
                    <div className='client-details'>
                      {renderClientAttribute('name', user?.name)}

                      {user?.email &&
                        renderClientAttribute('email', user?.email)}
                      {user?.secondary_email &&
                        renderClientAttribute(
                          'secondary email',
                          user?.secondary_email
                        )}
                      {user?.contact_number &&
                        renderClientAttribute(
                          'contact number',
                          user?.contact_number
                        )}
                      {user?.secondary_contact_number &&
                        renderClientAttribute(
                          'secondary contact number',
                          user?.secondary_contact_number
                        )}
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserDetails;
