import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import './ReviewForm.scss';

import TableContent from './Table/Table';
const ReviewForm = ({
  serverError,
  associatedUsers,
  isFetchingData,
  projectName,
  projectHoursAllocated,
  getProjectInfo,
  retainer,
  retainerAmount,
}) => {
  const [users, setUsers] = useState(associatedUsers);
  const [selectedUsers, setSelectedUsers] = useState(
    associatedUsers?.map(({ uid }) => uid)
  );

  useEffect(() => {
    setUsers(associatedUsers);
    setSelectedUsers(associatedUsers?.map(({ uid }) => uid));
  }, [associatedUsers]);

  return (
    <>
      {isFetchingData ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : (
        <TableContent
          serverError={serverError}
          users={users}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          projectName={projectName}
          projectHoursAllocated={projectHoursAllocated}
          getProjectInfo={getProjectInfo}
          retainer={retainer}
          retainerAmount={retainerAmount}
        />
      )}
    </>
  );
};

export default ReviewForm;
