import {
  DOWNLOAD_INVOICE_URL,
  DOWNLOAD_REVIEWED_INVOICE,
  IS_OWNER_URL,
  REVIEW_INVOICE_URL,
} from '../api-urls/owner';
import { makeAPICall } from '../api-utils';
export const isOwner = () =>
  makeAPICall({
    method: 'GET',
    url: IS_OWNER_URL,
    authorizedRequest: true,
  });
export const downloadInvoice = (payload) =>
  makeAPICall({
    method: 'GET',
    url: DOWNLOAD_INVOICE_URL(payload),
    authorizedRequest: true,
  });
export const reviewInvoice = (payload) =>
  makeAPICall({
    method: 'GET',
    url: REVIEW_INVOICE_URL(payload),
    authorizedRequest: true,
  });
export const downloadReviewedInvoice = (params, body) =>
  makeAPICall({
    method: 'POST',
    url: DOWNLOAD_REVIEWED_INVOICE(params),
    authorizedRequest: true,
    data: body,
  });
