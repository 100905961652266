import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { setEmployeeLeaveModal } from 'redux/actions/general';

import CustomModal from 'Components/Common/CustomModal/CustomModal';
import FormGroupWithLabel from 'Components/Common/FormGroupWithLabel/FormGroupWithLabel';
import { toast } from 'react-toastify';
import { applyLeave } from 'utils/api/api-requests/user';
import DayPicker from 'react-day-picker';
import './LeaveModal.scss';
import { makeCustomLeaveDataUser } from 'utils/helper-functions';
import { getUserLeaves } from 'utils/api/api-requests/user';

const LeaveModal = () => {
  const dispatch = useDispatch();
  const { employeeLeaveModal, remainingCasualLeaves, remainingMedicalLeaves } =
    useSelector((state) => state);
  const [leaveData, setleaveData] = useState([]);
  const [leaveDates, setLeaveDates] = useState([]);
  const [leaveObject, setLeaveObject] = useState({
    leaveType: '',
    leaveReason: '',
  });
  const [didSubmitButtonClicked, setDidSubmitButtonClicked] = useState(false);

  useEffect(() => {
    getUserLeaves()
      .then(({ data }) => setleaveData(makeCustomLeaveDataUser(data)))
      .catch(() => toast.error('Failed to get data'));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveDates, employeeLeaveModal]);

  const validateData = () =>
    leaveObject?.leaveType && leaveObject.leaveReason && leaveDates?.length > 0;

  const applyLeaveHandler = () => {
    setDidSubmitButtonClicked(true);
    if (validateData())
      applyLeave({
        dates: leaveDates,
        description: leaveObject?.leaveReason,
        leave_type: leaveObject?.leaveType?.toUpperCase(),
      })
        .then(() => {
          toast.success('applied successfully');
          dispatch(setEmployeeLeaveModal(false));
          setDidSubmitButtonClicked(false);
          setLeaveObject({
            leaveType: '',
            leaveReason: '',
          });
          setLeaveDates([]);
        })
        .catch(() => {
          toast.error('failed successfully');
          dispatch(setEmployeeLeaveModal(false));
          setDidSubmitButtonClicked(false);
          setLeaveObject({
            leaveType: '',
            leaveReason: '',
          });
          setLeaveDates([]);
        });
  };
  const setLeaveDatesHandler = (date) => {
    let momentDate = moment(date).format('YYYY-MM-DD').concat('T00:00:00Z');
    if (
      leaveDates?.findIndex((leaveDate) =>
        moment(leaveDate).isSame(momentDate)
      ) > -1
    ) {
      setLeaveDates(
        leaveDates?.filter((leaveDate) => !moment(leaveDate).isSame(momentDate))
      );
    } else {
      setLeaveDates([...leaveDates, momentDate]);
    }
  };
  const leaveModalToggler = () => {
    setDidSubmitButtonClicked(false);
    setLeaveObject({
      leaveType: '',
      leaveReason: '',
    });
    setLeaveDates([]);
    dispatch(setEmployeeLeaveModal(!employeeLeaveModal));
  };
  const modifiers = {
    applied: (day) =>
      leaveData
        ?.map(({ date }) => date)
        ?.includes(moment(day).format('YYYY-MM-DD').concat('T00:00:00Z')),
    selected: (day) =>
      leaveDates
        ?.map((date) => date)
        ?.includes(moment(day).format('YYYY-MM-DD').concat('T00:00:00Z')),
  };
  const selectBoxArray = [
    { uid: 'MEDICAL', name: 'Medical' },
    { uid: 'CASUAL', name: 'Casual' },
  ];
  return (
    <>
      <CustomModal
        isOpen={employeeLeaveModal}
        toggle={leaveModalToggler}
        headerText='Add Leave'
        cancelButtonText='Cancel'
        confirmButtonText='Submit'
        confirmFunction={applyLeaveHandler}
      >
        <FormGroupWithLabel
          labelName='Leave type'
          id='input-leave-type'
          placeholder='Leave type'
          type='select'
          setValue={(value) =>
            setLeaveObject({ ...leaveObject, leaveType: value })
          }
          value={leaveObject?.leaveType}
          didSubmitButtonClick={didSubmitButtonClicked}
          name='leave-type'
          invalidText='Please specify a leave type'
          required
          selectBoxText='Select leave type'
          selectBoxArray={selectBoxArray}
        />
        <div className='text-center'>
          {remainingCasualLeaves < 1 ? (
            <div className='custom-alert-msg'>
              <i className='fas fa-exclamation-circle' />
              <span>
                You have reached maximum allowed limit for Casual leaves.
              </span>
            </div>
          ) : (
            ''
          )}
          {remainingMedicalLeaves < 1 ? (
            <div className='custom-alert-msg'>
              <i className='fas fa-exclamation-circle' />
              <span>
                You have reached maximum allowed limit for Medical leaves.
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='leave-date-picker'>
          <DayPicker
            fromMonth={moment().toDate()}
            toMonth={moment().add(1, 'months').toDate()}
            selectedDays={leaveDates?.map((date) => moment(date).toDate())}
            onDayClick={(e) => setLeaveDatesHandler(e)}
            pagedNavigation
            firstDayOfWeek={1}
            disabledDays={(e) =>
              [0, 6]?.includes(moment(e).day()) ||
              moment().subtract(1, 'days').isAfter(moment(e))
            }
            modifiers={modifiers}
          />
          <div
            className={`invalid-date-message ${
              didSubmitButtonClicked && leaveDates?.length < 1
                ? ''
                : 'hidden-class'
            }`}
          >
            <i className='fas fa-exclamation-circle' />
            <span>Please specify valid dates for leave</span>
          </div>
        </div>
        <FormGroupWithLabel
          labelName='Leave reason'
          id='input-leave-remaining-leaves'
          placeholder='Leave reason'
          type='textarea'
          setValue={(value) => {
            setLeaveObject({ ...leaveObject, leaveReason: value });
          }}
          value={leaveObject?.leaveReason}
          didSubmitButtonClick={didSubmitButtonClicked}
          name='leave-reason'
          invalidText='Please specify a reason for leave'
          required
        />
      </CustomModal>
    </>
  );
};

export default LeaveModal;
