import React, { useState } from 'react';
import { Form } from 'reactstrap';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import '../styles.scss';
import { clientResetPassword } from '../../../../utils/api/api-requests/auth';
import { toast } from 'react-toastify';
import ErrorMessage from '../ErrorMessage';
import { useHistory } from 'react-router';

const ResetPassword = () => {
  const history = useHistory();
  const token = new URLSearchParams(document.location.search).get('token');
  const [clientPassword, setClientPassword] = useState('');
  const [clientConfirmPassword, setClientConfirmPassword] = useState('');
  const [didResetBtnClick, setDidResetBtnClick] = useState(false);

  const handleEnterPress = (e) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      onResetPassword();
    }
  };
  const onResetPassword = () => {
    setDidResetBtnClick(true);
    if (
      clientPassword &&
      clientPassword?.length >= 8 &&
      clientPassword === clientConfirmPassword
    ) {
      setDidResetBtnClick(false);
      clientResetPassword({
        email: localStorage.getItem('email'),
        password: clientPassword,
        token,
      })
        .then(() => {
          toast.success('Password reset successfully.');
          history.push('/auth/client/login');
        })
        .catch(() => toast.error('Unable to reset password.'));
    }
  };
  return (
    <Form>
      <CustomFormGroup
        type='password'
        name='clientPassword'
        id='clientPassword'
        placeholder='New Password'
        value={clientPassword}
        setValue={setClientPassword}
        iconClassName='fas fa-key fa-lock'
        layout='admin'
        handleEnterPress={handleEnterPress}
      />
      <ErrorMessage
        isInvalid={didResetBtnClick && clientPassword?.length < 8}
        message='Password should be at least 8 characters long'
      />
      <CustomFormGroup
        type='password'
        name='clientConfirmPassword'
        id='clientConfirmPassword'
        placeholder='Confirm New Password'
        value={clientConfirmPassword}
        setValue={setClientConfirmPassword}
        iconClassName='fas fa-key fa-lock'
        layout='admin'
        handleEnterPress={handleEnterPress}
      />
      <ErrorMessage
        isInvalid={didResetBtnClick && clientPassword !== clientConfirmPassword}
        message='Passwords do not match'
      />

      <div onClick={onResetPassword} className='log-time-btn client-signup-btn'>
        Reset
      </div>
    </Form>
  );
};

export default ResetPassword;
