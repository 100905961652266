import { useState } from 'react';
import { Spinner, Table } from 'reactstrap';

import { updateStateArray } from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import {
  archiveMultipleInvoices,
  reDownloadInvoice,
} from 'utils/api/api-requests/admin';

import { toast } from 'react-toastify';
import DownloadSvg from 'assets/svg/bx_bx-export-disabled.svg';
import DownloadSvgActive from 'assets/svg/bx_bx-export.svg';
import './table.css';

const handleDownload = (uid) => {
  reDownloadInvoice(uid)
    .then((response) => {
      window.open(response.data, '_blank').focus();
    })
    .catch((error) => {
      console.error('Error while downloading:', error);
    });
};

const InvoicesTable = ({
  invoices,
  areInvoicesBeingLoaded,
  setSelectedInvoices,
  selectedInvoices,
  archiveModal,
  setArchiveModal,
  billChecked,
  setInvoices,
  loadInvoices,
}) => {
  const [isHoveredId, setIsHoveredId] = useState(null);
  const archiveMultipleInvoicesHandler = () => {
    if (selectedInvoices?.length > 0) {
      archiveMultipleInvoices(selectedInvoices)
        .then(() => {
          setArchiveModal(false);
          setSelectedInvoices([]);
          toast.success('Invoices archived successfully');
        })
        .then(() => {
          // Update the table data
          loadInvoices();
        })
        .catch(() => {
          toast?.error('Unable to fetch invoices list.');
        });
    }
  };

  return (
    <>
      {areInvoicesBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : invoices?.length > 0 ? (
        <>
          <Table
            className='align-items-center table-flush invoice-table'
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='invoicesCheckBox'
                      type='checkbox'
                      checked={
                        selectedInvoices?.length === invoices?.length &&
                        selectedInvoices?.length !== 0
                      }
                      onClick={(e) => {
                        setSelectedInvoices(
                          e?.target?.checked
                            ? invoices?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='invoicesCheckBox'
                    ></label>
                  </div>
                </th>
                <th scope='col'>SR#</th>
                <th scope='col'>Name</th>
                <th scope='col'>Date Generated</th>
                <th scope='col'>Date Range</th>
                <th scope='col'>Type</th>
                <th scope='col'>Download</th>
                <th scope='col'>Billed</th>
              </tr>
            </thead>
            <tbody>
              {invoices?.map(
                ({
                  uid,
                  serial_number,
                  name,
                  date_generated,
                  invoice_range,
                  type,
                  is_billed,
                }) => (
                  <tr key={uid}>
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedInvoices?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedInvoices,
                              setArray: setSelectedInvoices,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td> {serial_number ? serial_number : '-'}</td>
                    <td>{name ? name : '-'}</td>
                    <td>{date_generated ? date_generated : '-'}</td>
                    <td>
                      {invoice_range?.from} - {invoice_range?.to}
                    </td>{' '}
                    <td>{type ? type : '-'}</td>
                    <td>
                      {' '}
                      <div className='d-flex justify-content-center'>
                        <span
                          className='custom-link-class user-theme-color cursor-pointer'
                          onMouseEnter={() => setIsHoveredId(uid)}
                          onMouseLeave={() => setIsHoveredId(null)}
                          onClick={() => handleDownload(uid)}
                        >
                          <img
                            src={
                              isHoveredId === uid
                                ? DownloadSvgActive
                                : DownloadSvg
                            }
                            width={19}
                            alt='Download invoice'
                          />
                        </span>
                      </div>
                    </td>
                    <td className='pl-pt-0 '>
                      <div className='custom-control custom-checkbox user'>
                        <input
                          className='custom-control-input'
                          id={`billed-${uid}`}
                          type='checkbox'
                          checked={is_billed}
                          onChange={(e) => {
                            const isChecked = e.target.checked;

                            setInvoices((prevInvoices) =>
                              prevInvoices.map((invoice) =>
                                invoice.uid === uid
                                  ? { ...invoice, is_billed: isChecked }
                                  : invoice
                              )
                            );
                            billChecked(uid, isChecked);
                          }}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={`billed-${uid}`}
                        />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div button'>There are no Invoices yet</div>
      )}
      <CustomModal
        isOpen={archiveModal}
        toggle={() => setArchiveModal(!archiveModal)}
        headerText='Archive Invoices'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={archiveMultipleInvoicesHandler}
      >
        <p>{'Are you sure you want to archive selected Invoices?'}</p>
      </CustomModal>
    </>
  );
};

export default InvoicesTable;
