import React from 'react';
import alertTriangle from 'assets/svg/alert-triangle.svg';
import './styles.scss';
const ErrorPage = () => {
  return (
    <div className='alert-triangle'>
      <img src={alertTriangle} alt='alert' />
      <div className='main-text'>Uh oh!</div>
      <div className='description'>
        Something went wrong. We’re trying to fix the problem
      </div>
    </div>
  );
};

export default ErrorPage;
