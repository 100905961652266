import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Table,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Spinner,
} from 'reactstrap';

import moment from 'moment';
import { toast } from 'react-toastify';

import {
  addTrackingLog,
  getTrackingLogs,
  getUserDashboard,
  attendanceRequest,
} from 'utils/api/api-requests/user';
import { getWeekdays, toggleName } from 'utils/helper-functions';

import { setTrackingLogs } from 'redux/actions/logs';
import { setProjectsList } from 'redux/actions/projects';
import { setCheckinTime, setCheckoutTime } from 'redux/actions/checkin';

import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import LogTimer from 'Components/Common/LogTimer/LogTimer';
import CustomDay from 'Components/Common/CustomDay/CustomDay';

const User = ({ dashboardLoading, setDashboardLoading }) => {
  const projectsList = useSelector(({ projectsList }) => projectsList);
  const checkinTime = useSelector(({ checkinTime }) => checkinTime);
  const checkoutTime = useSelector(({ checkoutTime }) => checkoutTime);
  const trackingLogs = useSelector(({ trackingLogs }) => trackingLogs);
  const dispatch = useDispatch();
  const history = useHistory();
  let currentDate = moment().format('YYYY-MM-DD')?.concat('T00:00:00Z');
  const [toggleManager, setToggleManager] = useState([]);
  const [logDescription, setLogDescription] = useState('');
  const [isStartTimerBtnClicked, setIsStartTimerBtnClicked] = useState(false);
  // const mploUser = useSelector((state) => state.mploUser);
  const { mploUser } = useSelector((state) => state);

  useEffect(() => {
    getTrackingLogs()
      .then(({ data }) => {
        dispatch(setTrackingLogs(data));
      })
      .catch((error) => {
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshData = () => {
    setDashboardLoading(true);
    let startDate = moment().format('YYYY-MM-DD')?.concat('T00:00:00Z');
    let endDate = moment().format('YYYY-MM-DDTHH:mm:ss')?.concat('Z');
    getUserDashboard(startDate, endDate)
      .then(
        ({
          data: {
            attendance: { check_in, check_out },
            user_project_details,
          },
        }) => {
          dispatch(setProjectsList(user_project_details));
          dispatch(setCheckinTime(check_in));
          dispatch(setCheckoutTime(check_out));
          setDashboardLoading(false);
        }
      )
      .catch((error) => {
        dispatch(setProjectsList([]));
        setDashboardLoading(false);
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error(error);
      });
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingLogs]);

  const attendanceHandler = () => {
    attendanceRequest(false)
      .then(() => {
        toast.success('Successfully updated work status');
        refreshData();
      })
      .catch(() => toast.success('Failed to update work status'));
  };
  const startTimer = (projectUid) => {
    setIsStartTimerBtnClicked(true);
    if (!checkinTime ? true : checkinTime && checkoutTime) {
      attendanceHandler();
    }
    if (logDescription) {
      addTrackingLog({
        projectId: projectUid,
        log: {
          working_on_what: logDescription,
        },
      }).then(() => {
        getTrackingLogs().then(({ data }) => {
          dispatch(setTrackingLogs(data ?? []));
        });
      });
      document.body.click();
    }
  };
  return (
    <>
      {dashboardLoading ? (
        <div className='logs-spinner'>
          <Spinner color='user' />
        </div>
      ) : (
        <>
          {projectsList?.length > 0 ? (
            <Table
              className='align-items-center table-flush user-project-table'
              responsive
            >
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Name</th>
                  <th scope='col'>Manager</th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {projectsList?.map(
                  ({ uid, name, managers, start_date, log_details }) => (
                    <tr key={uid}>
                      <td>
                        <NavLink
                          className='custom-link-class  user-theme-color'
                          activeClassName='custom-link-class-active'
                          to={`/datumbrain/user/project/${uid}/logs`}
                        >
                          {name}
                        </NavLink>
                      </td>
                      <td>
                        {managers?.[0]?.name ? (
                          mploUser?.name !== managers?.[0]?.name ? (
                            <div>{managers?.[0]?.name}</div>
                          ) : (
                            <div
                              className='toggle-name'
                              onClick={() =>
                                toggleName({
                                  uid,
                                  array: toggleManager,
                                  setArray: setToggleManager,
                                })
                              }
                            >
                              {toggleManager?.includes(uid)
                                ? managers?.[0]?.name
                                : 'You'}
                            </div>
                          )
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <div className='d-flex'>
                          {getWeekdays()?.map((day, index) => (
                            <CustomDay
                              day={day}
                              key={index}
                              index={index}
                              projectId={uid}
                              logDetails={log_details}
                              missed={
                                !log_details
                                  ?.map(({ log_date }) =>
                                    moment(log_date, 'YYYY-MM-DD').format(
                                      'YYYY-MM-DD'
                                    )
                                  )
                                  ?.includes(
                                    moment()
                                      .startOf('isoweek')
                                      .add(index, 'days')
                                      .format('YYYY-MM-DD')
                                  )
                              }
                            ></CustomDay>
                          ))}
                        </div>
                      </td>
                      <td className='justify-content-end text-default'>
                        {currentDate <
                        moment(start_date, 'YYYY-MM-DD').format(
                          'YYYY-MM-DD'
                        ) ? (
                          '-'
                        ) : (
                          <div
                            className='view-logs-btn admin-theme-button'
                            onClick={() =>
                              history.push(
                                `/datumbrain/user/project/${uid}/logs`
                              )
                            }
                          >
                            View logs
                          </div>
                        )}
                      </td>
                      <td>
                        {trackingLogs
                          ?.map(({ project_uid }) => project_uid)
                          ?.includes(uid) ? (
                          <LogTimer
                            projectId={uid}
                            startTime={
                              trackingLogs?.filter(
                                ({ project_uid }) => project_uid === uid
                              )?.[0]?.time_start
                            }
                          />
                        ) : (
                          <>
                            <div
                              className={`log-time-btn admin-theme-button ${
                                trackingLogs?.length > 0 &&
                                !trackingLogs
                                  ?.map(({ project_uid }) => project_uid)
                                  ?.includes(uid)
                                  ? 'disabled-btn'
                                  : ''
                              }`}
                              id={`log-time-${uid}`}
                              onClick={() => {
                                setLogDescription('');
                                setIsStartTimerBtnClicked(false);
                              }}
                            >
                              Time tracker
                            </div>

                            <UncontrolledPopover
                              trigger='legacy'
                              placement='left'
                              target={`log-time-${uid}`}
                            >
                              <PopoverHeader>Add logs</PopoverHeader>
                              <PopoverBody>
                                <CustomFormGroup
                                  type='textarea'
                                  name='logDescription'
                                  placeholder='Description'
                                  value={logDescription}
                                  iconClassName='fas fa-quote-right'
                                  setValue={setLogDescription}
                                  invalidText='Description cannot be empty'
                                  didSubmitButtonClick={isStartTimerBtnClicked}
                                />
                                <center>
                                  <Button
                                    className='start-timer-btn mb-2 admin-theme-button'
                                    onClick={() => startTimer(uid)}
                                  >
                                    Start timer
                                  </Button>
                                </center>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          ) : (
            <div className='no-data-div'>There are no projects yet</div>
          )}
        </>
      )}
    </>
  );
};

export default User;
