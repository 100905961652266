import moment from 'moment';
export const checkinFromFilter = (
  checkinFromFilter = moment().format('YYYY-MM-DD'),
  { type, payload }
) => (type === 'SET_CHECKIN_FROM_FILTER' ? payload : checkinFromFilter);

export const checkinToFilter = (
  checkinToFilter = moment().format('YYYY-MM-DD'),
  { type, payload }
) => (type === 'SET_CHECKIN_TO_FILTER' ? payload : checkinToFilter);

export const checkinViewMode = (viewMode = '', { type, payload }) =>
  type === 'SET_CHECKIN_VIEW_MODE' ? payload : viewMode;

export const checkinTime = (checkTime = '', { type, payload }) =>
  type === 'SET_CHECKIN_TIME' ? payload : checkTime;

export const checkoutTime = (checkTime = '', { type, payload }) =>
  type === 'SET_CHECKOUT_TIME' ? payload : checkTime;
