import React, { useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import '../styles.scss';
import { validate } from 'email-validator';
import { toast } from 'react-toastify';
import ErrorMessage from '../ErrorMessage';
import { clientForgotPassword } from '../../../../utils/api/api-requests/auth';

const ForgotPassword = () => {
  const [clientEmail, setClientEmail] = useState('');
  const [hasEmailSent, setHasEmailSent] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [didSendBtnClick, setDidSendBtnClick] = useState(false);

  useEffect(() => {
    setClientEmail(localStorage?.getItem('email') || '');
  }, []);
  const handleEnterPress = (e) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      onSendEmail();
    }
  };
  const onSendEmail = () => {
    setDidSendBtnClick(true);
    setIsSendingEmail(true);
    if (validate(clientEmail)) {
      setDidSendBtnClick(false);
      clientForgotPassword(clientEmail)
        .then(() => {
          setIsSendingEmail(false);
          setHasEmailSent(true);
        })
        .catch(() => {
          setIsSendingEmail(false);
          setHasEmailSent(false);
          toast.error('Unable to send email.');
        });
    }
  };
  return isSendingEmail ? (
    <i class='fas fa-circle-notch fa-spin client-password-reset-spinner' />
  ) : hasEmailSent ? (
    <p className='email-sent-text'>
      Dear user, we have sent you an email to reset your password. Kindly check
      your inbox.
    </p>
  ) : (
    <Form>
      <CustomFormGroup
        type='text'
        name='clientEmail'
        id='clientEmail'
        placeholder='Email'
        value={clientEmail}
        setValue={setClientEmail}
        iconClassName='far fa-envelope fa-lg'
        layout='admin'
        handleEnterPress={handleEnterPress}
      />
      <ErrorMessage
        isInvalid={didSendBtnClick && !validate(clientEmail)}
        message='Enter a valid email address'
      />

      <div onClick={onSendEmail} className='log-time-btn client-signup-btn'>
        Send Email
      </div>
    </Form>
  );
};

export default ForgotPassword;
