import React, { useEffect, useState } from 'react';
import { CardFooter } from 'reactstrap';
import { Button } from 'reactstrap';
import ConfirmationPopover from '../../../../../../Common/ConfirmationPopover/ConfirmationPopover';
import downloadIcon from 'assets/svg/bx_bx-export.svg';
import disabledDownloadIcon from 'assets/svg/bx_bx-export-disabled.svg';
import resetIcon from 'assets/svg/bx_bx-reset.svg';
import disabledResetIcon from 'assets/svg/bx_bx-reset-disabled.svg';
import downloadingIcon from 'assets/svg/bx_bx-export-progress.svg';
import alertTriangle from 'assets/svg/alert-triangle.svg';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { LoggedItem } from './logged-data-model';
import { validateTime } from '../../../../../../../utils/helper-functions';
import { toast } from 'react-toastify';
import { DEFAULT_DESCRIPTION } from '../../../../../../../constants';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { downloadReviewedInvoice } from '../../../../../../../utils/api/api-requests/owner';
const TableFooter = ({
  dirty,
  setFieldValue,
  selectedUsers,
  values,
  users,
  haveSetDefaultHours,
  setHaveSetDefaultHours,
  retainer,
  resetRetainerInfo,
  retainerAmount,
  resetSelectedEmployees,
  computeGrandTotal,
  rowCount,
  addNewRow,
  retainerRows,
  computeGrandTotalRetainer,
  areAllRetainerRowsFilled,
  getProjectInfo,
}) => {
  const [isDownoadingInvoice, setIsDownloadingInvoice] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [currency, setCurrency] = useState(null);
  const { projectId } = useParams();
  const { fromDateFilter, toDateFilter } = useSelector((state) => state);

  const grandTotal = computeGrandTotal(values);
  const grandTotalRetainer = computeGrandTotalRetainer(retainerRows);

  const generateHourlyInvoice = () => {
    // Get the user's current timezone
    const userTimezone = momentTimezone?.tz?.guess();
    const timezoneInfo = momentTimezone?.tz?.zone(userTimezone);
    const utcOffsetMinutes = timezoneInfo?.utcOffset(new Date());
    const utcOffsetHours = Math?.floor(Math?.abs(utcOffsetMinutes) / 60);

    setIsDownloadingInvoice(true);
    const requestBody = {
      grand_total: grandTotal,
      log_details: [],
    };

    // eslint-disable-next-line array-callback-return
    values?.associatedUsers?.map((user) => {
      const index = selectedUsers.indexOf(user.uid);
      if (index > -1) {
        const perHourRate = Number.isNaN(user?.per_hour_rate)
          ? 0
          : user?.per_hour_rate;
        const loggedHours = Number.isNaN(user?.time_logged.hours)
          ? 0
          : user?.time_logged.hours;
        const loggedMinutes = Number.isNaN(user?.time_logged.minutes)
          ? 0
          : user?.time_logged.minutes;
        const subTotal = (
          perHourRate *
          (loggedHours + loggedMinutes / 60)
        ).toFixed(2);
        requestBody.log_details.push(
          new LoggedItem(
            user?.name,
            user?.job_title,
            validateTime(user?.time_logged?.hours, user?.time_logged?.minutes),
            user?.per_hour_rate,
            +subTotal
          )
        );
      }
    });

    downloadReviewedInvoice(
      {
        projectId,
        from:
          fromDateFilter === moment()?.format('YYYY-MM-DD')
            ? moment(fromDateFilter)
                ?.utc()
                ?.subtract(1, 'day')
                ?.utcOffset(utcOffsetHours)
                ?.format('YYYY-MM-DD')
            : moment(fromDateFilter)
                ?.utc()
                ?.utcOffset(utcOffsetHours)
                ?.format('YYYY-MM-DD'),
        to:
          toDateFilter === moment()?.format('YYYY-MM-DD')
            ? moment(toDateFilter)
                ?.utc()
                ?.subtract(1, 'day')
                ?.utcOffset(utcOffsetHours)
                ?.format('YYYY-MM-DD')
            : moment(toDateFilter)
                ?.utc()
                ?.utcOffset(utcOffsetHours)
                ?.format('YYYY-MM-DD'),
        projectType: 'HOURLY',
      },
      requestBody
    )
      .then((response) => {
        setIsDownloadingInvoice(false);
        window.open(response.data, '_blank').focus();
      })
      .catch((err) => {
        setIsDownloadingInvoice(false);
        toast.error('Unable to download invoice.');
      });
  };
  const generateRetainerInvoice = () => {
    // Get the user's current timezone
    const userTimezone = momentTimezone?.tz?.guess();
    const timezoneInfo = momentTimezone?.tz?.zone(userTimezone);
    const utcOffsetMinutes = timezoneInfo?.utcOffset(new Date());
    const utcOffsetHours = Math?.floor(Math?.abs(utcOffsetMinutes) / 60);
    console.log(retainerRows);
    setIsDownloadingInvoice(true);
    const requestBody = {
      retailer_details: retainerRows.map((row) => ({
        description: row.description,
        quantity: row.quantity,
        amount: row.amount,
      })),
    };
    downloadReviewedInvoice(
      {
        projectId,
        from:
          fromDateFilter === moment()?.format('YYYY-MM-DD')
            ? moment(fromDateFilter)
                ?.utc()
                ?.subtract(1, 'day')
                ?.utcOffset(utcOffsetHours)
                ?.format('YYYY-MM-DD')
            : moment(fromDateFilter)
                ?.utc()
                ?.utcOffset(utcOffsetHours)
                ?.format('YYYY-MM-DD'),
        to:
          toDateFilter === moment()?.format('YYYY-MM-DD')
            ? moment(toDateFilter)
                ?.utc()
                ?.subtract(1, 'day')
                ?.utcOffset(utcOffsetHours)
                ?.format('YYYY-MM-DD')
            : moment(toDateFilter)
                ?.utc()
                ?.utcOffset(utcOffsetHours)
                ?.format('YYYY-MM-DD'),
        projectType: 'RETAINER',
      },
      requestBody
    )
      .then((response) => {
        setIsDownloadingInvoice(false);
        window.open(response.data, '_blank').focus();
      })
      .catch((err) => {
        setIsDownloadingInvoice(false);
        toast.error('Unable to download invoice.');
      });
  };
  useEffect(() => {
    getProjectInfo().then((data) => setCurrency(data.currency));
  });

  return (
    <CardFooter>
      {retainer && (
        <div className='d-flex justify-content-center'>
          <Button
            disabled={rowCount > 4}
            className='user-theme-button custom-btn-size add-button'
            color='primary'
            onClick={() => addNewRow()}
          >
            + Add Row
          </Button>
        </div>
      )}
      <div className='review-card-footer'>
        <div className='review-invoice-buttons'>
          <button
            disabled={
              !retainer
                ? selectedUsers?.length === 0
                : !areAllRetainerRowsFilled()
            }
            onClick={retainer ? generateRetainerInvoice : generateHourlyInvoice}
            className={`${isDownoadingInvoice ? 'downloading-button' : ''}`}
          >
            <img
              src={
                isDownoadingInvoice
                  ? downloadingIcon
                  : (
                      !retainer
                        ? selectedUsers?.length === 0
                        : !areAllRetainerRowsFilled()
                    )
                  ? disabledDownloadIcon
                  : downloadIcon
              }
              alt='download'
            />{' '}
            {isDownoadingInvoice ? 'Downloading...' : 'Download Invoice'}
          </button>
          {retainer ? (
            <>
              <button
                disabled={
                  retainerRows.length === 1 &&
                  retainerRows[0].description === DEFAULT_DESCRIPTION &&
                  retainerRows[0].amount === retainerAmount
                }
                id='reset-all-popover'
                onClick={resetRetainerInfo}
              >
                <img
                  src={
                    retainerRows.length === 1 &&
                    retainerRows[0].description === DEFAULT_DESCRIPTION &&
                    retainerRows[0].amount === retainerAmount
                      ? disabledResetIcon
                      : resetIcon
                  }
                  alt='reset'
                />
                Reset
              </button>
            </>
          ) : (
            <>
              <ConfirmationPopover
                isPopoverOpen={isPopoverOpen}
                setIsPopoverOpen={setIsPopoverOpen}
                icon={alertTriangle}
                mainText='Have you made your mind?'
                description={`Clicking ‘Reset ${
                  selectedUsers?.length === users?.length ? 'All' : 'Selected'
                }’ will revert all the changes you have made.`}
                confirmHandler={() => {
                  resetSelectedEmployees(setFieldValue);
                  setIsPopoverOpen(false);
                  setHaveSetDefaultHours(false);
                }}
                cancelHandler={() => setIsPopoverOpen(false)}
              />

              <button
                className={`${isPopoverOpen ? 'active-button' : ''}`}
                id='reset-all-popover'
                disabled={
                  !(
                    haveSetDefaultHours ||
                    dirty ||
                    selectedUsers?.length < users?.length
                  )
                }
                onClick={() => setIsPopoverOpen((prevState) => !prevState)}
              >
                <img
                  src={
                    haveSetDefaultHours ||
                    dirty ||
                    selectedUsers?.length < users?.length
                      ? resetIcon
                      : disabledResetIcon
                  }
                  alt='reset'
                />{' '}
                Reset{' '}
                {selectedUsers?.length === users?.length ? 'All' : 'Selected'}
              </button>
            </>
          )}
        </div>
        <div className='invoice-final-output'>
          <div className='invoice-grand-total'>
            <div>Grand Total</div>
            <div>
              {currency}{' '}
              {retainer
                ? grandTotalRetainer || 0
                : grandTotal.toLocaleString('en-US')}
            </div>
          </div>
          {!retainer && (
            <span>
              {selectedUsers?.length} of {users?.length} employees selected
            </span>
          )}
        </div>
      </div>
      {!retainer && selectedUsers?.length === 0 && (
        <div className={`custom-invalid-feedback-text`}>
          <i className='fas fa-exclamation-circle' />{' '}
          <span>
            To enable download and see invoice please select minimum one
            employee name.
          </span>
        </div>
      )}
    </CardFooter>
  );
};

export default TableFooter;
