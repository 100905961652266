import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Spinner,
  UncontrolledTooltip,
  Label,
} from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import './Checkin.scss';
import lateClockIcon from 'assets/svg/late-clock.svg';
import multipleCheckinsIcon from 'assets/svg/multiple-checkins.svg';
import {
  setCheckinViewMode,
  setCheckinFromFilter,
  setCheckinToFilter,
} from 'redux/actions/checkin';

import {
  getUsers,
  getAttendanceForSpecificUser,
  getAttendance,
} from 'utils/api/api-requests/admin';
import {
  setFilterDates,
  isAdminLoggedIn,
  filterViews,
} from 'utils/helper-functions';
import {
  getUserAttendanceList,
  deleteUserAttendance,
} from 'utils/api/api-requests/user';

import Header from 'Components/Headers/Header';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import CheckinFilter from './CheckinFilter';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';

import { DEFAULT_PAGE_SIZE, PAGE_SIZE_INCREMENT } from 'constants/index';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import CheckinPopover from './CheckinPopover';
import TimeInput from './TimePicker';
import {
  getLateCheckinsCount,
  getOfficeTime,
} from '../../../utils/api/api-requests/admin';
import warningIcon from 'assets/svg/akar-icons_info.svg';
const Checkin = () => {
  const dispatch = useDispatch();
  const { checkinFromFilter, checkinToFilter, checkinViewMode } = useSelector(
    (state) => state
  );
  const mploUser = useSelector((state) => state.mploUser);
  const [checkInList, setCheckInList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [checkinDeleteModal, setCheckinDeleteModal] = useState(false);
  const [checkinUid, setCheckinUid] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('all-users');
  const [userList, setUserList] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [officeTime, setOfficeTime] = useState(null);
  const [isOfficeTimeFetching, setIsOfficeTimeFetching] = useState(false);
  const [lateCount, setLateCount] = useState(null);

  const getUserName = () =>
    userList?.filter(({ uid }) => uid === selectedUserId)?.[0]?.name;

  const getViewModeText = () =>
    filterViews
      ?.filter(({ uid }) => uid === checkinViewMode)?.[0]
      ?.name?.toLowerCase();

  useEffect(() => {
    setPageSize(DEFAULT_PAGE_SIZE);
  }, []);

  useEffect(() => {
    if (isAdminLoggedIn()) {
      setIsOfficeTimeFetching(true);
      getOfficeTime()
        .then(({ data }) => {
          const hours = moment(data?.value, 'HH:mm').hours();
          const minutes = moment(data?.value, 'HH:mm').minutes();
          const time = moment().hours(hours).minutes(minutes);
          setOfficeTime(time);
          setIsOfficeTimeFetching(false);
        })
        .catch(() => {
          setIsOfficeTimeFetching(false);
          toast.error('Unable to get office time.');
        });
    }
  }, []);
  const getEmployeesAttendance = useCallback(() => {
    let startDate = moment(checkinFromFilter)
      .format('YYYY-MM-DD')
      ?.concat('T00:00:00Z');
    let endDate = moment(checkinToFilter)
      .format('YYYY-MM-DDTHH:mm:ss')
      ?.concat('Z');
    if (isAdminLoggedIn()) {
      if (selectedUserId && selectedUserId !== 'all-users')
        getAttendanceForSpecificUser(selectedUserId, startDate, endDate)
          .then(({ data }) => {
            setCheckInList(data);
            getLateCheckinsCount({
              userId: selectedUserId,
              startDate,
              endDate,
            })
              .then(({ data }) => setLateCount(data || null))
              .catch(() => setLateCount(null));
            setTimeout(() => {
              setIsDataLoading(false);
            }, 1000);
            console.error('Checkins updated successfully');
          })
          .catch(() => {
            setTimeout(() => {
              setIsDataLoading(false);
            }, 1000);
            toast.error('Failed to get user attendance user');
          });
      else
        getAttendance(startDate, endDate)
          .then(({ data }) => {
            setCheckInList(data);
            setTimeout(() => {
              setIsDataLoading(false);
            }, 1000);
          })
          .catch(() => {
            setTimeout(() => {
              setIsDataLoading(false);
            }, 1000);
            toast.error('Failed to get user attendance');
          });
      getUsers()
        .then(({ data }) => {
          data.unshift({ uid: 'all-users', name: 'All users' });
          setUserList(data?.map(({ uid, name }) => ({ uid, name })));
        })
        .catch(() => toast.error('Failed to get users'));
    } else {
      getUserAttendanceList(startDate, endDate)
        .then(({ data }) => {
          setTimeout(() => {
            setIsDataLoading(false);
            const checkins = data?.map((checkIn) => ({
              ...checkIn,
              first_check_in: checkIn?.check_in,
              last_check_out: checkIn?.check_out,
            }));
            setCheckInList(checkins);
          }, 300);
        })
        .catch(() => {
          setIsDataLoading(false);
        });
    }
  }, [checkinFromFilter, checkinToFilter, selectedUserId]);
  useEffect(() => {
    getEmployeesAttendance();
  }, [getEmployeesAttendance]);

  const deleteCheckinApiHandler = () => {
    deleteUserAttendance(checkinUid)
      .then(() => {
        toast.success('Successfully deleted checkin');
        let startDate = moment(checkinFromFilter)
          .format('YYYY-MM-DD')
          ?.concat('T00:00:00Z');
        let endDate = moment(checkinToFilter)
          .format('YYYY-MM-DDTHH:mm:ss')
          ?.concat('Z');
        getUserAttendanceList(startDate, endDate)
          .then(({ data }) => {
            setTimeout(() => {
              setIsDataLoading(false);
              // console.table(
              //   data?.map((checkIn) => ({
              //     ...checkIn,
              //     first_check_in: checkIn?.checkin,
              //     last_check_out: checkIn?.checkout,
              //   }))
              // );
              setCheckInList(
                data?.map((checkIn) => ({
                  ...checkIn,
                  first_check_in: checkIn?.checkin,
                  last_check_out: checkIn?.checkout,
                }))
              );
            }, 300);
          })
          .catch(() => {
            setIsDataLoading(false);
          });
        setCheckinDeleteModal(false);
      })
      .catch(() => {
        toast.error('Failed to delete checkin');
        setCheckinDeleteModal(false);
      });
  };
  return (
    <>
      <div className='main-content'>
        <Header />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0 checkins-header'>
                  <Row className='align-items-center'>
                    <div className='table-container-header'>
                      <div className='table-container-heading-select'>
                        <Col className='checkin-column custom-breadcrumb-user'>
                          <h3 className='mb-0 text-dark'>Checkin</h3>
                        </Col>
                        <div className='table-container-select-group'>
                          <div>
                            <Label className='header-label'>Duration</Label>
                            <CustomFormGroup
                              type='select'
                              name='selectCheckinView'
                              id='selectCheckinView'
                              value={checkinViewMode}
                              setValue={(value) => {
                                dispatch(setCheckinViewMode(value));
                                setFilterDates(
                                  value,
                                  dispatch,
                                  setCheckinFromFilter,
                                  setCheckinToFilter
                                );
                              }}
                              iconClassName='fas fa-history fa-lg'
                              invalidText='No view is selected'
                              selectBoxText='Select view'
                              selectBoxArray={filterViews}
                              invalidTextFlag={true}
                            />
                          </div>
                          {isAdminLoggedIn() ? (
                            <div className='ml-4'>
                              <Label className='header-label'>Users</Label>
                              <CustomFormGroup
                                type='select'
                                name='selectCheckinUser'
                                id='selectCheckinUser'
                                value={selectedUserId}
                                setValue={(value) => setSelectedUserId(value)}
                                iconClassName='fas fa-users fa-lg'
                                invalidText='No view is selected'
                                selectBoxText='Select user view'
                                selectBoxArray={userList}
                                invalidTextFlag={true}
                              />
                            </div>
                          ) : (
                            ''
                          )}

                          {isAdminLoggedIn() && (
                            <div className='ml-4'>
                              <Label className='header-label'>
                                Office Time
                              </Label>
                              {isOfficeTimeFetching ? (
                                <i
                                  class='fas fa-circle-notch fa-spin'
                                  style={{
                                    verticalAlign: 'middle',
                                    color: '#fdc13f',
                                  }}
                                />
                              ) : (
                                <TimeInput
                                  officeTime={officeTime}
                                  setOfficeTime={setOfficeTime}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='table-container-dates'>
                        <CheckinFilter />
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className='pl-0 pr-0 pt-0'>
                  {isAdminLoggedIn() &&
                    selectedUserId !== 'all-users' &&
                    lateCount &&
                    lateCount > 0 && (
                      <div className='late-coming-count'>
                        <img src={warningIcon} alt='warning' />
                        {getUserName()} has been late{' '}
                        {lateCount === 1
                          ? `${lateCount} day`
                          : `${lateCount} days`}{' '}
                        in{' '}
                        {getViewModeText() !== 'custom date'
                          ? getViewModeText()
                          : 'selected date range'}
                      </div>
                    )}
                  {isDataLoading ? (
                    <div className='logs-spinner'>
                      <Spinner color='admin' />
                    </div>
                  ) : checkInList?.length > 0 ? (
                    <>
                      <Table
                        className={`align-items-center table-flush responsive check-in ${
                          isAdminLoggedIn() ? 'admin' : ''
                        }`}
                      >
                        <thead className='thead-light'>
                          <tr>
                            {isAdminLoggedIn() ? <th>Employee name</th> : ''}
                            <th>Date</th>
                            <th>Day</th>
                            <th>Duration</th>
                            <th>Work Status</th>
                            {isAdminLoggedIn() ? '' : <th />}
                          </tr>
                        </thead>
                        <tbody>
                          {checkInList
                            ?.filter((_, index) => index < pageSize)
                            ?.map(
                              ({
                                uid,
                                first_check_in,
                                first_checkin_uid,
                                last_check_out,
                                working_remotely,
                                user_name,
                                is_late,
                                attendance,
                              }) => (
                                <tr key={first_checkin_uid}>
                                  {isAdminLoggedIn() ? (
                                    <td>{user_name}</td>
                                  ) : (
                                    ''
                                  )}
                                  <td>{moment(first_check_in).format('LL')}</td>
                                  <td>
                                    {moment(first_check_in).format('dddd')}
                                  </td>
                                  <td>
                                    {isAdminLoggedIn() &&
                                    (attendance?.length > 1 || is_late) ? (
                                      <OverlayTrigger
                                        trigger='click'
                                        rootClose
                                        target={`checkins-popover-${first_checkin_uid}`}
                                        overlay={
                                          <Popover
                                            id={`checkins-popover-${first_checkin_uid}`}
                                          >
                                            <CheckinPopover
                                              getAttendance={
                                                getEmployeesAttendance
                                              }
                                              firstCheckin={first_checkin_uid}
                                              attendance={attendance}
                                              isLate={is_late}
                                            />
                                          </Popover>
                                        }
                                      >
                                        <div className='checkin-time-info'>
                                          {moment(first_check_in).format(
                                            'hh:mm A'
                                          )}
                                          {' - '}
                                          {last_check_out ? (
                                            moment(last_check_out).format(
                                              'hh:mm A'
                                            )
                                          ) : (
                                            <i>in progress</i>
                                          )}
                                          {is_late && (
                                            <img
                                              src={lateClockIcon}
                                              alt='late-clock'
                                            />
                                          )}
                                          {attendance?.length > 1 && (
                                            <img
                                              src={multipleCheckinsIcon}
                                              alt='multiple-checkins'
                                            />
                                          )}
                                        </div>
                                      </OverlayTrigger>
                                    ) : (
                                      <div className='checkin-time-info'>
                                        {moment(first_check_in).format(
                                          'hh:mm A'
                                        )}
                                        {' - '}
                                        {last_check_out ? (
                                          moment(last_check_out).format(
                                            'hh:mm A'
                                          )
                                        ) : (
                                          <i>in progress</i>
                                        )}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {working_remotely ? (
                                      <i
                                        className='fas fa-laptop-house fa-lg work-from-icon text-success'
                                        id={`work-status-${first_checkin_uid}`}
                                      />
                                    ) : (
                                      <i
                                        className='fas fa-laptop fa-lg work-from-icon text-success'
                                        id={`work-status-${first_checkin_uid}`}
                                      />
                                    )}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={`work-status-${first_checkin_uid}`}
                                    >
                                      {working_remotely
                                        ? 'Working from home'
                                        : 'Working from office'}
                                    </UncontrolledTooltip>
                                  </td>

                                  {isAdminLoggedIn() ? (
                                    ''
                                  ) : (
                                    <td className='client-edit-button only-child'>
                                      <i
                                        className='fas fa-trash-alt fa-lg user-theme-color'
                                        onClick={() => {
                                          setCheckinDeleteModal(true);
                                          setCheckinUid(uid);
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                        </tbody>
                      </Table>
                      {pageSize < checkInList?.length ? (
                        <div className='load-more-btn-container'>
                          <span
                            className={`load-more-btn ${
                              ['ADMIN', 'SUPER_ADMIN'].includes(mploUser?.role)
                                ? ''
                                : 'user-load-btn'
                            }`}
                            onClick={() =>
                              setPageSize(pageSize + PAGE_SIZE_INCREMENT)
                            }
                          >
                            Load more...
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <div className='no-data-div'>There are no checkins yet</div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomModal
        isOpen={checkinDeleteModal}
        toggle={() => setCheckinDeleteModal(!checkinDeleteModal)}
        headerText='Delete Checkin'
        cancelButtonText={'Cancel'}
        confirmButtonText={'Confirm'}
        confirmFunction={deleteCheckinApiHandler}
      >
        <p>Are you sure you want to delete this checkin</p>
      </CustomModal>
    </>
  );
};

export default Checkin;
