import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setEditUserInfoFlag, setMploUser } from 'redux/actions/general';
import {
  addUserInfo,
  updateUserInfo,
  getUserInfo,
  changeUsername,
} from 'utils/api/api-requests/general';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import FormGroupWithLabel from 'Components/Common/FormGroupWithLabel/FormGroupWithLabel';
import { toast } from 'react-toastify';
import validator from 'validator';
import { validateCnic } from 'utils/helper-functions';
import { useHistory } from 'react-router-dom';

const ProfileInfo = () => {
  const cnicInfo = document.getElementById('input-cnic-number-emergency');
  cnicInfo?.addEventListener('keydown', (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') return;
    if (e.target.value.length === 5) {
      cnicInfo.value = cnicInfo.value + '-';
    }
    if (e.target.value.length === 13) {
      cnicInfo.value = cnicInfo.value + '-';
    }
  });
  const history = useHistory();

  const [profileInfoObject, setProfileInfoObject] = useState({
    fullName: '',
    contactNumber: '',
    additionalContactNumber: '',
    currentAddress: '',
    permanentAddress: '',
    cnicNumber: '',
  });
  const [displayName, setDisplayName] = useState('');
  const dispatch = useDispatch();
  const { editUserInfoFlag } = useSelector((state) => state);
  const [newInfoFlag, setNewInfoFlag] = useState(false);
  const [didSaveButtonClicked, setDidSaveButtonClicked] = useState(false);
  useEffect(() => {
    getUserInfo()
      .then(
        ({
          data: {
            full_name,
            phone1,
            phone2,
            current_address,
            permanent_address,
            user_info_uid,
            cnic_number,
          },
        }) => {
          setProfileInfoObject({
            fullName: full_name || '',
            contactNumber: phone1 || '',
            additionalContactNumber: phone2 || '',
            currentAddress: current_address || '',
            permanentAddress: permanent_address || '',
            uid: user_info_uid || '',
            cnicNumber: cnic_number || '',
          });
          if (
            !full_name &&
            !phone1 &&
            !phone2 &&
            !current_address &&
            !permanent_address &&
            !user_info_uid
          )
            setNewInfoFlag(true);
          else setNewInfoFlag(false);
        }
      )
      .catch((error) => {
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error(error);
      });
    whoamiApiRequest('datumbrain')
      .then(({ data: { name, roles, uid, email, image_url } }) => {
        setDisplayName(name);
        const mploUser = {
          name,
          roles,
          uid,
          email,
          profileImage:
            image_url == null ? 'assets/img/theme/no-user-1.jpeg' : image_url,
        };
        dispatch(setMploUser(mploUser));
      })
      .catch((error) => {
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error('Failed to get user data');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUserInfoFlag]);
  const validateInfoObject = () => {
    return (
      validator.isMobilePhone(profileInfoObject?.contactNumber) &&
      (profileInfoObject?.additionalContactNumber
        ? validator.isMobilePhone(profileInfoObject?.additionalContactNumber)
        : true) &&
      profileInfoObject?.currentAddress &&
      profileInfoObject?.permanentAddress &&
      validateCnic(profileInfoObject?.cnicNumber) &&
      displayName
    );
  };

  const userInfoApiHandler = () => {
    setDidSaveButtonClicked(true);
    if (validateInfoObject()) {
      if (!newInfoFlag) {
        updateUserInfo({
          full_name: profileInfoObject?.fullName,
          phone1: profileInfoObject?.contactNumber,
          phone2: profileInfoObject?.additionalContactNumber,
          current_address: profileInfoObject?.currentAddress,
          permanent_address: profileInfoObject?.permanentAddress,
          cnic_number: profileInfoObject?.cnicNumber,
        })
          .then(() => {
            toast.success('Updated user info successfully');
            dispatch(setEditUserInfoFlag(false));
            setDidSaveButtonClicked(false);
          })
          .catch((err) => {
            toast.error('Failed to update user info');
            dispatch(setEditUserInfoFlag(false));
            setDidSaveButtonClicked(false);
          });
        changeUsername({ name: displayName })
          .then(() => {})
          .catch((err) => toast.error(err));
      } else {
        addUserInfo({
          full_name: profileInfoObject?.fullName,
          phone1: profileInfoObject?.contactNumber,
          phone2: profileInfoObject?.additionalContactNumber,
          current_address: profileInfoObject?.currentAddress,
          permanent_address: profileInfoObject?.permanentAddress,
          cnic_number: profileInfoObject?.cnicNumber,
        })
          .then(() => {
            toast.success('Added user info successfully');
            dispatch(setEditUserInfoFlag(false));
            setDidSaveButtonClicked(false);
          })
          .catch((err) => {
            toast.error('Failed to add user info');
            dispatch(setEditUserInfoFlag(false));
            setDidSaveButtonClicked(false);
          });
      }
    }
  };
  const cancelUserInfoHandler = () => {
    setDidSaveButtonClicked(false);
    dispatch(setEditUserInfoFlag(false));
  };
  return (
    <>
      <Row className='m-0'>
        <h6 className='heading-small text-muted mb-4'>Basic information</h6>
        <div className='col text-right pr-0'>
          <Button
            className='admin-theme-button user-profile-btn'
            onClick={() => dispatch(setEditUserInfoFlag(true))}
            disabled={editUserInfoFlag}
          >
            <i className='fas fa-pencil-alt mr-1' /> Edit
          </Button>
        </div>
      </Row>
      <div>
        <Row>
          <Col lg='6'>
            <FormGroupWithLabel
              labelName='Display Name'
              id='input-displayname'
              placeholder='John'
              type='text'
              setValue={setDisplayName}
              value={displayName}
              disabled={!editUserInfoFlag || newInfoFlag}
              didSubmitButtonClick={didSaveButtonClicked}
              name='display-name'
              invalidText='Display name is empty'
              required
            />
          </Col>
          <Col lg='6'>
            <FormGroupWithLabel
              labelName='Full Name'
              id='input-fullname'
              placeholder='John Doe'
              type='text'
              setValue={(value) => {
                setProfileInfoObject({ ...profileInfoObject, fullName: value });
              }}
              value={profileInfoObject?.fullName}
              disabled={!editUserInfoFlag}
              didSubmitButtonClick={didSaveButtonClicked}
              name='full-name'
              invalidText='Full name is empty'
              required
            />
          </Col>
        </Row>
        <Row>
          <Col lg='6'>
            <FormGroupWithLabel
              labelName='Current Address'
              id='input-current-address'
              placeholder='123 XYZ, NYC'
              type='textarea'
              setValue={(value) =>
                setProfileInfoObject({
                  ...profileInfoObject,
                  currentAddress: value,
                })
              }
              value={profileInfoObject?.currentAddress}
              disabled={!editUserInfoFlag}
              didSubmitButtonClick={didSaveButtonClicked}
              name='current-address'
              invalidText='Current address is empty'
              required
            />
          </Col>
          <Col lg='6'>
            <FormGroupWithLabel
              labelName='Permanent Address'
              id='input-permanent-address'
              placeholder='123 XYZ, NYC'
              type='textarea'
              setValue={(value) =>
                setProfileInfoObject({
                  ...profileInfoObject,
                  permanentAddress: value,
                })
              }
              value={profileInfoObject?.permanentAddress}
              disabled={!editUserInfoFlag}
              didSubmitButtonClick={didSaveButtonClicked}
              name='permanent-address'
              invalidText='Permanent address is empty'
              required
            />
          </Col>
        </Row>
        <Row>
          <Col lg='4'>
            <FormGroupWithLabel
              labelName='CNIC Number'
              id='input-cnic-number-emergency'
              type='text'
              setValue={(value) =>
                setProfileInfoObject({
                  ...profileInfoObject,
                  cnicNumber: value,
                })
              }
              value={profileInfoObject?.cnicNumber}
              disabled={!editUserInfoFlag}
              didSubmitButtonClick={didSaveButtonClicked}
              name='cnic-number'
              invalidText='CNIC number is invalid'
              dataInputmask="'mask': '99999-9999999-9'"
              placeholder='XXXXX-XXXXXXX-X'
              required
              maxLength={15}
            />
          </Col>
          <Col lg='4'>
            <FormGroupWithLabel
              labelName='Contact Number'
              id='input-contact-number'
              placeholder='+92xxxxxxxxx'
              type='text'
              setValue={(value) =>
                setProfileInfoObject({
                  ...profileInfoObject,
                  contactNumber: value,
                })
              }
              value={profileInfoObject?.contactNumber}
              disabled={!editUserInfoFlag}
              didSubmitButtonClick={didSaveButtonClicked}
              name='contact-number'
              invalidText='Invalid phone number entered'
              required
            />
          </Col>
          <Col lg='4'>
            <FormGroupWithLabel
              labelName='Additional Number'
              id='input-additional-number'
              placeholder='+92xxxxxxxxx'
              type='text'
              setValue={(value) =>
                setProfileInfoObject({
                  ...profileInfoObject,
                  additionalContactNumber: value,
                })
              }
              value={profileInfoObject?.additionalContactNumber}
              disabled={!editUserInfoFlag}
              didSubmitButtonClick={didSaveButtonClicked}
              name='additional-contact-number'
              invalidText='Invalid phone number entered'
            />
          </Col>
        </Row>
        {editUserInfoFlag ? (
          <div className='d-flex justify-content-end'>
            <Button
              className='admin-theme-button custom-btn-size2 btn-width'
              onClick={userInfoApiHandler}
            >
              Save
            </Button>
            <Button
              className='admin-theme-button custom-btn-size2 btn-width'
              onClick={cancelUserInfoHandler}
            >
              Cancel
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default ProfileInfo;
