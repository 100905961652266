import CustomModal from 'Components/Common/CustomModal/CustomModal';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setEmployeeRoleModal } from 'redux/actions/modals';
import { toast } from 'react-toastify';
import { changeEmployeeRole } from 'utils/api/api-requests/admin';
import { setUserList } from 'redux/actions/employee';

const RolesModal = () => {
  const dispatch = useDispatch();
  const [localStateRoles, setLocalStateRoles] = useState([]);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const {
    employeeRoleModal,
    employeeData: { uid, roles, name },
  } = useSelector((state) => state);
  useEffect(() => {
    setLocalStateRoles(roles?.filter((role) => role !== 'OWNER'));
  }, [roles]);
  const changeRolesArrayHandler = (role) => {
    setIsSubmitButtonClicked(false);
    if (localStateRoles?.includes(role))
      setLocalStateRoles(
        localStateRoles?.filter(
          (localRole) => localRole?.toLowerCase() !== role?.toLowerCase()
        )
      );
    else
      localStateRoles?.length > 0
        ? setLocalStateRoles([...localStateRoles, role?.toUpperCase()])
        : setLocalStateRoles([role?.toUpperCase()]);
  };
  const rolesApiHandler = (e) => {
    e.preventDefault();
    setIsSubmitButtonClicked(true);
    if (localStateRoles?.length > 0) {
      changeEmployeeRole(uid, localStateRoles)
        .then(() => {
          toast.success('Role changed successfully');
          setIsSubmitButtonClicked(false);
          dispatch(setUserList({ success: () => {}, failure: () => {} }));
          dispatch(setEmployeeRoleModal(false));
          setTimeout(() => setLocalStateRoles([]), 1000);
        })
        .catch(() => {
          toast.error('Some error occurred');
          setIsSubmitButtonClicked(false);
          dispatch(setEmployeeRoleModal(false));
        });
    }
  };
  return (
    <div>
      <CustomModal
        isOpen={employeeRoleModal}
        toggle={() => dispatch(setEmployeeRoleModal(!employeeRoleModal))}
        headerText={`Change roles`}
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={(e) => rolesApiHandler(e)}
        roleModal={true}
        customClass='form-modal'
      >
        <p className='mb-2'>
          Select checkboxes to change roles for <i>{name}</i>.
        </p>
        <div className='d-flex flex-column justify-content-around'>
          <div className='styled-checkbox-container mb-2'>
            <label className='styled-checkbox role'>
              <input
                id='admin-role'
                type='checkbox'
                checked={localStateRoles?.includes('ADMIN')}
                onChange={() => changeRolesArrayHandler('ADMIN')}
                disabled={roles?.includes('OWNER')}
              />
              <span className='checkbox-text'>Admin</span>
              <span className='checkmark'></span>
            </label>
          </div>

          <div className='styled-checkbox-container'>
            <label className='styled-checkbox role'>
              <input
                id='employee-role'
                type='checkbox'
                checked={localStateRoles?.includes('EMPLOYEE')}
                onChange={() => changeRolesArrayHandler('EMPLOYEE')}
              />
              <span className='checkbox-text'>Employee</span>
              <span className='checkmark'></span>
            </label>
          </div>
          <div
            className={`custom-invalid-feedback-text ${
              isSubmitButtonClicked && localStateRoles?.length <= 0
                ? ''
                : 'hidden-class'
            }`}
          >
            <i className='fas fa-exclamation-circle' />{' '}
            <span>Please select a role.</span>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};
export default RolesModal;
