import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { getAttendanceOverview } from 'utils/api/api-requests/admin';
import TimelineComponent from './TimelineView/TimelineComponent';

const Timeline = () => {
  const [view, setView] = useState(324);
  const [checkinUserList, setCheckinUserList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let startDate = moment().format('YYYY-MM-DD')?.concat('T00:00:00Z');
    let endDate = moment().format('YYYY-MM-DDTHH:mm:ss')?.concat('Z');
    getAttendanceOverview(startDate, endDate)
      .then(({ data }) => {
        setCheckinUserList(data);
      })
      .catch(() => {
        toast.error('Failed to get user attendance');
      });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Row>
        <Col className='admin-header text-dark'>Today's Check-ins</Col>

        <div className='mt-3 mr-2 timeline-hours-btn'>
          <UncontrolledDropdown>
            <DropdownToggle className='toggle-btn'>
              <i className='fas fa-history fa-lg' />
              <span>{view > 200 ? 'Proximate view' : 'Broad view'} </span>
              <i class='fa fa-angle-down'></i>
            </DropdownToggle>
            <DropdownMenu className='dropdown-menu-arrow' right>
              <DropdownItem
                className='dropdown-logout d-flex align-items-center'
                onClick={() => setView(324)}
              >
                <span>Proximate view</span>
              </DropdownItem>

              <DropdownItem
                onClick={() => setView(162)}
                className='dropdown-logout d-flex align-items-center'
              >
                <span>Broad view</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Row>
      <Col>
        <TimelineComponent
          gap={view}
          userList={checkinUserList}
          loading={loading}
        />
      </Col>
    </>
  );
};

export default Timeline;
