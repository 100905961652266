import { useEffect, useState } from 'react';

import './styles.scss';

import DropDown from './DropDown';
import UserStatusCard from './UserStatusCard';
import StatusPagination from './StatusPagination';

import { getUserStatusByProject } from 'utils/api/api-requests/admin';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import noProject from '../../../../assets/svg/no-status.svg';

import { PAGE_LIMIT } from '../../../../constants';

import { getPaginationCount } from 'utils/helper-functions';

import { setCurrentPagesChunk } from 'redux/actions/userStatus';
import { getAllStatuses } from 'utils/api/api-requests/admin';
import {
  combineAllProjectStatuses,
  filterStatuses,
} from 'utils/helper-functions';

const UserStatus = () => {
  const dispatch = useDispatch();

  const [statusData, setStatusData] = useState('');
  const { selectedProjectId, pageNumber } = useSelector((state) => state);
  const [activePage, setActivePage] = useState(1);

  const paginationCount = selectedProjectId
    ? getPaginationCount(statusData?.user_statuses?.length)?.length
    : getPaginationCount(statusData?.length)?.length;

  useEffect(() => {
    if (selectedProjectId) {
      getUserStatusByProject(selectedProjectId)
        .then((response) => {
          setStatusData(response?.data);
          setActivePage(1);
          dispatch(
            setCurrentPagesChunk(paginationCount >= 3 ? [1, 2, 3] : [1, 2])
          );
        })
        .catch(() => {
          toast.error('Unable to fetch user statuses');
        });
    } else {
      getAllStatuses()
        .then(({ data }) => {
          setStatusData(
            data.user_statuses.map((allStatues) =>
              combineAllProjectStatuses(allStatues)
            )
          );
          setActivePage(1);
          dispatch(
            setCurrentPagesChunk(paginationCount >= 3 ? [1, 2, 3] : [1, 2])
          );
        })
        .catch((err) => toast.error('Unable to fetch user statuses'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationCount, selectedProjectId]);

  return (
    <div className='status'>
      <div className='status__header'>
        <h3 className='status__header--title'>Check-ins Status</h3>
        <DropDown paginationCount={paginationCount} />
      </div>
      <div className='status__body'>
        {/* get all statuses */}
        {!selectedProjectId && statusData?.length > 0 ? (
          statusData
            ?.slice(PAGE_LIMIT * (pageNumber - 1), pageNumber * PAGE_LIMIT)
            ?.map((status) => <UserStatusCard statusData={status} />)
        ) : (
          <>
            {statusData?.length === 0 && (
              <>
                <img src={noProject} alt='no projects' />
                <p className='status__body--subtitle'>
                  There are no statuses for the selected project(s)
                </p>
              </>
            )}
          </>
        )}

        {/* get status by project name */}
        {statusData?.user_statuses?.length > 0 ? (
          statusData?.user_statuses
            ?.filter((status) => status.checked_in)
            ?.slice(PAGE_LIMIT * (pageNumber - 1), pageNumber * PAGE_LIMIT)
            ?.map((status) => <UserStatusCard statusData={status} />)
        ) : (
          <>
            {statusData && selectedProjectId && statusData?.length === 0 && (
              <>
                <img src={noProject} alt='no projects' />
                <p className='status__body--subtitle'>
                  There are no statuses for the selected project(s)
                </p>
              </>
            )}
          </>
        )}
        {statusData &&
          selectedProjectId &&
          !filterStatuses(statusData?.user_statuses) && (
            <>
              <img src={noProject} alt='no projects' />
              <p className='status__body--subtitle'>
                There are no statuses for the selected project(s)
              </p>
            </>
          )}
      </div>
      <div className='status__pagination'>
        {!selectedProjectId
          ? statusData?.length > 2 && (
              <StatusPagination
                statusesLength={statusData?.length}
                activePage={activePage}
                setActivePage={setActivePage}
              />
            )
          : statusData?.user_statuses?.filter((status) => status?.checked_in)
              ?.length > 2 && (
              <StatusPagination
                statusesLength={statusData?.user_statuses?.length}
                activePage={activePage}
                setActivePage={setActivePage}
              />
            )}
      </div>
    </div>
  );
};

export default UserStatus;
