import React, { useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import RoleSpan from './RoleSpan';
import '../styles.scss';
import { validate } from 'email-validator';
import { clientSignup } from '../../../../utils/api/api-requests/auth';
import { toast } from 'react-toastify';
import ErrorMessage from '../ErrorMessage';
import { useHistory } from 'react-router';

const Signup = () => {
  const history = useHistory();
  const token = new URLSearchParams(document.location.search).get('token');
  const id = new URLSearchParams(document.location.search).get('id');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPassword, setClientPassword] = useState('');
  const [clientConfirmPassword, setClientConfirmPassword] = useState('');
  const [clientRoles, setClientRoles] = useState([]);
  const [didSignupBtnClick, setDidSignupBtnClick] = useState(false);
  useEffect(() => {
    setClientEmail(localStorage.getItem('email'));
    const roles = localStorage.getItem('roles') || [];
    setClientRoles(
      !roles || roles.length <= 0 || roles?.[0] === '' ? [] : roles?.split(',')
    );
  }, []);
  const handleEnterPress = (e) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      onSignup();
    }
  };
  const onSignup = () => {
    setDidSignupBtnClick(true);
    if (
      validate(clientEmail) &&
      clientPassword &&
      clientPassword?.length >= 8 &&
      clientPassword === clientConfirmPassword
    ) {
      setDidSignupBtnClick(false);
      clientSignup({
        token,
        id,
        payload: {
          email: clientEmail,
          password: clientPassword,
        },
      })
        .then(() => {
          toast.success('Signed up successfully.');
          localStorage.setItem('email', clientEmail);
          history.push('/auth/client/login');
        })
        .catch(() => toast.error('Unable to sign up.'));
    }
  };
  return (
    <Form
    // onKeyPress={(e) => (e?.key === 'Enter' ? handleAPIRequest(e) : {})}
    >
      <CustomFormGroup
        disabled
        type='text'
        name='clientEmail'
        id='clientEmail'
        value={clientEmail}
        setValue={setClientEmail}
        iconClassName='far fa-envelope fa-lg'
        layout='admin'
        handleEnterPress={handleEnterPress}
      />
      <ErrorMessage isInvalid={false} message />
      <CustomFormGroup
        type='password'
        name='clientPassword'
        id='clientPassword'
        placeholder='New Password'
        value={clientPassword}
        setValue={setClientPassword}
        iconClassName='fas fa-key fa-lock'
        layout='admin'
        handleEnterPress={handleEnterPress}
      />
      <ErrorMessage
        isInvalid={didSignupBtnClick && clientPassword?.length < 8}
        message='Password should be at least 8 characters long'
      />
      <CustomFormGroup
        type='password'
        name='clientConfirmPassword'
        id='clientConfirmPassword'
        placeholder='Confirm New Password'
        value={clientConfirmPassword}
        setValue={setClientConfirmPassword}
        iconClassName='fas fa-key fa-lock'
        layout='admin'
        handleEnterPress={handleEnterPress}
      />
      <ErrorMessage
        isInvalid={
          didSignupBtnClick && clientPassword !== clientConfirmPassword
        }
        message='Passwords do not match'
      />
      {clientRoles?.length > 0 && (
        <div>
          Roles:{' '}
          <span className='client-signup-roles'>
            {clientRoles?.map((role) => (
              <RoleSpan role={role} />
            ))}
          </span>
        </div>
      )}
      <div onClick={onSignup} className='log-time-btn client-signup-btn'>
        Sign up
      </div>
    </Form>
  );
};

export default Signup;
