import React from 'react';

const ErrorMessage = ({ isInvalid, message }) => (
  <div
    className={`signup-error custom-invalid-feedback-text ${
      isInvalid ? '' : ' hidden-class'
    }`}
  >
    <i className='fas fa-exclamation-circle' />
    <span className='signup-error-message'>{message}</span>
  </div>
);
export default ErrorMessage;
