import RedirectSpinner from 'Components/Common/RedirectSpinner/RedirectSpinner';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { setMploUser } from 'redux/actions/general';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { useQuery } from 'utils/helper-functions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let refresh = useQuery(useLocation)?.get('refresh');
  useEffect(() => {
    if (refresh !== 'true' || !localStorage.getItem('jwt-token')) {
      localStorage.clear();
      setTimeout(() => history.push(`/auth/login`), 1000);
    } else {
      whoamiApiRequest('datumbrain')
        .then(({ data: { name, roles, uid, email, image_url } }) => {
          const mploUser = {
            name,
            roles,
            uid,
            email,
            profileImage:
              image_url == null ? 'assets/img/theme/no-user-1.jpeg' : image_url,
          };
          dispatch(setMploUser(mploUser));
          if (roles?.includes('ADMIN')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ) {
              localStorage.setItem('currentUserRole', 'admin');
              history.push(`/datumbrain/admin/index`);
            } else if (roles?.includes('EMPLOYEE')) {
              localStorage.setItem('currentUserRole', 'user');
              history.push(`/datumbrain/user/index`);
            }
          } else if (roles?.includes('EMPLOYEE')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'user'
            ) {
              localStorage.setItem('currentUserRole', 'user');
              history.push(`/datumbrain/user/index`);
            } else if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ) {
              localStorage.setItem('currentUserRole', 'admin');
              history.push(`/datumbrain/admin/index`);
            }
          }
        })
        .catch(() => {
          toast.error('Failed to get logged in user data');
          history.push(`/auth/login`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='redirect-header'>
      <center className='d-flex flex-column align-items-center '>
        <RedirectSpinner text='Redirect .... page' />
      </center>
    </div>
  );
};

export default Logout;
