import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { sortLabels } from 'utils/helper-functions';
import DatumbrainIcon from 'assets/svg/datum-brain-invoice-icon.svg';
import InvoiceSeperator from 'assets/img/invoice-separator.png';
import './index.scss';
const BillingInfo = ({ values }) => {
  const [formValues, setFormValues] = useState(values);
  useEffect(() => {
    const sortedLabels = sortLabels(values.billing_info);
    setFormValues((prevState) => {
      return {
        ...prevState,
        billing_info: sortedLabels,
      };
    });
  }, [values]);
  return (
    <Container className='billing-information-preview'>
      <Row>
        <Col lg='6'>
          <div className='invoice-text'>INVOICE</div>
          <img
            alt='datumbrain'
            className='navbar-brand-img invoice-line'
            src={InvoiceSeperator}
          />
          <br />
          <div className='text-gray'>-- Invoice ID --</div>
          <br />
          <div className='invoice-name'>{formValues?.name}</div>
        </Col>
        <Col lg='6' className='invoice-icon-container'>
          <img
            height='35'
            alt='datumbrain'
            className='datum-brain-invoice-icon'
            src={DatumbrainIcon}
          />
        </Col>
      </Row>

      <div className='billing-to-text'>{formValues?.billing_info_label}</div>
      <>
        {formValues?.billing_info.map((info) => (
          <Row>
            <Col lg='4' className='billing-key'>
              {info?.label}
            </Col>
            <Col lg='8' className='billing-value'>
              {info?.value}
            </Col>
          </Row>
        ))}
      </>
    </Container>
  );
};

export default BillingInfo;
