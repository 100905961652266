import { API_URL } from 'utils/api/api-constants';
const ADMIN = `${API_URL}/admin`;
const CLIENT = `${API_URL}/admin/client`;
const USER = `${API_URL}/admin/user`;
const STAT = `${API_URL}/admin/stats`;
export const PROJECT_URL = `${API_URL}/admin/project`;

/* OWNER ROLES */
export const CHANGE_ROLE_URL = (userId) =>
  `${API_URL}/owner/user/${userId}/role`;

//Owner invoices

export const GET_INVOICES_URL = (pagesize, pagenumber) =>
  `${API_URL}/owner/invoice?page_size=${pagesize}&page_number=${pagenumber}`;
export const GET_ARCHIVED_INVOICES_URL = `${API_URL}/owner/invoice/archived`;

export const IS_BILLED_URL = (invoice_id, isChecked) =>
  `${API_URL}/owner/invoice/${invoice_id}/billed?status=${isChecked}`;
export const REDOWNLOAD_INVOICE = (invoice_id) =>
  `${API_URL}/owner/invoice/${invoice_id}/download`;
export const ARCHIVE_MULTIPLE_INVOICES = `${API_URL}/owner/invoice/archive`;
export const DELETE_MULTIPLE_INVOICES = `${API_URL}/owner/invoice`;

/* STATS */
export const ADMIN_STATS = STAT;

/* CLIENT */
export const CLIENT_URL = `${CLIENT}`;
export const CLIENT_CRUD = (clientID) => `${CLIENT}/${clientID}`;
export const ARCHIVE_MULTIPLE_CLIENTS = `${CLIENT}/archive`;
export const UNARCHIVE_MULTIPLE_CLIENTS = `${CLIENT}/unarchive`;
export const DELETE_MULTIPLE_CLIENTS = CLIENT;
export const GET_SUPPORTED_CURRENCIES = `${API_URL}/general/currencies`;

/* USER */
export const USER_URL = USER;
export const DELETE_MULTIPLE_USERS = USER;
export const ARCHIVE_MULTIPLE_USERS = `${USER}/archive`;
export const UNARCHIVE_MULTIPLE_USERS = `${USER}/unarchive`;
export const USER_CRUD = (userID) => `${USER}/${userID}`;
export const USER_ARCHIVE = (userID) => `${USER_CRUD(userID)}/archive`;
export const USER_UNARCHIVE = (userID) => `${USER_CRUD(userID)}/unarchive`;
export const USER_IS_MANAGER = (userID) => `${USER_CRUD(userID)}/manager`;
// export const GET_USER_URL = (userId) => `${USER}/${userId}`;
export const USER_INVITE = `${USER}/invite`;

/* PROJECTS */
export const PROJECT_CRUD = (projectID) => `${PROJECT_URL}/${projectID}`;
export const ARCHIVE_MULTIPLE_PROJECTS = `${PROJECT_URL}/archive`;
export const UNARCHIVE_MULTIPLE_PROJECTS = `${PROJECT_URL}/unarchive`;
export const DELETE_MULTIPLE_PROJECTS = PROJECT_URL;

/* USER TO PROJECT ASSOCIATION */
export const TEAM_URL = (projectID) => `${PROJECT_CRUD(projectID)}/team`;
export const ASSOCIATE_USER_TO_PROJECT_URL = (projectID, userID, flag) =>
  `${PROJECT_CRUD(projectID)}/team/${userID}?force=${flag ? 'true' : 'false'}`;

/* PROJECT LOGS */
export const LOGS_ADMIN_URL = (projectID, from, to) =>
  `${PROJECT_CRUD(projectID)}/log?from=${from}&to=${to}`;
export const USER_LOGS_ADMIN_URL = (projectID, from, to, userId) =>
  `${PROJECT_CRUD(projectID)}/user/${userId}/log?from=${from}&to=${to}`;
export const GET_USER_LOG_URL = (logId) => `${ADMIN}/log/${logId}`;

/* ACTIVITY */
export const ADMIN_ACTIVTY_URL = (from, to) =>
  `${API_URL}/activity?from=${from}&to=${to}`;
export const ADMIN_USERS_ACTIVTY_URL = (from, to) =>
  `${USER}/activity?from=${from}&to=${to}`;

/* WORKSPACE */
export const WHOAMI_URL = () => `${API_URL}/whoami`;

/*INTEGRATIONS*/
export const REQUEST_SLACK_INTEGRATION = `${API_URL}/owner/integration/slack/connect/auth`;
export const SEND_SLACK_CODE = (code) =>
  `${API_URL}/owner/integration/slack/connect?code=${code}`;

export const REQUEST_SLACK_UNINTEGRATION = `${API_URL}/owner/integration/slack/connect`;
export const GET_CHANNEL = `${API_URL}/owner/integration/slack/channel`;
export const LEAVE_CHANNEL = `${API_URL}/owner/integration/slack/channel`;
export const SELECT_CHANNEL = (channelId) =>
  `${API_URL}/owner/integration/slack/channel/${channelId}`;
export const GET_INTEGRATIONS = `${API_URL}/owner/integration`;

export const ATTENDANCE = (startDate, endDate) =>
  `${ADMIN}/attendance?start=${startDate}&end=${endDate}`;

export const ATTENDANCE_OVERVIEW = (startDate, endDate) =>
  `${ADMIN}/attendance/overview?start=${startDate}&end=${endDate}`;

export const ATTENDANCE_USER = (userId, startDate, endDate) =>
  `${ADMIN}/attendance/${userId}?start=${startDate}&end=${endDate}`;

export const LEAVE_CRUD = (uid) => `${ADMIN}/leaves${uid ? `/${uid}` : ''}`;

export const LEAVE_STATUS = (from, to) =>
  `${ADMIN}/leaves/status?from=${from}&to=${to}`;

export const GET_INVOICE_DATA = ({ projectId, from, to }) =>
  `${ADMIN}/project/${projectId}/invoice?from=${from}&to=${to}`;

export const INVITE_CLIENT = (clientId) => `${ADMIN}/client/${clientId}/invite`;

export const REVOKE_CLIENT = (clientId) =>
  `${ADMIN}/client/${clientId}/disable-login`;
export const UPDATE_OFFICE_TIME = `${ADMIN}/office/time`;
export const DELETE_LATE_COMING = (checkinId) =>
  `${ADMIN}/attendance/${checkinId}/late-comings`;
export const GET_LATE_CHECKINS_COUNT = ({ userId, startDate, endDate }) =>
  `${ADMIN}/attendance/${userId}/late?start=${startDate}&end=${endDate}`;

export const GET_USERS_STATUS_BY_PROJECT = (projectId) =>
  `${API_URL}/admin/status/project/${projectId}`;

export const ERP_DETAILS = `${API_URL}/admin/dashboard/erp/details`;

export const GET_ALL_STATUSES = `${ADMIN}/status/user`;
