import React from 'react';
import { Card, Container, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import './Admin.scss';
import Timeline from './Timeline/Timeline';
import StatCardComponent from './StatCardComponent/StatCardComponent';

const Header = () => {
  const history = useHistory();
  const isValidPath = history.location.pathname?.split('/')?.includes('index');

  return (
    <div
      className={`header bg-gradient-admin${
        isValidPath ? '-large' : ''
      } pb-7 pt-5 ${isValidPath ? 'pt-md-7' : 'pt-md-6'}`}
    >
      <Container fluid>
        <div className='header-body'>
          {isValidPath ? (
            <Card>
              <Col className='admin-header text-dark'>Time logs</Col>
              <Col className='col-custom-cards'>
                <StatCardComponent />
              </Col>
              <Col className='timeline-wrapper'>
                <Timeline />
              </Col>
            </Card>
          ) : (
            ''
          )}
        </div>
      </Container>
    </div>
  );
};

export default Header;
