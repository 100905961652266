import React from 'react';
import loadingGif from 'assets/gif/loading.gif';

export default function RedirectSpinner({ text }) {
  return (
    <div>
      <img src={loadingGif} alt='loading...' style={{ width: '300px' }} />
    </div>
  );
}
