import React, { useState, useEffect } from 'react';
import { addLogs, getLogsUser } from 'utils/api/api-requests/user';
import {
  createLogApiObject,
  getCustomProjectLogs,
} from 'utils/helper-functions';
import moment from 'moment';
import '../Logs.scss';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import CustomSlider from 'Components/Common/CustomSlider/CustomSlider';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import './AddModal.scss';
import { NUM_OF_DAYS_TO_ADD_LOG } from 'constants/index.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCustomSliderArray,
  setReasonForLateSitting,
  setTimeLogEndTime,
  setTimeLogStartTime,
  setWorkedOnWhat,
} from 'redux/actions/projects';
import {
  setProjectLogsList,
  setLogAddModal,
  setAreLogsBeingLoaded,
  setLogDate,
} from 'redux/actions/logs';
import { TIMESTAMP_WITH_ZONE } from 'constants/index';
import { setIsEditingTrackingLog } from '../../../../redux/actions/logs';

const Logs = () => {
  const dispatch = useDispatch();
  const {
    fromDateFilter,
    toDateFilter,
    customSliderArray,
    workedOnWhat,
    timeLogStartTime,
    timeLogEndTime,
    logDate,
    reasonForLateSitting,
    selectedProjectId,
    logAddModal,
  } = useSelector((state) => state);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  const setDefaultValues = () => {
    dispatch(setWorkedOnWhat(''));
    dispatch(setReasonForLateSitting(''));
    setIsSubmitButtonClicked(false);
    dispatch(setIsEditingTrackingLog(false));
  };
  const toggleAdd = (e) => {
    e?.preventDefault();
    setDefaultValues();
    dispatch(setCustomSliderArray([]));
    dispatch(setTimeLogStartTime(540));
    dispatch(
      setTimeLogEndTime(
        moment().format('YYYY-MM-DD') ===
          moment(logDate).format('YYYY-MM-DD') &&
          moment().minutes() + moment().hours() * 60 < 1080
          ? (Math.ceil(moment().minutes() + moment().hours() * 60 - 1) / 10) *
              10
          : 1080
      )
    );
    dispatch(
      setLogDate(moment(new Date(), TIMESTAMP_WITH_ZONE)?.format('YYYY-MM-DD'))
    );
    dispatch(setLogAddModal(!logAddModal));
  };
  const setLogDateWrapper = (date) => {
    dispatch(setLogDate(date));
    if (
      moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') &&
      moment().minutes() + moment().hours() * 60 < timeLogEndTime
    )
      setTimeLogEndTime(
        (Math.ceil(moment().minutes() + moment().hours() * 60 - 1) / 10) * 10
      );
  };
  useEffect(() => {
    setDefaultValues();
    dispatch(setTimeLogStartTime(540));
    dispatch(
      setTimeLogEndTime(
        moment().format('YYYY-MM-DD') ===
          moment(logDate).format('YYYY-MM-DD') &&
          moment().minutes() + moment().hours() * 60 < 1080
          ? (Math.ceil(moment().minutes() + moment().hours() * 60 - 1) / 10) *
              10
          : 1080
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addProjectLogHandler = (e) => {
    setIsSubmitButtonClicked(true);
    e?.preventDefault();
    let projectLogsArray = [...customSliderArray];
    if (
      workedOnWhat &&
      (timeLogEndTime - timeLogStartTime > 540 ? reasonForLateSitting : true)
    ) {
      if (workedOnWhat)
        projectLogsArray?.push({
          min: timeLogStartTime,
          max: timeLogEndTime,
          workedOnWhat: workedOnWhat,
          lateSitting: reasonForLateSitting,
        });

      projectLogsArray = projectLogsArray?.map(
        ({ workedOnWhat, lateSitting, min, max }) =>
          createLogApiObject(min, max, workedOnWhat, lateSitting, logDate)
      );
      dispatch(setAreLogsBeingLoaded(true));
      setIsSubmitButtonClicked(false);
      addLogs(selectedProjectId, projectLogsArray)
        .then(() => {
          toast.success('Project log added successfully.');
          getLogsUser(
            selectedProjectId,
            moment(fromDateFilter).format('YYYY-MM-DD'),
            moment(toDateFilter).format('YYYY-MM-DD')
          )
            .then(({ data }) => {
              dispatch(setProjectLogsList(getCustomProjectLogs(data, false)));
              toggleAdd(e);
              dispatch(setLogAddModal(false));

              dispatch(setAreLogsBeingLoaded(false));
            })
            .catch(() => {
              toast.error('Unable to fetch project logs.');
              toggleAdd(e);
              dispatch(setLogAddModal(false));
              dispatch(setAreLogsBeingLoaded(false));
            });
        })
        .catch(() => {
          toast.error('Unable to add project log.');
          toggleAdd(e);
          dispatch(setLogAddModal(false));
          dispatch(setAreLogsBeingLoaded(false));
        });
    }
  };

  const keyDownCalander = (event) => {
    const key = event.key;
    const allowedKeys = [
      'Tab',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Backspace',
      'Delete',
    ];

    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }
  };

  const keyPressCalander = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const enteredChar = String.fromCharCode(charCode);

    const validChars = /^[0-9]*$/;
    if (!validChars.test(enteredChar)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <CustomModal
        isOpen={logAddModal}
        toggle={toggleAdd}
        headerText='Add Time Log'
        confirmFunction={addProjectLogHandler}
        confirmButtonText='Submit'
        cancelButtonText='Cancel'
        customClass='time-log-modal'
      >
        <Form className='needs-validation' noValidate>
          <div className='log-form-group-wrapper'>
            <FormGroup className='log-form-group'>
              <Label className='mr-3' for='date'>
                Date
              </Label>
              <Input
                type='date'
                name='date'
                placeholder='date placeholder'
                min={moment()
                  .subtract(NUM_OF_DAYS_TO_ADD_LOG, 'days')
                  .format('YYYY-MM-DD')}
                value={logDate}
                onChange={(e) => setLogDateWrapper(e?.target?.value)}
                max={moment().format('YYYY-MM-DD')}
                onKeyDown={(e) => keyDownCalander(e)}
                onKeyPress={(e) => keyPressCalander(e)}
                disabled={customSliderArray?.length >= 1}
              />
            </FormGroup>
          </div>
          <FormGroup className='slider-form'>
            <div className='slider-component-wrapper'>
              {customSliderArray?.map(({ min, max, workedOnWhat }, index) => (
                <CustomSlider
                  key={index + min + max}
                  index={index}
                  disabled={true}
                  min={min}
                  max={max}
                  workedOnWhatProp={workedOnWhat}
                  multipleLogs={true}
                  sliderManipulateHandler={setDefaultValues}
                />
              ))}
              <CustomSlider
                index={100}
                disabled={false}
                min={timeLogStartTime}
                max={timeLogEndTime}
                multipleLogs={true}
                sliderManipulateHandler={setDefaultValues}
              />
            </div>
          </FormGroup>
          <div>
            <FormGroup
              className={`mr-3 form-group-w-100 ${
                !workedOnWhat && isSubmitButtonClicked
                  ? 'custom-invalid-feedback-date'
                  : ''
              }`}
            >
              <Label
                className={`${
                  !workedOnWhat && isSubmitButtonClicked
                    ? 'custom-invalid-label'
                    : ''
                }`}
                for='work'
              >
                Add details of your work
              </Label>
              <Input
                type='textarea'
                name='work'
                value={workedOnWhat}
                placeholder='Write complete detail of your work here...'
                className='work-details-text-area'
                rows='4'
                required
                onChange={(e) => dispatch(setWorkedOnWhat(e?.target?.value))}
              />
              <div
                className={`custom-invalid-feedback-text text-sm ${
                  !workedOnWhat && isSubmitButtonClicked ? '' : 'hidden-class'
                }`}
              >
                <i className='fas fa-exclamation-circle' />{' '}
                <span>Please fill this field</span>
              </div>
            </FormGroup>
            {timeLogEndTime - timeLogStartTime > 540 ? (
              <FormGroup
                className={`form-group-w-100 ${
                  !reasonForLateSitting &&
                  isSubmitButtonClicked &&
                  timeLogEndTime - timeLogStartTime > 540
                    ? 'custom-invalid-feedback-date'
                    : ''
                }`}
              >
                <Label
                  className={`${
                    !reasonForLateSitting &&
                    isSubmitButtonClicked &&
                    timeLogEndTime - timeLogStartTime > 540
                      ? 'custom-invalid-label'
                      : ''
                  }`}
                  for='lateSitting'
                >
                  Reason For Late Sitting
                </Label>
                <Input
                  type='textarea'
                  name='lateSitting'
                  value={reasonForLateSitting}
                  placeholder='Write a specific/genuine reason for working late...'
                  className='late-sitting-text-area'
                  rows='3'
                  required
                  onChange={(e) =>
                    dispatch(setReasonForLateSitting(e?.target?.value))
                  }
                />
                <div
                  className={`custom-invalid-feedback-text text-sm ${
                    !reasonForLateSitting && isSubmitButtonClicked
                      ? ''
                      : 'hidden-class'
                  }`}
                >
                  <i className='fas fa-exclamation-circle' />{' '}
                  <span>Please fill this field</span>
                </div>
              </FormGroup>
            ) : (
              ''
            )}
          </div>
        </Form>
      </CustomModal>
    </>
  );
};

export default Logs;
