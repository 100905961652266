import Logs from 'Components/Admin/Logs/Logs.js';
import ProjectContainer from 'Components/Admin/Project/ProjectContainer';
import Screen from 'Components/Admin/Project/Screen/Screen';
import User from 'Components/Admin/User/User';
import Client from 'Components/Admin/Client/Client';
import ClientDetails from 'Components/Admin/Client/Form/ClientDetails';
import AdminDashboard from 'Components/Dashboards/Admin';
import UserDashboard from 'Components/Dashboards/User';
import ActivityList from 'Components/Common/ActivityList/ActivityList';
import UserDetails from 'Components/Admin/User/UserDetails';
import LogDetails from 'Components/Admin/Logs/LogDetails';
import Manage from 'Components/Admin/User/Manage/Manage';
import ProjectActivity from 'Components/Common/ActivityList/ProjectActivity';
import ProjectDetails from 'Components/Admin/Project/ProjectDetails';
import InvoicesContainer from 'Components/Admin/Invoices/InvoicesContainer';
import Redirect from 'Components/Auth/RedirectPage';
import IntegrationCards from 'Components/Integration/IntegrationCards';
import Login from 'Components/Auth/Login';
import Profile from 'Components/Admin/User/UserProfile/Profile';
import SecurityAndPrivacy from 'Components/Admin/User/UserProfile/SecurityAndPrivacy';
import Checkin from 'Components/Admin//Checkin/Checkin';
// import LeaveModule from 'Components/Admin/LeaveModule/LeaveModule';
import EditEmployeesTable from './Components/Admin/Logs/LogsContainer/Invoice/Review/EditEmployeesTable';
import ClientSignUp from './Components/Auth/Client/Signup/Signup';
import ClientLogin from './Components/Auth/Client/Login/Login';
import ClientDashboard from './Components/Dashboards/Client/Client';
import ClientForgotPassword from './Components/Auth/Client/ForgotPassword/ForgotPassword';
import ClientResetPassword from './Components/Auth/Client/ResetPassword/ResetPassword';
import ProjectLogs from './Components/Dashboards/Client/ProjectLogs';

const routes = [
  {
    path: 'client/signup',
    name: 'Client',
    component: ClientSignUp,
    layout: `/auth`,
  },
  {
    path: 'client/login',
    name: 'Client',
    component: ClientLogin,
    layout: `/auth`,
  },
  {
    path: 'client/forgot-password',
    name: 'Client',
    component: ClientForgotPassword,
    layout: `/auth`,
  },
  {
    path: 'client/reset-password',
    name: 'Client',
    component: ClientResetPassword,
    layout: `/auth`,
  },
  {
    path: 'client/reset-password',
    name: 'Client',
    component: ClientLogin,
    layout: `/auth`,
  },
  {
    path: 'login',
    name: 'Login',
    component: Login,
    layout: `/auth`,
  },
  {
    path: 'redirect',
    name: 'Redirect',
    icon: '',
    component: Redirect,
    layout: `/:workspace/admin`,
  },
  {
    path: 'connect',
    name: 'Connect',
    icon: '',
    component: IntegrationCards,
    layout: `/:workspace/admin`,
  },
  {
    path: 'index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-danger',
    component: AdminDashboard,
    layout: `/:workspace/admin`,
  },
  {
    path: 'index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: UserDashboard,
    layout: `/:workspace/user`,
  },
  {
    path: 'manage',
    name: 'Manage',
    icon: 'ni ni-tv-2 text-primary',
    component: Manage,
    layout: `/:workspace/user`,
  },
  {
    path: 'projects/:projectId/activity',
    name: 'Project Activity',
    icon: 'ni ni-tv-2 text-primary',
    component: ProjectActivity,
    layout: `/:workspace/user`,
  },
  {
    path: 'users',
    name: 'Manage Employees',
    icon: 'ni ni-single-02 text-yellow',
    component: User,
    layout: `/:workspace/admin`,
  },
  {
    path: 'projects/:projectId/details',
    name: 'Project Details',
    icon: 'ni ni-bullet-list-67 text-red',
    component: ProjectDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'invoices',
    name: 'Invoices',
    icon: 'ni ni-bullet-list-67 text-red',
    component: InvoicesContainer,
    layout: `/:workspace/admin`,
  },
  {
    path: 'user/:userId',
    name: 'Employee',
    icon: 'ni ni-single-02 text-yellow',
    component: UserDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'projects/:projectId/logs',
    name: 'Project Logs',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Logs,
    layout: `/:workspace/admin`,
  },
  {
    path: 'log/:logId',
    name: 'Project Log',
    icon: 'ni ni-bullet-list-67 text-red',
    component: LogDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'log/:logId',
    name: 'Project Log',
    icon: 'ni ni-bullet-list-67 text-red',
    component: LogDetails,
    layout: `/:workspace/user`,
  },
  {
    path: 'project/:projectId/logs',
    name: 'Project Logs',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Logs,
    layout: `/:workspace/user`,
  },
  {
    path: 'projects',
    name: 'Projects',
    icon: 'ni ni-paper-diploma text-blue',
    component: ProjectContainer,
    layout: `/:workspace/admin`,
  },
  {
    path: 'clients/:clientId',
    name: 'Client',
    icon: 'ni ni-paper-diploma text-blue',
    component: ClientDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'clients/new',
    name: 'New Client',
    icon: 'ni ni-paper-diploma text-blue',
    component: ClientDetails,
    layout: `/:workspace/admin`,
  },
  {
    path: 'projects/:projectId',
    name: 'Project Screen',
    icon: 'ni ni-paper-diploma text-blue',
    component: Screen,
    layout: `/:workspace/admin`,
  },
  {
    path: 'projects/:projectId/invoice/review',
    name: 'Invoice Review',
    icon: 'ni ni-paper-diploma text-blue',
    component: EditEmployeesTable,
    layout: `/:workspace/admin`,
  },
  {
    path: 'activity',
    name: 'Activity',
    icon: 'ni ni-paper-diploma text-blue',
    component: ActivityList,
    layout: `/:workspace/admin`,
  },
  {
    path: 'activity',
    name: 'Activity',
    icon: 'fas fa-tasks text-blue',
    component: ActivityList,
    layout: `/:workspace/user`,
  },
  {
    path: 'projects',
    name: 'Projects',
    icon: 'ni ni-paper-diploma text-blue',
    component: ProjectContainer,
    layout: `/:workspace/user`,
  },
  {
    path: 'clients',
    name: 'Manage Clients',
    icon: 'ni ni-badge text-default',
    component: Client,
    layout: `/:workspace/admin`,
  },
  {
    path: 'profile',
    name: 'User Profile',
    icon: 'ni ni-badge text-default',
    component: Profile,
    layout: `/:workspace/admin`,
  },
  {
    path: 'profile',
    name: 'User Profile',
    icon: 'ni ni-badge text-default',
    component: Profile,
    layout: `/:workspace/user`,
  },
  {
    path: 'privacy',
    name: 'Privacy',
    icon: 'ni ni-badge text-default',
    component: SecurityAndPrivacy,
    layout: `/:workspace/admin`,
  },
  {
    path: 'privacy',
    name: 'Privacy',
    icon: 'ni ni-badge text-default',
    component: SecurityAndPrivacy,
    layout: `/:workspace/user`,
  },
  {
    path: 'checkins',
    name: 'Checkin',
    icon: 'ni ni-badge text-default',
    component: Checkin,
    layout: `/:workspace/admin`,
  },
  {
    path: 'checkins',
    name: 'Checkin',
    icon: 'ni ni-badge text-default',
    component: Checkin,
    layout: `/:workspace/user`,
  },
  // {
  //   path: 'leaves',
  //   name: 'My Leaves',
  //   icon: 'ni ni-badge text-default',
  //   component: LeaveModule,
  //   layout: `/:workspace/user`,
  // },
  // {
  //   path: 'leaves',
  //   name: 'Leave Management',
  //   icon: 'ni ni-badge text-default',
  //   component: LeaveModule,
  //   layout: `/:workspace/admin`,
  // },
  {
    path: 'dashboard',
    name: 'Client',
    component: ClientDashboard,
    layout: `/client`,
  },
  {
    path: 'projects/:uid/logs',
    name: 'Client',
    component: ProjectLogs,
    layout: `/client`,
  },
];
export default routes;
