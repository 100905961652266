export const setYesterdayGoals = (goals) => ({
  type: 'SET_YESTERDAY_GOALS',
  payload: goals,
});

export const setTodayGoals = (goals) => ({
  type: 'SET_TODAY_GOALS',
  payload: goals,
});

export const setBlockers = (blockers) => ({
  type: 'SET_BLOCKERS',
  payload: blockers,
});

export const setMetYesterdayGoals = (goals) => ({
  type: 'SET_MET_YESTERDAY_GOALS',
  payload: goals,
});

export const setUserStatus = (status) => ({
  type: 'SET_USER_STATUS',
  payload: status,
});

export const setIsCheckedIn = (checkedIn) => ({
  type: 'SET_IS_CHECKED_IN',
  payload: checkedIn,
});

export const setSelectedMood = (mood) => ({
  type: 'SET_SELECTED_MOOD',
  payload: mood,
});

export const setCurrentPagesChunk = (pagesChunk) => ({
  type: 'SET_CURRENT_PAGES_CHUNK',
  payload: pagesChunk,
});
