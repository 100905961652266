import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import { getProject } from '../../../../../../utils/api/api-requests/admin';
import { reviewInvoice } from '../../../../../../utils/api/api-requests/owner';
import Header from '../../../../../Headers/Admin/Admin';
import momentTimezone from 'moment-timezone';
import ReviewForm from './ReviewForm';

const EditEmployeesTable = () => {
  const { projectId } = useParams();
  const { fromDateFilter, toDateFilter } = useSelector((state) => state);
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projectHoursAllocated, setProjectHoursAllocated] = useState('');
  const [serverError, setServerError] = useState(false);
  const [retainer, setRetainer] = useState(false);
  const [retainerAmount, setRetainerAmount] = useState(null);

  const updateAllocatedHours = useCallback((hours) => {
    setProjectHoursAllocated(hours);
  }, []);
  const history = useHistory();

  useEffect(() => {
    const userTimezone = momentTimezone?.tz?.guess();
    const timezoneInfo = momentTimezone?.tz?.zone(userTimezone);
    const utcOffsetMinutes = timezoneInfo?.utcOffset(new Date());
    const utcOffsetHours = Math?.floor(Math?.abs(utcOffsetMinutes) / 60);
    setIsFetchingData(true);
    const startDate =
      fromDateFilter === toDateFilter &&
      fromDateFilter !== moment().format('YYYY-MM-DD')
        ? moment(toDateFilter)
            ?.utc()
            ?.utcOffset(utcOffsetHours)
            ?.format('YYYY-MM-DD')
        : fromDateFilter === toDateFilter &&
          fromDateFilter === moment().format('YYYY-MM-DD')
        ? moment(toDateFilter)
            ?.utc()
            ?.subtract(1, 'day')
            ?.utcOffset(utcOffsetHours)
            ?.format('YYYY-MM-DD')
        : moment(fromDateFilter)
            ?.utc()
            ?.utcOffset(utcOffsetHours)
            ?.format('YYYY-MM-DD');
    const endDate =
      moment().format('YYYY-MM-DD') === toDateFilter
        ? moment(toDateFilter)
            ?.utc()
            ?.subtract(1, 'day')
            ?.utcOffset(utcOffsetHours)
            ?.format('YYYY-MM-DD')
        : moment(toDateFilter)
            ?.utc()
            ?.utcOffset(utcOffsetHours)
            ?.format('YYYY-MM-DD');
    reviewInvoice({
      projectId,
      from: startDate,
      to: endDate,
    })
      .then((response) => {
        setServerError(false);
        setRetainerAmount(response?.data?.retainer_amount);
        setAssociatedUsers(response?.data?.user_log_details);
        setIsFetchingData(false);
      })
      .catch(() => {
        setServerError(true);
        toast.error('Unable to get invoice information.');
        setIsFetchingData(false);
      });
  }, [fromDateFilter, projectId, toDateFilter]);

  const getProjectInfo = useCallback(
    () =>
      getProject(projectId).then(({ data }) => {
        setProjectName(data?.name);
        setProjectHoursAllocated(data?.max_hours_allocated);
        return data;
      }),
    [projectId]
  );
  useEffect(() => {
    getProjectInfo();
  }, [getProjectInfo]);
  return (
    <div className='main-content'>
      <Header />
      <Container className='mt--5' fluid>
        <div className='col'>
          <Card className='shadow mb-5 review-card'>
            <CardHeader className='border-0 review-card-header'>
              <Row className='d-flex justify-content-between align-items-center'>
                <div className=' custom-breadcrumb-admin'>
                  <span onClick={() => history.go(-2)}>Projects / </span>
                  <span onClick={() => history.goBack()}>{projectName} / </span>
                  Review Invoice
                </div>
                <div className='retainer-checkbox'>
                  <div>Select template</div>
                  <div
                    onClick={() => setRetainer(false)}
                    className={`template-option${retainer ? '' : '-selected'}`}
                  >
                    Hourly
                  </div>
                  <div
                    onClick={() => setRetainer(true)}
                    className={`template-option${retainer ? '-selected' : ''}`}
                  >
                    Retainer
                  </div>
                </div>
              </Row>
            </CardHeader>

            <ReviewForm
              serverError={serverError}
              associatedUsers={associatedUsers}
              setAssociatedUsers={setAssociatedUsers}
              isFetchingData={isFetchingData}
              projectName={projectName}
              projectHoursAllocated={projectHoursAllocated}
              getProjectInfo={getProjectInfo}
              retainer={retainer}
              retainerAmount={retainerAmount}
              updateAllocatedHours={updateAllocatedHours}
            />
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default EditEmployeesTable;
