import React, { useState } from 'react';
import {
  UncontrolledTooltip,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';
import defaultImage from 'assets/img/theme/no-user-1.jpeg';

const LoggedByEmployeeRow = ({
  loggedEmployeeList,
  name,
  hours,
  activeUsers,
  cardNo,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <>
      {!loggedEmployeeList ? (
        <div className='card-employee-logged-loader' />
      ) : (
        <>
          {loggedEmployeeList?.length > 0 ? (
            <div className='d-flex align-items-center'>
              <span className='logged-by'>{'Logged by'}</span>
              <div className='avatar-group d-flex align-items-center'>
                {loggedEmployeeList
                  ?.filter((_, index) => index <= 3)
                  ?.map(({ name, uid, image_url }) => (
                    <>
                      <span
                        className='avatar avatar-sm rounded-circle'
                        id={`employee-${cardNo}-${uid}-${name
                          ?.split('')
                          ?.filter((e) => e?.trim()?.length)
                          ?.join('')}`}
                      >
                        <img
                          alt={`${name}-img`}
                          src={image_url == null ? defaultImage : image_url}
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defaultImage;
                          }}
                          width='40'
                        />
                      </span>
                      <UncontrolledTooltip
                        delay={0}
                        target={`employee-${cardNo}-${uid}-${name
                          ?.split('')
                          ?.filter((e) => e?.trim()?.length)
                          ?.join('')}`}
                      >
                        {name}
                      </UncontrolledTooltip>
                    </>
                  ))}
                {loggedEmployeeList?.length > 4 ? (
                  <>
                    <span
                      className='avatar avatar-sm rounded-circle cursor-pointer'
                      id={`employee-${cardNo}-logged-${name}`}
                      onClick={(e) => e?.preventDefault()}
                      onMouseEnter={() => setPopoverOpen(true)}
                    >
                      {`+${loggedEmployeeList?.length - 4}`}
                    </span>
                    <UncontrolledPopover
                      trigger='legacy'
                      onMouseLeave={() => setPopoverOpen(false)}
                      placement='left'
                      isOpen={popoverOpen}
                      target={`employee-${cardNo}-logged-${name}`}
                      toggle={toggle}
                      fade={true}
                      rootClose
                    >
                      <PopoverHeader>
                        <div className='d-flex flex-row align-items-baseline justify-content-between'>
                          Users List
                          <i
                            class='fas fa-times'
                            onClick={() => setPopoverOpen(false)}
                          />
                        </div>
                      </PopoverHeader>
                      <PopoverBody>
                        <div>
                          {loggedEmployeeList?.map(
                            ({ name, uid, image_url }, index) => (
                              <>
                                <span
                                  style={{ margin: '0.15rem' }}
                                  className='avatar avatar-sm rounded-circle cursor-pointer'
                                  id={`employee-${cardNo}-${uid}-${index}`}
                                >
                                  <img
                                    alt={`${name}-img`}
                                    src={
                                      image_url == null
                                        ? defaultImage
                                        : image_url
                                    }
                                    onError={(e) => {
                                      e.target.onError = null;
                                      e.target.src = defaultImage;
                                    }}
                                  />
                                </span>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`employee-${cardNo}-${uid}-${index}`}
                                >
                                  {name}
                                </UncontrolledTooltip>
                              </>
                            )
                          )}
                        </div>
                      </PopoverBody>
                      <div className='text-center p-2'>
                        <span>
                          {hours
                            ? `Hours logged (${hours})`
                            : `Users (${activeUsers})`}
                        </span>
                      </div>
                    </UncontrolledPopover>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <span className='no-user-logged'>No users logged time</span>
          )}
        </>
      )}
    </>
  );
};
export default LoggedByEmployeeRow;
