import ToggleButton from 'react-toggle-button';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import lateClockIcon from 'assets/svg/late-clock.svg';
import { deleteLateComing } from '../../../utils/api/api-requests/admin';
import { toast } from 'react-toastify';
const styles = {
  trackStyle: {
    width: '3rem',
  },
  thumbStyle: {
    width: '16px',
    height: '16px',
  },
  colors: {
    activeThumb: {
      base: 'rgb(255, 255, 255)',
    },
    inactiveThumb: {
      base: 'rgb(255, 255, 255)',
    },
    active: {
      base: 'rgb(255, 201, 82)',
    },
    inactive: {
      base: 'rgb(65,66,68)',
    },
  },
};
const CheckinPopover = ({
  firstCheckin,
  attendance,
  isLate,
  getAttendance,
}) => {
  const [toggleBtnValue, setToggleBtnValue] = useState(false);
  useEffect(() => {
    if (toggleBtnValue) {
      deleteLateComing(firstCheckin)
        .then(() => {
          toast.success('Late coming deleted.');
          getAttendance();
        })
        .catch(() => {
          toast.error('Unable to delete late coming.');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleBtnValue]);
  return (
    <div className='checkin-popover'>
      {attendance?.length > 1 && (
        <div className='checkin-popover-checkins'>
          <div className='popover-head'>All check-ins</div>
          <div className='popover-body'>
            {attendance?.map(({ check_in, check_out, working_remotely }) => (
              <div className='check_in-data'>
                <div className='timeslot'>
                  {moment(check_in).format('hh:mm A')} -{' '}
                  {check_out
                    ? moment(check_out).format('hh:mm A')
                    : 'In progress'}
                </div>
                <div>
                  {' '}
                  {working_remotely ? (
                    <i className='ml-1 fas fa-laptop-house work-from-icon text-success' />
                  ) : (
                    <i className='ml-1 fas fa-laptop work-from-icon text-success' />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isLate && (
        <div className='checkin-popover-late'>
          <div className='popover-head'>
            Latecomer <img src={lateClockIcon} alt='late-clock' />
          </div>
          <div className='popover-body'>
            <span>Latecomer indication</span>
            <div
              className={`${
                toggleBtnValue ? 'active' : 'inactive'
              }-late-switch`}
            >
              <ToggleButton
                inactiveLabel=''
                activeLabel=''
                trackStyle={styles.trackStyle}
                thumbStyle={styles.thumbStyle}
                value={toggleBtnValue}
                onToggle={(value) => {
                  setToggleBtnValue(!value);
                }}
                colors={styles.colors}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckinPopover;
