export const yesterdayGoals = (goals = '', { type, payload }) =>
  type === 'SET_YESTERDAY_GOALS' ? payload : goals;

export const todayGoals = (goals = '', { type, payload }) =>
  type === 'SET_TODAY_GOALS' ? payload : goals;

export const blockers = (blockers = '', { type, payload }) =>
  type === 'SET_BLOCKERS' ? payload : blockers;

export const selectedMood = (mood = 'Neutral', { type, payload }) =>
  type === 'SET_SELECTED_MOOD' ? payload : mood;

export const metYesterdayGoals = (goals = false, { type, payload }) =>
  type === 'SET_MET_YESTERDAY_GOALS' ? payload : goals;

export const userStatus = (status = null, { type, payload }) =>
  type === 'SET_USER_STATUS' ? payload : status;

export const isCheckedIn = (checkedIn = false, { type, payload }) =>
  type === 'SET_IS_CHECKED_IN' ? payload : checkedIn;

export const currentPagesChunk = (
  currentPagesChunk = [1, 2, 3],
  { type, payload }
) => (type === 'SET_CURRENT_PAGES_CHUNK' ? payload : currentPagesChunk);
