export class LoggedItem {
  name;
  job_title;
  hours_logged;
  hourly_rate;
  sub_total;
  constructor(name, job_title, hours_logged, hourly_rate, sub_total) {
    this.name = name;
    this.job_title = job_title;
    this.hours_logged = hours_logged;
    this.hourly_rate = hourly_rate;
    this.sub_total = sub_total;
  }
}
