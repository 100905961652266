import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { updateStateArray } from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import {
  archiveMultipleClients,
  getClients,
} from 'utils/api/api-requests/admin';
import RolesDropdown from './RolesDropdown';

const ClientTable = ({
  clients,
  areClientsBeingLoaded,
  setAreClientsBeingLoaded,
  selectedClients,
  setSelectedClients,
  archiveModal,
  setArchiveModal,
  updateClientsStatus,
}) => {
  const [clientData, setClientData] = useState(clients);
  const archiveMultipleClientsHandler = () => {
    archiveMultipleClients(selectedClients)
      .then(() => {
        setArchiveModal(false);
        setSelectedClients([]);
        toast.success('Clients archived successfully');
        getClients()
          .then(({ data }) => {
            updateClientsStatus(data);
            setAreClientsBeingLoaded(false);
          })
          .catch((error) => {
            toast.error(error);
            setAreClientsBeingLoaded(false);
          });
      })
      .catch(() => {
        toast.error('Unable to archive selected Clients');
      });
  };
  useEffect(() => {
    setClientData(clients);
  }, [clients]);

  return (
    <>
      {areClientsBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : clientData?.length > 0 ? (
        <>
          <Table className='align-items-center table-flush clients-table'>
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='clientCheckbox'
                      type='checkbox'
                      checked={clients?.length === selectedClients?.length}
                      onClick={(e) => {
                        setSelectedClients(
                          e?.target?.checked
                            ? clientData?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='clientCheckbox'
                    ></label>
                  </div>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Invites</th>
                <th className='w-5' scope='col' />
              </tr>
            </thead>
            <tbody>
              {clientData?.map(
                ({ uid, name, email, contact_number, roles }) => (
                  <tr key={uid}>
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedClients?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedClients,
                              setArray: setSelectedClients,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <Link
                        className='custom-link-class user-theme-color'
                        to={`/datumbrain/admin/clients/${uid}`}
                      >
                        {name}
                      </Link>
                    </td>
                    <td>{email || '-'}</td>
                    <td>{contact_number || '-'}</td>
                    <td>
                      <span className='link-bold'>
                        <RolesDropdown
                          roles={roles ? roles?.split(',') : []}
                          clientId={uid}
                        />
                      </span>
                    </td>
                    <td className='client-edit-button'>
                      <div>
                        <Link
                          className='custom-link-class user-theme-color'
                          to={`/datumbrain/admin/clients/${uid}`}
                        >
                          <i className='fas fa-pencil-alt fa-lg edit-btn client-edit-btn user-theme-color' />
                        </Link>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div button'>There are no clients yet</div>
      )}
      <CustomModal
        isOpen={archiveModal}
        toggle={() => setArchiveModal(!archiveModal)}
        headerText='Archive Clients'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={archiveMultipleClientsHandler}
      >
        <p>Are you sure you want to archive selected clients?</p>
      </CustomModal>
    </>
  );
};

export default ClientTable;
