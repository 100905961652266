import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'Components/Headers/Header';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Button,
  Spinner,
} from 'reactstrap';
import { getTeam, getProject } from 'utils/api/api-requests/admin';
import UserRoleRow from './UserRoleRow';
import UserModal from 'Components/Admin/Project/Modals/UserModal';
import { useHistory, useParams } from 'react-router-dom';
import {
  setProjectTeamArray,
  setSelectedProjectId,
} from 'redux/actions/projects';
import { setProjectUserModal } from 'redux/actions/modals';
import {
  setEmployeeRoleCustomSelectArray,
  setProjectTeamCustomSelectArray,
} from 'redux/actions/employee';

const Screen = () => {
  const { projectTeamArray } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [project, setProject] = useState({});
  const [didProjectInfoLoad, setDidProjectInfoLoad] = useState(false);

  useEffect(() => {
    dispatch(setSelectedProjectId(projectId));
    getProject(projectId)
      .then(({ data }) => {
        setProject(data);
        setDidProjectInfoLoad(true);
      })
      .catch(() => setDidProjectInfoLoad(false));
    getTeam(projectId).then(({ data }) => dispatch(setProjectTeamArray(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
  return (
    <>
      <div className='main-content'>
        <Header />

        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                {!didProjectInfoLoad ? (
                  <>
                    <CardHeader className='border-0' />
                    <div className='logs-spinner'>
                      <Spinner color='admin' />
                    </div>
                  </>
                ) : (
                  <>
                    <CardHeader className='border-0'>
                      <Row className='align-items-center'>
                        <div className='col custom-breadcrumb-admin'>
                          <span
                            onClick={() =>
                              history.push(`/datumbrain/admin/projects`)
                            }
                          >
                            Projects
                          </span>
                          {' / '}
                          {project?.name}
                        </div>
                        <div className='col text-right'>
                          <Button
                            className='admin-theme-button custom-btn-size'
                            color='primary'
                            onClick={(e) => {
                              e?.preventDefault();
                              dispatch(setProjectUserModal(true));
                              dispatch(setEmployeeRoleCustomSelectArray([]));
                              dispatch(setProjectTeamCustomSelectArray([]));
                            }}
                          >
                            <span>
                              <i className='fas fa-plus' /> Associate
                            </span>
                          </Button>
                        </div>
                      </Row>
                    </CardHeader>
                    {projectTeamArray?.length > 0 ? (
                      <>
                        <Table
                          className='align-items-center table-flush  user-role-table'
                          responsive
                        >
                          <thead className='thead-light'>
                            <tr>
                              <th>User</th>

                              <th>
                                <span className='padding-inline-half'>
                                  Role
                                </span>
                              </th>
                              <th>
                                <span className='padding-inline-half'>
                                  Rate
                                </span>
                              </th>
                              <th>
                                <span className='padding-inline-half'>
                                  Job Title
                                </span>
                              </th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {projectTeamArray?.map(
                              ({
                                uid,
                                role,
                                user_per_hour_rate,
                                name,
                                job_title,
                                email,
                                is_archived,
                                image_url,
                              }) => (
                                <tr
                                  className={`user-role-row${
                                    is_archived ? 'pink-row' : ''
                                  }`}
                                  key={`${uid} + ${projectId} + ${role}`}
                                >
                                  <UserRoleRow
                                    name={name}
                                    email={email}
                                    role={role}
                                    rate={user_per_hour_rate}
                                    jobTitle={job_title}
                                    userId={uid}
                                    managerFlag={
                                      projectTeamArray?.filter(
                                        ({ role }) => role === 'MANAGER'
                                      )?.length > 0
                                    }
                                    projectName={project?.name}
                                    imageUrl={image_url}
                                  />
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <div className='no-data-div'>
                        No employee is associated with this project.
                      </div>
                    )}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
        <UserModal projectName={project?.name} />
      </div>
    </>
  );
};

export default Screen;
