import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './Toast.scss';

const Toast = () => (
  <>
    <ToastContainer
      position='bottom-right'
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      enableMultiContainer
      limit={5}
    />
  </>
);

export default Toast;
