import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Label,
  Input,
} from 'reactstrap';
import { getManagerProjectsActivity } from 'utils/api/api-requests/user';
import Header from '../../Headers/User/User';
import ActivityCard from '../ActivityCard/ActivityCard';
import moment from 'moment';

const ProjectActivity = () => {
  const [projectActivityLogs, setProjectActivityLogs] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, 'days').format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const { projectId } = useParams();
  useEffect(() => {
    setIsDataLoading(true);
    getManagerProjectsActivity(projectId, fromDate, toDate)
      .then(({ data }) => {
        setProjectActivityLogs(data);
        setIsDataLoading(false);
      })
      .catch(() => {
        setIsDataLoading(false);
      });
  }, [projectId, fromDate, toDate]);
  return (
    <div className='main-content'>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              <CardHeader className='border-0 date-user-header'>
                <Row className='align-items-center'>
                  <div className='col'>
                    <h3 className='mb-0 text-dark'>Project Activity</h3>
                  </div>

                  <div className='activity-date-container'>
                    <Col sm='0'>
                      <Input
                        className='user-select-none'
                        type='date'
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(
                            moment(e.target.value).format('YYYY-MM-DD')
                          );
                          moment(e.target.value).format('YYYY-MM-DD') > toDate
                            ? setToDate(
                                moment(e.target.value)
                                  .add(1, 'days')
                                  .format('YYYY-MM-DD')
                              )
                            : setToDate(toDate);
                        }}
                        name='fromDate'
                        id='fromDate'
                        placeholder='date placeholder'
                      />
                    </Col>

                    <Col sm='0'>
                      <Label className='custom-label custom-date-to'>To</Label>
                    </Col>
                    <Col sm='0' className='d-flex'>
                      <Input
                        className='user-select-none'
                        type='date'
                        onChange={(e) =>
                          setToDate(moment(e.target.value).format('YYYY-MM-DD'))
                        }
                        min={moment(fromDate)
                          .add(1, 'day')
                          .format('YYYY-MM-DD')}
                        value={toDate}
                        name='toDate'
                        id='toDate'
                        placeholder='date placeholder'
                      />
                    </Col>
                  </div>
                </Row>
              </CardHeader>

              {isDataLoading ? (
                <div className='p-5 text-center'>
                  <Spinner color='admin' size='lg' />
                </div>
              ) : (
                <div className='activity-list'>
                  {projectActivityLogs?.length > 0 ? (
                    projectActivityLogs?.map((log) => (
                      <ActivityCard log={log} />
                    ))
                  ) : (
                    <div className='no-activity-data'>No activity found</div>
                  )}
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectActivity;
