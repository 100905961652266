import React from 'react';
import Header from 'Components/Headers/Header';
import { Card, CardHeader, Col, Container, Row, CardBody } from 'reactstrap';
import underDev from 'assets/gif/development.gif';

const SecurityAndPrivacy = () => {
  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5 mb-xl-0' xl='12'>
            <Card className='shadow'>
              <CardHeader className='border-0 projects-header'>
                <Row className='align-items-center'>
                  <div className='col'>
                    <h3 className='mb-0 text-dark'>Privacy</h3>
                  </div>

                  <div className='col text-right'></div>
                </Row>
              </CardHeader>
              <CardBody>
                <center>
                  <img
                    src={underDev}
                    alt='loading...'
                    style={{ width: '300px' }}
                  />
                  <p className='p-2 text-dark'>
                    This screen is under development.
                  </p>
                </center>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SecurityAndPrivacy;
