import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Header from '../../Headers/User/User';
import ProjectTable from './ProjectsTable';
import './styles.scss';
const Client = () => {
  return (
    <div className='main-content'>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              <CardHeader className='border-0 checkins-header'>
                <Row className='align-items-center'>
                  <div className='col d-flex align-items-center'>
                    <div className='workspace-heading'>
                      <h3 className='mb-0 text-dark fira-sans-text'>
                        Projects
                      </h3>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className='pl-0 pr-0 pt-0'>
                <ProjectTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Client;
