import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProjects, deleteProject } from 'utils/api/api-requests/admin';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { toast } from 'react-toastify';
import { setProjectsList, setSelectedProjectId } from 'redux/actions/projects';
import { setProjectDeleteModal } from 'redux/actions/modals';

const DeleteModal = () => {
  const { projectDeleteModal, selectedProjectId } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const toggleDelete = (projectId) => {
    dispatch(setSelectedProjectId(projectId));
    dispatch(setProjectDeleteModal(!projectDeleteModal));
  };
  const handleDeleteProject = (e) => {
    e?.preventDefault();
    deleteProject(selectedProjectId)
      .then(() => {
        toast.success('Project deleted successfully.');
        getProjects().then(({ data }) => dispatch(setProjectsList(data)));
      })
      .catch(() => toast.error('Unable to delete project.'));

    dispatch(setProjectDeleteModal(!projectDeleteModal));
  };

  return (
    <>
      <CustomModal
        isOpen={projectDeleteModal}
        toggle={toggleDelete}
        headerText='Delete project'
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        confirmFunction={handleDeleteProject}
      >
        <p>{'Are you sure you want to delete this project?'}</p>
      </CustomModal>
    </>
  );
};

export default DeleteModal;
