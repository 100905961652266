import React from 'react';
import { deleteLog, getLogsUser } from 'utils/api/api-requests/user';
import moment from 'moment';
import '../Logs.scss';
import { toast } from 'react-toastify';
import { getCustomProjectLogs } from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  setProjectLogsList,
  setLogDeleteModal,
  setAreLogsBeingLoaded,
} from 'redux/actions/logs';

const Logs = () => {
  const dispatch = useDispatch();
  const {
    fromDateFilter,
    toDateFilter,
    selectedProjectId,
    logDeleteModal,
    logID,
  } = useSelector((state) => state);

  const deleteLogHandler = (e) => {
    e?.preventDefault();
    deleteLog(logID, selectedProjectId)
      .then(() => {
        dispatch(setAreLogsBeingLoaded(true));
        toast.success('Log deleted successfully');
        getLogsUser(
          selectedProjectId,
          moment(fromDateFilter).utc().format('YYYY-MM-DD'),
          moment(toDateFilter).utc().format('YYYY-MM-DD')
        )
          .then(({ data }) => {
            dispatch(setProjectLogsList(getCustomProjectLogs(data, false)));
            dispatch(setLogDeleteModal(!logDeleteModal));
            dispatch(setLogDeleteModal(false));
            dispatch(setAreLogsBeingLoaded(false));
          })
          .catch(() => {
            toast.error('Unable to fetch project logs.');
            dispatch(setLogDeleteModal(!logDeleteModal));
            dispatch(setLogDeleteModal(false));
            dispatch(setAreLogsBeingLoaded(false));
          });
      })
      .catch(() => {
        toast.error('Unable to delete log');
        dispatch(setLogDeleteModal(false));
        dispatch(setAreLogsBeingLoaded(false));
      });
  };

  return (
    <>
      <CustomModal
        isOpen={logDeleteModal}
        toggle={() => dispatch(setLogDeleteModal(!logDeleteModal))}
        headerText='Delete Log'
        confirmFunction={deleteLogHandler}
        confirmButtonText='Confirm'
        cancelButtonText='Cancel'
      >
        <p>Are you sure you want to delete this log?</p>
      </CustomModal>
    </>
  );
};

export default Logs;
