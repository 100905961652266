import { getArchivedUsers } from 'utils/api/api-requests/admin';
import { getUsers } from '../../../utils/api/api-requests/admin';
export const setEmployeeDataHr = (employeeData) => ({
  type: 'SET_EMPLOYEE_DATA_HR',
  payload: employeeData,
});
export const setEmployeeEmergencyContactInfo = (
  employeeEmergencyContactInfo
) => ({
  type: 'SET_EMPLOYEE_EMERGENCY_CONTACT_INFO',
  payload: employeeEmergencyContactInfo,
});
export const setEmployeeBankInfo = (employeeBankInfo) => ({
  type: 'SET_EMPLOYEE_BANK_INFO',
  payload: employeeBankInfo,
});
export const setEmployeeRoleCustomSelectArray = (employeeRole) => ({
  type: 'SET_EMPLOYEE_ROLE_CUSTOM_SELECT_ARRAY',
  payload: employeeRole,
});
export const setProjectTeamCustomSelectArray = (projectTeam) => ({
  type: 'SET_PROJECT_TEAM_CUSTOM_SELECT_ARRAY',
  payload: projectTeam,
});

export const setUserList =
  ({ success, failure }) =>
  async (dispatch) => {
    try {
      const { data } = await getUsers();
      dispatch({
        type: 'SET_USER_LIST',
        payload: data ?? [],
      });
      success(data);
    } catch (error) {
      dispatch({
        type: 'SET_USER_LIST',
        payload: [],
      });
      // failure(error);
    }
  };
export const setArchivedUsersList =
  ({ success, failure }) =>
  async (dispatch) => {
    try {
      const { data } = await getArchivedUsers();
      dispatch({
        type: 'SET_ARCHIVED_USERS_LIST',
        payload: data ?? [],
      });
      success(data);
    } catch (error) {
      dispatch({
        type: 'SET_ARCHIVED_USERS_LIST',
        payload: [],
      });
      failure(error);
    }
  };

export const setWelcomeStatus = (status) => ({
  type: 'SET_WELCOME_STATUS',
  payload: status,
});
export const setEmployeeData = (employeeData) => ({
  type: 'SET_EMPLOYEE_DATA',
  payload: employeeData,
});

export const setProfileImage = (profileImage) => ({
  type: 'SET_PROFILE_IMAGE',
  payload:
    profileImage == null ? profileImage : 'assets/img/theme/no-user-1.jpeg',
});

export const setLeaveEditModal = (modal) => ({
  type: 'SET_LEAVE_EDIT_MODAL',
  payload: modal,
});

export const setLeaveDeleteModal = (modal) => ({
  type: 'SET_LEAVE_DELETE_MODAL',
  payload: modal,
});

export const setRemainingMedicalLeaves = (flag) => ({
  type: 'SET_REMAINING_MEDICAL_LEAVES',
  payload: flag,
});

export const setRemainingCasualLeaves = (flag) => ({
  type: 'SET_CASUAL_LEAVE_LIMIT',
  payload: flag,
});
