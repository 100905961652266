import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import AdminNavbar from 'Components/Common/Navbars/Admin';
import Sidebar from 'Components/Common/Sidebar/Sidebar.js';
import routes from 'routes';
import Toast from 'Components/Common/Toast/Toast';
import sideBarLinks from 'sidebar-links';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { setMploUser } from '../redux/actions/general';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'assets/plugins/nucleo/css/nucleo.css';

const AdminLayout = (props) => {
  const dispatch = useDispatch();
  const mainContent = React.useRef(null);
  const history = useHistory();
  const { mploUser, employeeRoleModal } = useSelector((state) => state);

  useEffect(() => {
    if (!localStorage.getItem('jwt-token')) {
      history.push('/auth/login');
    } else {
      whoamiApiRequest('datumbrain')
        .then(({ data: { name, roles, uid, email, image_url } }) => {
          const mploUser = {
            name,
            roles,
            uid,
            email,
            profileImage:
              image_url == null ? 'assets/img/theme/no-user-1.jpeg' : image_url,
          };
          dispatch(setMploUser(mploUser));
          if (roles?.includes('ADMIN')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            )
              localStorage.setItem('currentUserRole', 'admin');
            else if (roles?.includes('EMPLOYEE'))
              localStorage.setItem('currentUserRole', 'user');
          } else if (roles?.includes('EMPLOYEE')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'user'
            )
              localStorage.setItem('currentUserRole', 'user');
            else if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            )
              localStorage.setItem('currentUserRole', 'admin');
          }
        })
        .catch((errorMessage) => {
          if (errorMessage === 'token expired, please login again') {
            toast.error(errorMessage);
            localStorage.clear();
            history.push('/auth/login');
          } else toast.error('Failed to get logged in user data Admin');
        });
      const rolesArray = mploUser?.roles;
      if (
        rolesArray?.length > 0 &&
        rolesArray?.includes('EMPLOYEE') &&
        localStorage?.getItem('currentUserRole')?.toLowerCase() === 'user'
      )
        history.push(`/datumbrain/user/index`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, localStorage, employeeRoleModal]);

  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      if (prop.layout === `/:workspace/admin`) {
        return (
          <Route
            path={`${prop.layout}/${prop.path}`}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return '';
  };

  return (
    <>
      <Toast />

      <Sidebar
        {...props}
        routes={sideBarLinks.filter(
          ({ layout, label, name }) =>
            layout?.includes(`/admin`) ||
            (mploUser?.roles?.includes('OWNER')
              ? ['Owner Settings', 'Owner Finances'].includes(label)
              : ['Admin Settings'].includes(label)) ||
            ['Workspace'].includes(name) ||
            name === 'Logout'
        )}
        logo={{
          innerLink: `/datumbrain/admin/index`,
          imgSrc: require('assets/svg/Logo.svg').default,
          imgAlt: '...',
        }}
        screenLayout='admin'
      />
      <div className='main-content mb-5' ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from='*' to='' />
        </Switch>
      </div>
    </>
  );
};

export default AdminLayout;
