import React from 'react';
import Header from 'Components/Headers/Header';
import { useSelector } from 'react-redux';
import { Card, CardHeader, Col, Container, Row, CardBody } from 'reactstrap';
import ProfileContainer from './ProfileContainer/ProfileContainer';
import './Profile.scss';
import noUserImage from 'assets/img/theme/no-user-1.jpeg';

const Profile = () => {
  const {
    mploUser: { profileImage, name, email },
  } = useSelector((state) => state);
  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              <CardHeader>
                <Row className='align-items-center'>
                  <div className='col profile-header'>
                    <img
                      src={profileImage}
                      alt='....'
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = noUserImage;
                      }}
                      width='70'
                    />
                    <div>
                      {' '}
                      <h3>{name}</h3>
                      <p>{email}</p>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className='profile-card-body'>
                <ProfileContainer />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
