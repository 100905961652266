import * as Yup from 'yup';
export const getInitialValues = (users) => ({
  associatedUsers: users?.map(
    ({ uid, name, per_hour_rate, sub_total, time_logged, job_title }) => ({
      uid,
      name,
      per_hour_rate,
      sub_total,
      time_logged,
      job_title,
      isChecked: true,
    })
  ),
});

export const validationSchema = Yup.object().shape({
  associatedUsers: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      job_title: Yup.string().nullable(),
      per_hour_rate: Yup.number()
        .min(0, 'Rate cannot be negative')
        .required('Rate is required')
        .typeError('Rate cannot be empty'),
      time_logged: Yup.object({
        hours: Yup.number()
          .min(0, 'Hours cannot be negative')
          .typeError('Enter hours'),

        minutes: Yup.number()
          .min(0, 'Minutes cannot be negative')
          .max(59, 'Minutes must be < 60')
          .typeError('Enter minutes'),
      }),
    })
  ),
});
