import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import { updateWelcomeStatus } from 'utils/api/api-requests/user';
import { setWelcomeStatus } from '../../../redux/actions/employee';
import UserCheckinStatus from './UserCheckinStatus/UserCheckinStatus';
import './User.scss';
const Header = ({ showStatusUpateForm, setShowStatusUpateForm }) => {
  const welcomeStatus = useSelector(({ welcomeStatus }) => welcomeStatus);
  const [currentHour, setCurrentHour] = useState('');
  const dispatch = useDispatch();
  const mploUser = useSelector((state) => state.mploUser);

  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    const date = new Date();
    const hour = date.getHours();
    setCurrentHour(hour);
  }, [welcomeStatus]);
  const closeWelcomeMessage = async () => {
    const requestStatus = await updateWelcomeStatus();
    if (requestStatus?.status !== 200) {
      toast.error('Could not close message.');
    } else {
      dispatch(setWelcomeStatus(false));
    }
  };
  return (
    <div
      className={`header bg-gradient-user${
        pathname === '/datumbrain/user/index' ? '-large pb-6' : ' pb-5'
      } pt-md-4`}
    >
      {pathname === '/datumbrain/user/index' ? (
        <Container fluid>
          <div className='header-body'>
            <div className='col-md-12 user-welcome-container'>
              <p className='display-2 welcome-text flex-basis-80'>
                {currentHour >= 5 && currentHour < 12
                  ? 'Good morning'
                  : currentHour >= 12 && currentHour < 16
                  ? 'Good afternoon'
                  : currentHour >= 16 && currentHour < 22
                  ? 'Good evening'
                  : 'Good night'}
                {', '} {mploUser?.name?.split(' ')?.[0]}
                {'!'}
              </p>
              {false ? (
                <div className='welcome-sub-text-container'>
                  <div>
                    <i className='fas fa-times' onClick={closeWelcomeMessage} />
                  </div>
                  <p className='mt-0 '>
                    This is your profile page. You can see the progress you've
                    made with your work and manage your projects or assigned
                    tasks.
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {!showStatusUpateForm?.visible && (
            <UserCheckinStatus
              setShowStatusUpateForm={setShowStatusUpateForm}
            />
          )}
        </Container>
      ) : pathname === '/client/dashboard' ? (
        <Container fluid style={{ justifyContent: 'flex-end' }}>
          <div className='header-body'>
            <div className='col-md-12 user-welcome-container'>
              <p className='display-2 welcome-text flex-basis-80'>
                {currentHour >= 5 && currentHour < 12
                  ? 'Good morning'
                  : currentHour >= 12 && currentHour < 16
                  ? 'Good afternoon'
                  : currentHour >= 16 && currentHour < 22
                  ? 'Good evening'
                  : 'Good night'}
                {', '} {localStorage.getItem('client-name')?.split(' ')?.[0]}
                {'!'}
              </p>
            </div>
          </div>
        </Container>
      ) : (
        <div className='no-greeting-text'>&nbsp;</div>
      )}
    </div>
  );
};

export default Header;
