import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getLogsUser } from 'utils/api/api-requests/user';
import { getLogsAdmin, getUserLogsAdmin } from 'utils/api/api-requests/admin';
import { getCustomProjectLogs } from 'utils/helper-functions';

import { setProjectLogsList, setAreLogsBeingLoaded } from 'redux/actions/logs';

import './Logs.scss';

import LogsContainer from './LogsContainer/LogsContainer';
import { Container, Row } from 'reactstrap';
import Header from 'Components/Headers/Header.js';
import LogAddModal from './Modal/AddModal';
import LogEditModal from './Modal/EditModal';
import LogDeleteModal from './Modal/DeleteModal';

const Logs = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const refreshData = (projectId, fromDate, toDate, userId) => {
    if (fromDate > toDate) {
      toast.error('Invalid date range selected');
      return;
    }
    if (fromDate && toDate) {
      if (localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin') {
        dispatch(setAreLogsBeingLoaded(true));
        if (userId && userId !== 'all-users')
          getUserLogsAdmin(projectId, fromDate, toDate, userId)
            .then(({ data }) => {
              dispatch(setProjectLogsList(getCustomProjectLogs(data, true)));
              dispatch(setAreLogsBeingLoaded(false));
            })

            .catch((error) => {
              dispatch(setAreLogsBeingLoaded(false));
              if (error === 'token expired, please login again') {
                toast.error(error);
                localStorage.clear();
                history.push('/auth/login');
              } else toast?.error('Unable to fetch project logs list');
            });
        else
          getLogsAdmin(projectId, fromDate, toDate)
            .then(({ data }) => {
              dispatch(setProjectLogsList(getCustomProjectLogs(data, true)));
              dispatch(setAreLogsBeingLoaded(false));
            })
            .catch((error) => {
              dispatch(setAreLogsBeingLoaded(false));
              if (error === 'token expired, please login again') {
                toast.error(error);
                localStorage.clear();
                history.push('/auth/login');
              } else toast?.error('Unable to fetch project logs list');
            });
      } else if (
        localStorage.getItem('currentUserRole')?.toLowerCase() === 'user'
      ) {
        dispatch(setAreLogsBeingLoaded(true));
        getLogsUser(projectId, fromDate, toDate)
          .then(({ data }) => {
            dispatch(setProjectLogsList(getCustomProjectLogs(data, false)));
            dispatch(setAreLogsBeingLoaded(false));
          })
          .catch((error) => {
            dispatch(setAreLogsBeingLoaded(false));
            if (error === 'token expired, please login again') {
              toast.error(error);
              localStorage.clear();
              history.push('/auth/login');
            } else toast?.error('Unable to fetch project logs list');
          });
      }
    }
  };

  return (
    <>
      <div className='main-content'>
        <Header />
        <Container className='mt--5' fluid>
          <Row>
            <LogsContainer refreshData={refreshData} />
          </Row>
        </Container>
        <LogAddModal />
        <LogEditModal />
        <LogDeleteModal />
      </div>
    </>
  );
};

export default Logs;
