import React from 'react';
import validator from 'validator';
import { validate } from 'email-validator';
import './CustomFormGroup.scss';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
const CustomFormGroup = ({
  type,
  name,
  id,
  placeholder,
  value,
  setValue,
  iconClassName,
  invalidText,
  selectBoxText,
  selectBoxArray,
  didSubmitButtonClick,
  password,
  min,
  max,
  disabled,
  invalidTextFlag,
  marginBottomZero,
  position,
  iconRight,
  editHandler,
  required = true,
  appendedInfo,
  handleEnterPress,
}) => (
  <>
    <FormGroup
      className={`${
        name === 'selectViewUserRole' ||
        marginBottomZero ||
        position === 'tableCell'
          ? 'mb-0'
          : 'mb-3'
      }
       ${
         (name?.includes('Rate')
           ? value <= 0 || !value
           : name?.includes('confirm')
           ? value !== password
           : name?.includes('Phone')
           ? !validator.isMobilePhone(value, 'any', true)
           : !name?.includes('mail')
           ? !value
           : !validate(value)) &&
         didSubmitButtonClick &&
         invalidText
           ? 'custom-invalid-feedback'
           : ''
       }`}
    >
      <InputGroup className='input-group-alternative'>
        {iconClassName && (
          <InputGroupAddon addonType='prepend'>
            <InputGroupText className={`icon-width`}>
              <i className={iconClassName} />
            </InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          autoComplete='new-password'
          type={type}
          name={name + id}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e?.target?.value)}
          className={
            type !== 'select'
              ? type === 'number'
                ? 'input-number'
                : 'input-not-select'
              : `${value && !disabled ? 'main-text-color' : ''}`
          }
          required={required}
          disabled={disabled}
          min={min || ''}
          max={max || ''}
          onKeyPress={handleEnterPress ? handleEnterPress : () => {}}
        >
          {type === 'select' ? (
            <>
              {selectBoxText ? (
                <option value={0} selected={!value} disabled={value}>
                  {selectBoxText}
                </option>
              ) : (
                ''
              )}
              {selectBoxArray?.map(({ uid, name }) => (
                <option value={uid} key={uid} selected={uid === value}>
                  {name}
                </option>
              ))}
            </>
          ) : (
            <></>
          )}
        </Input>
        {name === 'userRate' && disabled ? (
          <InputGroupAddon addonType='append'>
            <InputGroupText className={`icon-width edit-color`}>
              <i
                className={iconRight}
                onClick={editHandler ? editHandler : () => {}}
              />
            </InputGroupText>
          </InputGroupAddon>
        ) : (
          ''
        )}
        {appendedInfo && (
          <InputGroupAddon addonType='append' className='form-group-addon'>
            <InputGroupText className='icon-width'>
              {appendedInfo?.type === 'image' ? (
                <img src={appendedInfo?.value} alt={appendedInfo?.value} />
              ) : (
                appendedInfo?.value
              )}
            </InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
    </FormGroup>
    {name !== 'selectView' && position !== 'tableCell' && !invalidTextFlag ? (
      <div
        className={`${required ? 'custom-invalid-feedback-text' : ''} mt--3
          ${
            (name?.includes('Rate')
              ? value <= 0 || !value
              : name?.includes('confirm')
              ? value !== password
              : name?.includes('Phone')
              ? !validator.isMobilePhone(value)
              : !name?.includes('mail')
              ? !value && didSubmitButtonClick
              : !validate(value)) && didSubmitButtonClick
              ? ''
              : ' hidden-class'
          }`}
      >
        {invalidText && (
          <>
            <i className='fas fa-exclamation-circle' />{' '}
            <span>{invalidText}</span>
          </>
        )}
      </div>
    ) : (
      ''
    )}
  </>
);

export default CustomFormGroup;
