import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { makeCSVlogObject, isUserLoggedIn } from 'utils/helper-functions';
import moment from 'moment';
import ExoprtIcon from 'assets/img/active-export.png';

const CSVExporter = ({
  isClient,
  projectLogs,
  selectedLogs,
  projectName,
  totalHoursLogged,
}) => {
  const { projectLogsList, fromDateFilter, toDateFilter } = useSelector(
    (state) => state
  );
  const [csvLogs, setCsvLogs] = useState(
    makeCSVlogObject(isClient ? projectLogs : projectLogsList)
  );
  const [headersArray, setHeadersArray] = useState([]);
  const mploUser = useSelector((state) => state?.mploUser);
  const totalHours = {
    id: '',
    loggedBy: '',
    date: '',
    timeStart: 'Total time',
    timeEnd: totalHoursLogged,
  };
  useEffect(() => {
    let headersArrayVariable = [{ label: 'Logged By', key: 'loggedBy' }];
    if (isUserLoggedIn()) headersArrayVariable = [];
    headersArrayVariable?.push(
      { label: 'ID', key: 'id' },
      { label: 'Date', key: 'date' },
      { label: 'Start Time', key: 'timeStart' },
      { label: 'End Time', key: 'timeEnd' },
      { label: 'Hours Logged', key: 'hours' },
      { label: 'Details', key: 'worked' },
      { label: 'Late Sitting', key: 'lateSitting' }
    );
    setHeadersArray(headersArrayVariable);
    setCsvLogs(
      makeCSVlogObject(
        (isClient ? projectLogs : projectLogsList)?.filter(({ uid }) =>
          selectedLogs?.includes(uid)
        )
      )
    );
    setCsvLogs((prevLogs) => [...prevLogs, totalHours]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient, projectLogs, projectLogsList, selectedLogs]);

  return (
    <>
      <CSVLink
        headers={headersArray}
        data={csvLogs}
        filename={`${projectName}-${moment(fromDateFilter, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )}-to-${moment(toDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY')}${
          isUserLoggedIn() ? `_${mploUser?.name}` : ''
        }`}
        onClick={() => {
          if (selectedLogs?.length === 0) return false;
        }}
      >
        <div>
          <img className='export-icon' alt='...' src={ExoprtIcon} />
          <div>Export</div>
        </div>
      </CSVLink>
    </>
  );
};

export default CSVExporter;
