import moment from 'moment';
import { TIMESTAMP_WITH_ZONE } from 'constants/index';
import { BASE_URL } from 'constants/index';
import { MARKDOWN_CODE_STYLE } from 'constants/index';
import defaultImage from 'assets/img/theme/no-user-1.jpeg';

export const getCustomProjectLogs = (data, isAdmin) => {
  let projectLogs = [];
  if (isAdmin) {
    data?.map(({ logs, user_name, user_is_archived }) =>
      logs?.map(
        ({
          uid,
          time_start,
          time_end,
          worked_on_what,
          reason_for_late_sitting,
          tracking,
        }) => {
          projectLogs.push({
            uid,
            loggedBy: user_name,
            date: moment(time_start, 'YYYY-MM-DDTHH:mm:ssZ').format(
              'YYYY-MM-DD'
            ),
            timeStart: moment(time_start, TIMESTAMP_WITH_ZONE).format(
              'hh:mm A'
            ),
            timeEnd: moment(time_end, TIMESTAMP_WITH_ZONE).format('hh:mm A'),
            hours: parseInt(
              moment(time_end, TIMESTAMP_WITH_ZONE).diff(
                moment(time_start, TIMESTAMP_WITH_ZONE),
                'mm'
              )
            ),
            worked: worked_on_what,
            lateSitting: reason_for_late_sitting
              ? reason_for_late_sitting
              : '-',
            archived: user_is_archived,
            tracking,
          });
          return 1;
        }
      )
    );
  } else {
    data?.map(
      ({
        uid,
        time_start,
        time_end,
        worked_on_what,
        reason_for_late_sitting,
        tracking,
      }) => {
        projectLogs?.push({
          uid,
          date: moment(time_start, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'),
          timeStart: moment(time_start, TIMESTAMP_WITH_ZONE).format('hh:mm A'),
          timeEnd: moment(time_end, TIMESTAMP_WITH_ZONE).format('hh:mm A'),

          hours: parseInt(
            moment(time_end, TIMESTAMP_WITH_ZONE).diff(
              moment(time_start, TIMESTAMP_WITH_ZONE),
              'mm'
            )
          ),

          worked: worked_on_what,
          lateSitting: reason_for_late_sitting ? reason_for_late_sitting : '-',
          tracking,
        });
        return 1;
      }
    );
  }
  return projectLogs;
};

export const generateCardInfoLogs = (
  title,
  { hours, minutes },
  progressBarValue,
  cardStatPercentage,
  hoursAllowed,
  activeUsersList,
  sinceTimeText,
  CustomComponent
) => ({
  title,
  hours,
  minutes,
  progressBarValue,
  hoursAllowed,
  percentageArrow: cardStatPercentage < 0 ? 'down' : 'up',
  percentageValue:
    cardStatPercentage < 0 ? -1 * cardStatPercentage : cardStatPercentage,
  sinceTimeText,
  activeUsersList,
  CustomComponent,
});

export const generateCardInfoUsers = (
  title,
  userValue,
  cardStatPercentage,
  activeUsersList,
  sinceTimeText,
  CustomComponent
) => ({
  title,
  userValue,
  userText:
    userValue === 0
      ? 'No active users'
      : userValue === 1
      ? 'active user'
      : 'active users',
  percentageArrow: cardStatPercentage < 0 ? 'down' : 'up',
  percentageValue:
    cardStatPercentage < 0 ? -1 * cardStatPercentage : cardStatPercentage,
  sinceTimeText,
  CustomComponent,
  activeUsersList,
});

export const generateCustomComponent = (variant, icon) => (
  <div
    className={`icon icon-shape bg-${variant} text-white rounded-circle shadow`}
  >
    <i className={`fas fa-${icon}`} />
  </div>
);
export const isAdminLoggedIn = () =>
  localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin';
export const isUserLoggedIn = () =>
  ['user', 'employee'].includes(
    localStorage.getItem('currentUserRole')?.toLowerCase()
  );
export const toggleDate = ({ uid, array, setArray }) => {
  if (array?.includes(uid)) {
    setArray(array?.filter((objectId) => objectId !== uid));
  } else {
    setArray([...array, uid]);
  }
};

export const getZonedTime = ({ value, type }) =>
  type === 'hours'
    ? Math.floor(moment.duration(value, 'milliseconds').asHours())
    : moment(moment(value).utcOffset(0)).minutes();

export const formatLoggedTime = ({ hours, minutes }, text = '') =>
  `${
    hours || minutes
      ? `${
          hours === 0
            ? ''
            : hours === 1
            ? hours + `${text === 'short' ? 'h ' : ' hr'}`
            : hours + `${text === 'short' ? 'h ' : ' hrs'}`
        }${minutes !== 0 && hours !== 0 ? ' ' : ''}${
          minutes === 0
            ? ''
            : minutes === 1
            ? minutes + `${text === 'short' ? 'm' : ' min'}`
            : minutes + `${text === 'short' ? 'm' : ' mins'}`
        }${hours === 0 && minutes === 0 ? '-' : ''}`
      : `${text === 'short' ? '-- --' : 'No hours logged '}`
  }`;
export const setFilterDates = (
  currentView,
  dispatch,
  setFromDateFilter,
  setToDateFilter
) => {
  if (currentView === 'THIS_WEEK') {
    const mondayDate = moment().isoWeekday(1).format('YYYY-MM-DD');
    dispatch(setFromDateFilter(mondayDate));
    dispatch(setToDateFilter(moment().format('YYYY-MM-DD')));
  } else if (currentView === 'LAST_WEEK') {
    const lastWeekIndex = moment().week();
    const lastMondayDate = moment(lastWeekIndex, 'w')
      .isoWeekday(1)
      .format('YYYY-MM-DD');
    const lastSundayDate = moment(lastWeekIndex, 'w')
      .isoWeekday(7)
      .format('YYYY-MM-DD');
    dispatch(setFromDateFilter(lastMondayDate));
    dispatch(setToDateFilter(lastSundayDate));
  } else if (currentView === 'THIS_MONTH') {
    const currentMonth = moment().month() + 1;
    const firstDateOfMonth = `${moment().year()}-${
      currentMonth.toString().length === 1 ? '0' + currentMonth : currentMonth
    }-01`;
    dispatch(setFromDateFilter(firstDateOfMonth));
    dispatch(setToDateFilter(moment().format('YYYY-MM-DD')));
  } else if (currentView === 'LAST_MONTH') {
    const firstDate = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    const lastDate = moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');

    dispatch(setFromDateFilter(firstDate));
    dispatch(setToDateFilter(lastDate));
  } else if (currentView === 'THIS_YEAR') {
    const firstDate = `${moment().year()}-01-01`;
    dispatch(setFromDateFilter(firstDate));
    dispatch(setToDateFilter(moment().format('YYYY-MM-DD')));
  }
};

export const setFilterDatesInverse = (
  fromDate,
  toDate,
  dispatch,
  setLogsViewMode
) => {
  let start = moment(fromDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
  let end = moment(toDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

  let thisWeekMonday = moment().startOf('isoweek').format('YYYY-MM-DD');
  let firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
  let startOfYear = moment().startOf('year').format('YYYY-MM-DD');

  let lastWeekMonday = moment()
    .startOf('isoweek')
    .subtract(1, 'weeks')
    .format('YYYY-MM-DD');
  let lastWeekSunday = moment()
    .endOf('isoweek')
    .subtract(1, 'weeks')
    .format('YYYY-MM-DD');

  let firstDayOfLastMonth = moment()
    .startOf('month')
    .subtract(1, 'month')
    .format('YYYY-MM-DD');
  let lastDayOfLastMonth = moment()
    .startOf('month')
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  if (end === moment().format('YYYY-MM-DD')) {
    if (thisWeekMonday === start) dispatch(setLogsViewMode('THIS_WEEK'));
    else if (firstDayOfCurrentMonth === start)
      dispatch(setLogsViewMode('THIS_MONTH'));
    else if (startOfYear === start) dispatch(setLogsViewMode('THIS_YEAR'));
    else dispatch(setLogsViewMode('CUSTOM_DATE'));
  } else if (start === lastWeekMonday && end === lastWeekSunday)
    dispatch(setLogsViewMode('LAST_WEEK'));
  else if (firstDayOfLastMonth === start && lastDayOfLastMonth === end)
    dispatch(setLogsViewMode('LAST_MONTH'));
  else dispatch(setLogsViewMode('CUSTOM_DATE'));
};

export const setValidatedDateRange = ({ date1, date2, setDate2 }) => {
  if (date1 > date2) {
    setDate2(date1);
  }
};

export const createLogApiObject = (
  min,
  max,
  workedOnWhat,
  reasonForLateSitting,
  date,
  isEditingTrackingLog
) => {
  let startTime = moment
    .utc()
    .startOf('day')
    .add(min, 'minutes')
    .format('hh:mm:ss a');
  let endTime = moment
    .utc()
    .startOf('day')
    .add(max, 'minutes')
    .format('hh:mm:ss a');
  return {
    time_start: moment(`${date} ${startTime}`, 'YYYY-MM-DDHH:mm:ss a').format(
      'YYYY-MM-DDTHH:mm:ssZ'
    ),
    time_end: isEditingTrackingLog
      ? null
      : moment(`${date} ${endTime}`, 'YYYY-MM-DDHH:mm:ss a').format(
          'YYYY-MM-DDTHH:mm:ssZ'
        ),
    worked_on_what: workedOnWhat,
    reason_for_late_sitting:
      reasonForLateSitting === '' ? '-' : reasonForLateSitting,
  };
};

export const makeCSVlogObject = (projectLogArray) =>
  projectLogArray.map(
    ({
      id,
      loggedBy,
      date,
      timeStart,
      timeEnd,
      hours,
      worked,
      lateSitting,
    }) => ({
      id,
      loggedBy,
      date,
      timeStart,
      timeEnd,
      lateSitting,
      worked,
      hours: formatLoggedTime({
        hours: getZonedTime({
          type: 'hours',
          value: hours,
        }),
        minutes: getZonedTime({
          type: 'minutes',
          value: hours,
        }),
      }),
    })
  );
export const getWeekdays = () => {
  const array = [1, 2, 3, 4, 5, 6, 0];
  return array?.map((day) => moment(day, 'd').format('ddd'));
};

export const toggleName = ({ uid, array, setArray }) => {
  if (array?.includes(uid)) {
    setArray(array?.filter((objectId) => objectId !== uid));
  } else {
    setArray([...array, uid]);
  }
};
export const getTimerValue = (seconds) =>
  new Date(seconds * 1000).toISOString().substr(11, 8);

export const updateStateArray = ({ array, setArray, value }) =>
  array?.includes(value)
    ? setArray(array?.filter((item) => item !== value))
    : setArray([...array, value]);
export const extractValues = (str, user) => {
  let startIndex = str.indexOf('<', 0) + 1;
  while (startIndex > 0) {
    let endIndex = str.indexOf('>', startIndex);
    let substr = str.substr(startIndex, endIndex - startIndex);
    let newsubstr = getLink(substr.split('|'), user);
    str = str.replace(substr, newsubstr);
    startIndex = str.indexOf('<', startIndex + newsubstr?.length) + 1;
  }
  return str;
};

const getLink = (opts, user) => {
  switch (opts[1]) {
    case 'USER':
      return `a href="${
        user !== 'admin'
          ? '#'
          : `${BASE_URL}/datumbrain/${user}/user/${opts[2]}/profile`
      }">${opts[0]}</a`;
    case 'LOG':
      return `a href="${
        user !== 'admin' ? '#' : `${BASE_URL}/datumbrain/${user}/log/${opts[2]}`
      }">${opts[0]}</a`;
    case 'PROJECT':
      return `a href='${
        user !== 'admin'
          ? '#'
          : `${BASE_URL}/datumbrain/${user}/project/${opts[2]}/details`
      }'>${opts[0]}</a`;
    case 'CLIENT':
      return `a href='${
        user !== 'admin'
          ? '#'
          : `${BASE_URL}/datumbrain/${user}/clients/${opts[2]}`
      }'>${opts[0]}</a`;
    case 'B':
      return `span style="color:#ccc;font-weight:400;">${opts[0]}</span`;
    case 'I':
      return `i>${opts[0]}</i`;
    default:
      return `a href='#'>${opts[0]}</a`;
  }
};

const landingPageHost = process.env.REACT_APP_LANDING_PAGE;
export const redirectToLandingPage = (path) =>
  window.location.replace(
    `${
      landingPageHost?.endsWith('/')
        ? landingPageHost.slice(0, -1)
        : landingPageHost
    }/${path}`,
    '_blank'
  );

export const redirectToExternalLink = (link) =>
  window.location.replace(link, '_blank');
export const parseMarkdown = (markdownText) =>
  markdownText
    ?.replace(/`(.*?)`/gim, MARKDOWN_CODE_STYLE)
    ?.replace(/^-(.*?)$/gim, '<li>$1</li>')
    ?.replace(/^### (.*$)/gim, '<h3>$1</h3>')
    ?.replace(/^## (.*$)/gim, '<h2>$1</h2>')
    ?.replace(/^# (.*$)/gim, '<h1>$1</h1>')
    ?.replace(/^ (.*$)/gim, '<blockquote>$1</blockquote>')
    ?.replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
    ?.replace(/\*(.*)\*/gim, '<i>$1</i>')
    ?.replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    ?.replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    ?.replace(/\n$/gim, '<br />')
    ?.trim();

export const useQuery = (useLocation) => {
  return new URLSearchParams(useLocation().search);
};

export const validateCnic = (cnic) => {
  let regexp = new RegExp('^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$');
  return regexp.test(cnic);
};

export const filterViews = [
  { uid: 'THIS_WEEK', name: 'This week' },
  { uid: 'LAST_WEEK', name: 'Last week' },
  { uid: 'THIS_MONTH', name: 'This month' },
  { uid: 'LAST_MONTH', name: 'Last month' },
  { uid: 'THIS_YEAR', name: 'This year' },
  { uid: 'CUSTOM_DATE', name: 'Custom date' },
];

export const makeAdminCheckins = ({ attendance, name, image_url }) => {
  let customCheckin = [];
  attendance?.map(({ check_in, check_out, uid, working_remotely }) => {
    customCheckin.push({
      check_in,
      check_out,
      uid,
      working_remotely,
      user: {
        name,
        image_url,
      },
    });
    return 1;
  });
  return customCheckin;
};

export const makeCustomLeaveDataUser = (data) => {
  let customLeaveData = [];
  data?.forEach(({ uid, description, leave_type, leave_dates }) => {
    let leaveUid = uid;
    leave_dates?.forEach(
      ({
        uid,
        date,
        urgent,
        approver,
        disapprover,
        note,
        project_wise_approvals,
      }) => {
        customLeaveData.push({
          leaveUid,
          description,
          leave_type,
          uid,
          date,
          urgent,
          approver,
          disapprover,
          note,
          project_wise_approvals,
        });
      }
    );
  });
  return customLeaveData;
};

export const makeCustomLeaveDataAdmin = (data) => {
  let customLeaveData = [];
  data?.forEach(({ image_url, name, uid, leave }) => {
    let userUid = uid;
    leave?.forEach(({ uid, description, leave_type, leave_dates }) => {
      customLeaveData.push({
        image_url,
        name,
        userUid,
        uid,
        description,
        leave_type,
        leave_dates,
      });
    });
  });
  return customLeaveData;
};

export const firstDayofLeave = (leaveDates) =>
  leaveDates
    ?.map(({ date }) => moment(date).format('YYYY-MM-D'))
    ?.sort((a, b) => {
      if (moment(a).isBefore(b)) {
        return -1;
      }
      if (moment(a).isAfter(b)) {
        return 1;
      }
      return 0;
    })?.[0];

export const leaveDateIsApprovedAdmin = ({
  admin_approver,
  admin_disapprover,
  manager_approver,
  manager_disapprover,
}) => {
  if (admin_approver) return 'APPROVED';
  else if (admin_disapprover) return 'DISAPPROVED';
  if (manager_approver) return 'PENDING';
  else if (manager_disapprover) return 'PENDING';
  return 'PENDING';
};

export const leaveStatusAdmin = (leaveDates) => {
  let status = 'NEW';
  leaveDates = leaveDates?.map(
    ({
      admin_approver,
      admin_disapprover,
      manager_approver,
      manager_disapprover,
    }) => {
      return leaveDateIsApprovedAdmin({
        admin_approver,
        admin_disapprover,
        manager_approver,
        manager_disapprover,
      });
    }
  );
  status = leaveDates?.find((element) => element === 'PENDING');
  if (!status) {
    status = leaveDates?.find((element) => element === 'APPROVED');
    if (!status) {
      status = leaveDates?.find((element) => element === 'DISAPPROVED');

      if (!status) return 'PENDING';
      else return 'DISAPPROVED';
    } else if (status === 'APPROVED') {
      status = leaveDates?.find((element) => element === 'DISAPPROVED');
      if (!status) return 'APPROVED';
      else return 'PARTIALLY APPROVED';
    }
  }
  return status;
};

export const approvalsListAdmin = (leaveDates) => {
  let admin = 'new';
  let manager = 'new';

  leaveDates?.forEach(
    ({
      admin_approver,
      admin_disapprover,
      manager_approver,
      manager_disapprover,
    }) => {
      if (admin !== 'pending') {
        if (admin_approver) admin = 'approved';
        else if (!admin_disapprover) admin = 'pending';
        else if (admin !== 'approved') admin = 'disapproved';
      }
      if (manager !== 'pending') {
        if (manager_approver) manager = 'approved';
        else if (!manager_disapprover) manager = 'pending';
        else if (manager !== 'approved') manager = 'disapproved';
      }
    }
  );
  return { admin, manager };
};

export const leaveDateIsApprovedManager = ({
  admin_approver,
  admin_disapprover,
  manager_approver,
  manager_disapprover,
}) => {
  if (admin_approver) return 'APPROVED';
  else if (admin_disapprover) return 'DISAPPROVED';
  if (manager_approver) return 'APPROVED';
  else if (manager_disapprover) return 'DISAPPROVED';
  return 'PENDING';
};

export const leaveStatusManager = (leaveDates) => {
  let status = '';
  leaveDates = leaveDates?.map(
    ({
      admin_approver,
      admin_disapprover,
      manager_approver,
      manager_disapprover,
    }) => {
      return leaveDateIsApprovedManager({
        admin_approver,
        admin_disapprover,
        manager_approver,
        manager_disapprover,
      });
    }
  );
  status = leaveDates?.find((element) => element === 'PENDING');
  if (status === 'PENDING') return status;
  status = leaveDates?.find((element) => element === 'APPROVED');
  if (!status) {
    status = leaveDates?.find((element) => element === 'DISAPPROVED');
    if (!status) return 'PENDING';
    else return 'DISAPPROVED';
  } else if (status === 'APPROVED') {
    status = leaveDates?.find((element) => element === 'DISAPPROVED');
    if (!status) return 'APPROVED';
    else return 'PARTIALLY APPROVED';
  }
  return 'NEW';
};

export const leaveStatusUser = ({
  approver,
  disapprover,
  project_wise_approvals,
}) => {
  if (approver) return 'APPROVED';
  else if (disapprover) return 'DISAPPROVED';

  let status = 'PENDING';
  project_wise_approvals?.forEach(({ approver, disapprover }) => {
    if (approver) status = 'PARTIALLY APPROVED';
    else if (status !== 'PARTIALLY APPROVED' && disapprover)
      status = 'DISAPPROVED';
  });

  return status;
};

export const approvalsListUser = ({
  approver,
  disapprover,
  project_wise_approvals,
}) => {
  let admin = 'pending';
  let manager = 'pending';
  if (approver) admin = 'approved';
  else if (disapprover) admin = 'disapproved';

  project_wise_approvals?.forEach(({ approver, disapprover }) => {
    if (approver) manager = 'approved';
    else if (manager !== 'approved' && disapprover) manager = 'disapproved';
  });

  return { admin, manager };
};

export const convertToFirstCapital = (str) =>
  `${str?.toUpperCase()?.charAt(0)}${str?.slice(1)?.toLowerCase()}`;
export const getImageUrl = (path) => (path == null ? defaultImage : path);

export const isImageValid = (url) => {
  let image = new Image();
  image.src = url;
  if (image.complete) {
    return true;
  } else {
    image.onload = () => {
      return true;
    };
    image.onerror = () => {
      return false;
    };
  }
};
export const convertTime = (time) => ({
  hours: parseInt(time.split(' ')?.[0] || 0),
  mins: parseInt(time.split(' ')?.[2] || 0),
});

export const validateTime = (hrs, mins) =>
  hrs > 0 && mins > 0
    ? `${hrs} ${hrs > 1 ? 'hrs' : 'hr'} ${mins} ${mins > 1 ? 'mins' : 'min'}`
    : hrs > 0 && mins === 0
    ? `${hrs} ${hrs > 1 ? 'hrs' : 'hr'}`
    : hrs === 0 && mins > 0
    ? `${mins} ${mins > 1 ? 'mins' : 'min'}`
    : '-';

export const changeBillingInfoFormat = (info) => {
  const tempObj = {};
  // eslint-disable-next-line array-callback-return
  info?.map(({ label, value }) => {
    tempObj[`${label}`] = value;
  });
  return tempObj;
};
export const mapBillingInfoToArray = (info = {}) => {
  const keys = Object.keys(info);
  const values = Object.values(info);
  let tempArray = [];
  // eslint-disable-next-line array-callback-return
  keys?.map((key, index) => {
    tempArray?.push({
      label: key,
      value: values?.[index],
    });
  });

  return tempArray;
};

export const getExperience = (joiningDate) => {
  const currentDate = moment();
  const monthsDifference = currentDate.diff(moment(joiningDate), 'month');
  const yearsDifference = currentDate.diff(moment(joiningDate), 'year');
  return monthsDifference < 2
    ? 'Probation'
    : monthsDifference >= 2 && monthsDifference < 12
    ? `${monthsDifference} months`
    : monthsDifference % 12 === 0
    ? `${yearsDifference} year${yearsDifference > 1 ? 's' : ''}`
    : `${yearsDifference}+ year${yearsDifference > 1 ? 's' : ''}`;
};

export const getPaginationCount = (length) => {
  const pages = [];
  for (let i = 0; i < length / 2; i++) {
    pages.push(i + 1);
  }

  return pages;
};

export const getChunk = (num) => {
  const x = Math.ceil(num / 3) * 3;
  return [x - 2, x - 1, x];
};

export const doesStartsWithHash = (value) => value?.startsWith('#');

export const getUserName = (name) => {
  const fullName = name?.split(' ');

  if (fullName) {
    const firstName = fullName[0];
    const secondName = fullName[1];

    if (fullName?.length === 1) {
      return fullName;
    } else {
      return `${firstName} ${secondName[0]}.`;
    }
  }

  return null;
};

export const combineAllProjectStatuses = (statuses) => {
  let user = {
    image_url: statuses.image_url,
    is_everything_on_track: statuses?.is_everything_on_track,
    mood: statuses?.mood,
    user_name: statuses?.user_name,
    user_id: statuses?.user_id,
    today_goal: '',
    yesterday_status: '',
    blocker: '',
    tag: '',
  };
  // eslint-disable-next-line array-callback-return
  statuses.project_statuses.map((project_status) => {
    if (project_status?.today) {
      user.today_goal += `${
        project_status?.tag ? `#${project_status.tag}:` : ''
      } ${project_status?.today} `;
    }
    if (project_status?.yesterday) {
      user.yesterday_status += `${
        project_status?.tag ? `#${project_status.tag}:` : ''
      } ${project_status?.yesterday} `;
    }
    if (project_status?.blocker) {
      user.blocker += `${
        project_status?.tag ? `#${project_status.tag}:` : ''
      } ${project_status?.blocker} `;
    }
  });
  return user;
};

export const filterStatuses = (statuses) => {
  for (let i = 0; i < statuses?.length; i++) {
    if (statuses[i]?.checked_in) {
      return true;
    }
  }
  return false;
};
export const addZeroToStart = (num) => num.toString().padStart(2, '0');

export const sortLabels = (arr) => {
  const tempArray = [...arr];
  tempArray.sort((a, b) => a.label.localeCompare(b.label));
  return tempArray;
};
