import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import {
  getClientProject,
  getLogsByClient,
  getTeamByClient,
  getUserLogsByClient,
} from '../../../utils/api/api-requests/client';
import Header from '../../Headers/User/User';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LogsTable from './LogsTable';
import { toast } from 'react-toastify';
import { getCustomProjectLogs, setFilterDates } from 'utils/helper-functions';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import { setLogsViewMode } from 'redux/actions/logs';
import {
  setFromDateFilter,
  setToDateFilter,
} from '../../../redux/actions/projects/index';
import {
  filterViews,
  formatLoggedTime,
  getZonedTime,
} from '../../../utils/helper-functions';
import CSVExporter from '../../Admin/Logs/LogsContainer/CSVExporter';
import { setUserIdForLogs } from '../../../redux/actions/logs';
import ProjectLogTableFilter from '../../Admin/Logs/LogsContainer/LogsFilter';

const ProjectLogs = () => {
  const { uid } = useParams();
  const history = useHistory();
  const {
    fromDateFilter,
    toDateFilter,
    logsViewMode,
    activeView,
    userIdForLogs,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [projectLogs, setProjectLogs] = useState([]);
  const [didPageLoad, setDidPageLoad] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const getAllLogs = useCallback(() => {
    if (fromDateFilter && toDateFilter) {
      getLogsByClient({
        projectId: uid,
        startDate: fromDateFilter,
        endDate: toDateFilter,
      })
        .then(({ data }) => {
          const logs = getCustomProjectLogs(data, true);
          setProjectLogs(logs ?? []);
        })
        .catch(() => {
          toast.error('Unable to fetch project logs.');
        });
    }
  }, [fromDateFilter, toDateFilter, uid]);

  useEffect(() => {
    setDidPageLoad(false);
    getClientProject(uid)
      .then(({ data }) => {
        setProjectName(data?.name ?? '');
      })
      .catch(() => {
        toast.error('Unable to get project info.');
      });
    getTeamByClient(uid)
      .then(({ data }) => {
        setDidPageLoad(true);
        data?.unshift({ uid: 'all-users', name: 'All users' });
        setAssociatedUsers(data?.map(({ uid, name }) => ({ uid, name })));
      })
      .catch(() => {
        setDidPageLoad(true);
        toast.error('Unable to get project team.');
      });
  }, [uid]);
  useEffect(() => {
    if (fromDateFilter && toDateFilter) {
      if (userIdForLogs === 'all-users') {
        getAllLogs();
      } else {
        getUserLogsByClient({
          projectId: uid,
          userId: userIdForLogs,
          startDate: fromDateFilter,
          endDate: toDateFilter,
        })
          .then(({ data }) => {
            setDidPageLoad(true);
            const logs = getCustomProjectLogs(data, true);
            setProjectLogs(logs);
          })
          .catch(() => {
            setDidPageLoad(true);
            toast.error('Unable to fetch project logs.');
          });
      }
    }
  }, [fromDateFilter, getAllLogs, toDateFilter, uid, userIdForLogs]);
  return (
    <div className='main-content'>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              {didPageLoad ? (
                <>
                  <CardHeader className='border-0 checkins-header'>
                    <Row className='align-items-center'>
                      <div className='table-container-header'>
                        <div className='table-container-heading-select'>
                          <div className='col custom-breadcrumb-admin no-button'>
                            <span onClick={() => history.goBack()}>
                              {'Projects '}
                            </span>
                            /{` ${projectName}`}
                          </div>

                          <div className='table-container-select-group'>
                            <div>
                              <CustomFormGroup
                                type='select'
                                name='selectLogsView'
                                id='selectLogsView'
                                value={logsViewMode}
                                setValue={(value) => {
                                  dispatch(setLogsViewMode(value));
                                  setSelectedLogs([]);
                                  setFilterDates(
                                    value,
                                    dispatch,

                                    setFromDateFilter,
                                    setToDateFilter
                                  );
                                }}
                                iconClassName='fas fa-history fa-lg'
                                invalidText='No view is selected'
                                selectBoxText='Select view'
                                selectBoxArray={filterViews}
                                invalidTextFlag={true}
                              />
                            </div>

                            {activeView === 'calendar' ? (
                              ''
                            ) : (
                              <>
                                <div
                                  className={`custom-action-card ${
                                    selectedLogs?.length === 0 ? 'disabled' : ''
                                  }`}
                                >
                                  <CSVExporter
                                    isClient
                                    projectLogs={projectLogs}
                                    selectedLogs={selectedLogs}
                                    projectName={projectName}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className='table-container-dates client-header-actions'>
                          <ProjectLogTableFilter isClient />
                          <div className='table-container-add-view'>
                            <div className='select-user-wrapper'>
                              <CustomFormGroup
                                type='select'
                                name='selectUsersView'
                                id='selectUsersView'
                                value={userIdForLogs}
                                setValue={(value) => {
                                  dispatch(setUserIdForLogs(value));
                                  setSelectedLogs([]);
                                }}
                                iconClassName='fas fa-users fa-lg'
                                invalidText='No view is selected'
                                selectBoxText='Select view'
                                selectBoxArray={associatedUsers}
                                invalidTextFlag={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className='pl-0 pr-0 pt-0 client-logs-card-body'>
                    {projectLogs?.length > 0 ? (
                      <LogsTable
                        projectLogs={projectLogs}
                        selectedLogs={selectedLogs}
                        setSelectedLogs={setSelectedLogs}
                      />
                    ) : (
                      <div className='no-data-div'>
                        Nothing logged{' '}
                        <span className='lowercase-text'>
                          {logsViewMode === 'CUSTOM_DATE'
                            ? fromDateFilter && toDateFilter
                              ? `from ${fromDateFilter} to ${toDateFilter}`
                              : ''
                            : logsViewMode?.replace(/_/g, ' ')}
                        </span>
                      </div>
                    )}
                  </CardBody>
                  <CardFooter className='client-logs-card-footer'>
                    <div className='total-hours-container display-flex'>
                      <div className='total-hours'>
                        <span className='total-hours-text'>
                          Total time logged: &nbsp;
                        </span>
                        <span>
                          {formatLoggedTime({
                            hours: getZonedTime({
                              type: 'hours',
                              value: projectLogs
                                ?.map(({ hours }) => (isNaN(hours) ? 0 : hours))
                                ?.reduce((total, sum) => total + sum, 0),
                            }),
                            minutes: getZonedTime({
                              type: 'minutes',
                              value: projectLogs
                                ?.map(({ hours }) => (isNaN(hours) ? 0 : hours))
                                ?.reduce((total, sum) => total + sum, 0),
                            }),
                          })}
                        </span>
                      </div>
                    </div>
                  </CardFooter>
                </>
              ) : (
                <i class='fas fa-circle-notch fa-spin client-data-spinner' />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectLogs;
