const OverlappingUsers = (timeline) => {
  let multipleUsers = {};

  for (let j = 0; j < timeline.length; j++) {
    const row = timeline[j];
    if (multipleUsers[row.position]) multipleUsers[row.position].push(row);
    else multipleUsers[row.position] = [row];
  }
  const objectKeys = Object.keys(multipleUsers);

  let multipleView = [];

  for (let index = 0; index < objectKeys.length; index++) {
    if (multipleUsers[objectKeys[index]].length > 1) {
      const tempArray = multipleUsers[objectKeys[index]]?.map(
        ({ uid, image, name, checkin, checkout, status }) => ({
          uid,
          image,
          name,
          checkin,
          checkout,
          status,
        })
      );
      let checkedinArray = tempArray?.filter(({ status }) => status === 'in');
      let checkedoutArray = tempArray?.filter(({ status }) => status === 'out');

      multipleView.push({
        position: parseInt(objectKeys[index]),
        checkedinUsers: checkedinArray,
        checkedinUsersCount: checkedinArray?.length,
        checkedoutUsers: checkedoutArray,
        checkedoutUsersCount: checkedoutArray?.length,
      });
    }
  }
  return multipleView;
};

export default OverlappingUsers;
