import React, { useEffect, useState } from 'react';
import AdminHeader from 'Components/Headers/Admin/Admin';
import { inviteUser } from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Form,
  Spinner,
} from 'reactstrap';
import { validate } from 'email-validator';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import UserTable from './Table/Table';
import ArchiveTable from './Table/ArchiveTable';
import { useDispatch, useSelector } from 'react-redux';
import './User.scss';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { setArchivedUsersList } from '../../../redux/actions/employee';
import ExoprtIcon from 'assets/img/active-export.png';

const User = () => {
  const { archivedUsersList } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [inviteModal, setInviteModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [spinnerBackDrop, setSpinnerBackDrop] = useState(false);
  const [didInviteButtonClick, setDidInviteButtonClick] = useState(false);

  const [archiveModal, setArchiveModal] = useState(false);
  const [selectedActiveUsers, setSelectedActiveUsers] = useState([]);

  const [unArchiveModal, setUnArchiveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedArchivedUsers, setSelectedArchivedUsers] = useState([]);

  const [inActiveUsersModal, setInActiveUsersModal] = useState(false);

  const handleInvite = (e) => {
    setDidInviteButtonClick(true);
    e?.preventDefault();
    if (validate(userEmail)) {
      setDidInviteButtonClick(false);
      setSpinnerBackDrop(true);
      inviteUser({ email: userEmail })
        .then(() => {
          toast.success('Invite sent successfully');
          setInviteModal(!inviteModal);
          setUserEmail('');
          setSpinnerBackDrop(false);
        })
        .catch(() => {
          toast.error('Unable to send invite');
          setInviteModal(!inviteModal);
          setUserEmail('');
          setSpinnerBackDrop(false);
        });
    }
  };

  const toggleInviteModal = () => {
    setInviteModal(!inviteModal);
    setUserEmail('');
    setSpinnerBackDrop(false);
  };

  useEffect(() => {
    dispatch(setArchivedUsersList({ success: () => {}, failure: () => {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className='main-content'>
        <AdminHeader />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col d-flex align-items-center'>
                      <div className='workspace-heading'>
                        <h3 className='mb-0 text-dark'>Employees</h3>
                      </div>

                      <div
                        className={`custom-action-button ${
                          selectedActiveUsers?.length <= 0 ? 'disabled' : ''
                        }`}
                        onClick={() => setArchiveModal(true)}
                      >
                        <img
                          className='export-icon'
                          alt='...'
                          src={ExoprtIcon}
                        />
                        <div>Archive</div>
                      </div>
                    </div>

                    <div className='col text-right'></div>
                  </Row>
                </CardHeader>
                <UserTable
                  areArchivedEmployees={false}
                  selectedActiveUsers={selectedActiveUsers}
                  setSelectedActiveUsers={setSelectedActiveUsers}
                  archiveModal={archiveModal}
                  setArchiveModal={setArchiveModal}
                />

                <div className='d-flex flex-row justify-content-center '>
                  <button
                    className={`view-archived-button admin-theme-button ${
                      archivedUsersList?.length === 0 ? 'disabled' : ''
                    }`}
                    onClick={() => setInActiveUsersModal(true)}
                  >
                    View archived employees
                  </button>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={inviteModal} toggle={toggleInviteModal} centered>
            <ModalHeader className='p-3' toggle={toggleInviteModal}>
              <h2>Invite user</h2>
            </ModalHeader>
            <ModalBody className='p-3'>
              <Form
                onKeyPress={(e) => (e?.key === 'Enter' ? handleInvite(e) : {})}
              >
                <CustomFormGroup
                  type='email'
                  name='userEmail'
                  placeholder='Email'
                  value={userEmail}
                  iconClassName='ni ni-email-83'
                  setValue={setUserEmail}
                  invalidText='Invalid email address'
                  didSubmitButtonClick={didInviteButtonClick}
                  layout='admin'
                />
              </Form>
            </ModalBody>
            <ModalFooter className='p-3'>
              {spinnerBackDrop && userEmail ? (
                <div className='text-center'>
                  <Spinner color='success' size='sm' />
                </div>
              ) : (
                ''
              )}
              <Button
                className='admin-theme-button custom-btn-size2'
                color='primary'
                onClick={handleInvite}
              >
                Invite
              </Button>
              <Button
                color='secondary'
                onClick={toggleInviteModal}
                className='custom-btn-size2'
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <CustomModal
            isOpen={inActiveUsersModal}
            toggle={() => {
              setInActiveUsersModal(!inActiveUsersModal);
              setSelectedArchivedUsers([]);
            }}
            headerText='Archived users'
            noButtons={true}
            customSize='lg'
            customClass='archived-user-modal'
          >
            <ArchiveTable
              data={archivedUsersList}
              selectedArchivedUsers={selectedArchivedUsers}
              setSelectedArchivedUsers={setSelectedArchivedUsers}
              setUnArchiveModal={setUnArchiveModal}
              unArchiveModal={unArchiveModal}
              setDeleteModal={setDeleteModal}
              deleteModal={deleteModal}
            />
            <div className='d-flex modal-action-button'>
              <div
                className={`custom-action-button ${
                  selectedArchivedUsers?.length <= 0 ? 'disabled' : ''
                }`}
                onClick={() => setUnArchiveModal(true)}
              >
                <img className='export-icon' alt='...' src={ExoprtIcon} />
                <div>Unarchive</div>
              </div>
              <div
                className={`custom-action-button align-items-baseline ${
                  selectedArchivedUsers?.length <= 0 ? 'disabled' : ''
                }`}
                onClick={() => setDeleteModal(true)}
              >
                <i className='fa fa-trash-alt delete-icon' />
                <div>Delete</div>
              </div>
            </div>
          </CustomModal>
        </Container>
      </div>
    </>
  );
};

export default User;
