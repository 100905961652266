import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LogCard from 'Components/Common/LogCard/LogCard';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_INCREMENT } from 'constants/index';
import { formatLoggedTime } from 'utils/helper-functions';
import { getZonedTime } from 'utils/helper-functions';
import moment from 'moment';

const DayLogsView = ({ logsDate, editLogHandler }) => {
  const { projectLogsList } = useSelector((state) => state);
  const mploUser = useSelector((state) => state.mploUser);

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const totalLoggedTime = projectLogsList
    ?.filter(({ date }) =>
      moment(moment(logsDate).format('YYYY-MM-DD')).isSame(date)
    )
    ?.map(({ hours }) => hours)
    .reduce((total, sum) => total + sum, 0);
  return (
    <>
      <div className='log-cards-container pt-1 pb-1 calendar-view-modal-body'>
        {projectLogsList
          ?.filter(
            ({ date }, index) =>
              index < pageSize &&
              moment(moment(logsDate).format('YYYY-MM-DD')).isSame(date)
          )
          ?.map(
            ({
              uid,
              loggedBy,
              date,
              timeStart,
              timeEnd,
              hours,
              worked,
              lateSitting,
              archived,
              tracking,
            }) => (
              <LogCard
                uid={uid}
                loggedBy={loggedBy}
                date={date}
                timeStart={timeStart}
                timeEnd={timeEnd}
                hours={hours}
                worked={worked}
                lateSitting={lateSitting}
                archived={archived}
                tracking={tracking}
                editLogHandler={editLogHandler}
                calendarView={true}
              />
            )
          )}
      </div>
      {pageSize < projectLogsList?.length ? (
        <div className='load-more-btn-container'>
          <span
            className={`load-more-btn ${
              ['ADMIN', 'SUPER_ADMIN'].includes(mploUser?.role)
                ? ''
                : 'user-load-btn'
            }`}
            onClick={() => setPageSize(pageSize + PAGE_SIZE_INCREMENT)}
          >
            Load more...
          </span>
        </div>
      ) : (
        ''
      )}
      <div className='total-hours-container display-flex p-0 pt-3'>
        <div className='total-hours'>
          <span className='total-hours-text'>Hours Logged: &nbsp;</span>
          <span>
            {`${
              isNaN(totalLoggedTime)
                ? '-'
                : formatLoggedTime({
                    hours: getZonedTime({
                      type: 'hours',
                      value: totalLoggedTime,
                    }),
                    minutes: getZonedTime({
                      type: 'minutes',
                      value: totalLoggedTime,
                    }),
                  })
            }`}
          </span>
        </div>
      </div>
    </>
  );
};
export default DayLogsView;
