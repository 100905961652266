import axios from 'utils/api/axios';

// MAKE AN API REQUEST & RESOLVE PROMISE AFTER AXIOS REQUEST
export const makeAPICall = ({
  url = '',
  method = 'GET',
  data = {},
  authorizedRequest,
}) => {
  return new Promise((resolve, reject) => {
    const config = { url, method, data, authorizedRequest };
    axios
      .request(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          const { data, status } = error.response;
          const { message } = data;
          if (status === 400) {
            if (
              [
                'user already exists',
                'user is currently logging time in this project',
              ]?.includes(message)
            ) {
              reject(message);
            } else reject('Bad Request');
          } else if (status === 401) {
            reject('Unauthorized Access');
          } else if (status === 403) {
            reject('Forbidden Action');
          } else if (status === 404) {
            reject('Unable to find requested record');
          } else if (status === 406) {
            reject('Not Acceptable');
          } else if (status === 410) {
            if (message === 'token expired, please login again') {
              reject(message);
            } else reject('Token expired');
          } else if (status === 500) {
            reject('Internal Server Error');
          } else if (status === 502) {
            reject('Bad Gateway');
          } else {
            reject('Could not Process Request');
          }
        }
        reject('Could not Process Request');
      });
  });
};

export const getJWT = () => {
  return localStorage.getItem('jwt-token');
};
