import React, { useState, useEffect } from 'react';
import emergencyContactImg from 'assets/img/emergency_contact.png';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import FormGroupWithLabel from 'Components/Common/FormGroupWithLabel/FormGroupWithLabel';
import { Row, Button, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  addEmergencyContact,
  getEmergencyContact,
  updateEmergencyContact,
  deleteEmergencyContact,
} from 'utils/api/api-requests/general';
import { validateCnic } from 'utils/helper-functions';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import CustomFormGroup from '../../../../Common/CustomFormGroup/CustomFormGroup';
const relations = ['Family', 'Friend'];
const EmergencyContacts = () => {
  const history = useHistory();
  const cnicInfo = document.getElementById('input-cnic-number');

  cnicInfo?.addEventListener('keydown', (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') return;
    if (e.target.value.length === 5) {
      cnicInfo.value = cnicInfo.value + '-';
    }
    if (e.target.value.length === 13) {
      cnicInfo.value = cnicInfo.value + '-';
    }
  });
  const [emergencyContactlist, setEmergencyContactlist] = useState([]);
  const [editEmergencyContact, setEditEmergencyContact] = useState(false);
  const [emergencyContactObject, setEmergencyContactObject] = useState({
    fullName: '',
    cnicNumber: '',
    contactNumber: '',
    additionalContactNumber: '',
    address: '',
    relation: '',
    uid: '',
  });
  const [emergencyContactModal, setEmergencyContactModal] = useState(false);
  const [deleteEmergencyContactModal, setDeleteEmergencyContactModal] =
    useState(false);
  const [deleteEmergencyContactUid, setDeleteEmergencyContactUid] =
    useState('');
  const [didSaveButtonClicked, setDidSaveButtonClicked] = useState(false);
  useEffect(() => {
    getEmergencyContact()
      .then(({ data }) => {
        setEmergencyContactlist(data);
      })
      .catch((error) => {
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error('Failed to get emergency contact list');
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergencyContactModal, deleteEmergencyContactModal]);
  const validateEmergencyContactObject = () =>
    validator.isMobilePhone(emergencyContactObject?.contactNumber) &&
    (emergencyContactObject?.additionalContactNumber
      ? validator.isMobilePhone(emergencyContactObject?.additionalContactNumber)
      : true) &&
    emergencyContactObject?.address &&
    emergencyContactObject?.relation &&
    (emergencyContactObject?.cnicNumber
      ? validateCnic(emergencyContactObject?.cnicNumber)
      : true) &&
    emergencyContactObject?.fullName;
  const emergencyContactApiHandler = () => {
    setDidSaveButtonClicked(true);
    const tempObject = {
      fullName: '',
      cnicNumber: '',
      contactNumber: '',
      additionalContactNumber: '',
      address: '',
      relation: '',
      uid: '',
    };
    if (validateEmergencyContactObject()) {
      if (editEmergencyContact) {
        updateEmergencyContact(
          {
            full_name: emergencyContactObject?.fullName,
            phone1: emergencyContactObject?.contactNumber,
            phone2: emergencyContactObject?.additionalContactNumber,
            address: emergencyContactObject?.address,
            relation: emergencyContactObject?.relation,
            cnic_number: emergencyContactObject?.cnicNumber,
          },
          emergencyContactObject?.uid
        )
          .then(() => {
            toast.success('Successfully updated emergency contact');
            setEmergencyContactModal(false);
            setEditEmergencyContact(false);
            setEmergencyContactObject(tempObject);
            setDidSaveButtonClicked(false);
          })
          .catch(() => {
            toast.error('Failed to update emergency contact');
            setEmergencyContactModal(false);
            setEditEmergencyContact(false);
            setEmergencyContactObject(tempObject);
            setDidSaveButtonClicked(false);
          });
      } else
        addEmergencyContact({
          full_name: emergencyContactObject?.fullName,
          phone1: emergencyContactObject?.contactNumber,
          phone2: emergencyContactObject?.additionalContactNumber,
          address: emergencyContactObject?.address,
          relation: emergencyContactObject?.relation,
          cnic_number: emergencyContactObject?.cnicNumber,
        })
          .then(() => {
            toast.success('Successfully added emergency contact');
            setEmergencyContactModal(false);
            setEmergencyContactObject(tempObject);
            setDidSaveButtonClicked(false);
          })
          .catch(() => {
            toast.error('Failed to add emergency contact');
            setEmergencyContactModal(false);
            setEmergencyContactObject(tempObject);
            setDidSaveButtonClicked(false);
          });
    }
  };
  const editEmergencyContactHandler = (contactObject) => {
    const tempObject = {
      fullName: contactObject?.full_name || '',
      cnicNumber: contactObject?.cnic_number || '',
      contactNumber: contactObject?.phone1 || '',
      additionalContactNumber: contactObject?.phone2 || '',
      address: contactObject?.address || '',
      relation: contactObject?.relation || '',
      uid: contactObject?.uid || '',
    };
    setEmergencyContactObject(tempObject);
    setEditEmergencyContact(true);
    setEmergencyContactModal(true);
  };
  const deleteEmergencyContactModalHandler = (uid) => {
    setDeleteEmergencyContactModal(true);
    setDeleteEmergencyContactUid(uid);
  };
  const deleteEmergencyContactApiHandler = () => {
    deleteEmergencyContact(deleteEmergencyContactUid)
      .then(() => {
        toast.success('Successfully deleted emergency contact');
        setDeleteEmergencyContactModal(false);
        setDeleteEmergencyContactUid('');
      })
      .catch(() => {
        toast.error('Failed to delete emergency contact');
        setDeleteEmergencyContactModal(false);
        setDeleteEmergencyContactUid('');
      });
  };
  return (
    <>
      <Row className='m-0'>
        <h6 className='heading-small text-muted mb-4'> Emergency Contacts</h6>
        <div className='col text-right pr-0'>
          <Button
            className='admin-theme-button user-profile-btn'
            onClick={() => setEmergencyContactModal(true)}
            disabled={emergencyContactlist?.length >= 3}
          >
            <i className='fas fa-plus mr-1'></i>
            Add
          </Button>
        </div>
      </Row>
      <div>
        {emergencyContactlist?.length > 0 ? (
          <Row>
            {emergencyContactlist?.map((emergencyContactObject) => (
              <Col lg='4'>
                <div className='emergency-contact-item'>
                  <div className='emergency-contact-header'>
                    <img
                      src={emergencyContactImg}
                      alt='emergencyContactHeader'
                      width='10'
                    />
                    <span
                      className='cursor-pointer'
                      onClick={() =>
                        editEmergencyContactHandler(emergencyContactObject)
                      }
                    >
                      {emergencyContactObject?.full_name}
                    </span>{' '}
                  </div>

                  <i
                    className='fa fa-trash'
                    onClick={() =>
                      deleteEmergencyContactModalHandler(
                        emergencyContactObject?.uid
                      )
                    }
                  />
                </div>
                <div className='custom-invalid-feedback-text hidden-class'>
                  &nbsp;
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <center className='text-disabled text-sm'>
            You haven't added any emergency contact yet.
          </center>
        )}
      </div>

      <CustomModal
        isOpen={emergencyContactModal}
        toggle={() => {
          setDidSaveButtonClicked(false);
          setEmergencyContactModal(!emergencyContactModal);
          setEmergencyContactObject({
            fullName: '',
            cnicNumber: '',
            contactNumber: '',
            additionalContactNumber: '',
            address: '',
            relation: '',
            uid: '',
          });
        }}
        headerText={`${
          editEmergencyContact ? 'Edit' : 'Add'
        } Emergency Contact Detail`}
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={emergencyContactApiHandler}
        customSize='lg'
        customClass='form-modal'
      >
        <Row>
          <Col>
            <FormGroupWithLabel
              labelName='Full Name'
              id='input-full-name'
              placeholder='John Doe'
              type='text'
              setValue={(value) =>
                setEmergencyContactObject({
                  ...emergencyContactObject,
                  fullName: value,
                })
              }
              value={emergencyContactObject?.fullName}
              didSubmitButtonClick={didSaveButtonClicked}
              name='full-name'
              invalidText='Full name is empty'
              required
            />
            <FormGroupWithLabel
              labelName='CNIC Number'
              id='input-cnic-number'
              placeholder='XXXXX-XXXXXXX-X'
              type='text'
              setValue={(value) =>
                setEmergencyContactObject({
                  ...emergencyContactObject,
                  cnicNumber: value,
                })
              }
              value={emergencyContactObject?.cnicNumber}
              didSubmitButtonClick={didSaveButtonClicked}
              name='cnic-number'
              invalidText='Invalid CNIC Number'
              maxLength={15}
            />
            <FormGroupWithLabel
              labelName='Contact Number'
              id='input-contact-number'
              placeholder='+92xxxxxxxxx'
              type='text'
              setValue={(value) =>
                setEmergencyContactObject({
                  ...emergencyContactObject,
                  contactNumber: value,
                })
              }
              value={emergencyContactObject?.contactNumber}
              didSubmitButtonClick={didSaveButtonClicked}
              name='contact-number'
              invalidText='Invalid Contact Number'
              required
            />

            <FormGroupWithLabel
              labelName='Additional Contact Number'
              id='input-additional-contact-number'
              placeholder='+92xxxxxxxxx'
              type='text'
              setValue={(value) =>
                setEmergencyContactObject({
                  ...emergencyContactObject,
                  additionalContactNumber: value,
                })
              }
              value={emergencyContactObject?.additionalContactNumber}
              didSubmitButtonClick={didSaveButtonClicked}
              name='additional-contact-number'
              invalidText='Invalid phone number'
            />
          </Col>
          <Col>
            <div className='label-for-input'>
              <label className='form-control-label'>Relation</label>
            </div>

            <CustomFormGroup
              type='select'
              name='selectCheckinView'
              id='selectCheckinView'
              value={emergencyContactObject?.relation}
              setValue={(value) => {
                setEmergencyContactObject({
                  ...emergencyContactObject,
                  relation: value,
                });
              }}
              iconClassName='fas fa-users fa-lg'
              invalidText='No view is selected'
              selectBoxArray={[
                { uid: '', name: 'None' },
                ...relations.map((rel) => ({ uid: rel, name: rel })),
              ]}
              invalidTextFlag={true}
            />
            <FormGroupWithLabel
              labelName='Address'
              id='input-address'
              placeholder='123 XYZ, NYC'
              type='textarea'
              setValue={(value) =>
                setEmergencyContactObject({
                  ...emergencyContactObject,
                  address: value,
                })
              }
              value={emergencyContactObject?.address}
              didSubmitButtonClick={didSaveButtonClicked}
              name='address'
              invalidText='Address is empty'
              required
            />
          </Col>
        </Row>
      </CustomModal>

      <CustomModal
        isOpen={deleteEmergencyContactModal}
        toggle={() =>
          setDeleteEmergencyContactModal(!deleteEmergencyContactModal)
        }
        headerText='Delete Emergency Contact Detail'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteEmergencyContactApiHandler}
      >
        <p>Are you sure you want to delete this emergency contact?</p>
      </CustomModal>
    </>
  );
};

export default EmergencyContacts;
