import moment from 'moment';

const TimelineAttendance = (
  userList,
  unitGap,
  defaultTimeline,
  endPosition,
  gutter
) => {
  let timeline = [];

  const calculatePosition = (minutes, unitGap, hourIndex) => {
    if (unitGap > 200) {
      let nearestMinute = Math.round(minutes / 5) * 5;
      let position = (unitGap / 12) * (nearestMinute / 5) + hourIndex;
      let totalWidth = endPosition - gutter;
      return position % totalWidth;
    } else {
      let nearestMinute = Math.round(minutes / 10) * 10;
      let position = (unitGap / 6) * (nearestMinute / 10) + hourIndex;
      let totalWidth = endPosition - gutter;
      return position % totalWidth;
    }
  };
  for (var i = 0; i < userList?.length; i++) {
    let currentHour = moment(userList[i]?.attendance?.check_in)?.format(
      'hh:00 A'
    );
    let hourIndex = defaultTimeline?.find((i) => i?.time === currentHour);

    let currentPosition = calculatePosition(
      moment(userList[i]?.attendance?.check_in)?.minutes(),
      unitGap,
      hourIndex.position
    );

    timeline.push({
      uid: userList[i]?.attendance?.uid + 'in',
      name: userList[i]?.name,
      position: currentPosition,
      image: userList[i]?.image_url,
      status: 'in',
      checkin: userList[i]?.attendance?.check_in,
      checkout: userList[i]?.attendance?.check_out,
      working: userList[i]?.working_remotely,
    });
    if (userList[i]?.attendance?.check_out) {
      currentHour = moment(userList[i]?.attendance?.check_out)?.format(
        'hh:00 A'
      );
      hourIndex = defaultTimeline.find((i) => i?.time === currentHour);
      timeline.push({
        uid: userList[i]?.attendance?.uid + 'out',
        name: userList[i]?.name,
        position: calculatePosition(
          moment(userList[i]?.attendance?.check_out)?.minutes(),
          unitGap,
          hourIndex?.position
        ),
        image: userList[i]?.image_url,
        status: 'out',
        checkin: userList[i]?.attendance?.check_in,
        checkout: userList[i]?.attendance?.check_out,
        working: userList[i]?.working_remotely,
      });
    }
  }
  return timeline;
};

export default TimelineAttendance;
