import React, { useEffect, useRef, useState } from 'react';
import './TimelineComponent.scss';
import TimelineDefault from '../TimelinePositions/TimelineDefault';
import moment from 'moment';
import TimelineAttendance from '../TimelinePositions/TimelineAttendance';
import defaultImage from 'assets/img/theme/no-user-1.jpeg';
import { formatLoggedTime } from 'utils/helper-functions';
import useDraggableScroll from 'use-draggable-scroll';

import {
  PopoverBody,
  Spinner,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import OverlappingUsers from '../TimelinePositions/OverlappingUsers';
import MultipleUsers from './MultipleUsers';

const TimelineComponent = ({ gap, userList, loading }) => {
  const [startTime, setStartTime] = useState('');
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(0);
  const [gutter, setGutter] = useState(0);
  const [unitGap, setUnitGap] = useState(gap);
  const [defaultTimeline, setDefaultTimeline] = useState([]);

  useEffect(() => {
    setStartTime('5:00 AM');
    setStartPosition(32);
    setUnitGap(gap);
    setDefaultTimeline(TimelineDefault(startTime, startPosition, gap));
  }, [gap, startPosition, startTime, unitGap]);

  useEffect(() => {
    const slack = unitGap < 200 ? 90 : 100;
    setGutter(startPosition + slack);
    !loading && executeScroll(slack);
    setEndPosition(
      defaultTimeline[defaultTimeline?.length - 1]?.position + slack
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTimeline, unitGap, startPosition, endPosition, loading]);

  const [attendanceTimeline, setAttendanceTimeline] = useState([]);

  let scrl = useRef(null);

  const currentPosition = defaultTimeline?.find(
    (i) => i?.time === moment()?.add(1, 'hour')?.format('hh:00 A')
  );

  const executeScroll = (slack) => {
    scrl.current.scrollLeft =
      currentPosition?.position - scrl.current.offsetWidth + slack;
  };

  useEffect(() => {
    setAttendanceTimeline(
      TimelineAttendance(
        userList,
        unitGap,
        defaultTimeline,
        endPosition,
        gutter
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList, unitGap]);

  const [multiUserView, setMultiUserView] = useState([]);

  const today = moment().startOf('day');

  useEffect(() => {
    setMultiUserView(OverlappingUsers(attendanceTimeline));
  }, [attendanceTimeline]);

  // const parent = document.querySelector('.custom-timeline-container');
  // parent.addEventListener('mousedown', (e) => e.preventDefault());

  const { onMouseDown } = useDraggableScroll(scrl, { direction: 'horizontal' });

  return (
    <>
      <div
        className='custom-timeline-container'
        onMouseDown={(e) => e.preventDefault()}
      >
        {loading && (
          <div className='loading'>
            <Spinner
              color={
                localStorage?.getItem('currentUserRole')?.toLowerCase() ===
                'admin'
                  ? 'admin'
                  : 'user'
              }
              size='lg'
            />
          </div>
        )}
        <span className='timeline-header top'>check-ins</span>
        <span className='timeline-header bottom'>check-outs</span>

        <div className='timeline' ref={scrl} onMouseDown={onMouseDown}>
          <div
            style={{
              width: `${endPosition}px`,
            }}
            className='timeline-horizontal'
          >
            <div className='timeline-block'>
              <div className='timeline-content'>
                <div className='timeline-timeslot'>
                  {defaultTimeline?.map(({ time, position }) => (
                    <div
                      className='timeline-time'
                      style={{ left: `${position}px` }}
                    >
                      <span
                        className={`text ${unitGap < 200 ? 'small-chunk' : ''}`}
                      >
                        {time}
                      </span>
                    </div>
                  ))}
                  {attendanceTimeline?.map(
                    ({
                      uid,
                      name,
                      position,
                      image,
                      status,
                      checkin,
                      checkout,
                      working,
                    }) => (
                      <div
                        className={`timeline-check ${status}`}
                        style={{ left: `${position}px` }}
                      >
                        <span
                          className='avatar avatar-sm rounded-circle'
                          id={`employee-${uid}`}
                        >
                          <img
                            alt={`-alt`}
                            src={image}
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = defaultImage;
                            }}
                            className={
                              moment(checkin).isBefore(today, 'day') &&
                              'black-n-white'
                            }
                            width='40'
                          />
                        </span>
                        <UncontrolledPopover
                          trigger='legacy'
                          target={`employee-${uid}`}
                          popperClassName='popover-users'
                          placement={
                            status === 'in' ? 'left-start' : 'left-end'
                          }
                        >
                          <PopoverBody>
                            <div className='checkin-wrapper-single-card'>
                              <div>
                                <img
                                  alt={name?.split(' ')[0] + image}
                                  src={image == null ? defaultImage : image}
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = defaultImage;
                                  }}
                                  width='40'
                                  className={
                                    moment(checkin).isBefore(today, 'day') &&
                                    'black-n-white'
                                  }
                                />
                              </div>
                              <div className='user-data'>
                                <div className='name'>{name}</div>
                                <p>
                                  In-out time: &nbsp;{' '}
                                  <i>{moment(checkin)?.format('hh:mm a')}</i>
                                  {' - '}
                                  {checkout ? (
                                    <i>{moment(checkout)?.format('hh:mm a')}</i>
                                  ) : (
                                    <i className='working'>*</i>
                                  )}
                                </p>
                                <p>
                                  {checkout ? (
                                    <span>
                                      (
                                      {formatLoggedTime({
                                        hours: parseInt(
                                          moment
                                            ?.duration(
                                              moment(checkout)?.diff(checkin)
                                            )
                                            ?.asHours()
                                        ),
                                        minutes: parseInt(
                                          moment
                                            ?.duration(
                                              moment(checkout)?.diff(checkin)
                                            )
                                            ?.asMinutes() % 60
                                        ),
                                      })}
                                      ){' '}
                                    </span>
                                  ) : (
                                    <span>(in progress)</span>
                                  )}
                                  {working ? (
                                    <i className='fas fa-laptop-house work-from-icon text-success' />
                                  ) : (
                                    ''
                                  )}
                                </p>
                              </div>
                            </div>
                          </PopoverBody>
                        </UncontrolledPopover>
                        <UncontrolledTooltip
                          delay={{ hide: '0' }}
                          target={`employee-${uid}`}
                          placement={status === 'in' ? 'top' : 'bottom'}
                        >
                          {name}
                        </UncontrolledTooltip>
                      </div>
                    )
                  )}
                  {multiUserView?.map(
                    ({ position, checkedinUsers, checkedinUsersCount }) =>
                      checkedinUsersCount > 1 && (
                        <MultipleUsers
                          number={checkedinUsersCount}
                          users={checkedinUsers}
                          position={position}
                          status='in'
                        />
                      )
                  )}
                  {multiUserView?.map(
                    ({ position, checkedoutUsers, checkedoutUsersCount }) =>
                      checkedoutUsersCount > 1 && (
                        <MultipleUsers
                          number={checkedoutUsersCount}
                          users={checkedoutUsers}
                          position={position}
                          status='out'
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TimelineComponent;
