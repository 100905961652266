import { Card, Container, Row, Col } from 'reactstrap';
import AdminHeader from 'Components/Headers/Admin/Admin';
import ErpDetailCard from './Admin/ErpDetail/ErpDetail';
import UserStatus from './Admin/UserStatus/UserStatus';

const Index = () => {
  return (
    <>
      <div className='main-content'>
        <AdminHeader />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='8' px='20'>
              <Card className='shadow'>
                <UserStatus />
              </Card>
            </Col>
            <Col className='mb-5' xl='4'>
              <Card className='shadow'>
                <ErpDetailCard />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
