import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Button, FormFeedback, Input } from 'reactstrap';
import './RolesDropdown.scss';
import { inviteClient } from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
const RolesDropdown = ({ roles, clientId }) => {
  const [clientRoles, setClientRoles] = useState(
    roles && roles?.[0] === '' ? [] : roles
  );
  const [newRole, setNewRole] = useState('');
  const [invalidRoleText, setInvalidRoleText] = useState('');
  const [selectedRoles, setSelectedRoles] = useState(
    roles && roles?.[0] === '' ? [] : roles
  );
  const [didSendBtnClick, setDidSendBtnClick] = useState(false);
  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const onChangeRole = (role) => {
    if (!selectedRoles?.includes(role)) {
      setSelectedRoles([...selectedRoles, role]);
    } else {
      setSelectedRoles(selectedRoles?.filter((rl) => rl !== role));
    }
  };
  const addNewRole = (e) => {
    if (e?.key === 'Enter') {
      setDidSendBtnClick(true);
      const rolesToBeTested = clientRoles?.map((role) => role?.toLowerCase());
      if (
        e?.target?.value &&
        !rolesToBeTested?.includes(e?.target?.value?.toLowerCase())
      ) {
        setInvalidRoleText('');
        setClientRoles([...clientRoles, e?.target?.value]);
        setSelectedRoles([...selectedRoles, e?.target?.value]);
        setNewRole('');
        setDidSendBtnClick(false);
      } else if (!e?.target?.value) {
        setInvalidRoleText('Role is empty');
      } else if (rolesToBeTested?.includes(e?.target?.value?.toLowerCase())) {
        setInvalidRoleText('Duplicate role found');
      }
    }
  };
  const sendInvites = () => {
    setIsSendingInvite(true);
    inviteClient({
      clientId,
      rolesInfo: {
        roles: selectedRoles?.length > 0 ? selectedRoles?.toString() : '',
      },
    })
      .then(() => {
        setIsSendingInvite(false);
        document.body.click();
        toast.success('Client invited successfully.');
      })
      .catch(() => {
        setIsSendingInvite(false);
        toast.error('Unable to invite client.');
      });
  };
  return (
    <Dropdown className='d-inline mx-2' autoClose='outside'>
      <Dropdown.Toggle
        split
        id='dropdown-autoclose-outside'
        className='send-invite-dropdown'
      >
        <span className='button-txt'>Send Invite</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Header className='send-invite-dropdown-header'>
          Client Role
        </Dropdown.Header>
        <Dropdown.Divider />
        <div className='send-invite-dropdown-items'>
          {clientRoles?.length > 0 ? (
            clientRoles?.map((role, index) => (
              <div className='send-invite-dropdown-role'>
                <span
                  className='styled-checkbox'
                  onClick={() => onChangeRole(role)}
                >
                  <input
                    id={role + index}
                    type='checkbox'
                    checked={selectedRoles?.includes(role)}
                  />
                  <span className='checkbox-text' for={role + index}>
                    {role}
                  </span>
                  <span
                    className={`checkmark ${
                      selectedRoles?.includes(role) ? 'unchecked-box' : ''
                    }`}
                  />
                </span>
              </div>
            ))
          ) : (
            <span className='no-roles-found'>No roles found.</span>
          )}
        </div>

        <Dropdown.Divider />
        <Dropdown.Header className='send-invite-dropdown-footer'>
          {isSendingInvite ? (
            <i class='fas fa-circle-notch fa-spin sending-invite-loader' />
          ) : (
            <>
              <Input
                invalid={didSendBtnClick && invalidRoleText}
                className='new-role-input'
                placeholder='Create new role...'
                value={newRole}
                onChange={(e) => {
                  setNewRole(e?.target?.value);
                }}
                onKeyPress={addNewRole}
              />
              <FormFeedback className='custom-invalid-feedback-text invalid-role-error'>
                {invalidRoleText}
              </FormFeedback>
              <Button
                className='log-time-btn admin-theme-button send-ivite-btn'
                onClick={sendInvites}
              >
                Send
              </Button>
            </>
          )}
        </Dropdown.Header>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RolesDropdown;
