import axios from 'axios';
import { getJWT } from 'utils/api/api-utils';

axios.interceptors.request.use(
  (request) => {
    request.headers['Content-Type'] = 'application/json';
    request.headers['Cache-Control'] = 'no-cache';
    if (request.authorizedRequest) {
      request.headers['Authorization'] = 'Bearer ' + getJWT();
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
