import { Spinner, Table } from 'reactstrap';

import { toast } from 'react-toastify';
import { updateStateArray } from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import {
  unArchiveMultipleInvoices,
  deleteMultipleInvoices,
} from 'utils/api/api-requests/admin';

const ArchiveInvoicesTable = ({
  archivedInvoices,

  selectedArchivedInvoices,
  setSelectedArchivedInvoices,
  setUnArchiveModal,
  unArchiveModal,
  setDeleteModal,
  deleteModal,
  areInvoicesBeingLoaded,
  loadInvoices,
}) => {
  const unarchiveMultipleInvoicesHandler = () => {
    if (selectedArchivedInvoices?.length > 0) {
      unArchiveMultipleInvoices(selectedArchivedInvoices)
        .then(() => {
          setUnArchiveModal(false);
          setSelectedArchivedInvoices([]);
          toast.success('Invoices unarchived successfully');
        })
        .then(() => {
          loadInvoices();
        })
        .catch(() => {
          toast?.error('Unable to fetch project list.');
          setUnArchiveModal(false);
        });
    }
  };

  const deleteMultipleInvoicesHandler = () => {
    deleteMultipleInvoices(selectedArchivedInvoices)
      .then(() => {
        setDeleteModal(false);
        setSelectedArchivedInvoices([]);
        toast.success('Invoices deleted successfully');
      })
      .then(() => {
        loadInvoices();
      })
      .catch(() => {
        toast.error('Unable to delete selected Invoices');
        setDeleteModal(false);
      });
  };

  return (
    <>
      {areInvoicesBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : archivedInvoices?.length > 0 ? (
        <>
          <Table
            className='align-items-center table-flush invoice-table'
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='archiveInvoiceCheckbox'
                      type='checkbox'
                      checked={
                        selectedArchivedInvoices?.length ===
                          archivedInvoices?.length &&
                        selectedArchivedInvoices?.length !== 0
                      }
                      onClick={(e) => {
                        setSelectedArchivedInvoices(
                          e?.target?.checked
                            ? archivedInvoices?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='archiveInvoiceCheckbox'
                    ></label>
                  </div>
                </th>
                <th scope='col'>SR#</th>
                <th scope='col'>Name</th>
                <th scope='col'>Date Generated</th>
                <th scope='col'>Date Range</th>
                <th scope='col'>Type</th>
              </tr>
            </thead>
            <tbody>
              {archivedInvoices?.map(
                ({
                  uid,
                  serial_number,
                  name,
                  date_generated,
                  invoice_range,
                  type,
                }) => (
                  <tr key={uid}>
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedArchivedInvoices?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedArchivedInvoices,
                              setArray: setSelectedArchivedInvoices,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <div className='custom-link-class user-theme-color cursor-pointer'>
                        {serial_number ? serial_number : '-'}
                      </div>
                    </td>
                    <td>{name ? name : '-'}</td>
                    <td>{date_generated ? date_generated : '-'}</td>
                    <td>
                      {invoice_range?.from} - {invoice_range?.to}
                    </td>
                    <td>{type ? type : '-'}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div button'>There are no Invoices yet</div>
      )}
      <CustomModal
        isOpen={unArchiveModal}
        toggle={() => setUnArchiveModal(!unArchiveModal)}
        headerText='Unarchive Invoices'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={unarchiveMultipleInvoicesHandler}
      >
        <p>Are you sure you want to unarchive selected Invoices?</p>
      </CustomModal>{' '}
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Invoices'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleInvoicesHandler}
      >
        <p>Are you sure you want to delete selected Invoices?</p>
      </CustomModal>
    </>
  );
};

export default ArchiveInvoicesTable;
