import { API_URL } from 'utils/api/api-constants';
const AUTH_URL = (url) => `${API_URL}/auth/${url}`;

export const LOGIN_WITH_EMAIL = `${AUTH_URL('login')}/email`;
export const SEND_LOGIN_EMAIL = `${AUTH_URL('login')}/email/begin`;
export const SIGNUP = `${AUTH_URL('signup')}/begin`;
export const VERIFY_INVITE = (token) =>
  `${AUTH_URL('signup')}/verify?token=${token}`;
export const LOGIN = `${AUTH_URL('login')}`;
export const VALIDATE_ALIAS = (alias) =>
  `${API_URL}/workspace/${alias}/available`;
export const ADD_WORKSPACE = `${API_URL}/workspace`;
export const GET_WORKSPACES = `${API_URL}/workspace`;
export const GET_WORKSPACE_INVITES = `${API_URL}/workspace/invite`;
export const JOIN_WORKSPACE_INVITE = (alias) =>
  `${API_URL}/workspace/invite/${alias}/join`;
export const REGISTER = (token) => `${AUTH_URL('signup')}?token=${token}`;
export const RESET_PASSWORD_REQUEST = () => `${AUTH_URL('password/reset')}`;
export const VERIFY_RESET_PASSWORD_TOKEN = (token) =>
  `${AUTH_URL('password/reset/')}verify?token=${token}`;
export const RESET_PASSWORD = (token) =>
  `${AUTH_URL('password/reset')}?token=${token}`;
export const UPDATE_PASSWORD = `${API_URL}/user/password`;
export const VERIFY_CLIENT_AUTH = `${API_URL}/auth/client/invite/verify`;
export const VERIFY_CLIENT_RESET_PWD = `${API_URL}/auth/client/password/reset/verify`;
export const CLIENT_SIGNUP = `${API_URL}/auth/signup/client`;
export const CLIENT_LOGIN = `${API_URL}/auth/login/client`;
export const CLIENT_FORGOT_PASSWORD = `${API_URL}/auth/client/password/reset`;
