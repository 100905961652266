import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'Layouts/Admin';
import UserLayout from 'Layouts/User';
import AuthLayout from 'Layouts/Auth';
import RedirectPage from 'Components/Auth/RedirectPage';
import SlackRedirectPage from 'Components/Integration/SlackRedirectPage';
import Logout from 'Components/Auth/LogOut';
import ClientAuthVerification from './Components/Common/ClientAuthVerification/ClientAuthVerification';
import ClientLayout from './Layouts/Client';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route
        path='/integration/slack/callback'
        render={(props) => <SlackRedirectPage {...props} />}
      />
      <Route
        path='/:workspace/admin'
        render={(props) => <AdminLayout {...props} />}
      />
      <Route
        path='/:workspace/user'
        render={(props) => <UserLayout {...props} />}
      />
      <Route path='/client' render={(props) => <ClientLayout {...props} />} />

      <Route
        path='/auth/invitation'
        render={(props) => <ClientAuthVerification {...props} />}
      />
      <Route
        path='/auth/password-reset'
        render={(props) => (
          <ClientAuthVerification isResettingPassword {...props} />
        )}
      />
      <Route path='/auth' render={(props) => <AuthLayout {...props} />} />
      <Route
        path='/redirect/:workspace/:token'
        render={(props) => <RedirectPage {...props} />}
      />
      <Route path='/logout' render={(props) => <Logout {...props} />} />
      <Redirect from='/' to='/auth/login' />
    </Switch>
  </BrowserRouter>
);

export default Router;
