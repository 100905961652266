import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import RedirectSpinner from 'Components/Common/RedirectSpinner/RedirectSpinner';
import { addWorkspace } from 'utils/api/api-requests/auth';
import { toast } from 'react-toastify';
import { setNewWorkspaceObject } from 'redux/actions/general';
import { useDispatch, useSelector } from 'react-redux';
import { acceptWorkspaceInvite } from 'utils/api/api-requests/auth';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { setMploUser } from '../../redux/actions/general';

const RedirectPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    newWorkspaceObject: { name, alias, action },
  } = useSelector((state) => state);
  const { token, workspace } = useParams();
  useEffect(() => {
    if (token && workspace && token !== 'logout') {
      if (alias && action) {
        localStorage.removeItem('newUserFlag');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('workspaceAction');
        action === 'add'
          ? addWorkspace({
              alias: workspace,
              name: name,
            })
              .then(() => {
                localStorage.removeItem('addWorkspace');
                localStorage.removeItem('addWorkspaceAlias');
                localStorage.removeItem('jwt-token');
                dispatch(setNewWorkspaceObject());
                window.location.replace(`/auth/login`);
                return;
              })
              .catch(() => {
                toast.error('Failed to add workspace');
              })
          : action === 'join'
          ? acceptWorkspaceInvite(workspace)
              .then(() => {
                toast.success('workspace joined');
                localStorage.removeItem('joinWorkspaceAlias');
                dispatch(setNewWorkspaceObject());
                whoamiApiRequest('datumbrain')
                  .then(({ data: { name, roles, uid, email, image_url } }) => {
                    const mploUser = {
                      name,
                      roles,
                      uid,
                      email,
                      profileImage: image_url,
                    };
                    dispatch(setMploUser(mploUser));
                    roles?.includes('ADMIN')
                      ? localStorage.setItem('currentUserRole', 'admin')
                      : roles?.includes('EMPLOYEE')
                      ? localStorage.setItem('currentUserRole', 'user')
                      : localStorage.setItem('currentUserRole', 'null');

                    history.push(
                      `/${alias}/${
                        roles?.includes('ADMIN') ? 'admin' : 'user'
                      }/index}`
                    );
                  })
                  .catch(() => {
                    console.error('Failed to get logged in user data');
                  });
                return;
              })
              .catch(() => console.error('unable to join workspace'))
          : toast.error('Failed to perform action');
      } else {
        window.location.replace(
          `/datumbrain/${localStorage.getItem('currentUserRole')}/index`
        );
      }
    } else history.push('/auth/login');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='redirect-header'>
      <center className='d-flex flex-column align-items-center '>
        <>
          <RedirectSpinner text='Redirect .... page' />
        </>
      </center>
    </div>
  );
};

export default RedirectPage;
