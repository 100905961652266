import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectsList } from 'redux/actions/projects';
import {
  getUserDashboard,
  updateTrackingLog,
} from 'utils/api/api-requests/user';
import { setTrackingLogs } from 'redux/actions/logs';
import './LogTimer.scss';
import { addZeroToStart } from 'utils/helper-functions';
import { getTrackingLogs } from 'utils/api/api-requests/user';
import { useStopwatch } from 'react-timer-hook';

const LogTimer = ({ projectId, startTime }) => {
  const trackingLogs = useSelector(({ trackingLogs }) => trackingLogs);

  const offsetSeconds = moment().diff(
    moment(
      trackingLogs?.filter(({ project_uid }) => project_uid === projectId)?.[0]
        ?.time_start
    ),
    'seconds'
  );
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offsetSeconds);

  const { seconds, minutes, hours } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  });

  const dispatch = useDispatch();

  const stopTimer = () => {
    updateTrackingLog({
      projectId,
      trackingId: trackingLogs?.filter(
        ({ project_uid }) => projectId === project_uid
      )?.[0]?.tracking_uid,
      finish: true,
    }).then(() => {
      getTrackingLogs().then(({ data }) => {
        dispatch(setTrackingLogs(data ?? []));
      });

      let startDate = moment().format('YYYY-MM-DD')?.concat('T00:00:00Z');
      let endDate = moment().format('YYYY-MM-DDTHH:mm:ss')?.concat('Z');
      getUserDashboard(startDate, endDate)
        .then(({ data: { user_project_details } }) => {
          dispatch(setProjectsList(user_project_details));
        })
        .catch(() => {
          dispatch(setProjectsList([]));
        });
    });
  };
  return (
    <div className='log-timer-container'>
      <span className='log-timer' onClick={stopTimer}>
        <i className='fas fa-pause' />
        <div className='timer-count'>
          {addZeroToStart(hours)}:{addZeroToStart(minutes)}:
          {addZeroToStart(seconds)}
        </div>
      </span>
    </div>
  );
};

export default LogTimer;
