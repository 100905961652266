import {
  PROJECT_URL,
  GET_TEAM_BY_CLIENT,
  GET_LOGS_BY_CLIENT,
  GET_USER_LOGS_BY_CLIENT,
  GET_PROJECT_INVOICE,
  DOWNLOAD_INVOICE_CLIENT_URL,
} from '../api-urls/client';
import { makeAPICall } from '../api-utils';
export const getClientProjects = () =>
  makeAPICall({
    method: 'GET',
    url: PROJECT_URL,
    authorizedRequest: true,
  });
export const getClientProject = (projectId) =>
  makeAPICall({
    method: 'GET',
    url: `${PROJECT_URL}/${projectId}`,
    authorizedRequest: true,
  });
export const getTeamByClient = (projectId) =>
  makeAPICall({
    method: 'GET',
    url: GET_TEAM_BY_CLIENT(projectId),
    authorizedRequest: true,
  });
export const getLogsByClient = ({ projectId, startDate, endDate }) =>
  makeAPICall({
    method: 'GET',
    url: `${GET_LOGS_BY_CLIENT(projectId)}?from=${startDate}&to=${endDate}`,
    authorizedRequest: true,
  });
export const getUserLogsByClient = ({
  projectId,
  userId,
  startDate,
  endDate,
}) =>
  makeAPICall({
    method: 'GET',
    url: `${GET_USER_LOGS_BY_CLIENT({
      projectId,
      userId,
    })}?from=${startDate}&to=${endDate}`,
    authorizedRequest: true,
  });
export const getInvoiceDataByClient = ({ projectId, from, to }) =>
  makeAPICall({
    method: 'GET',
    url: `${GET_PROJECT_INVOICE(projectId)}?from=${from}&to=${to}`,
    authorizedRequest: true,
  });
export const downloadInvoiceByClient = (payload) =>
  makeAPICall({
    method: 'GET',
    url: DOWNLOAD_INVOICE_CLIENT_URL(payload),
    authorizedRequest: true,
  });
