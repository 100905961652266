import React, { useState } from 'react';
import { Card, CardHeader, Container, Row, Col } from 'reactstrap';
import './User.scss';
import UserHeader from 'Components/Headers/User/User';
import DashboardHeader from 'Components/Dashboards/User/DashboardHeader';
import ProjectTable from 'Components/Dashboards/User/ProjectTable';
import LeaveModal from 'Components/Admin/LeaveModule/LeaveModal/LeaveModal';
import UpdateStatusForm from './User/UpdateStatusForm/UpdateStatusForm';

const User = () => {
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [showStatusUpateForm, setShowStatusUpateForm] = useState({
    visible: false,
    buttonText: 'Complete Check-in',
  });
  const [desiredFunction, setDesiredFunction] = useState(null);
  return (
    <>
      <div className='main-content'>
        <UserHeader
          showStatusUpateForm={showStatusUpateForm}
          setShowStatusUpateForm={setShowStatusUpateForm}
        />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='border-0 projects-header'>
                  <DashboardHeader
                    dashboardLoading={dashboardLoading}
                    setDashboardLoading={setDashboardLoading}
                    showStatusUpateForm={showStatusUpateForm}
                    setShowStatusUpateForm={setShowStatusUpateForm}
                    setDesiredFunction={setDesiredFunction}
                  />
                </CardHeader>
                {showStatusUpateForm?.visible ? (
                  <UpdateStatusForm
                    showStatusUpateForm={showStatusUpateForm}
                    setShowStatusUpateForm={setShowStatusUpateForm}
                    attendanceHandler={desiredFunction}
                  />
                ) : (
                  <ProjectTable
                    dashboardLoading={dashboardLoading}
                    setDashboardLoading={setDashboardLoading}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <LeaveModal />
    </>
  );
};

export default User;
