import React, { useEffect, useState } from 'react';
import { updateMaxHoursAllocated } from '../../../../../../../utils/api/api-requests/admin';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const TableHeader = ({
  serverError,
  hoursAllocated,
  resetHoursToDefault,
  getProjectInfo,
  isAllSelected,
  toggleAllSelection,
}) => {
  useEffect(() => {
    getProjectInfo();
  }, [getProjectInfo]);
  const { projectId } = useParams();
  const [editedHours, setEditedHours] = useState(hoursAllocated);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const stopEditing = (e) => {
    let node = e?.target;
    while (node) {
      if (node?.id) {
        if (node?.id === 'target-div') {
          return;
        }
      }
      node = node.parentElement;
    }
    setIsEditing(false);
  };

  useEffect(() => {
    document.addEventListener('click', stopEditing);
    return () => document.removeEventListener('click', stopEditing);
  }, []);

  const allowEditing = () => {
    setIsEditing(true);
  };
  const saveMaxAllocatedHours = () => {
    setIsEditing(false);
    setIsLoading(true);
    updateMaxHoursAllocated({ projectId, hours: editedHours })
      .then(() => {
        getProjectInfo()
          .then((data) => {
            setEditedHours(data?.max_hours_allocated);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
        toast.success('Max. allocated hours updated.');
      })
      .catch(() => {
        toast.error('Unable to update hours.');
      });
  };
  return (
    <thead>
      <tr>
        <th className='pl-pt-0'>
          <div className='custom-control custom-checkbox'>
            <input
              disabled={serverError}
              className='custom-control-input'
              id='clientCheckbox'
              type='checkbox'
              checked={isAllSelected}
              onChange={toggleAllSelection}
            />
            <label className='custom-control-label' htmlFor='clientCheckbox' />
          </div>
        </th>
        <th>Name</th>
        <th>Hourly Rate</th>
        <th>
          <div className='d-flex align-items-center'>
            Time Logged{' '}
            {isLoading ? (
              <i
                class='fas fa-circle-notch fa-spin'
                style={{ verticalAlign: 'middle', marginLeft: '1rem' }}
              />
            ) : (
              <div className='hours-allowed-btn cursor-pointer'>
                <div
                  className='d-flex align-items-center cursor-pointer'
                  id='target-div'
                >
                  {isEditing ? (
                    <input
                      onWheel={(e) => e.target.blur()}
                      type='number'
                      autoFocus
                      min={0}
                      value={editedHours}
                      onKeyPress={(e) => {
                        if (e?.key === 'Enter') {
                          saveMaxAllocatedHours();
                        }
                      }}
                      onChange={(e) => {
                        if (e?.target?.value?.length <= 3) {
                          setEditedHours(parseInt(e?.target?.value));
                        }
                      }}
                    />
                  ) : (
                    <span
                      className='user-theme-color'
                      onClick={() => resetHoursToDefault(editedHours)}
                    >
                      {editedHours}
                    </span>
                  )}

                  <span className='action-icons'>
                    {isEditing ? (
                      <i
                        class='fas fa-check fa-lg user-theme-color'
                        title='save'
                        onClick={saveMaxAllocatedHours}
                      />
                    ) : (
                      <i
                        class='fas fa-pencil-alt fa-lg user-theme-color'
                        title='edit'
                        onClick={allowEditing}
                      />
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </th>
        <th>Sub Total</th>
        <th />
      </tr>
    </thead>
  );
};

export default TableHeader;
