import React from 'react';
import {
  getClients,
  deleteMultipleClients,
} from 'utils/api/api-requests/admin';
import { Spinner, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { updateStateArray } from 'utils/helper-functions';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import { unArchiveMultipleClients } from 'utils/api/api-requests/admin';

const ArchivedClientTable = ({
  clients,
  areClientsBeingLoaded,
  setAreClientsBeingLoaded,
  selectedArchivedClients,
  setSelectedArchivedClients,
  unArchiveModal,
  setUnArchiveModal,
  deleteModal,
  setDeleteModal,
  updateClientsStatus,
}) => {
  const unArchiveMultipleClientsHandler = () => {
    unArchiveMultipleClients(selectedArchivedClients)
      .then(() => {
        setUnArchiveModal(false);
        setSelectedArchivedClients([]);
        toast.success('Clients archived successfully');
        getClients()
          .then(({ data }) => {
            updateClientsStatus(data);
            setAreClientsBeingLoaded(false);
          })
          .catch((error) => {
            toast.error(error);
            setAreClientsBeingLoaded(false);
          });
      })
      .catch(() => {
        setUnArchiveModal(false);
        toast.error('Unable to archive selected Clients');
      });
  };

  const deleteMultipleClientsHandler = () => {
    if (selectedArchivedClients.length > 0) {
      deleteMultipleClients(selectedArchivedClients)
        .then(() => {
          setDeleteModal(false);
          setSelectedArchivedClients([]);
          toast.success('Clients deleted successfully');
          getClients()
            .then(({ data }) => {
              updateClientsStatus(data);
              setAreClientsBeingLoaded(false);
            })
            .catch((error) => {
              toast.error(error);
              setAreClientsBeingLoaded(false);
            });
        })
        .catch(() => {
          setDeleteModal(false);
          toast.error('Unable to delete selected Clients');
        });
    }
  };
  return (
    <>
      {areClientsBeingLoaded ? (
        <div className='logs-spinner archived-modal'>
          <Spinner color='admin' />
        </div>
      ) : clients?.length > 0 ? (
        <>
          <Table className='align-items-center table-flush clients-table'>
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='archivedClientCheckbox'
                      type='checkbox'
                      checked={
                        clients?.length === selectedArchivedClients?.length
                      }
                      onClick={(e) => {
                        setSelectedArchivedClients(
                          e?.target?.checked
                            ? clients?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='archivedClientCheckbox'
                    ></label>
                  </div>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Secondary Email</th>
                <th>Secondary Phone</th>
              </tr>
            </thead>
            <tbody>
              {clients?.map(
                ({
                  uid,
                  name,
                  email,
                  contact_number,
                  secondary_email,
                  secondary_contact_number,
                }) => (
                  <tr key={uid}>
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedArchivedClients?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedArchivedClients,
                              setArray: setSelectedArchivedClients,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>{name}</td>
                    <td>{email || '-'}</td>
                    <td>{contact_number || '-'}</td>
                    <td>{secondary_email || '-'}</td>
                    <td>{secondary_contact_number || '-'}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div'>There are no clients yet</div>
      )}
      <CustomModal
        isOpen={unArchiveModal}
        toggle={() => setUnArchiveModal(!unArchiveModal)}
        headerText='Unarchive Clients'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={unArchiveMultipleClientsHandler}
      >
        <p>Are you sure you want to unarchive selected clients?</p>
      </CustomModal>
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Clients'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleClientsHandler}
      >
        <p>Are you sure you want to delete selected clients?</p>
      </CustomModal>
    </>
  );
};

export default ArchivedClientTable;
