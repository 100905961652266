import moment from 'moment';
import { TIMESTAMP_WITH_ZONE } from 'constants/index';

export const isProjectEditButtonClicked = (
  editButton = false,
  { type, payload }
) => (type === 'SET_PROJECT_EDIT_BUTTON_CLICKED' ? payload : editButton);

export const projectObject = (project = {}, { type, payload }) =>
  type === 'SET_PROJECT_OBJECT' ? payload : project;
export const projectsList = (projects = [], { type, payload }) =>
  type === 'SET_PROJECTS_LIST' ? payload : projects;
export const selectedProjectObject = (project = null, { type, payload }) =>
  type === 'SET_SELECTED_PROJECT' ? payload : project;
export const projectTeamArray = (projectTeam = [], { type, payload }) =>
  type === 'SET_PROJECT_TEAM_ARRAY' ? payload : projectTeam;

export const selectedProjectId = (projectId = null, { type, payload }) =>
  type === 'SET_SELECTED_PROJECT_ID' ? payload : projectId;
export const selectedProjectName = (projectName = null, { type, payload }) =>
  type === 'SET_SELECTED_PROJECT_NAME' ? payload : projectName;
export const customSliderArray = (sliderArray = [], { type, payload }) =>
  type === 'SET_CUSTOM_SLIDER_ARRAY' ? payload : sliderArray;
export const workedOnWhat = (worked = '', { type, payload }) =>
  type === 'SET_WORKED_ON_WHAT' ? payload : worked;
export const reasonForLateSitting = (lateSitting = '', { type, payload }) =>
  type === 'SET_REASON_FOR_LATE_SITTING' ? payload : lateSitting;

export const timeLogStartTime = (timeStart = 540, { type, payload }) =>
  type === 'SET_TIME_LOG_START_TIME' ? payload : timeStart;

export const timeLogEndTime = (timeEnd = 1080, { type, payload }) =>
  type === 'SET_TIME_LOG_END_TIME' ? payload : timeEnd;

export const fromDateFilter = (
  fromDate = moment(moment().week() + 1, 'w')
    .isoWeekday(1)
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  { type, payload }
) => (type === 'SET_FROM_DATE_FILTER' ? payload : fromDate);

export const toDateFilter = (
  toDate = moment().format('YYYY-MM-DD'),
  { type, payload }
) => (type === 'SET_TO_DATE_FILTER' ? payload : toDate);

export const validateFilterDate = (validateDate = null, { type, payload }) =>
  type === 'SET_VALIDATE_FILTER_DATE' ? payload : validateDate;
export const projectStartDate = (
  startDate = moment(
    moment(moment(new Date()).subtract(1, 'month')).toDate(),
    TIMESTAMP_WITH_ZONE
  ).format('YYYY-MM-DD'),
  { type, payload }
) =>
  type === 'SET_PROJECT_START_DATE' ? { type, payload }?.payload : startDate;
export const projectEndDate = (
  endDate = moment(
    moment(moment(new Date()).subtract(1, 'month')).toDate(),
    TIMESTAMP_WITH_ZONE
  ).format('YYYY-MM-DD'),
  { type, payload }
) => (type === 'SET_PROJECT_END_DATE' ? { type, payload }?.payload : endDate);
export const projectTeamCustomSelectArray = (
  projectTeam = [],
  { type, payload }
) => (type === 'SET_PROJECT_TEAM_CUSTOM_SELECT_ARRAY' ? payload : projectTeam);

export const endDateFlag = (endDateFlag = false, { type, payload }) =>
  type === 'SET_END_DATE_FLAG' ? payload : endDateFlag;
