import subway_tick from '../../../../assets/svg/subway_tick.svg';
import happy from '../../../../assets/svg/happy-mood.svg';
import neutral from '../../../../assets/svg/neutral-mood.svg';
import sad from '../../../../assets/svg/sad-mood.svg';
import blocker from '../../../../assets/svg/blocker.svg';
import not_found from '../../../../assets/img/person-not-found.svg';
import { useState } from 'react';
import { getUserName } from 'utils/helper-functions';

const UserStatusCard = ({ statusData }) => {
  const [validImage, setValidImage] = useState({ id: null, image_url: null });
  return (
    <div className='cardStatus'>
      <div className='cardStatus__img'>
        <img
          onError={(err) => {
            setValidImage({ id: statusData?.id, image_url: not_found });
          }}
          src={validImage?.image_url || statusData?.image_url}
          alt={statusData?.user_name}
          className='cardStatus__img--user'
        />
        <span>{getUserName(statusData?.user_name)}</span>
        <div className='cardStatus__icons'>
          <img
            src={
              statusData?.mood?.toLowerCase() === 'happy'
                ? happy
                : statusData?.mood?.toLowerCase() === 'sad'
                ? sad
                : neutral
            }
            alt='happy'
            className='cardStatus__icons--mood'
          />
          {statusData?.is_everything_on_track && (
            <img
              src={subway_tick}
              alt='tick'
              className='cardStatus__icons--tick'
            />
          )}
          {statusData.blocker && (
            <img
              src={blocker}
              alt='blocker'
              className='cardStatus__icons--blocker'
            />
          )}
        </div>
      </div>
      <div className='cardStatus__details'>
        <div className='cardStatus__btns'>
          <div className='cardStatus__btns--yesterday cardStatus__btn'>
            Yesterday
          </div>
          <div className='cardStatus__btns--today cardStatus__btn'>Today</div>
          <div className='cardStatus__btns--blockers cardStatus__btn'>
            Blockers
          </div>
        </div>
        <div className='cardStatus__info'>
          <p>{statusData?.yesterday_status || 'N/A'} </p>
          <p>{statusData?.today_goal || 'N/A'}</p>
          <p>{statusData?.blocker || 'No Blockers'} </p>
        </div>
      </div>
    </div>
  );
};
export default UserStatusCard;
