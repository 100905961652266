import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PopoverBody, Table, UncontrolledPopover } from 'reactstrap';
import Parser from 'html-react-parser';
import { formatLoggedTime, getZonedTime } from 'utils/helper-functions';

import './Table.scss';
import { updateStateArray } from 'utils/helper-functions';
import moment from 'moment';
import { parseMarkdown } from 'utils/helper-functions';
import { NUM_OF_DAYS_TO_ADD_LOG } from 'constants/index.js';
const ProjectLogTable = ({
  pageSize,
  selectedLogs,
  setSelectedLogs,
  editLogHandler,
}) => {
  const { projectLogsList } = useSelector((state) => state);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [markDownTooglerFlag, setMarkDownTooglerFlag] = useState(false);
  const mouseEnterMarkDownHandler = (uid) => {
    if (!markDownTooglerFlag) setPopoverOpen(uid);
  };
  return (
    <>
      <Table
        className={`align-items-center table-flush ${
          localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ? 'admin-logs-table'
            : 'employee-logs-table'
        }`}
        responsive
      >
        <thead className='thead-light'>
          <tr className='project-log-table-row'>
            <th className='pl-pt-0'>
              <div className='custom-control custom-checkbox user'>
                <input
                  className='custom-control-input'
                  id='customCheck'
                  type='checkbox'
                  checked={projectLogsList?.length === selectedLogs?.length}
                  onClick={(e) => {
                    setSelectedLogs(
                      e?.target?.checked
                        ? projectLogsList?.map(({ uid }) => uid)
                        : []
                    );
                  }}
                />
                <label className='custom-control-label' htmlFor='customCheck' />
              </div>
            </th>
            {localStorage.getItem('currentUserRole')?.toLowerCase() ===
            'admin' ? (
              <th>Logged By</th>
            ) : (
              ''
            )}
            <th>Date</th>
            <th>Day</th>
            <th>Duration</th>
            <th>Time Logged</th>
            <th>Details</th>
            <th>Off Hours</th>
            {localStorage.getItem('currentUserRole')?.toLowerCase() ===
            'admin' ? (
              ''
            ) : (
              <th scope='col' />
            )}
          </tr>
        </thead>
        <tbody>
          {projectLogsList
            ?.filter((_, index) => index < pageSize)
            ?.map(
              ({
                uid,
                loggedBy,
                date,
                timeStart,
                timeEnd,
                hours,
                worked,
                lateSitting,
                archived,
                tracking,
              }) => (
                <tr
                  className={`project-log-table-row ${
                    archived ? 'pink-row' : ''
                  }`}
                  key={uid}
                >
                  <td className='pl-pt-0'>
                    <div className='custom-control custom-checkbox user'>
                      <input
                        className='custom-control-input'
                        id={uid}
                        type='checkbox'
                        checked={selectedLogs?.includes(uid)}
                        onClick={() =>
                          updateStateArray({
                            array: selectedLogs,
                            setArray: setSelectedLogs,
                            value: uid,
                          })
                        }
                      />
                      <label className='custom-control-label' htmlFor={uid} />
                    </div>
                  </td>
                  {localStorage.getItem('currentUserRole')?.toLowerCase() ===
                  'admin' ? (
                    <td>{loggedBy}</td>
                  ) : (
                    ''
                  )}
                  <td>{date}</td>
                  <td>{moment(date, 'YYYY-MM-DD').format('dddd')}</td>
                  <td>
                    {moment(timeStart, 'hh:mm A').format('hh:mm A')}
                    {` - `}
                    {tracking === false ? (
                      moment(timeEnd, 'hh:mm A').format('hh:mm A')
                    ) : (
                      <span className='in-progress'>in progress</span>
                    )}
                  </td>
                  <td>{`${
                    isNaN(hours)
                      ? '-'
                      : formatLoggedTime({
                          hours: getZonedTime({
                            type: 'hours',
                            value: hours,
                          }),
                          minutes: getZonedTime({
                            type: 'minutes',
                            value: hours,
                          }),
                        })
                  }`}</td>
                  <td>
                    <div>
                      <span
                        className='td-details'
                        onMouseEnter={() => mouseEnterMarkDownHandler(uid)}
                        id={`details-${uid}`}
                      >
                        {worked?.replace(/[^a-zA-Z ]/g, ' ')}
                      </span>
                      <UncontrolledPopover
                        trigger={
                          markDownTooglerFlag === uid ? 'focus' : 'legacy'
                        }
                        onMouseLeave={() => setPopoverOpen(false)}
                        placement='left'
                        isOpen={popoverOpen === uid}
                        toggle={() =>
                          setPopoverOpen(
                            markDownTooglerFlag ? true : !popoverOpen
                          )
                        }
                        target={`details-${uid}`}
                      >
                        <PopoverBody className='p-3'>
                          <div>
                            <div className='d-flex flex-row justify-content-end'>
                              <label className='mr-2 mb-0 custom-styled-label'>
                                Hold
                              </label>
                              <div>
                                <label className='mb-0 custom-toggle markdown-toogle custom-parent-toggler small'>
                                  <input
                                    type='checkbox'
                                    onClick={() =>
                                      setMarkDownTooglerFlag(
                                        !markDownTooglerFlag ? uid : false
                                      )
                                    }
                                    checked={markDownTooglerFlag === uid}
                                  />
                                  <span
                                    className={`custom-toggle-slider markdown-toogle-slider rounded-circle custom-child-toggler ${
                                      markDownTooglerFlag === uid
                                        ? 'right'
                                        : 'left'
                                    }`}
                                  />
                                </label>
                              </div>
                            </div>
                            <div>{Parser(parseMarkdown(worked))}</div>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div>
                  </td>
                  <td>{lateSitting}</td>
                  {localStorage.getItem('currentUserRole')?.toLowerCase() ===
                  'admin' ? (
                    ''
                  ) : (
                    <td>
                      <div className='client-edit-button-child'>
                        <i
                          className={`fas fa-pencil-alt fa-lg  ${
                            moment()
                              .subtract(NUM_OF_DAYS_TO_ADD_LOG + 1, 'days')
                              .isBefore(moment(date, 'YYYY-MM-DD'))
                              ? 'logs-edit-btn'
                              : 'disabled-icon'
                          }`}
                          title={'edit'}
                          onClick={() => {
                            setPopoverOpen(false);
                            editLogHandler({
                              uid,
                              date,
                              timeStart,
                              timeEnd,
                              hours,
                              worked,
                              lateSitting,
                              tracking,
                            });
                          }}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              )
            )}
        </tbody>
      </Table>
    </>
  );
};

export default ProjectLogTable;
