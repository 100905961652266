import { makeAPICall } from 'utils/api/api-utils';

/* PROJECT*/
import {
  PROJECT_URL,
  LOG_URL,
  ADD_LOG_URL,
  UPDATE_LOG_URL,
  DID_MISS_LOGS_TODAY,
  DID_MISS_LOGS_YESTERDAY,
  GET_USER_DASHBOARD,
  UPDATE_WELCOME_STATUS_URL,
  DELETE_MULTIPLE_LOGS,
  GET_USER_PROJECT,
  ADD_TRACKING_LOG_URL,
  ATTENDANCE,
  GET_LOG_URL,
  GET_MANAGER_PROJECTS_ACTIVITY,
  GET_MANAGER_PROJECTS_LOGS,
  GET_TRACKING_LOGS_URL,
  UPDATE_TRACKING_LOG_URL,
  USER_ACTIVTY_URL,
  ATTENDANCE_LIST,
  ATTENDANCE_CRUD,
  USER_LEAVE_CRUD,
  MANAGER_LEAVE_CRUD,
} from 'utils/api/api-urls/user';

import { USER_STATUS_URL } from '../api-urls/user';

export const getProjectsUser = () =>
  makeAPICall({
    url: PROJECT_URL,
    authorizedRequest: true,
  });

export const getLogsUser = (projectID, from, to) =>
  makeAPICall({
    url: LOG_URL(projectID, from, to),
    authorizedRequest: true,
  });

export const getLog = ({ projectId, logId }) =>
  makeAPICall({
    url: GET_LOG_URL({ projectId, logId }),
    authorizedRequest: true,
  });
export const addLogs = (projectID, projectLog) =>
  makeAPICall({
    method: 'POST',
    url: ADD_LOG_URL(projectID),
    authorizedRequest: true,
    data: projectLog,
  });

export const updateLog = (logID, selectedProjectId, projectLog) =>
  makeAPICall({
    method: 'PATCH',
    url: UPDATE_LOG_URL(logID, selectedProjectId),
    authorizedRequest: true,
    data: projectLog,
  });

export const deleteLog = (logID, projectId) =>
  makeAPICall({
    method: 'DELETE',
    url: UPDATE_LOG_URL(logID, projectId),
    authorizedRequest: true,
  });

export const deleteMultipleLogs = (logIds, projectId) =>
  makeAPICall({
    method: 'DELETE',
    url: DELETE_MULTIPLE_LOGS(projectId),
    authorizedRequest: true,
    data: logIds,
  });

export const updateWelcomeStatus = () =>
  makeAPICall({
    method: 'PATCH',
    url: UPDATE_WELCOME_STATUS_URL,
    authorizedRequest: true,
  });

export const didMissLogsToday = () =>
  makeAPICall({
    url: DID_MISS_LOGS_TODAY,
    authorizedRequest: true,
  });

export const didMissLogsYesterday = () =>
  makeAPICall({
    url: DID_MISS_LOGS_YESTERDAY,
    authorizedRequest: true,
  });

export const getUserProject = (projectId) =>
  makeAPICall({
    url: GET_USER_PROJECT(projectId),
    authorizedRequest: true,
  });

export const getUserDashboard = (startDate, endDate) =>
  makeAPICall({
    url: GET_USER_DASHBOARD(startDate, endDate),
    authorizedRequest: true,
  });

export const getTrackingLogs = () =>
  makeAPICall({
    url: GET_TRACKING_LOGS_URL,
    authorizedRequest: true,
  });
export const addTrackingLog = ({ projectId, log }) =>
  makeAPICall({
    method: 'POST',
    url: ADD_TRACKING_LOG_URL(projectId),
    authorizedRequest: true,
    data: log,
  });
export const updateTrackingLog = ({ projectId, trackingId, finish }) =>
  makeAPICall({
    method: 'PUT',
    url: `${UPDATE_TRACKING_LOG_URL({ projectId, trackingId })}${
      finish ? '?finish=true' : ''
    }`,
    authorizedRequest: true,
  });

export const editTrackingLog = (trackingId, projectId, workedOnWhat, finish) =>
  makeAPICall({
    method: 'PATCH',
    url: `${UPDATE_TRACKING_LOG_URL({ projectId, trackingId })}${
      finish ? '?finish=true' : ''
    }`,
    authorizedRequest: true,
    data: workedOnWhat,
  });
export const getManagerProjects = () =>
  makeAPICall({
    url: GET_MANAGER_PROJECTS_LOGS,
    authorizedRequest: true,
  });
export const getManagerProjectsActivity = (projectId, from, to) =>
  makeAPICall({
    url: GET_MANAGER_PROJECTS_ACTIVITY(projectId, from, to),
    authorizedRequest: true,
  });
export const getUserActivity = (from, to) =>
  makeAPICall({
    url: USER_ACTIVTY_URL(from, to),
    authorizedRequest: true,
  });
export const attendanceRequest = (workFromHome) =>
  makeAPICall({
    method: 'POST',
    url: ATTENDANCE(workFromHome),
    authorizedRequest: true,
  });

export const getUserAttendanceList = (start, end) =>
  makeAPICall({
    url: ATTENDANCE_LIST(start, end),
    authorizedRequest: true,
  });

export const updateUserAttendance = (uid, attendance) =>
  makeAPICall({
    method: 'PATCH',
    url: ATTENDANCE_CRUD(uid),
    authorizedRequest: true,
    data: attendance,
  });

export const deleteUserAttendance = (uid) =>
  makeAPICall({
    method: 'DELETE',
    url: ATTENDANCE_CRUD(uid),
    authorizedRequest: true,
  });

export const updateUserCheckin = (uid, checkin) =>
  makeAPICall({
    method: 'PATCH',
    url: ATTENDANCE_CRUD(uid),
    authorizedRequest: true,
    data: checkin,
  });

export const getUserLeaves = () =>
  makeAPICall({
    url: USER_LEAVE_CRUD(),
    authorizedRequest: true,
  });

export const applyLeave = (leavInfo) =>
  makeAPICall({
    method: 'POST',
    url: USER_LEAVE_CRUD(),
    authorizedRequest: true,
    data: leavInfo,
  });

export const updateUserLeave = (uid, leaveData) =>
  makeAPICall({
    method: 'PATCH',
    url: USER_LEAVE_CRUD(uid),
    authorizedRequest: true,
    data: leaveData,
  });

export const deleteUserLeave = (uid) =>
  makeAPICall({
    method: 'DELETE',
    url: USER_LEAVE_CRUD(uid),
    authorizedRequest: true,
  });

export const getUserLeaveStats = () =>
  makeAPICall({
    url: USER_LEAVE_CRUD('total'),
    authorizedRequest: true,
  });

export const getManagerLeaves = (projectUid) =>
  makeAPICall({
    url: MANAGER_LEAVE_CRUD(projectUid),
    authorizedRequest: true,
  });

export const approveLeavesManager = (projectUid, uid, leaveData) =>
  makeAPICall({
    method: 'POST',
    url: MANAGER_LEAVE_CRUD(projectUid, uid),
    authorizedRequest: true,
    data: leaveData,
  });
export const updateApprovalManager = (projectUid, uid, leaveData) =>
  makeAPICall({
    method: 'PATCH',
    url: MANAGER_LEAVE_CRUD(projectUid, uid),
    authorizedRequest: true,
    data: leaveData,
  });

export const getCheckinStatus = () =>
  makeAPICall({
    method: 'GET',
    url: USER_STATUS_URL,
    authorizedRequest: true,
  });
export const addCheckinStatus = ({
  yesterdayGoals,
  todayGoals,
  blockers,
  selectedMood,
  metYesterdayGoals,
}) =>
  makeAPICall({
    method: 'POST',
    url: USER_STATUS_URL,
    authorizedRequest: true,
    data: {
      yesterday_status: yesterdayGoals,
      today_goal: todayGoals,
      blocker: blockers,
      is_everything_on_track: metYesterdayGoals,
      mood: selectedMood,
    },
  });

export const userStatusUpdate = ({
  yesterdayGoals,
  todayGoals,
  blockers,
  selectedMood,
  metYesterdayGoals,
  statusId,
}) =>
  makeAPICall({
    method: 'PATCH',
    url: `${USER_STATUS_URL}/${statusId}`,
    authorizedRequest: true,
    data: {
      yesterday_status: yesterdayGoals,
      today_goal: todayGoals,
      blocker: blockers,
      is_everything_on_track: metYesterdayGoals,
      mood: selectedMood,
    },
  });
