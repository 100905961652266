import { Field, FieldArray, Formik } from 'formik';
import {
  addClient,
  getSupportedCurrencies,
} from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Button, Row, Col, FormGroup } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import { useHistory } from 'react-router';
import BillingInfo from './BillingInfo';
import { updateClient } from '../../../../utils/api/api-requests/admin';
import { useState, useEffect } from 'react';
import { PAYMENT_TERMS } from 'constants/PaymentTerms';
import {
  changeBillingInfoFormat,
  sortLabels,
} from '../../../../utils/helper-functions';

const ClientEdit = ({ clientInfo, isNewClient }) => {
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    getSupportedCurrencies().then((response) => {
      setCurrencies(response.data);
    });
  }, []);

  const [didSubmitButtonClick, setDidSubmitButtonClick] = useState(false);
  const [clientRole, setClientRole] = useState([]);
  const [currentRoleInput, setCurrentRoleInput] = useState([]);
  const [isValidTag, setIsValidTag] = useState(true);
  const phoneRegExp = /^\+?\d*$/;

  const history = useHistory();
  useEffect(() => {
    setClientRole(clientInfo?.roles?.split(',') ?? []);
  }, [clientInfo?.roles]);

  const addOrUpdateClient = ({ formValues, formErrors, action }) => {
    setDidSubmitButtonClick(true);
    if (Object.keys(formErrors).length === 0) {
      const sortedLabels = sortLabels(formValues.billing_info);
      setDidSubmitButtonClick(false);
      const client = {
        name: formValues.name,
        email: formValues.email,
        roles: clientRole?.toString(),
        secondary_email: formValues.secondary_email,
        contact_number: formValues.contact_number,
        secondary_contact_number: formValues.secondary_contact_number,
        organization_name: formValues.organization_name,
        designation: formValues.designation,
        payment_term: formValues.payment_term,
        organization_address1: formValues.organization_address1,
        organization_address2: formValues.organization_address2,
        billing_info_label: formValues.billing_info_label,
        billing_info: changeBillingInfoFormat(sortedLabels) ?? [],
        currency: formValues.currency,
      };

      if (action === 'add') {
        addClient(client)
          .then(() => {
            toast.success('Client added successfully.');
            history.goBack();
          })
          .catch(() => {
            toast.error('Unable to add client.');
          });
      } else {
        updateClient(clientInfo?.uid, client)
          .then(() => {
            toast.success(
              `Client ${isNewClient ? 'add' : 'updat'}ed successfully.`
            );
            history.goBack();
          })
          .catch(() => {
            toast.error(`Unable to ${isNewClient ? 'add' : 'update'} client.`);
          });
      }
    }
  };

  const form = (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        name: clientInfo?.name || '',
        email: clientInfo?.email || '',
        roles: clientInfo?.roles || '',
        secondary_email: clientInfo?.secondary_email || '',
        contact_number: clientInfo?.contact_number || '',
        secondary_contact_number: clientInfo?.secondary_contact_number || '',
        organization_name: clientInfo?.organization_name || '',
        designation: clientInfo?.designation || '',
        payment_term: clientInfo?.payment_term || '',
        organization_address1: clientInfo?.organization_address1 || '',
        organization_address2: clientInfo?.organization_address2 || '',
        billing_info_label: clientInfo?.billing_info_label || '',
        billing_info: clientInfo?.billing_info || [],
        currency: clientInfo?.currency || 'USD',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(' Name should not be empty'),
        email: Yup.string()
          .email(' Enter a valid contact number.')
          .required(' Enter a valid email'),
        secondary_email: Yup.string(' Enter a valid contact number.')
          .email(' Enter a valid email')
          .nullable(),
        contact_number: Yup.string()
          .nullable()
          .matches(phoneRegExp, ' Enter a valid contact number.'),
        secondary_contact_number: Yup.string(' Enter a valid contact number.')
          .nullable()
          .matches(phoneRegExp, ' Enter a valid contact number.'),
        organization_address1: Yup.string().nullable(),
        organization_address2: Yup.string().nullable(),
        payment_term: Yup.string().required(
          ' Payment terms should not be empty'
        ),
        billing_info_label: Yup.string().required(
          ' Billing title should not be empty'
        ),
        billing_info: Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required(' Label should not be empty'),
            value: Yup.string(),
          })
        ),
        currency: Yup.string().required('Currency should not be empty'),
      })}
    >
      {({ values, errors, touched, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <h6 className='heading-small text-muted mb-4'>
                Basic information
              </h6>
              <Row>
                <Col lg='4'>
                  <div
                    className={`input-with-label ${
                      (didSubmitButtonClick || touched.name) &&
                      errors.name &&
                      'invalid-form'
                    }`}
                  >
                    <div className='label-for-input'>
                      <label className='form-control-label'>
                        Name <span>*</span>
                      </label>
                    </div>
                    <Field
                      type='text'
                      name='name'
                      className='form-control custom-invalid-feedback'
                    />

                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick || touched.name) && errors.name
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />
                      <span>{errors.name}</span>
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='input-with-label'>
                    <label className='form-control-label'>Organization</label>
                    <Field
                      type='text'
                      name='organization_name'
                      className='form-control'
                    />
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='input-with-label'>
                    <label className='form-control-label'>Designation</label>
                    <Field
                      type='text'
                      name='designation'
                      className='form-control'
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <FieldArray
                  render={() => (
                    <>
                      <Col lg='4'>
                        <div
                          className={`input-with-label mb--2 roles  
                          ${
                            (didSubmitButtonClick || touched.roles) &&
                            errors.roles &&
                            'invalid-form'
                          } ${touched.roles}
                          `}
                        >
                          <div className='label-for-input'>
                            <label className='form-control-label'>Role</label>
                          </div>
                          <Field name='roles'>
                            {() => (
                              <TagsInput
                                name='role'
                                value={clientRole?.[0] === '' ? [] : clientRole}
                                className='form-control tag'
                                placeholder='Add a role'
                                onlyUnique={true}
                                inputProps={{
                                  className: 'react-tagsinput-input',
                                  placeholder: 'Add a role',
                                }}
                                onChangeInput={setCurrentRoleInput}
                                onChange={(tags) => {
                                  if (
                                    !clientRole
                                      ?.map((tag) => tag?.toLowerCase())
                                      ?.includes(currentRoleInput)
                                  ) {
                                    setIsValidTag(true);
                                    setClientRole(tags);
                                    values.roles = [...tags];
                                  } else {
                                    setIsValidTag(false);
                                  }
                                }}
                              />
                            )}
                          </Field>
                          <div
                            className={`custom-invalid-feedback-text
                      ${!isValidTag ? '' : ' hidden-class'}`}
                          >
                            <i className='fas fa-exclamation-circle' />{' '}
                            <span>Tag already exists</span>
                          </div>
                          <div
                            className={`custom-invalid-feedback-text
                          ${
                            (didSubmitButtonClick || touched.roles) &&
                            errors.roles
                              ? ''
                              : ' hidden-class'
                          }`}
                          >
                            <i className='fas fa-exclamation-circle' />{' '}
                            <span>{errors.roles}</span>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                />
              </Row>

              <hr />
              <h6 className='heading-small text-muted mb-4'>
                Contact Information
              </h6>
              <Row>
                <Col lg='4'>
                  <div
                    className={`input-with-label ${
                      (didSubmitButtonClick || touched.email) &&
                      errors.email &&
                      'invalid-form'
                    }`}
                  >
                    <div className='label-for-input'>
                      <label className='form-control-label'>
                        Email <span>*</span>
                      </label>
                    </div>
                    <Field type='email' name='email' className='form-control' />
                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick || touched.email) && errors.email
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.email}</span>
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div
                    className={`input-with-label ${
                      (didSubmitButtonClick || touched.contact_number) &&
                      errors.contact_number &&
                      'invalid-form'
                    }`}
                  >
                    <div className='label-for-input'>
                      <label className='form-control-label'>Contact no.</label>
                    </div>
                    <Field
                      type='text'
                      name='contact_number'
                      className='form-control'
                    />
                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick || touched.contact_number) &&
                        errors.contact_number
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.contact_number}</span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg='4'>
                  <div
                    className={`input-with-label ${
                      values.secondary_email && errors.secondary_email
                        ? 'invalid-form'
                        : ''
                    }`}
                  >
                    <label className='form-control-label'>
                      Secondary Email
                    </label>
                    <Field
                      type='email'
                      name='secondary_email'
                      className='form-control'
                    />
                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        values.secondary_email && errors.secondary_email
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.secondary_email}</span>
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div
                    className={`input-with-label ${
                      values.secondary_contact_number &&
                      errors.secondary_contact_number &&
                      'invalid-form'
                    }`}
                  >
                    <label className='form-control-label'>
                      Secondary Contact no.
                    </label>
                    <Field
                      type='text'
                      name='secondary_contact_number'
                      className='form-control'
                    />
                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick ||
                          touched.secondary_contact_number) &&
                        errors.secondary_contact_number
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.secondary_contact_number}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg='8'>
                  <div
                    className={`input-with-label ${
                      (didSubmitButtonClick || touched.organization_address1) &&
                      errors.organization_address1 &&
                      'invalid-form'
                    }`}
                  >
                    <div className='label-for-input'>
                      <label className='form-control-label'>
                        Address Line 1
                      </label>
                    </div>
                    <Field
                      type='text'
                      name='organization_address1'
                      className='form-control'
                    />
                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick ||
                          touched.organization_address1) &&
                        errors.organization_address1
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.organization_address1}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg='8'>
                  <div
                    className={`input-with-label ${
                      (didSubmitButtonClick || touched.organization_address2) &&
                      errors.organization_address2 &&
                      'invalid-form'
                    }`}
                  >
                    <div className='label-for-input'>
                      <label className='form-control-label'>
                        Address Line 2
                      </label>
                    </div>
                    <Field
                      type='text'
                      name='organization_address2'
                      className='form-control'
                    />
                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick ||
                          touched.organization_address2) &&
                        errors.organization_address2
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.organization_address2}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <h6 className='heading-small text-muted mb-4'>
                Payment Terms <span style={{ color: 'red' }}>*</span>
              </h6>
              <Row>
                <Col lg='4'>
                  <div
                    className={`input-with-label ${
                      (didSubmitButtonClick || touched.payment_term) &&
                      errors.payment_term &&
                      'invalid-form'
                    }`}
                  >
                    <Field
                      as='select'
                      name='payment_term'
                      className='form-control'
                    >
                      <option value={''}>None</option>
                      {PAYMENT_TERMS.map(({ term }) => (
                        <option value={term}>{term}</option>
                      ))}
                    </Field>

                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick || touched.payment_term) &&
                        errors.payment_term
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.payment_term}</span>
                    </div>
                    <div
                      className={`custom-info-feedback-text
                      ${
                        !(
                          (didSubmitButtonClick || touched.payment_term) &&
                          errors.payment_term
                        ) && values.payment_term
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-info-circle' />{' '}
                      <span>
                        {
                          PAYMENT_TERMS.filter(
                            (pt) => pt.term === values.payment_term
                          )?.[0]?.description
                        }
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <h6 className='heading-small text-muted mb-4'>Billing Details</h6>
              <Row>
                <Col lg='6'>
                  <div
                    className={`input-with-label ${
                      (didSubmitButtonClick || touched.billing_info_label) &&
                      errors.billing_info_label &&
                      'invalid-form'
                    }`}
                  >
                    <div className='label-for-input'>
                      <label className='form-control-label'>
                        Billing Title <span>*</span>
                      </label>
                    </div>
                    <Field
                      type='text'
                      name='billing_info_label'
                      className='form-control'
                      placeholder='Billing Title'
                    />
                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick || touched.billing_info_label) &&
                        errors.billing_info_label
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.billing_info_label}</span>
                    </div>
                  </div>
                </Col>

                <Col lg='6'>
                  <div
                    className={`input-with-label ${
                      (didSubmitButtonClick || touched.currency) &&
                      errors.currency &&
                      'invalid-form'
                    }`}
                  >
                    <div className='label-for-input'>
                      <label className='form-control-label'>
                        Currency <span>*</span>
                      </label>
                    </div>

                    <Field as='select' name='currency' className='form-control'>
                      <option value=''>Select a Currency</option>
                      {currencies.map((currency) => (
                        <option key={currency?.abbr} value={currency?.abbr}>
                          {currency?.symbol} &nbsp; {currency?.name}
                        </option>
                      ))}
                    </Field>
                    <div
                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick || touched.currency) &&
                        errors.currency
                          ? ''
                          : ' hidden-class'
                      }`}
                    >
                      <i className='fas fa-exclamation-circle' />{' '}
                      <span>{errors.currency}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg='6'>
                  <FieldArray
                    name='billing_info'
                    render={(arrayHelpers) => (
                      <>
                        <div>
                          {values?.billing_info?.length &&
                            values?.billing_info?.map((_, index) => (
                              <Row>
                                <Col lg='5'>
                                  <div
                                    className={`input-with-label  ${
                                      (didSubmitButtonClick ||
                                        touched?.billing_info?.[index]
                                          ?.label) &&
                                      errors?.billing_info?.[index]?.label &&
                                      'invalid-form'
                                    }`}
                                  >
                                    <Field
                                      type='text'
                                      name={`billing_info[${index}].label`}
                                      className='form-control'
                                      placeholder='Label'
                                    />
                                    <div
                                      className={`custom-invalid-feedback-text
                      ${
                        (didSubmitButtonClick ||
                          touched?.billing_info?.[index]?.label) &&
                        errors?.billing_info?.[index]?.label
                          ? ''
                          : ' hidden-class'
                      }`}
                                    >
                                      <i className='fas fa-exclamation-circle' />{' '}
                                      <span>
                                        {errors?.billing_info?.[index]?.label}
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg='5'>
                                  <div className='input-with-label mb-4'>
                                    <Field
                                      type='text'
                                      name={`billing_info[${index}].value`}
                                      className='form-control'
                                      placeholder='Value'
                                    />
                                  </div>
                                </Col>
                                <Col lg='2'>
                                  <h6
                                    className='heading-small text-muted user-theme-color cursor-pointer mt-2 remove-button'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    Remove
                                  </h6>
                                </Col>
                              </Row>
                            ))}
                        </div>
                        <div>
                          <h6
                            className='heading-small text-muted mb-4 user-theme-color cursor-pointer add-billing-button'
                            onClick={() => arrayHelpers.push()}
                          >
                            Add
                            {values?.billing_info?.length > 0
                              ? ' more'
                              : ' info'}
                          </h6>
                        </div>
                      </>
                    )}
                  />
                </Col>
                {(values?.billing_info?.[0]?.label ||
                  values?.billing_info?.[0]?.value) &&
                  values?.billing_info?.length > 0 && (
                    <Col lg='6'>
                      <BillingInfo values={values} />
                    </Col>
                  )}
              </Row>
              <Row>
                <Col>
                  {isNewClient ? (
                    <Button
                      type='submit'
                      value='Submit'
                      className='admin-theme-button user-profile-btn mt-2'
                      onClick={() =>
                        addOrUpdateClient({
                          formValues: values,
                          formErrors: errors,
                          action: 'add',
                        })
                      }
                    >
                      Add Client
                    </Button>
                  ) : (
                    <Button
                      type='submit'
                      value='Submit'
                      className='admin-theme-button user-profile-btn mt-2'
                      onClick={() =>
                        addOrUpdateClient({
                          formValues: values,
                          formErrors: errors,
                          action: 'update',
                        })
                      }
                    >
                      Update
                    </Button>
                  )}
                </Col>
              </Row>
            </FormGroup>
          </form>
        );
      }}
    </Formik>
  );

  return form;
};
export default ClientEdit;
