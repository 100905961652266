import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, Table } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { NavLink, useHistory } from 'react-router-dom';
import {
  setSelectedProjectId,
  setProjectsList,
  setProjectEndDate,
} from 'redux/actions/projects';
import {
  archiveMultipleProjects,
  getProjects,
} from 'utils/api/api-requests/admin';
import { setProjectEditModal } from 'redux/actions/modals';
import { toggleDate, updateStateArray } from 'utils/helper-functions';
import { TIMESTAMP_WITH_ZONE } from 'constants/index';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import AssociatedUserRow from './AssociatedEmployeeRow';
import { setFromDateFilter, setToDateFilter } from 'redux/actions/projects';

const ProjectTable = ({
  projects,
  areProjectsBeingLoaded,
  selectedProjects,
  setSelectedProjects,
  archiveModal,
  setArchiveModal,
  updateProjectsStatus,
}) => {
  const [toggledCreationDates, setToggledCreationDates] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  const dispatch = useDispatch();
  let currentDate = moment().format('YYYY-MM-DD');

  const archiveMultipleProjectsHandler = () => {
    if (selectedProjects?.length > 0) {
      archiveMultipleProjects(selectedProjects).then((users) => {
        setArchiveModal(false);
        setSelectedProjects([]);
        toast.success('Projects archived successfully');
        getProjects()
          .then(({ data }) => {
            dispatch(setProjectsList(data));
            updateProjectsStatus(data);
          })
          .catch(() => {
            toast?.error('Unable to fetch project list.');
          });
      });
    }
  };
  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, [projects]);
  const history = useHistory();
  return (
    <>
      {areProjectsBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : projects?.length > 0 ? (
        <>
          <Table
            className='align-items-center table-flush project-table-class'
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='projectsCheckBox'
                      type='checkbox'
                      checked={
                        selectedProjects?.length === projects?.length &&
                        selectedProjects?.length !== 0
                      }
                      onClick={(e) => {
                        setSelectedProjects(
                          e?.target?.checked
                            ? projects?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='projectsCheckBox'
                    ></label>
                  </div>
                </th>
                <th scope='col'>Name</th>
                <th scope='col'>Client</th>
                <th scope='col'>Manager</th>
                <th scope='col'>Commenced</th>
                <th scope='col'>Logs</th>
                <th scope='col'>Associated Employees</th>
                <th className='w-5' scope='col' />
              </tr>
            </thead>
            <tbody>
              {projects?.map(
                ({
                  uid,
                  name,
                  start_date,
                  end_date,
                  managers,
                  client,
                  created_at,
                }) => (
                  <tr
                    key={uid}
                    className={
                      currentDate <
                        moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') ||
                      currentDate >
                        moment(end_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                        ? 'pink-row'
                        : ''
                    }
                  >
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedProjects?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedProjects,
                              setArray: setSelectedProjects,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <NavLink
                        className='custom-link-class user-theme-color'
                        activeClassName='custom-link-class-active'
                        to={`/datumbrain/admin/projects/${uid}/logs`}
                      >
                        {name}
                      </NavLink>
                    </td>
                    <td>
                      {client?.name ? (
                        <NavLink
                          className='custom-link-class user-theme-color'
                          activeClassName='custom-link-class-active'
                          to={`/datumbrain/admin/clients/${client?.uid}`}
                        >
                          {client?.name}
                        </NavLink>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>{managers?.[0]?.name || '-'}</td>
                    <td>
                      <span
                        className='toggled-date'
                        onClick={() =>
                          toggleDate({
                            uid,
                            array: toggledCreationDates,
                            setArray: setToggledCreationDates,
                          })
                        }
                      >
                        {start_date
                          ? toggledCreationDates?.includes(uid)
                            ? moment(start_date, 'YYYY-MM-DD').format('LL')
                            : moment(
                                moment(
                                  start_date +
                                    moment(
                                      created_at,
                                      'YYYY-MM-DDTHH:mm:ssZ'
                                    ).format('HH:mm:ss'),
                                  TIMESTAMP_WITH_ZONE
                                ).format(TIMESTAMP_WITH_ZONE)
                              ).from(moment(currentTime))
                          : '-'}
                      </span>
                    </td>
                    <td className='justify-content-end text-default'>
                      <div
                        className='cursor-pointer custom-link-class user-theme-color'
                        onClick={() => {
                          dispatch(
                            setFromDateFilter(
                              moment().isoWeekday(1).format('YYYY-MM-DD')
                            )
                          );
                          dispatch(
                            setToDateFilter(moment().format('YYYY-MM-DD'))
                          );
                          history.push(
                            `/datumbrain/admin/projects/${uid}/logs`
                          );
                        }}
                      >
                        view logs
                      </div>
                    </td>
                    <td>
                      <AssociatedUserRow projectName={name} projectId={uid} />
                    </td>
                    <td>
                      <div className='d-flex justify-content-between'>
                        <i
                          title='Associate employee'
                          class='fas fa-user-plus fa-lg user-theme-color'
                          onClick={() =>
                            history.push(`/datumbrain/admin/projects/${uid}`)
                          }
                        />{' '}
                        <i
                          className='fas fa-pencil-alt fa-lg user-theme-color'
                          onClick={() => {
                            dispatch(setProjectEditModal(true));
                            dispatch(setSelectedProjectId(uid));
                            dispatch(setProjectEndDate(end_date));
                          }}
                          title='edit'
                        />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div button'>There are no projects yet</div>
      )}
      <CustomModal
        isOpen={archiveModal}
        toggle={() => setArchiveModal(!archiveModal)}
        headerText='Archive Projects'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={archiveMultipleProjectsHandler}
      >
        <p>{'Are you sure you want to archive selected projects?'}</p>
      </CustomModal>
    </>
  );
};

export default ProjectTable;
