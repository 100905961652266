import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardHeader, CardBody, Button, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import ProjectLogTable from './TableView/Table';
import LogsFilter from './LogsFilter';
import CSVExporter from './CSVExporter';
import InvoiceGenerator from './Invoice/InvoiceGenerator';
import ViewSelector from 'Components/Common/ViewSelector/ViewSelector';
import GridView from './GridView/GridView';
import CalendarView from './CalendarView/CalendarView';
import CustomModal from 'Components/Common/CustomModal/CustomModal';

import {
  setLogAddModal,
  setLogsViewMode,
  setAreLogsBeingLoaded,
  setIsEditingTrackingLog,
  setProjectLogsList,
  setLogDate,
  setLogEditModal,
  setLogID,
  setUserIdForLogs,
} from 'redux/actions/logs';
import {
  setCustomSliderArray,
  setReasonForLateSitting,
  setTimeLogEndTime,
  setTimeLogStartTime,
  setWorkedOnWhat,
  setFromDateFilter,
  setToDateFilter,
  setSelectedProjectId,
} from 'redux/actions/projects';

import {
  deleteMultipleLogs,
  getLogsUser,
  getUserProject,
} from 'utils/api/api-requests/user';
import { getProject, getTeam } from 'utils/api/api-requests/admin';

import {
  formatLoggedTime,
  getZonedTime,
  filterViews,
  getCustomProjectLogs,
  isAdminLoggedIn,
  isUserLoggedIn,
  setFilterDates,
} from 'utils/helper-functions';

import { DEFAULT_PAGE_SIZE, PAGE_SIZE_INCREMENT } from 'constants/index';
import './LogsContainer.scss';
import ReviewInvoice from './Invoice/ReviewInvoice';
import { isOwner } from '../../../../utils/api/api-requests/owner';

const ProjectLogTableContainer = ({ refreshData }) => {
  const mploUser = useSelector((state) => state.mploUser);

  const dispatch = useDispatch();
  const {
    logsViewMode,
    activeView,
    fromDateFilter,
    toDateFilter,
    projectLogsList,
    areLogsBeingLoaded,
    userIdForLogs,
  } = useSelector((state) => state);
  const history = useHistory();
  const { projectId } = useParams();
  const [projectName, setProjectName] = useState('');
  const [projectClient, setProjectClient] = useState('');
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isOwnerLoggedIn, setIsOwnerLoggedIn] = useState(false);
  const totalHoursLogged = () => {
    return formatLoggedTime({
      hours: getZonedTime({
        type: 'hours',
        value: projectLogsList
          ?.map(({ hours }) => (isNaN(hours) ? 0 : hours))
          ?.reduce((total, sum) => total + sum, 0),
      }),
      minutes: getZonedTime({
        type: 'minutes',
        value: projectLogsList
          ?.map(({ hours }) => (isNaN(hours) ? 0 : hours))
          ?.reduce((total, sum) => total + sum, 0),
      }),
    });
  };

  useEffect(() => {
    setPageSize(DEFAULT_PAGE_SIZE);
    isOwner()
      .then(({ data }) => {
        setIsOwnerLoggedIn(data);
      })
      .catch(() => setIsOwnerLoggedIn(false));
  }, []);

  useEffect(() => {
    if (!projectId || projectId <= 0) {
      const redirectRoute = `/${
        isAdminLoggedIn() ? 'admin' : 'user'
      }/project/${parseInt(projectId)}/logs`;
      history?.push(redirectRoute);
    }
    dispatch(setSelectedProjectId(projectId));
    refreshData(projectId, fromDateFilter, toDateFilter, userIdForLogs);

    if (isAdminLoggedIn()) {
      getProject(projectId)
        .then(({ data: { name, client } }) => {
          setProjectName(name);
          setProjectClient(client);
        })
        .catch(() => toast.error('Failed to get project data'));
      getTeam(projectId)
        .then(({ data }) => {
          data?.unshift({ uid: 'all-users', name: 'All users' });
          setUserList(data?.map(({ uid, name }) => ({ uid, name })));
        })
        .catch(() => toast.error('Failed to get users'));
    } else if (isUserLoggedIn())
      getUserProject(projectId)
        .then(({ data: { name } }) => setProjectName(name))
        .catch(() => toast.error('Failed to get project data'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateFilter, toDateFilter, userIdForLogs, logsViewMode]);
  const editLogHandler = ({
    uid,
    date,
    timeStart,
    timeEnd,
    worked,
    lateSitting,
    tracking,
  }) => {
    dispatch(setIsEditingTrackingLog(tracking));
    let startMinutes = moment
      .duration(moment(timeStart, 'HH::mm A').format('HH:mm:ss'))
      .asMinutes();
    let endMinutes = moment
      .duration(
        moment(
          tracking === false ? timeEnd : moment().format('HH::mm A'),
          'HH::mm A'
        ).format('HH:mm:ss')
      )
      .asMinutes();

    dispatch(setCustomSliderArray([]));
    dispatch(setLogID(uid));
    dispatch(setTimeLogStartTime(startMinutes));
    dispatch(setTimeLogEndTime(endMinutes));
    dispatch(setWorkedOnWhat(worked));
    dispatch(setReasonForLateSitting(lateSitting === '-' ? '' : lateSitting));
    dispatch(setLogDate(moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')));
    dispatch(setLogEditModal(true));
  };

  const deleteMultipleLogsHandler = () => {
    if (selectedLogs.length > 0) {
      deleteMultipleLogs(selectedLogs, projectId)
        .then(() => {
          setDeleteModal(false);
          setSelectedLogs([]);
          toast.success('Logs deleted successfully');
          dispatch(setAreLogsBeingLoaded(true));
          getLogsUser(projectId, fromDateFilter, toDateFilter)
            .then(({ data }) => {
              dispatch(setProjectLogsList(getCustomProjectLogs(data, false)));
              dispatch(setAreLogsBeingLoaded(false));
            })
            .catch(() => toast?.error('Unable to fetch project logs list'));
        })
        .catch(() => {
          toast.error('Unable to delete log');
        });
    }
  };

  return (
    <>
      <div className='col'>
        <Card className='shadow mb-5'>
          <CardHeader className='border-0'>
            <Row className='align-items-center'>
              <div className='table-container-header'>
                <div className='table-container-heading-select'>
                  {localStorage.getItem('currentUserRole')?.toLowerCase() ===
                  'admin' ? (
                    <div className='col custom-breadcrumb-admin no-button'>
                      <span
                        onClick={() =>
                          history.push(`/datumbrain/admin/projects`)
                        }
                      >
                        {'Projects '}
                      </span>
                      /{` ${projectName}`}
                    </div>
                  ) : (
                    <div className='col custom-breadcrumb-user'>
                      <span onClick={() => history.push(`/datumbrain/user`)}>
                        {'Dashboard '}
                      </span>
                      /{` ${projectName}`}
                    </div>
                  )}

                  <div className='table-container-select-group'>
                    <div>
                      <CustomFormGroup
                        type='select'
                        name='selectLogsView'
                        id='selectLogsView'
                        value={logsViewMode}
                        setValue={(value) => {
                          dispatch(setLogsViewMode(value));
                          setSelectedLogs([]);
                          setFilterDates(
                            value,
                            dispatch,

                            setFromDateFilter,
                            setToDateFilter
                          );
                        }}
                        iconClassName='fas fa-history fa-lg'
                        invalidText='No view is selected'
                        selectBoxText='Select view'
                        selectBoxArray={filterViews}
                        invalidTextFlag={true}
                      />
                    </div>

                    {activeView === 'calendar' ? (
                      ''
                    ) : (
                      <>
                        <div
                          className={`custom-action-card ${
                            selectedLogs?.length === 0 ? 'disabled' : ''
                          }`}
                        >
                          <CSVExporter
                            selectedLogs={selectedLogs}
                            projectName={projectName}
                            totalHoursLogged={totalHoursLogged}
                          />
                        </div>
                      </>
                    )}
                    {isOwnerLoggedIn && projectClient ? (
                      <>
                        <InvoiceGenerator />
                        <ReviewInvoice />
                      </>
                    ) : (
                      <div
                        className={`delete custom-action-card ${
                          selectedLogs?.length === 0 ? 'visibility-hidden' : ''
                        }`}
                        onClick={() => setDeleteModal(true)}
                      >
                        <i className='fa fa-trash-alt delete-icon' />
                        <div>
                          Delete{' '}
                          {selectedLogs?.length > 0 &&
                          projectLogsList?.length === selectedLogs?.length
                            ? 'all'
                            : 'checked'}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='table-container-dates'>
                  <LogsFilter />
                </div>

                <div className='table-container-add-view'>
                  {localStorage.getItem('currentUserRole')?.toLowerCase() ===
                  'admin' ? (
                    <div className='select-user-wrapper'>
                      <CustomFormGroup
                        type='select'
                        name='selectUsersView'
                        id='selectUsersView'
                        value={userIdForLogs}
                        setValue={(value) => {
                          dispatch(setUserIdForLogs(value));
                          setSelectedLogs([]);
                        }}
                        iconClassName='fas fa-users fa-lg'
                        invalidText='No view is selected'
                        selectBoxText='Select view'
                        selectBoxArray={userList}
                        invalidTextFlag={true}
                      />
                    </div>
                  ) : (
                    <div className='add-log-button-container'>
                      <Button
                        color='primary'
                        className='admin-theme-button add-log-button custom-btn-size'
                        onClick={() => dispatch(setLogAddModal(true))}
                      >
                        <span>
                          <i className='fas fa-plus' />
                          <span className='icon-button-text'>Time logger</span>
                        </span>
                      </Button>
                    </div>
                  )}
                  <ViewSelector setSelectedLogs={setSelectedLogs} />
                </div>
              </div>
            </Row>
          </CardHeader>
          <CardBody className='logs-card-body-container'></CardBody>
          <>
            {areLogsBeingLoaded ? (
              <div className='logs-spinner'>
                <Spinner
                  color={mploUser?.role === 'ADMIN' ? 'admin' : 'user'}
                />
              </div>
            ) : projectLogsList?.length > 0 ? (
              <>
                <div className='position-relative'>
                  {activeView === 'list' ? (
                    <ProjectLogTable
                      pageSize={pageSize}
                      selectedLogs={selectedLogs}
                      setSelectedLogs={setSelectedLogs}
                      editLogHandler={editLogHandler}
                    />
                  ) : activeView === 'grid' ? (
                    <GridView
                      pageSize={pageSize}
                      selectedLogs={selectedLogs}
                      setSelectedLogs={setSelectedLogs}
                      editLogHandler={editLogHandler}
                    />
                  ) : activeView === 'calendar' ? (
                    <CalendarView editLogHandler={editLogHandler} />
                  ) : (
                    ''
                  )}
                  <div className='load-more-btn-container'>
                    {pageSize < projectLogsList?.length &&
                    activeView !== 'calendar' ? (
                      <span
                        className={`load-more-btn ${
                          ['ADMIN', 'SUPER_ADMIN'].includes(mploUser?.role)
                            ? ''
                            : 'user-load-btn'
                        }`}
                        onClick={() =>
                          setPageSize(pageSize + PAGE_SIZE_INCREMENT)
                        }
                      >
                        Load more...
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='total-hours-container display-flex'>
                    <div className='total-hours'>
                      <span className='total-hours-text'>
                        Total time logged: &nbsp;
                      </span>
                      <span>{totalHoursLogged()}</span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='no-data-div'>
                Nothing logged{' '}
                <span className='lowercase-text'>
                  {logsViewMode === 'CUSTOM_DATE'
                    ? fromDateFilter && toDateFilter
                      ? `from ${fromDateFilter} to ${toDateFilter}`
                      : ''
                    : logsViewMode?.replace(/_/g, ' ')}
                </span>
              </div>
            )}
          </>
        </Card>
      </div>
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Logs'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleLogsHandler}
      >
        <p>Are you sure you want to delete selected logs?</p>
      </CustomModal>
    </>
  );
};

export default ProjectLogTableContainer;
