import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getClient } from 'utils/api/api-requests/admin';
import Header from 'Components/Headers/Admin/Admin';
import '../ClientDetails.scss';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Spinner,
} from 'reactstrap';
import ClientEdit from './ClientEdit';
import { mapBillingInfoToArray } from '../../../../utils/helper-functions';

const ClientDetails = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState({});
  const [didClientsLoad, setDidClientsLoad] = useState(false);
  const [isNewClient, setIsNewClient] = useState(false);
  useEffect(() => {
    if (clientId !== 'new') {
      setIsNewClient(false);
      getClient(clientId)
        .then(({ data }) => {
          setDidClientsLoad(true);
          setClient({
            ...data,
            billing_info: mapBillingInfoToArray(data?.billing_info),
          });
        })
        .catch(() => {
          setDidClientsLoad(true);
          setClient(null);
        });
    } else {
      setIsNewClient(true);
      setDidClientsLoad(true);
    }
  }, [clientId]);

  return (
    <div>
      <Header />
      <Container className='mt--7' fluid>
        <Row className='mt-5 mb-5'>
          <Col className='mb-5' xl='12'>
            <Card className='shadow'>
              {!didClientsLoad &&
              !window.location.pathname?.includes('new') &&
              !client ? (
                <div className='not-found-message'>Client not found.</div>
              ) : !didClientsLoad ? (
                <div className='logs-spinner'>
                  <Spinner color='info' />
                </div>
              ) : (
                <>
                  <CardHeader className='border-0 details-card-header'>
                    <Row className='m-0'>
                      <h3 className='mb-0 text-dark'>Client Details</h3>
                    </Row>
                  </CardHeader>
                  <CardBody className='details-card-body'>
                    <ClientEdit clientInfo={client} isNewClient={isNewClient} />
                  </CardBody>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClientDetails;
