import React, { useEffect } from 'react';
import { Card } from 'reactstrap';
import './styles.scss';
import happyMood from 'assets/svg/happy-mood.svg';
import neutralMood from 'assets/svg/neutral-mood.svg';
import sadMood from 'assets/svg/sad-mood.svg';
import subwayTick from 'assets/svg/subway_tick.svg';
import updateStatus from 'assets/svg/update-status.svg';
import { getCheckinStatus } from '../../../../utils/api/api-requests/user';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import noUserImage from 'assets/img/theme/no-user-1.jpeg';
import {
  setBlockers,
  setMetYesterdayGoals,
  setSelectedMood,
  setTodayGoals,
  setYesterdayGoals,
  setUserStatus,
  setIsCheckedIn,
} from 'redux/actions/userStatus';
const StatusType = ({ text }) => (
  <span className={`${text}-type-color`}>{text}</span>
);
const UserCheckinStatus = ({ setShowStatusUpateForm }) => {
  const dispatch = useDispatch();
  const { userStatus } = useSelector((state) => state);
  const userImage = localStorage.getItem('profileImage');
  useEffect(() => {
    getCheckinStatus()
      .then(({ data }) => {
        dispatch(setIsCheckedIn(data ? true : false));
        dispatch(setUserStatus(data ?? null));
        dispatch(setBlockers(data?.blocker ?? null));
        dispatch(setMetYesterdayGoals(data?.is_everything_on_track ?? null));
        dispatch(setSelectedMood(data?.mood ?? 'Neutral'));
        dispatch(setTodayGoals(data?.today_goal ?? null));
        dispatch(setYesterdayGoals(data?.yesterday_status ?? null));
      })
      .catch(() => {
        toast.error('Unable to fetch user status.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    userStatus && (
      <Card className='user-checkin-status'>
        <div>
          <div className='user-image'>
            <img
              src={userImage}
              alt='user'
              onError={(e) => {
                e.target.onError = null;
                e.target.src = noUserImage;
              }}
            />
            <div className='user-icons'>
              {userStatus?.is_everything_on_track && (
                <img src={subwayTick} alt='tick' />
              )}
              <img
                src={
                  userStatus?.mood === 'Neutral'
                    ? neutralMood
                    : userStatus?.mood === 'Happy'
                    ? happyMood
                    : userStatus?.mood === 'Sad'
                    ? sadMood
                    : ''
                }
                alt='mood'
                className='user-icons-mood'
              />
            </div>
          </div>
        </div>
        <div>
          <StatusType text='yesterday' />
          <div className='status-description'>
            {userStatus?.yesterday_status}
          </div>
        </div>
        <div>
          <StatusType text='today' />
          <div className='status-description'>{userStatus?.today_goal}</div>
        </div>
        <div>
          <StatusType text='blockers' />
          <div className='status-description'>
            {userStatus?.blocker || 'N/A'}
          </div>
        </div>
        <div>
          <div
            className='update-checkin-status'
            onClick={() =>
              setShowStatusUpateForm({
                visible: true,
                buttonText: 'Update Status',
              })
            }
          >
            <img src={updateStatus} alt='update' />
            <span>Update Status</span>
          </div>
          <div className='last-update-time'>
            Last update {moment(userStatus?.updated_at).format('LT')}
          </div>
        </div>
      </Card>
    )
  );
};

export default UserCheckinStatus;
