import { makeAPICall } from 'utils/api/api-utils';
import {
  CHANGE_USERNAME,
  USERINFO_CRUD,
  USEREMAIL_CRUD,
  EMERGANCY_CONTACT_CRUD,
} from 'utils/api/api-urls/general';

export const changeUsername = (name) =>
  makeAPICall({
    method: 'PATCH',
    url: CHANGE_USERNAME,
    authorizedRequest: true,
    data: name,
  });

export const getUserInfo = () =>
  makeAPICall({
    url: USERINFO_CRUD(),
    authorizedRequest: true,
  });

export const addUserInfo = (userInfo) =>
  makeAPICall({
    method: 'POST',
    url: USERINFO_CRUD(),
    authorizedRequest: true,
    data: userInfo,
  });
export const updateUserInfo = (userInfo) =>
  makeAPICall({
    method: 'PATCH',
    url: USERINFO_CRUD(),
    authorizedRequest: true,
    data: userInfo,
  });

export const getUserEmail = () =>
  makeAPICall({
    url: USEREMAIL_CRUD(),
    authorizedRequest: true,
  });

export const addUserEmail = (userEmail) =>
  makeAPICall({
    method: 'POST',
    url: USEREMAIL_CRUD(),
    authorizedRequest: true,
    data: userEmail,
  });
export const updateUserEmail = (userEmail, uid) =>
  makeAPICall({
    method: 'PATCH',
    url: USEREMAIL_CRUD(uid),
    authorizedRequest: true,
    data: userEmail,
  });

export const deleteUserEmail = (uid) =>
  makeAPICall({
    method: 'DELETE',
    url: USEREMAIL_CRUD(uid),
    authorizedRequest: true,
  });

export const getEmergencyContact = () =>
  makeAPICall({
    url: EMERGANCY_CONTACT_CRUD(),
    authorizedRequest: true,
  });

export const addEmergencyContact = (emergencyContact) =>
  makeAPICall({
    method: 'POST',
    url: EMERGANCY_CONTACT_CRUD(),
    authorizedRequest: true,
    data: emergencyContact,
  });

export const updateEmergencyContact = (emergencyContact, uid) =>
  makeAPICall({
    method: 'PATCH',
    url: EMERGANCY_CONTACT_CRUD(uid),
    authorizedRequest: true,
    data: emergencyContact,
  });

export const deleteEmergencyContact = (uid) =>
  makeAPICall({
    method: 'DELETE',
    url: EMERGANCY_CONTACT_CRUD(uid),
    authorizedRequest: true,
  });
