import React, { useState } from 'react';
import CustomTextArea from './CutomTextArea';
import Moods from './Mood';
import './styles.scss';
import checkinTick from 'assets/svg/check-in-dark-tick.svg';
import updateIcon from 'assets/svg/dark-update-status.svg';
import cancelCheckin from 'assets/svg/cancel-update-status.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  addCheckinStatus,
  userStatusUpdate,
} from '../../../../utils/api/api-requests/user';
import { toast } from 'react-toastify';
import {
  setYesterdayGoals,
  setTodayGoals,
  setBlockers,
  setSelectedMood,
  setMetYesterdayGoals,
} from 'redux/actions/userStatus';

const UpdateStatusForm = ({
  showStatusUpateForm,
  setShowStatusUpateForm,
  attendanceHandler,
}) => {
  const [didCompleteBtnClick, setDidCompleteBtnClick] = useState(false);
  const {
    yesterdayGoals,
    todayGoals,
    blockers,
    selectedMood,
    isCheckedIn,
    metYesterdayGoals,
    userStatus,
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const completeCheckin = () => {
    setDidCompleteBtnClick(true);
    if (!isCheckedIn) {
      if (yesterdayGoals && todayGoals && selectedMood) {
        addCheckinStatus({
          yesterdayGoals,
          todayGoals,
          blockers,
          selectedMood,
          metYesterdayGoals,
        })
          .then(() => {
            setDidCompleteBtnClick(false);
            setShowStatusUpateForm({
              visible: false,
              buttonText: 'Complete Check-in',
            });
            if (attendanceHandler) attendanceHandler();
          })
          .catch(() => {
            toast.error('Unable to add status.');
          });
      }
    } else {
      if (yesterdayGoals && todayGoals && selectedMood) {
        userStatusUpdate({
          yesterdayGoals,
          todayGoals,
          blockers,
          selectedMood,
          metYesterdayGoals,
          statusId: userStatus?.daily_status_id,
        })
          .then(() => {
            setDidCompleteBtnClick(false);
            setShowStatusUpateForm({
              visible: false,
              buttonText: 'Complete Check-in',
            });
          })
          .catch(() => {
            toast.error('Unable to add status.');
          });
      }
    }
  };
  return (
    <div>
      <div className='update-status-form'>
        <CustomTextArea
          label='What did you do yesterday?'
          value={yesterdayGoals}
          setValue={(value) => dispatch(setYesterdayGoals(value))}
          didCompleteBtnClick={didCompleteBtnClick}
        />

        <CustomTextArea
          label='What are your goals today?'
          value={todayGoals}
          setValue={(value) => dispatch(setTodayGoals(value))}
          didCompleteBtnClick={didCompleteBtnClick}
        />
        <CustomTextArea
          label='Are you blocked by anything?'
          description='Leave this blank unless you need any help.'
          value={blockers}
          setValue={(value) => dispatch(setBlockers(value))}
          didCompleteBtnClick={didCompleteBtnClick}
        />
        <div className='custom-textarea'>
          <div className='custom-textarea-label'>
            How are you feeling today?
          </div>
          <Moods
            selectedMood={selectedMood}
            setSelectedMood={(value) => dispatch(setSelectedMood(value))}
          />
        </div>
        <div
          className={`check-in-work `}
          onClick={() => dispatch(setMetYesterdayGoals(!metYesterdayGoals))}
        >
          <span className='styled-checkbox met-yesterday-goals'>
            <input
              id='metYesterdayGoals'
              type='checkbox'
              checked={metYesterdayGoals}
            />
            <span className='checkbox-text' for='metYesterdayGoals'>
              Did you meet your yesterday’s goals?
            </span>
            <span
              className={`checkmark ${
                !metYesterdayGoals ? 'unchecked-box' : ''
              }`}
            />
          </span>
        </div>
      </div>
      <div className='action-buttons'>
        <div
          className={`check-in-btn complete-checkin-btn ${
            yesterdayGoals?.trim() && todayGoals?.trim()
              ? ''
              : 'disabled-checkin-btn'
          }`}
          onClick={completeCheckin}
        >
          <img
            src={
              showStatusUpateForm?.buttonText === 'Complete Check-in'
                ? checkinTick
                : updateIcon
            }
            alt='tick'
          />
          <span>{showStatusUpateForm?.buttonText}</span>
        </div>
        <div
          className='cancel-checkin-btn ml-3'
          onClick={() => setShowStatusUpateForm(false)}
        >
          <img src={cancelCheckin} alt='cancel' />
          <span>Cancel</span>
        </div>
      </div>
    </div>
  );
};

export default UpdateStatusForm;
