import React, { useEffect, useState } from 'react';
import {
  verifyClientToken,
  verifyResetPwdToken,
} from '../../../utils/api/api-requests/auth';
import RedirectSpinner from 'Components/Common/RedirectSpinner/RedirectSpinner';
import './styles.scss';
import { useHistory } from 'react-router-dom';
const ClientAuthVerification = ({ isResettingPassword }) => {
  const token = new URLSearchParams(document.location.search).get('token');
  const uid = new URLSearchParams(document.location.search).get('id');
  const [isVerifying, setIsVerifying] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setIsVerifying(true);
    (isResettingPassword ? verifyResetPwdToken : verifyClientToken)(
      isResettingPassword
        ? token
        : {
            token,
            uid,
          }
    )
      .then((response) => {
        const { email, roles } = response?.data;
        setIsVerifying(false);
        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
        localStorage.setItem('roles', roles || []);
        history.push(
          isResettingPassword
            ? `/auth/client/reset-password?token=${token}`
            : `/auth/client/signup?token=${token}&id=${uid}`
        );
      })
      .catch((err) => {
        if (err === 'Unable to find requested record') {
          history.push('/auth/client/login');
        }
        setIsVerifying(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    isVerifying && (
      <div className='client-auth-loader'>
        <RedirectSpinner />
      </div>
    )
  );
};

export default ClientAuthVerification;
