import React, { useEffect, useState } from 'react';
import homeNeutral from 'assets/img/sidebar-icons/neutral-home.png';
import manageNeutral from 'assets/img/sidebar-icons/neutral-manage.png';
import activityNeutral from 'assets/img/sidebar-icons/neutral-activity.png';
import settingsNeutral from 'assets/img/sidebar-icons/neutral-settings.png';
import homeActive from 'assets/img/sidebar-icons/active-home.png';
import manageActive from 'assets/img/sidebar-icons/active-manage.png';
import activityActive from 'assets/img/sidebar-icons/active-activity.png';
import settingsActive from 'assets/img/sidebar-icons/active-settings.png';
import finance from 'assets/img/sidebar-icons/fincance.png';
import financeActive from 'assets/img/sidebar-icons/finance-active.png';

import {
  useLocation,
  NavLink as NavLinkRRD,
  Link,
  useHistory,
} from 'react-router-dom';
import { isAdminLoggedIn, isUserLoggedIn } from 'utils/helper-functions';
import switchIcon from 'assets/img/switch-icon.png';

import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  NavbarBrand,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setProjectLogsList } from 'redux/actions/logs';
import PerfectScrollbar from 'react-perfect-scrollbar';

import './styles.scss';

const Sidebar = ({ routes, logo, isClient }) => {
  const history = useHistory();
  const [linkState, setLinkState] = useState({});
  const [previousLinkState, setPreviousLinkState] = useState({});
  const dispatch = useDispatch();
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const mploUser = useSelector((state) => state.mploUser);

  const location = useLocation();

  /* ***************** Functions ************************************** */
  useEffect(() => {
    if (localStorage.getItem('sidenav') === 'hidden') {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-show');
      document.body.classList.add('g-sidenav-hidden');
      setSidenavOpen(false);
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      setSidenavOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  //toggle roles

  const changeRoleHandler = () => {
    if (isAdminLoggedIn()) {
      localStorage.setItem('currentUserRole', 'user');
      history.push(`/datumbrain/user/index`);
      return;
    } else if (isUserLoggedIn()) {
      localStorage.setItem('currentUserRole', 'admin');
      history.push(`/datumbrain/admin/index`);
    }
  };

  // get collapse intial state
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  const [menuOpen, setMenuOpen] = useState(false);
  // toggle sidenav bar
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      // document.body.classList.add('g-sidenav-hidden');
      localStorage.setItem('sidenav', 'hidden');
      document.body.classList.remove('g-sidenav-pinned');
      setLinkState('');
    } else {
      localStorage.setItem('sidenav', 'pinned');
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
    setMenuOpen(!menuOpen);
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)

  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      setLinkState(previousLinkState);
      document.body.classList.add('g-sidenav-show');
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
      setPreviousLinkState(linkState);
      setLinkState('');
    }
  };

  // active routes
  const activeRoute = (routeName) => {
    let pathArray = location.pathname.split('/');
    return (pathArray?.includes('user') &&
      pathArray?.includes('project') &&
      routeName === '/user/index') ||
      (pathArray?.includes('user') &&
        pathArray?.includes('projects') &&
        routeName === '/user/manage')
      ? 'active'
      : location.pathname.indexOf(routeName) > -1
      ? 'active'
      : 'neutral';
  };
  const setActiveIcon = (icon) => {
    switch (icon) {
      case 'home':
        return homeActive;
      case 'manage':
        return manageActive;
      case 'settings':
        return settingsActive;
      case 'activity':
        return activityActive;
      case 'invoice':
        return financeActive;
      default:
        return '';
    }
  };
  const setDefaultIcon = (icon) => {
    switch (icon) {
      case 'home':
        return homeNeutral;
      case 'manage':
        return manageNeutral;
      case 'settings':
        return settingsNeutral;
      case 'activity':
        return activityNeutral;
      case 'invoice':
        return finance;
      default:
        return '';
    }
  };

  // close sidenav
  const closeSidenav = (propName) => {
    if (propName === 'Logout') {
      localStorage.removeItem('jwt-token');
      dispatch(setProjectLogsList([]));
    }
  };

  let navbarBrandProps;
  if (logo?.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }

  const createLinks = (routes) => {
    return routes?.map(
      ({ redirect, collapse, state, views, icon, name, layout, path }, key) => {
        if (redirect) {
          return null;
        }
        if (collapse) {
          var st = {};
          st[`${state}`] = !linkState[state];
          return (
            <>
              <NavItem key={key}>
                <NavLink
                  href='#'
                  data-toggle='collapse'
                  aria-expanded={linkState[`${state}`]}
                  className={classnames({
                    active: getCollapseInitialState(views),
                  })}
                  onClick={(e) => {
                    e?.preventDefault();
                    setLinkState(st);
                    localStorage.setItem('sidenav', 'pinned');
                    document.body.classList.add('g-sidenav-pinned');
                    document.body.classList.remove('g-sidenav-hidden');
                    setSidenavOpen(true);
                  }}
                >
                  {icon ? (
                    <>
                      <img
                        alt='sidebar-icon'
                        className='sidebar-icon'
                        src={
                          getCollapseInitialState(views)
                            ? setActiveIcon(icon)
                            : setDefaultIcon(icon)
                        }
                      />
                      <span className='nav-link-text'>{name}</span>
                    </>
                  ) : name ? (
                    <>
                      <span className='sidenav-normal'> {name} </span>
                    </>
                  ) : null}
                </NavLink>
                <Collapse isOpen={linkState[`${state}`]}>
                  <Nav className='nav-sm flex-column'>
                    <span className={`sidenav-child`}>
                      {createLinks(views)}
                    </span>
                  </Nav>
                </Collapse>
              </NavItem>
            </>
          );
        }

        return window.innerWidth > 768 && ['Logout']?.includes(name) ? (
          ''
        ) : (
          <NavItem className={activeRoute(layout + path)} key={key}>
            <NavLink
              to={`${
                localStorage?.getItem('currentUserRole') !== 'client'
                  ? '/datumbrain'
                  : ''
              }${layout}${path}`}
              activeClassName={icon !== undefined ? 'active' : ''}
              onClick={() => closeSidenav(name)}
              tag={NavLinkRRD}
            >
              {icon !== undefined ? (
                <>
                  <img
                    alt='sidebar-icon'
                    className='sidebar-icon'
                    src={
                      activeRoute(layout + path) === 'active'
                        ? setActiveIcon(icon)
                        : setDefaultIcon(icon)
                    }
                  />
                  <span className='nav-link-text'>{name}</span>
                </>
              ) : (
                <span className='sidenav-normal'> {name} </span>
              )}
            </NavLink>
          </NavItem>
        );
      }
    );
  };

  const scrollBarInner = (
    <div className='scrollbar-inner admin'>
      <div>
        <div className='sidenav-header d-flex align-items-center'>
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className='navbar-brand-img'
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          <div className='ml-auto pd-block-16'>
            <div
              className={classnames('sidenav-toggler d-xl-block')}
              onClick={toggleSidenav}
            >
              <div
                className={` sidenav-toggler-inner navbar__menu ${
                  menuOpen ? 'width-50' : null
                }`}
              >
                <i className='sidenav-toggler-line' />
                <i
                  className={`sidenav-toggler-line ${
                    menuOpen ? 'open_menu' : null
                  }`}
                />
                <i className='sidenav-toggler-line' />
              </div>
            </div>
          </div>
        </div>
        <div className='navbar-inner'>
          <Collapse
            navbar
            isOpen={true}
            className={`navbar__collapse ${sidenavOpen ? '' : 'custom_slide'}`}
          >
            <Nav navbar>{createLinks(routes)}</Nav>
          </Collapse>
        </div>
      </div>
      {!isClient &&
      ['ADMIN', 'admin']?.filter((value) => mploUser?.roles?.includes(value))
        ?.length > 0 &&
      ['employee', 'EMPLOYEE', 'user', 'USER']?.filter((value) =>
        mploUser?.roles?.includes(value)
      )?.length > 0 ? (
        <div className='manager-console'>
          <Nav navbar onClick={changeRoleHandler}>
            <NavItem className='neutral'>
              <NavLink>
                <img
                  alt='sidebar-icon'
                  width='15px'
                  className='sidebar-icon'
                  src={switchIcon}
                />
                <span
                  className={`nav-link-text ${menuOpen ? 'width-50' : null}`}
                >
                  {isAdminLoggedIn() ? 'User Console' : 'Manager Console'}
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      ) : (
        ''
      )}
    </div>
  );
  const conditionalProps =
    window.innerWidth > 991
      ? {
          onMouseEnter: onMouseEnterSidenav,
          onMouseLeave: onMouseLeaveSidenav,
        }
      : {};
  return (
    <Navbar
      className={
        'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white nav__sidebar'
      }
      {...conditionalProps}
    >
      {navigator.platform.indexOf('Win') > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
