import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { downloadInvoiceByClient } from '../../../../../utils/api/api-requests/client';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { downloadInvoice } from '../../../../../utils/api/api-requests/owner';

const InvoiceGenerator = ({ isClient, pId, clientProjectName }) => {
  const { projectId, uid } = useParams();
  const [isDownoadingInvoice, setIsDownloadingInvoice] = useState(false);

  const { fromDateFilter, toDateFilter } = useSelector((state) => state);

  const generateInvoice = () => {
    // Get the user's current timezone
    const userTimezone = momentTimezone?.tz?.guess();
    const timezoneInfo = momentTimezone?.tz?.zone(userTimezone);
    const utcOffsetMinutes = timezoneInfo?.utcOffset(new Date());
    const utcOffsetHours = Math?.floor(Math?.abs(utcOffsetMinutes) / 60);

    setIsDownloadingInvoice(true);
    const options = {
      projectId: isClient === true ? uid : projectId,
      from:
        fromDateFilter === moment()?.format('YYYY-MM-DD')
          ? moment(fromDateFilter)
              ?.utc()
              ?.subtract(1, 'day')
              ?.utcOffset(utcOffsetHours)
              ?.format('YYYY-MM-DD')
          : moment(fromDateFilter)
              ?.utc()
              ?.utcOffset(utcOffsetHours)
              ?.format('YYYY-MM-DD'),
      to:
        toDateFilter === moment()?.format('YYYY-MM-DD')
          ? moment(toDateFilter)
              ?.utc()
              ?.subtract(1, 'day')
              ?.utcOffset(utcOffsetHours)
              ?.format('YYYY-MM-DD')
          : moment(toDateFilter)
              ?.utc()
              ?.utcOffset(utcOffsetHours)
              ?.format('YYYY-MM-DD'),
      projectType: 'HOURLY',
    };
    (isClient === true ? downloadInvoiceByClient : downloadInvoice)(
      isClient === true ? options : { ...options, approved: false }
    )
      .then((response) => {
        setIsDownloadingInvoice(false);
        window.open(response.data, '_blank').focus();
      })
      .catch(() => {
        setIsDownloadingInvoice(false);
        toast.error('Unable to download invoice.');
      });
  };
  return (
    <>
      {isDownoadingInvoice ? (
        <div className='invoice-spinner'>
          <Spinner color='admin' />
        </div>
      ) : (
        <div
          className={`custom-action-card ${
            fromDateFilter && toDateFilter ? '' : 'disabled-button'
          }`}
          onClick={generateInvoice}
        >
          <img
            className='cursor-pointer'
            alt='...'
            src={require('assets/svg/download.svg').default}
          />
          <span>Download invoice</span>
        </div>
      )}
    </>
  );
};

export default InvoiceGenerator;
