import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { toggleDate } from 'utils/helper-functions';
import { TIMESTAMP_WITH_ZONE } from 'constants/index';
import { setFromDateFilter, setToDateFilter } from 'redux/actions/projects';
import AssociatedEmployeeRow from '../../Admin/Project/Table/AssociatedEmployeeRow';
import { getClientProjects } from '../../../utils/api/api-requests/client';
import { toast } from 'react-toastify';

const ProjectTable = () => {
  const [projects, setProjects] = useState([]);
  const [toggledCreationDates, setToggledCreationDates] = useState([]);
  const [areProjectsBeingLoaded, setAreProjectsBeingLoaded] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const history = useHistory();

  const dispatch = useDispatch();

  let currentDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, [projects]);
  useEffect(() => {
    setAreProjectsBeingLoaded(true);
    getClientProjects()
      .then(({ data }) => {
        setProjects(data ?? []);
        setAreProjectsBeingLoaded(false);
      })
      .catch(() => {
        setAreProjectsBeingLoaded(false);
        toast.error('Unable to fetch projects.');
      });
  }, []);
  return (
    <>
      {areProjectsBeingLoaded ? (
        <i class='fas fa-circle-notch fa-spin client-data-spinner' />
      ) : projects?.length > 0 ? (
        <>
          <Table className='align-items-center table-flush' responsive>
            <thead className='thead-light'>
              <tr>
                <th scope='col'>Name</th>
                <th scope='col'>Manager</th>
                <th scope='col'>Commenced</th>
                <th scope='col'>Logs</th>
                <th scope='col'>Associated Employees</th>
              </tr>
            </thead>
            <tbody>
              {projects?.map(
                ({
                  uid,
                  name,
                  start_date,
                  end_date,
                  managers,
                  client,
                  created_at,
                }) => (
                  <tr
                    key={uid}
                    className={
                      currentDate <
                        moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') ||
                      currentDate >
                        moment(end_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                        ? 'pink-row'
                        : ''
                    }
                  >
                    <td>{name}</td>

                    <td>{managers?.[0]?.name || '-'}</td>
                    <td>
                      <span
                        className='toggled-date'
                        onClick={() =>
                          toggleDate({
                            uid,
                            array: toggledCreationDates,
                            setArray: setToggledCreationDates,
                          })
                        }
                      >
                        {start_date
                          ? toggledCreationDates?.includes(uid)
                            ? moment(start_date, 'YYYY-MM-DD').format('LL')
                            : moment(
                                moment(
                                  start_date +
                                    moment(
                                      created_at,
                                      'YYYY-MM-DDTHH:mm:ssZ'
                                    ).format('HH:mm:ss'),
                                  TIMESTAMP_WITH_ZONE
                                ).format(TIMESTAMP_WITH_ZONE)
                              ).from(moment(currentTime))
                          : '-'}
                      </span>
                    </td>
                    <td className='justify-content-end text-default'>
                      <div
                        className='cursor-pointer custom-link-class user-theme-color'
                        onClick={() => {
                          dispatch(
                            setFromDateFilter(
                              moment().isoWeekday(1).format('YYYY-MM-DD')
                            )
                          );
                          dispatch(
                            setToDateFilter(moment().format('YYYY-MM-DD'))
                          );
                          history.push(`/client/projects/${uid}/logs`);
                        }}
                      >
                        view logs
                      </div>
                    </td>
                    <td>
                      <AssociatedEmployeeRow
                        isClient
                        projectName={name}
                        projectId={uid}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div button'>There are no projects yet</div>
      )}
    </>
  );
};

export default ProjectTable;
