import React from 'react';
import happyMood from 'assets/svg/happy-mood.svg';
import neutralMood from 'assets/svg/neutral-mood.svg';
import sadMood from 'assets/svg/sad-mood.svg';
export const userMoods = [
  {
    text: 'Happy',
    image: happyMood,
  },
  {
    text: 'Neutral',
    image: neutralMood,
  },
  {
    text: 'Sad',
    image: sadMood,
  },
];
const Mood = ({ text, image, selectedMood, setSelectedMood }) => (
  <div>
    <div>{text}</div>
    <img
      className={selectedMood === text ? 'selected-mood' : ''}
      src={image}
      alt={text}
      onClick={() => setSelectedMood(text)}
    />
  </div>
);
const Moods = (props) => (
  <div className='status-moods'>
    {userMoods?.map(({ text, image }, i) => (
      <Mood text={text} key={i + 1} image={image} {...props} />
    ))}
  </div>
);

export default Moods;
