export const TIMESTAMP_WITH_ZONE = 'YYYY-MM-DD HH:mm:ssZ';
export const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://192.168.31.150:3000' : '';
export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZE_INCREMENT = 5;
export const WORKSPACE = 'datumbrain';
export const MARKDOWN_CODE_STYLE =
  '<code style="color:white;background-color:silver;padding: .2em .4em;margin: 0;font-size: 85%;border-radius: 6px;font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;">$1</code>';
export const DEV_ENV = process.env.REACT_APP_DEVELOPMENT;
export const CHECKIN_UPPER_LIMIT = '06:00 AM';
export const tabs = [
  'pending',
  'approved',
  'partially approved',
  'disapproved',
  'all',
];
export const timePickerFormat = 'hh:mm A';
export const DEFAULT_DESCRIPTION = 'Custom Software Development Services';

export const PAGE_LIMIT = 2;

export const NUM_OF_DAYS_TO_ADD_LOG = 45;
