import moment from 'moment';

const TimelineDefault = (startTime, startPosition, unitGap) => {
  let timeline = [];
  let timeOfDay = [];
  let hourGap = [];
  for (var i = 0; i < 25; i++) {
    if (i === 0) {
      timeOfDay.push(startTime);
      hourGap.push(startPosition);
    } else {
      timeOfDay.push(
        moment(timeOfDay[i - 1], 'hh:mm A')
          .add(1, 'hour')
          .format('hh:mm A')
      );
      hourGap.push(hourGap[i - 1] + unitGap);
    }
    timeline.push({ time: timeOfDay[i], position: hourGap[i] });
  }
  return timeline;
};

export default TimelineDefault;
