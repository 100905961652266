import React, { useState } from 'react';
import { login } from 'utils/api/api-requests/auth';
import { GoogleLogin } from 'react-google-login';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setMploUser } from 'redux/actions/general';
import loadingGif from 'assets/gif/loading.gif';
import { getImageUrl } from 'utils/helper-functions';
import lineSeparator from 'assets/svg/line-separator.svg';
import clientLoginButton from 'assets/svg/client-login-button.svg';
import './styles.scss';
const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loginSpinnerFlag, setLoginSpinnerFlag] = useState(false);
  const loginGoogleHandler = ({
    tokenId,
    profileObj: { name, imageUrl: requestImageUrl },
  }) => {
    setLoginSpinnerFlag(true);
    login({
      google_id_token: tokenId,
      name,
      profile_image: requestImageUrl,
    })
      .then(
        ({
          data: { name, roles, uid, email, image_url: responseImageUrl },
          headers,
        }) => {
          localStorage.setItem('jwt-token', headers['jwt-token']);
          localStorage.setItem('profileImage', responseImageUrl);
          toast.success('Logged-in successfully');

          const mploUser = {
            name,
            roles,
            uid,
            email,
            profileImage: getImageUrl(responseImageUrl),
          };
          dispatch(setMploUser(mploUser));
          if (roles?.includes('ADMIN')) {
            localStorage.setItem('currentUserRole', 'admin');
            setLoginSpinnerFlag(false);
            history.push('/datumbrain/admin/index');
          } else if (roles?.includes('EMPLOYEE')) {
            localStorage.setItem('currentUserRole', 'user');
            setLoginSpinnerFlag(false);
            history.push('/datumbrain/user/index');
          }
        }
      )
      .catch((error) => {
        toast.error('Invalid email or password.');
        console.error(error);
        setLoginSpinnerFlag(false);
      });
  };

  return (
    <>
      {loginSpinnerFlag ? (
        <center className='d-flex flex-column align-items-center '>
          <img src={loadingGif} alt='loading...' style={{ width: '100px' }} />
        </center>
      ) : (
        <center>
          <GoogleLogin
            clientId='221882381163-i4ljb8b7nk8o3jm94n2fso7j52c18p6j.apps.googleusercontent.com'
            buttonText='Login with Google Workspace Account'
            onSuccess={loginGoogleHandler}
            cookiePolicy={'single_host_origin'}
            className='login-btn google-login-button'
          />
          <div className='auth-choice'>
            <img src={lineSeparator} alt='line' />
            <div>or</div>
            <img src={lineSeparator} alt='line' />
          </div>
          <img
            onClick={() => history.push('/auth/client/login')}
            src={clientLoginButton}
            className='cursor-pointer'
            alt='login'
          />
        </center>
      )}
    </>
  );
};

export default Login;
