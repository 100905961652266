import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import FormGroupWithLabel from 'Components/Common/FormGroupWithLabel/FormGroupWithLabel';
import { toast } from 'react-toastify';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import {
  updateUserEmail,
  deleteUserEmail,
  addUserEmail,
  getUserEmail,
} from 'utils/api/api-requests/general';
import { validate } from 'email-validator';
import { useHistory } from 'react-router-dom';

const ProfileEmails = () => {
  const {
    mploUser: { email },
  } = useSelector((state) => state);
  const history = useHistory();
  const [userEmails, setUserEmails] = useState([]);
  const [additionalEmail, setAdditionalEmail] = useState('');
  const [emailModal, setEmailModal] = useState(false);
  const [emailIndexArray, setEmailIndexArray] = useState([]);
  const [editUserEmailFlag, setEditUserEmailFlag] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEmailUid, setDeleteEmailUid] = useState('');
  const [didSaveButtonClicked, setDidSaveButtonClicked] = useState(false);
  useEffect(() => {
    getUserEmail()
      .then(({ data }) => {
        setUserEmails(data);
      })
      .catch((error) => {
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error('Failed to get user emails');
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteModal]);
  const addEmailHandler = () => {
    setDidSaveButtonClicked(true);
    if (validate(additionalEmail)) {
      addUserEmail({ email: additionalEmail }).then(() => {
        toast.success('Successfully addded email');
        getUserEmail()
          .then(({ data }) => {
            setUserEmails(data);
            setEmailModal(false);
            setDidSaveButtonClicked(false);
          })
          .catch(() => {
            toast.error('failed');
            setDidSaveButtonClicked(false);
          });
      });
    }
  };
  const setUserEmailHandler = (email, index, emailUid) => {
    setEditUserEmailFlag(true);
    const tempArray = userEmails?.filter(({ uid }) => uid !== emailUid);
    if (index === 0) tempArray?.unshift({ email, uid: emailUid });
    else tempArray?.push({ email, uid: emailUid });
    setUserEmails(tempArray);
    setDidSaveButtonClicked(false);
  };
  const userEmailApiHandler = () => {
    setDidSaveButtonClicked(true);
    userEmails?.forEach(({ email, uid }) => {
      if (emailIndexArray?.includes(uid) && validate(email)) {
        updateUserEmail({ email }, uid)
          .then(() => {
            toast.success('Updated Email Successfully');
            setEmailIndexArray([]);
            setEditUserEmailFlag(false);
            setDidSaveButtonClicked(false);
          })
          .catch(() => {
            toast.success('Failed Email Successfully');
            setEmailIndexArray([]);
            setEditUserEmailFlag(false);
            setDidSaveButtonClicked(false);
          });
      }
    });
  };
  const deletEmailHandler = () => {
    deleteUserEmail(deleteEmailUid)
      .then(() => {
        setDeleteEmailUid('');
        setDeleteModal(false);
        toast.success('Successfully deleted email');
      })
      .catch(() => {
        setDeleteEmailUid('');
        setDeleteModal(false);
        toast.success('Failed to delete email');
      });
  };
  const setDeleteEmailUidHandler = (uid) => {
    setDeleteEmailUid(uid);
    setDeleteModal(true);
  };
  const emailModalToggle = () => {
    setEmailModal(!emailModal);
    setAdditionalEmail('');
    setDidSaveButtonClicked(false);
  };
  const cancelUserEmailHandler = () => {
    setDidSaveButtonClicked(false);
    setEmailIndexArray([]);
    setEditUserEmailFlag(false);
    getUserEmail()
      .then(({ data }) => {
        setUserEmails(data);
      })
      .catch(() => {
        toast.error('Failed to get user emails');
      });
  };
  return (
    <>
      <Row className='m-0'>
        <h6 className='heading-small text-muted mb-4'>Emails</h6>
        <div className='col text-right pr-0'>
          <Button
            className='admin-theme-button user-profile-btn'
            onClick={() => setEmailModal(true)}
            disabled={userEmails?.length >= 2}
          >
            <i className='fas fa-plus mr-1'></i>
            Add
          </Button>
        </div>
      </Row>
      <div>
        <Row>
          <Col lg='4'>
            <FormGroupWithLabel
              labelName='Primary Email'
              id='input-primary-email'
              placeholder='Primary Email'
              type='email'
              value={email}
              disabled
            />
          </Col>
          {userEmails?.map(({ email, uid }, index) => (
            <Col lg='4'>
              <FormGroupWithLabel
                labelName={`Additional Email ${index + 1}`}
                id={`input-additiona-email-${index + 1}`}
                placeholder={`Additional Email ${index + 1}`}
                type='email'
                name='additional-email'
                value={email}
                setValue={(value) => setUserEmailHandler(value, index, uid)}
                icon
                emailIndexArray={emailIndexArray}
                setEmailIndexArray={setEmailIndexArray}
                uid={uid}
                setDeleteEmailUid={setDeleteEmailUidHandler}
                didSubmitButtonClick={didSaveButtonClicked}
                invalidText='Invalid email address'
                required
              />
            </Col>
          ))}
        </Row>{' '}
        {editUserEmailFlag ? (
          <div className='d-flex justify-content-end'>
            <Button
              className='admin-theme-button custom-btn-size2 btn-width'
              onClick={userEmailApiHandler}
            >
              Save
            </Button>{' '}
            <Button
              className='admin-theme-button custom-btn-size2 btn-width'
              onClick={cancelUserEmailHandler}
            >
              Cancel
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
      <CustomModal
        isOpen={emailModal}
        toggle={emailModalToggle}
        headerText='Add additional email'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={addEmailHandler}
        customClass='form-modal'
      >
        <FormGroupWithLabel
          labelName='Email'
          id='input-email'
          placeholder='someone@somewebisite.co'
          type='email'
          setValue={setAdditionalEmail}
          value={additionalEmail}
          didSubmitButtonClick={didSaveButtonClicked}
          invalidText='Invalid email address'
          name='email'
          required
        />
      </CustomModal>
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete email'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deletEmailHandler}
      >
        <p>Are you sure you want to delete this email?</p>
      </CustomModal>
    </>
  );
};

export default ProfileEmails;
