import React, { useState, useEffect } from 'react';
import loadingGif from 'assets/gif/loading.gif';
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
  Spinner,
} from 'reactstrap';
import AdminHeader from 'Components/Headers/Admin/Admin';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestSlackIntegration,
  requestSlackUnIntegration,
  getChannels,
  getIntegration,
  selectChannel,
  leaveChannel,
} from 'utils/api/api-requests/admin';
import { redirectToExternalLink } from 'utils/helper-functions';
import {
  setSlackChannelsArray,
  setIntegrationFlag,
} from 'redux/actions/integration/slack';
import { toast } from 'react-toastify';
import './IntegrationCards.scss';
import IntegrationCard from './IntegrationCard';

const IntegrationCards = () => {
  const { integrationFlag, slackChannelsArrays } = useSelector(
    (state) => state
  );
  const [channelsModal, setChannelsModal] = useState(false);
  const [slackConnectionFlag, setSlackConnectionFlag] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [channelId, setChannelId] = useState('');
  const [areChannelsLoading, setAreChannelsLoading] = useState(false);
  const [showNotificationApps, setShowNotificationApps] = useState(false);
  const [showOtherApps, setShowOtherApps] = useState(false);
  const [isSlackIntegrationLoading, setIsSlackIntegrationLoading] =
    useState(false);

  useEffect(() => {
    setIsSlackIntegrationLoading(true);
    getIntegration()
      .then(({ data: { slack } }) => {
        if (slack) {
          dispatch(setIntegrationFlag(true));
          if (slack?.channel) {
            setSlackConnectionFlag(true);
            setSelectedChannel(slack?.channel);
            setChannelId(slack?.channel?.id);
          } else setSlackConnectionFlag(false);
        } else dispatch(setIntegrationFlag(false));

        setTimeout(() => setIsSlackIntegrationLoading(false), 1000);
      })
      .catch(() => {
        dispatch(setIntegrationFlag(false));
        setTimeout(() => setIsSlackIntegrationLoading(false), 1000);
        toast.error('Unable to fetch integrations');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slackChannelsArrays]);
  const dispatch = useDispatch();

  const slackConnectHandler = (e) => {
    e.preventDefault();
    requestSlackIntegration()
      .then(({ data }) => {
        redirectToExternalLink(data);
      })
      .catch(() => toast.error('Failed to get slack auth'));
  };
  const slackDisconnectHandler = (e) => {
    e.preventDefault();
    requestSlackUnIntegration()
      .then((res) => {
        toast.success('Slack disconnected');
        setSlackConnectionFlag(false);
        dispatch(setIntegrationFlag(false));
        setSelectedChannel({});
      })
      .catch(() => toast.error('Failed to disconnect slack'));
  };

  const selectChannelHandler = () => {
    if (channelId) {
      if (selectedChannel?.id) {
        if (selectedChannel?.id !== channelId) {
          leaveChannel()
            .then(() => {
              toast.success('Left Successfully');
              selectChannel(channelId)
                .then(() => {
                  getSlackChannels();
                  toast.success('Joined Successfully');
                  setChannelsModal(false);
                })
                .catch((err) => {
                  toast.error('Failed to join channel');
                  setChannelsModal(false);
                });
            })
            .catch(() => {
              toast.error('Failed to leave channel');
              setChannelsModal(false);
            });
        }
      } else {
        selectChannel(channelId)
          .then(() => {
            getSlackChannels();
            toast.success('Joined Successfully');
            setChannelsModal(false);
          })
          .catch((err) => {
            toast.error('Failed to join channel');
            setChannelsModal(false);
          });
      }
    } else leaveChannelHandler();
  };
  const hideChannelsModalHandler = () => {
    setChannelsModal(false);
    setAreChannelsLoading(false);
    setTimeout(
      () => setChannelId(slackConnectionFlag ? selectedChannel?.id : ''),
      1000
    );
  };
  const leaveChannelHandler = () => {
    leaveChannel()
      .then(() => {
        toast.success('Left Successfully');
        getChannels()
          .then((res) => {
            dispatch(setSlackChannelsArray(res?.data));
            setAreChannelsLoading(false);
            setSelectedChannel({});
            setChannelsModal(false);
          })
          .catch((err) => {
            toast.error('Failed to leave channel');
            setAreChannelsLoading(false);
            setChannelsModal(false);
          });
      })
      .catch(() => toast.error('Failed to leave channel'));
  };
  const getSlackChannelsHandler = (e) => {
    setChannelsModal(true);
    setAreChannelsLoading(true);
    getSlackChannels();
  };
  const getSlackChannels = () => {
    getChannels()
      .then((res) => {
        dispatch(setSlackChannelsArray(res?.data));
        setAreChannelsLoading(false);
      })
      .catch(() => {
        toast.error('Failed to fetch channels');
        setAreChannelsLoading(false);
      });
  };
  const notificationAppCardsArray = [
    {
      title: 'MS Teams',
      text: ' Connect with your groups to receive updates.',
      imgPath: 'logos_teams',
    },
    {
      title: 'Rocket Chat',
      text: ' Connect to Rocket Chat to receive notifications.',
      imgPath: 'rocket_chat_logo',
    },
  ];
  const otherAppsCardsArray = [
    {
      title: 'Trello',
      text: 'Connect with your Trello boards per project to be able to associate cards with time logs.',
      imgPath: 'logos_trello',
    },
    {
      title: 'Jira',
      text: '     Connect with your Jira projects to associate tasks epics and stories with your time logs.',
      imgPath: 'logos_jira',
    },
    {
      title: 'Asana',
      text: ' Connect with your Asana projects to associate tasks with time logs.',
      imgPath: 'logos_asana',
    },

    {
      title: 'Basecamp',
      text: 'Connect your Basecamp account.',
      imgPath: 'logos_basecamp',
    },
    {
      title: 'Github',
      text: 'Bind your work logs with your PRs and track issues accordingly.',
      imgPath: 'logos_github',
    },
  ];

  return (
    <>
      <div className='main-content'>
        <AdminHeader />
        <Container className='mt--7' fluid>
          <Row className='mt-5 mb-5'>
            <Col className='mb-5' xl='12'>
              <Card className='shadow'>
                <CardHeader className='projects-header border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0 text-dark'>Connect</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className='pt-3'>
                  <Card className='notification-dropdown-container'>
                    <div> Notification Apps (3)</div>
                    <div>
                      {!showNotificationApps ? (
                        <img
                          src={require('assets/img/dropdown.svg').default}
                          alt='dropdown_icon'
                          onClick={() => setShowNotificationApps(true)}
                          className='cursor-pointer'
                        />
                      ) : (
                        <img
                          src={require('assets/img/dropdown.svg').default}
                          alt='dropdown_icon'
                          onClick={() => setShowNotificationApps(false)}
                          className='cursor-pointer transform-180 '
                        />
                      )}
                    </div>
                  </Card>{' '}
                  {showNotificationApps ? (
                    <div className='integration-card-container'>
                      {' '}
                      <Card className='text-center'>
                        <CardHeader>
                          <div
                            className={`integration-connect-button ${
                              isSlackIntegrationLoading
                                ? 'custom-toggle-loader'
                                : ''
                            }`}
                          >
                            {isSlackIntegrationLoading ? (
                              ''
                            ) : (
                              <label className='mb-0 custom-toggle markdown-toogle custom-parent-toggler'>
                                <input
                                  type='checkbox'
                                  onClick={
                                    integrationFlag
                                      ? slackDisconnectHandler
                                      : slackConnectHandler
                                  }
                                  checked={integrationFlag}
                                />
                                <span
                                  className={`custom-toggle-slider markdown-toogle-slider rounded-circle custom-child-toggler ${
                                    integrationFlag ? 'right' : 'left'
                                  }`}
                                />
                              </label>
                            )}
                          </div>
                          <img
                            src={
                              require('assets/img/logos_slack-icon.svg').default
                            }
                            alt='slack_icon'
                            width='46'
                          />
                        </CardHeader>
                        <CardBody className='integration-card-body'>
                          <span className='color-admin'>SLACK</span>
                          {isSlackIntegrationLoading ? (
                            <div className='text-center'>
                              <img
                                src={loadingGif}
                                alt='loading...'
                                style={{ width: '40px' }}
                              />
                            </div>
                          ) : (
                            <p>
                              {slackConnectionFlag
                                ? 'Successfully connected to the channel below.'
                                : integrationFlag
                                ? 'Please select a channel from channels list.'
                                : 'Connect to slack to recieve notifications.'}
                            </p>
                          )}
                        </CardBody>
                        <CardFooter>
                          {isSlackIntegrationLoading ? (
                            <Spinner
                              color='primary'
                              style={{ width: '1.75rem', height: '1.75rem' }}
                            />
                          ) : (
                            <>
                              <Button
                                className={`custom-btn-size integration-outline-btn ${
                                  integrationFlag ? '' : 'custom-disabled-btn'
                                }`}
                                outline
                                onClick={(e) => getSlackChannelsHandler(e)}
                              >
                                {slackConnectionFlag
                                  ? `# ${selectedChannel?.name}`
                                  : integrationFlag
                                  ? 'Channles List'
                                  : 'No channels'}
                                <img
                                  src={
                                    require(`assets/img/integration_connec${
                                      integrationFlag ? 't' : 't_disabled'
                                    }.png`).default
                                  }
                                  alt='connect'
                                  width='16'
                                />
                              </Button>
                            </>
                          )}
                        </CardFooter>
                      </Card>
                      {notificationAppCardsArray.map(
                        ({ title, text, imgPath }) => (
                          <IntegrationCard
                            title={title}
                            text={text}
                            imgPath={imgPath}
                          />
                        )
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <Card className='notification-dropdown-container'>
                    <div> Other Apps</div>
                    <div>
                      {!showOtherApps ? (
                        <img
                          src={require('assets/img/dropdown.svg').default}
                          alt='dropdown_icon'
                          onClick={() => setShowOtherApps(true)}
                          className='cursor-pointer'
                        />
                      ) : (
                        <img
                          src={require('assets/img/dropdown.svg').default}
                          alt='dropdown_icon'
                          onClick={() => setShowOtherApps(false)}
                          className='cursor-pointer transform-180'
                        />
                      )}
                    </div>
                  </Card>
                  {showOtherApps ? (
                    <div className='integration-card-container'>
                      {otherAppsCardsArray.map(({ title, text, imgPath }) => (
                        <IntegrationCard
                          title={title}
                          text={text}
                          imgPath={imgPath}
                        />
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={channelsModal}
        toggle={hideChannelsModalHandler}
        size='lg'
        centered
        className={`${
          areChannelsLoading ? 'redirect-spinner-modal' : 'channels-modal'
        }`}
      >
        {areChannelsLoading ? (
          <div className='text-center'>
            <img src={loadingGif} alt='loading...' style={{ width: '100px' }} />
          </div>
        ) : (
          <>
            <ModalHeader>
              <h3 className='mb-0 text-dark'>Channels list</h3>
              <div className='modal-app-logo'>
                <img
                  src={require('assets/img/logos_slack-icon.svg').default}
                  alt='slack_icon'
                  width='16'
                  onClick={() => setChannelsModal(false)}
                />
                <span>Slack</span>
              </div>
              <i className='fas fa-times' onClick={hideChannelsModalHandler} />
            </ModalHeader>
            <ModalBody className='pb-0 modal-overflow-y'>
              <Table className='align-items-center table-flush channels-table'>
                <tbody>
                  {slackChannelsArrays?.map(({ id, name }) => (
                    <tr>
                      <td># {name}</td>
                      <td>
                        <label className='mb-0 custom-toggle markdown-toogle custom-parent-toggler'>
                          <input
                            type='checkbox'
                            onClick={() =>
                              channelId ? setChannelId('') : setChannelId(id)
                            }
                            checked={channelId === id}
                            disabled={channelId && channelId !== id}
                          />
                          <span
                            className={`custom-toggle-slider markdown-toogle-slider rounded-circle custom-child-toggler ${
                              channelId === id ? 'right' : 'left'
                            }`}
                          />
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button
                className='custom-btn-size2 admin-theme-button'
                onClick={selectChannelHandler}
                color='primary'
              >
                Confirm
              </Button>
              <Button
                className='custom-btn-size2'
                color='secondary'
                onClick={hideChannelsModalHandler}
              >
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};

export default IntegrationCards;
