import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, Table } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { setProjectsList } from 'redux/actions/projects';
import { toggleDate, updateStateArray } from 'utils/helper-functions';
import { TIMESTAMP_WITH_ZONE } from 'constants/index';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import {
  unArchiveMultipleProjects,
  deleteMultipleProjects,
  getProjects,
} from 'utils/api/api-requests/admin';

const ArchiveProjectTable = ({
  projects,
  areProjectsBeingLoaded,
  selectedArchivedProjects,
  setSelectedArchivedProjects,
  unArchiveModal,
  setUnArchiveModal,
  deleteModal,
  setDeleteModal,
  updateProjectsStatus,
}) => {
  const [toggledCreationDates, setToggledCreationDates] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  const dispatch = useDispatch();
  let currentDate = moment().format('YYYY-MM-DD');

  const unarchiveMultipleProjectsHandler = () => {
    if (selectedArchivedProjects?.length > 0) {
      unArchiveMultipleProjects(selectedArchivedProjects).then((users) => {
        setUnArchiveModal(false);
        setSelectedArchivedProjects([]);
        toast.success('Projects unarchived successfully');
        getProjects()
          .then(({ data }) => {
            dispatch(setProjectsList(data));
            updateProjectsStatus(data);
          })
          .catch(() => {
            toast?.error('Unable to fetch project list.');
            setUnArchiveModal(false);
          });
      });
    }
  };

  const deleteMultipleProjectsHandler = () => {
    deleteMultipleProjects(selectedArchivedProjects)
      .then(() => {
        setDeleteModal(false);
        setSelectedArchivedProjects([]);
        toast.success('Projects deleted successfully');
        getProjects()
          .then(({ data }) => {
            dispatch(setProjectsList(data));
            updateProjectsStatus(data);
          })
          .catch(() => {
            toast?.error('Unable to fetch project list.');
          });
      })
      .catch(() => {
        toast.error('Unable to delete selected projects');
        setDeleteModal(false);
      });
  };
  useEffect(() => {
    const intervalId = setInterval(
      () => setCurrentTime(moment(new Date())),
      60000
    );
    return () => clearInterval(intervalId);
  }, [projects]);
  return (
    <>
      {areProjectsBeingLoaded ? (
        <div className='logs-spinner'>
          <Spinner color='admin' />
        </div>
      ) : projects?.length > 0 ? (
        <>
          <Table
            className='align-items-center table-flush project-table-class'
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th className='pl-pt-0'>
                  <div className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id='arcivedProjectsCheckBox'
                      type='checkbox'
                      checked={
                        selectedArchivedProjects?.length === projects?.length &&
                        selectedArchivedProjects?.length !== 0
                      }
                      onClick={(e) => {
                        setSelectedArchivedProjects(
                          e?.target?.checked
                            ? projects?.map(({ uid }) => uid)
                            : []
                        );
                      }}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='arcivedProjectsCheckBox'
                    ></label>
                  </div>
                </th>
                <th scope='col'>Name</th>
                <th scope='col'>Client</th>
                <th scope='col'>Manager</th>
                <th scope='col'>Commenced</th>
              </tr>
            </thead>
            <tbody>
              {projects?.map(
                ({
                  uid,
                  name,
                  start_date,
                  end_date,
                  managers,
                  client,
                  created_at,
                }) => (
                  <tr
                    key={uid}
                    className={
                      currentDate <
                        moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') ||
                      currentDate >
                        moment(end_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                        ? 'pink-row'
                        : ''
                    }
                  >
                    <td className='pl-pt-0'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id={uid}
                          type='checkbox'
                          checked={selectedArchivedProjects?.includes(uid)}
                          onClick={() =>
                            updateStateArray({
                              array: selectedArchivedProjects,
                              setArray: setSelectedArchivedProjects,
                              value: uid,
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={uid}
                        ></label>
                      </div>
                    </td>
                    <td>{name}</td>
                    <td>{client?.name ? client?.name : '-'}</td>
                    <td>{managers?.[0]?.name || '-'}</td>
                    <td>
                      <span
                        className='toggled-date'
                        onClick={() =>
                          toggleDate({
                            uid,
                            array: toggledCreationDates,
                            setArray: setToggledCreationDates,
                          })
                        }
                      >
                        {start_date
                          ? toggledCreationDates?.includes(uid)
                            ? moment(start_date, 'YYYY-MM-DD').format('LL')
                            : moment(
                                moment(
                                  start_date +
                                    moment(
                                      created_at,
                                      'YYYY-MM-DDTHH:mm:ssZ'
                                    ).format('HH:mm:ss'),
                                  TIMESTAMP_WITH_ZONE
                                ).format(TIMESTAMP_WITH_ZONE)
                              ).from(moment(currentTime))
                          : '-'}
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='no-data-div button'>There are no projects yet</div>
      )}
      <CustomModal
        isOpen={unArchiveModal}
        toggle={() => setUnArchiveModal(!unArchiveModal)}
        headerText='Unarchive Projects'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={unarchiveMultipleProjectsHandler}
      >
        <p>Are you sure you want to archive selected projects?</p>
      </CustomModal>{' '}
      <CustomModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        headerText='Delete Projects'
        cancelButtonText='Cancel'
        confirmButtonText='Confirm'
        confirmFunction={deleteMultipleProjectsHandler}
      >
        <p>Are you sure you want to delete selected projects?</p>
      </CustomModal>
    </>
  );
};

export default ArchiveProjectTable;
