import React, { useState } from 'react';
import { PopoverBody, Progress, UncontrolledPopover } from 'reactstrap';

const TimeStatComponent = ({ hours, progressBarValue, hoursAllowed, name }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <>
      {!progressBarValue && progressBarValue !== 0 ? (
        <>
          <div className='card-info-loader' />
          <div className='card-progress-bar-loader' />
        </>
      ) : (
        <>
          <div>
            {hours === 0 ? (
              ''
            ) : (
              <div className='status-card-time'>
                <UncontrolledPopover
                  trigger='legacy'
                  onMouseLeave={() => setPopoverOpen(false)}
                  placement='top'
                  isOpen={popoverOpen}
                  target={`employee-logged-popover-${name?.split(' ')[1]}`}
                  toggle={() => setPopoverOpen(!popoverOpen)}
                  fade={true}
                  rootClose
                >
                  <PopoverBody>
                    {hours} <span className='hours-text'>h</span> /{' '}
                    {hoursAllowed} <span className='hours-text'>h</span>
                  </PopoverBody>
                </UncontrolledPopover>
                <span
                  className='cursor-pointer'
                  id={`employee-logged-popover-${name?.split(' ')[1]}`}
                  onMouseEnter={() => setPopoverOpen(true)}
                  onMouseLeave={() => setPopoverOpen(false)}
                >
                  {`${
                    [100, 0].includes(parseInt(progressBarValue?.toFixed(0)))
                      ? progressBarValue?.toFixed(0)
                      : progressBarValue?.toFixed(2)
                  }% `}
                </span>
                <span>time logged </span>
              </div>
            )}
            {hours === 0 ? (
              <>
                <div className='no-hour-mb'>
                  <span className='h4 text-disabled no-hours-logged'>
                    No hours logged
                  </span>
                  <div className='progress-bar-container'>
                    <Progress className='status-progress-bar' value={0} />
                  </div>
                </div>
              </>
            ) : (
              <div className='progress-bar-container'>
                <Progress
                  className='status-progress-bar'
                  value={progressBarValue}
                  color={
                    progressBarValue < 30
                      ? 'danger'
                      : progressBarValue >= 30 && progressBarValue < 60
                      ? 'warning'
                      : 'success'
                  }
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TimeStatComponent;
