import { useDispatch, useSelector } from 'react-redux';
import { setPageNumber } from '../../../../redux/actions/pagination';
import { getPaginationCount } from 'utils/helper-functions';
import icon_last from '../../../../assets/svg/lastIcon.svg';
import icon_first from '../../../../assets/svg/firstIcon.svg';
import icon_prev from '../../../../assets/svg/prevIcon.svg';
import icon_next from '../../../../assets/svg/next.svg';
import { getChunk } from 'utils/helper-functions';

import { setCurrentPagesChunk } from 'redux/actions/userStatus';

const StatusPagination = ({ statusesLength, activePage, setActivePage }) => {
  const dispatch = useDispatch();
  const { pageNumber, currentPagesChunk } = useSelector((state) => state);
  const paginationCount = getPaginationCount(statusesLength)?.length;

  const firstPage = () => {
    dispatch(setPageNumber(1));
    setActivePage(1);
    dispatch(setCurrentPagesChunk(paginationCount >= 3 ? [1, 2, 3] : [1, 2]));
  };

  const prevPageNumber = () => {
    dispatch(setPageNumber(pageNumber - 1));
    setActivePage(pageNumber - 1);
    if ((pageNumber + 2) % 3 === 0) {
      dispatch(
        setCurrentPagesChunk(
          currentPagesChunk?.map((num) => {
            if (num - 3 > 0) {
              return num - 3;
            }
            return null;
          })
        )
      );
    }
  };

  const nextPage = () => {
    dispatch(setPageNumber(pageNumber + 1));
    setActivePage(pageNumber + 1);
    if (pageNumber % 3 === 0) {
      dispatch(
        setCurrentPagesChunk(
          currentPagesChunk?.map((num) => {
            return num + 3;
          })
        )
      );
    }
  };

  const lastPage = () => {
    dispatch(setPageNumber(paginationCount));
    setActivePage(paginationCount);
    dispatch(setCurrentPagesChunk(getChunk(paginationCount)));
  };

  return (
    <div className='pagination'>
      <div
        onClick={firstPage}
        className={`pagination__pageOne pagination__icon ${
          pageNumber === 1 ? 'disabled' : null
        } `}
      >
        <img src={icon_first} alt='first' />
      </div>
      <div
        className={`pagination__prevPage pagination__icon ${
          pageNumber === 1 ? 'disabled' : null
        }`}
        onClick={prevPageNumber}
      >
        <img src={icon_prev} alt='prev' />
      </div>
      <div className='pagination__numbers'>
        {currentPagesChunk?.map((number) => {
          return number <= paginationCount ? (
            <span
              key={number}
              onClick={() => {
                dispatch(setPageNumber(number));
                setActivePage(number);
              }}
              className={`${
                activePage === number
                  ? 'active pagination__numbers--number '
                  : 'pagination__numbers--number'
              }`}
            >
              {number}
            </span>
          ) : null;
        })}
      </div>
      <div
        className={`pagination__nextPage pagination__icon ${
          paginationCount === pageNumber ? 'disabled' : null
        }`}
        onClick={nextPage}
      >
        <img src={icon_next} alt='next' />
      </div>
      <div
        className={`pagination__pageLast pagination__icon ${
          paginationCount === pageNumber ? 'disabled' : null
        }`}
        onClick={lastPage}
      >
        <img src={icon_last} alt='last' />
      </div>
    </div>
  );
};
export default StatusPagination;
