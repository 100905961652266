import RedirectSpinner from 'Components/Common/RedirectSpinner/RedirectSpinner';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { redirectToLandingPage } from 'utils/helper-functions';

const Auth = () => {
  const history = useHistory();
  const mploUser = useSelector((state) => state.mploUser);

  useEffect(() => {
    const rolesArray = mploUser?.roles;
    if (
      rolesArray?.length > 0 &&
      localStorage.getItem('jwt-token') &&
      localStorage.getItem('currentUserRole')
    )
      history.push(
        `/${localStorage.getItem('workspace')}/${localStorage
          .getItem('currentUserRole')
          ?.toLowerCase()}/index`
      );
    else redirectToLandingPage();
  }, [history, mploUser?.roles]);

  return (
    <>
      <div className='redirect-header'>
        <center className='d-flex flex-column align-items-center '>
          <RedirectSpinner text='Redirect .... 404' />
        </center>
      </div>
    </>
  );
};

export default Auth;
