import React from 'react';
import Parser from 'html-react-parser';
import { extractValues } from 'utils/helper-functions';
import ExoprtIcon from 'assets/img/active-export.png';
import './ActivityCard.scss';
const ActivityCard = ({ log: { target, activity, operation } }) => {
  return (
    <div
      className={`activity-card ${localStorage
        .getItem('currentUserRole')
        ?.toLowerCase()}`}
    >
      {!['ARCHIVED', 'UNARCHIVED'].includes(operation) ? (
        <div className='activity-type'>
          <i
            className={`fas fa-${
              operation === 'ADDED'
                ? `${
                    target === 'LOG'
                      ? 'history'
                      : target === 'PROJECT'
                      ? 'plus'
                      : ['CLIENT', 'USER']?.includes(target)
                      ? 'user-plus'
                      : ''
                  }`
                : ''
            } fa-${
              ['PROJECT', 'CLIENT', 'USER', 'LOG']?.includes(target) &&
              operation === 'UPDATED'
                ? 'pencil-alt'
                : ['PROJECT', 'CLIENT', 'USER', 'LOG']?.includes(target) &&
                  operation === 'DELETED'
                ? 'trash-alt'
                : ''
            } fa-${
              operation === 'ASSOCIATED'
                ? 'user-plus'
                : operation === 'UNASSOCIATED'
                ? 'user-minus'
                : ''
            } fa-${target === 'INTEGRATION' ? 'plus-circle' : ''} fa-${
              target === 'INVITE' ? 'share' : ''
            } fa-${target === 'TRACKING' ? 'clock' : ''} fa-${
              target === 'ASSOCIATION'
                ? `user-${
                    operation === 'ADDED'
                      ? 'plus'
                      : operation === 'UPDATED'
                      ? 'edit'
                      : 'times'
                  }`
                : ''
            } fa-${target === 'ROLE' ? 'users' : ''}
            fa-${operation === 'CHECKED-IN' ? 'business-time' : ''}
            fa-${operation === 'CHECKED-OUT' ? 'stopwatch' : ''}
              fa-${operation === 'DELETED' ? 'trash' : ''}
              fa-${operation === 'UPDATED' ? 'user-clock' : ''}
              
              `}
            aria-hidden='true'
          />
        </div>
      ) : (
        ''
      )}
      {operation === 'ARCHIVED' ? (
        <img alt='...' className='archive-icon' src={ExoprtIcon} width='15px' />
      ) : operation === 'UNARCHIVED' ? (
        <img
          alt='...'
          className='unarchive-icon'
          src={ExoprtIcon}
          width='15px'
        />
      ) : (
        ''
      )}
      <p>
        {Parser(
          extractValues(
            activity,
            localStorage.getItem('currentUserRole')?.toLowerCase()
          )
        )}
      </p>
    </div>
  );
};

export default ActivityCard;
