import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import AdminNavbar from 'Components/Common/Navbars/Admin';
import Sidebar from 'Components/Common/Sidebar/Sidebar.js';
import routes from 'routes';
import Toast from 'Components/Common/Toast/Toast';
import sideBarLinks from 'sidebar-links';
import noUserImage from 'assets/img/theme/no-user-1.jpeg';

const ClientLayout = (props) => {
  const mainContent = React.useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('jwt-token')) {
      history.push('/auth/login');
    } else {
      localStorage?.setItem('clientImage', noUserImage);
      history.push('/client/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      if (prop.layout === '/client') {
        return (
          <Route
            path={`${prop.layout}/${prop.path}`}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return '';
  };

  return (
    <>
      <Toast />
      <Sidebar
        isClient
        {...props}
        routes={sideBarLinks?.filter(
          ({ layout, name, label }) =>
            [`/client`, '/auth']?.includes(layout) || name === 'Logout'
        )}
        logo={{
          innerLink: `/dashboard`,
          imgSrc: require('assets/svg/Logo.svg').default,
          imgAlt: '...',
        }}
        screenLayout='client'
      />
      <div className='main-content mb-5' ref={mainContent}>
        <AdminNavbar
          isClient
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from='*' to='' />
        </Switch>
      </div>
    </>
  );
};

export default ClientLayout;
