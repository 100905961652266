import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendSlackCode } from 'utils/api/api-requests/admin';
import RedirectSpinner from 'Components/Common/RedirectSpinner/RedirectSpinner';
const SlackRedirectPage = () => {
  const { search } = useLocation();
  const history = useHistory();
  useEffect(() => {
    const code = new URLSearchParams(search)?.get('code');
    if (code) {
      sendSlackCode(code)
        .then((res) => {
          history.push(`/datumbrain/admin/integration`);
          toast.success('Successfully connected to slack');
        })
        .catch(() => {
          toast.error('Failed to connect to slack');
          history.push(`/datumbrain/admin/integration`);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='redirect-header'>
      <center className='d-flex flex-column align-items-center '>
        <>
          <RedirectSpinner text='Redirect .... slack' />
        </>
      </center>
    </div>
  );
};

export default SlackRedirectPage;
