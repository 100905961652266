import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ProjectsDropdown from './ProjectsDropdown';

const CustomTextArea = ({ label, description, value, setValue }) => {
  const textareaRef = useRef();
  const projects = useSelector((state) => state?.projectsList);
  const [selectedProject, setSelectedProject] = useState(null);
  const [filteredProjects, setfFlteredProjects] = useState(projects);

  useEffect(() => {
    if (selectedProject) {
      setValue(value?.concat(selectedProject?.tag)?.concat(' '));
      setfFlteredProjects(
        projects?.filter(({ uid }) => uid !== selectedProject?.id)
      );
      setSelectedProject(null);
      textareaRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, selectedProject]);
  const onInputChange = (e) => {
    setValue(e?.target?.value);
    setfFlteredProjects(
      projects?.filter(({ tag }) => !e?.target?.value?.includes(`#${tag}`))
    );
  };

  return (
    <div className='custom-textarea'>
      <div className='custom-textarea-label'>{label}</div>
      <textarea
        ref={textareaRef}
        className='custom-textarea-box'
        value={value}
        onChange={onInputChange}
        placeholder='Make sure to start with #hashtag & project name'
      />

      {value?.endsWith('#') && (
        <ProjectsDropdown
          setSelectedProject={setSelectedProject}
          projects={filteredProjects}
        />
      )}
      <div className='custom-textarea-description'>{description}</div>
    </div>
  );
};

export default CustomTextArea;
