import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import editIcon from '../../../assets/svg/editIcon.svg';
import './styles.scss';

import moment from 'moment';
import { toast } from 'react-toastify';
import {
  getTrackingLogs,
  getUserDashboard,
  attendanceRequest,
  updateUserCheckin,
} from 'utils/api/api-requests/user';
import { formatLoggedTime } from 'utils/helper-functions';

import { setProjectsList } from 'redux/actions/projects';
import { setTrackingLogs } from 'redux/actions/logs';
import {
  setWelcomeStatus,
  setRemainingCasualLeaves,
  setRemainingMedicalLeaves,
} from 'redux/actions/employee';
// import { setEmployeeLeaveModal } from 'redux/actions/general';

import {
  getUserLeaveStats,
  updateTrackingLog,
} from 'utils/api/api-requests/user';

import { setCheckinTime, setCheckoutTime } from 'redux/actions/checkin';
import checkinTick from 'assets/svg/check-in-dark-tick.svg';
import { getUserAttendanceList } from '../../../utils/api/api-requests/user';
const User = ({
  dashboardLoading,
  setDashboardLoading,
  showStatusUpateForm,
  setShowStatusUpateForm,
  setDesiredFunction,
}) => {
  const {
    trackingLogs,
    leaveEditModal,
    leaveDeleteModal,
    employeeLeaveModal,
    checkinTime,
    checkoutTime,
    checkinFromFilter,
    checkinToFilter,
    mploUser,
    userStatus,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [totalHoursLoggedThisWeek, setTotalHoursLoggedThisWeek] = useState({
    hours: '',
    minutes: '',
  });
  const [totalHoursLoggedThisDay, setTotalHoursLoggedThisDay] = useState({
    hours: '',
    minutes: '',
  });
  const [workFromHome, setWorkFromHome] = useState(false);
  const [updateCheckin, setUpdateCheckin] = useState('');
  const [updateCheckinFlag, setUpdateCheckinFlag] = useState(false);
  const [checkinOnChangeFlag, setCheckinOnChangeFlag] = useState(false);
  const [checkinUid, setCheckinUid] = useState(false);
  const [isFirstCheckin, setIsFirstCheckin] = useState(false);
  useEffect(() => {
    let startDate = moment(checkinFromFilter)
      .format('YYYY-MM-DD')
      ?.concat('T00:00:00Z');
    let endDate = moment(checkinToFilter)
      .format('YYYY-MM-DDTHH:mm:ss')
      ?.concat('Z');
    getUserAttendanceList(startDate, endDate).then(({ data }) => {
      setIsFirstCheckin(data?.length > 0 ? false : true);
    });
  }, [checkinFromFilter, checkinToFilter]);
  useEffect(() => {
    getUserLeaveStats()
      .then(({ data }) => {
        let casualLeaves = data?.casual;
        let medicalLeaves = data?.medical;
        let remainigLeavesCasual = casualLeaves?.allowed - casualLeaves?.taken;
        let remainigLeavesMedical =
          medicalLeaves?.allowed - medicalLeaves?.taken;

        dispatch(setRemainingCasualLeaves(remainigLeavesCasual));
        dispatch(setRemainingMedicalLeaves(remainigLeavesMedical));
      })
      .catch(() => toast.error('Failed to ge user leave stats'));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveEditModal, leaveDeleteModal, employeeLeaveModal]);

  useEffect(() => {
    getTrackingLogs()
      .then(({ data }) => {
        dispatch(setTrackingLogs(data));
      })
      .catch((error) => {
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshData = () => {
    setDashboardLoading(true);
    let startDate = moment().format('YYYY-MM-DD')?.concat('T00:00:00Z');
    let endDate = moment().format('YYYY-MM-DDTHH:mm:ss')?.concat('Z');
    getUserDashboard(startDate, endDate)
      .then(
        ({
          data: {
            attendance: { check_in, check_out, working_remotely, uid },
            show_tips,
            total_hours_logged_current_day,
            total_hours_logged_current_week,
            user_project_details,
          },
        }) => {
          setDashboardLoading(false);
          setTotalHoursLoggedThisWeek(total_hours_logged_current_week);
          setTotalHoursLoggedThisDay(total_hours_logged_current_day);
          dispatch(setWelcomeStatus(show_tips));
          dispatch(setCheckinTime(check_in));
          dispatch(setCheckoutTime(check_out));
          setUpdateCheckin(moment(check_in).format('HH:mm'));
          setCheckinUid(uid);
          setWorkFromHome(check_in && !check_out ? working_remotely : false);
          dispatch(setProjectsList(user_project_details));
        }
      )
      .catch((error) => {
        setTotalHoursLoggedThisWeek({ hours: '', minutes: '' });
        setTotalHoursLoggedThisDay({ hours: '', minutes: '' });
        setDashboardLoading(false);
        dispatch(setProjectsList([]));
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast.error(error);
      });
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingLogs]);

  const attendanceHandler = () => {
    attendanceRequest(workFromHome)
      .then(() => {
        // if user is checking out stop timer for all projects
        if (checkinTime && !checkoutTime && trackingLogs?.length > 0) {
          trackingLogs?.forEach(({ project_uid, tracking_uid }) => {
            updateTrackingLog({
              projectId: project_uid,
              trackingId: tracking_uid,
              log: {
                time_end: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
              },
              finish: true,
            }).then(() => {
              getTrackingLogs().then(({ data }) => {
                dispatch(setTrackingLogs(data ?? []));
              });
              refreshData();
            });
          });
        } else refreshData();
        checkinTime && !checkoutTime
          ? toast.success(`Checked-out, Status update successfully`)
          : toast.success(`Checked-in, Welcome to work ${mploUser.name}!`);
      })
      .catch(() => toast.success('Failed to update work status'));
  };
  const updateCheckInHandler = () => {
    if (checkinOnChangeFlag) {
      updateUserCheckin(checkinUid, {
        check_in: moment(updateCheckin, 'HH:mm').format('YYYY-MM-DDTHH:mm:ssZ'),
      })
        .then(() => {
          setUpdateCheckinFlag(false);
          setCheckinOnChangeFlag(false);
          refreshData();
          toast.success('Successfully updated checkin');
        })
        .catch(() => {
          setUpdateCheckinFlag(false);
          setCheckinOnChangeFlag(false);
          refreshData();

          toast.error('Failed to update checkin');
        });
    } else setUpdateCheckinFlag(false);
  };

  const setUpdateCheckinHandler = (value) => {
    let momentValue = moment(value, 'hh:mm A').format('YYYY-MM-DDTHH:mm');
    if (checkoutTime && moment(checkoutTime).isBefore(momentValue)) {
      setUpdateCheckin(
        moment(checkoutTime).subtract(2, 'minutes').format('HH:mm')
      );
    } else setUpdateCheckin(value);
  };

  const checkUserCheckin = () => {
    if (checkinTime) {
      setDesiredFunction(attendanceHandler);
    } else if (!userStatus && !checkoutTime) {
      setShowStatusUpateForm({
        visible: true,
        buttonText: 'Complete Check-in',
      });
      setDesiredFunction(() => attendanceHandler);
    } else if (isFirstCheckin && !checkinTime) {
      setDesiredFunction(attendanceHandler);
    } else {
      attendanceHandler();
    }
  };

  return (
    <>
      <Row className='align-items-center'>
        <div className='col'>
          <h3 className='mb-0 dashboard__header--title title'>
            {showStatusUpateForm?.visible ? 'Status Update' : 'Projects'}
          </h3>
          {showStatusUpateForm?.visible && (
            <p className='dashboard__header--subtitle'>
              Play smart! Short, simple sentences with #projectname
            </p>
          )}
        </div>

        <div className='d-flex justify-content-between align-items-center dashboard__header--card'>
          {/* <div
            className='user-dashboard-card user-dashboard-button cursor-pointer'
            onClick={() => dispatch(setEmployeeLeaveModal(true))}
          >
            <div className='apply-leave'>
              <img src={applyLeave} alt='apply-leave-icon' />
              <span>Leave Application</span>
            </div>
          </div> */}
          <div className='user-dashboard-card'>
            <div className='check-in'>
              <div className='dashboard__header--btns d-flex align-center'>
                <div
                  className={`${
                    showStatusUpateForm?.visible ? 'active-' : ''
                  }check-in-btn user-dashboard-checkin-button `}
                  onClick={checkUserCheckin}
                >
                  <img src={checkinTick} alt='tick' />
                  <span>
                    Check-
                    {`${!checkinTime ? 'in' : !checkoutTime ? 'out' : 'in'}`}
                  </span>
                </div>
                <div
                  className={`check-in-work ${
                    checkinTime && !checkoutTime ? `disabled` : ''
                  }`}
                  onClick={() => setWorkFromHome(!workFromHome)}
                >
                  <span className='styled-checkbox wfh d-flex align-center'>
                    <input
                      id='WFH'
                      type='checkbox'
                      checked={workFromHome}
                      disabled={checkinTime && !checkoutTime ? true : false}
                    />
                    <span className='checkbox-text' for='WFH'>
                      WFH
                    </span>
                    <span
                      className={`checkmark ${
                        checkinTime && !checkoutTime && !workFromHome
                          ? 'unchecked-box'
                          : ''
                      }`}
                    ></span>
                  </span>
                </div>
              </div>
              <div className='check-in-value'>
                <div className='check-in-value-time'>
                  <i className='fas fa-circle text-success' />

                  {dashboardLoading ? (
                    <div className='check-in-value-time-loader' />
                  ) : (
                    <>
                      <div className='check-in-edit-container'>
                        {updateCheckinFlag ? (
                          <div className='time-input'>
                            <input
                              type='time'
                              name='time'
                              className='checkin-input'
                              value={updateCheckin}
                              onChange={({ target: { value } }) => {
                                setUpdateCheckinHandler(value);
                                setCheckinOnChangeFlag(true);
                              }}
                            />
                          </div>
                        ) : (
                          <span className={`${checkinTime ? 'checked' : ''}`}>
                            {checkinTime ? (
                              moment(checkinTime).format('hh:mm A')
                            ) : (
                              <span
                                className='dashboard__header--time'
                                style={{ padding: '3px' }}
                              >
                                --:-- am
                              </span>
                            )}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className='check-in-value-time'>
                  <i className='fas fa-circle text-danger' />
                  {dashboardLoading ? (
                    <div className='check-in-value-time-loader' />
                  ) : (
                    <div className='check-in-edit-container'>
                      <span className={`${checkoutTime ? 'checked' : ''}`}>
                        {checkoutTime ? (
                          moment(checkoutTime).format('hh:mm A')
                        ) : (
                          <span className='dashboard__header--time'>
                            --:-- pm
                          </span>
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className='checkin-edit-btn'>
                {!updateCheckinFlag ? (
                  <OverlayTrigger
                    placement='right'
                    overlay={<Tooltip>Update checkin</Tooltip>}
                  >
                    <img
                      src={editIcon}
                      alt='edit icon'
                      className='dashboard__header--editIcon'
                      onClick={() => setUpdateCheckinFlag(!updateCheckinFlag)}
                    />
                  </OverlayTrigger>
                ) : (
                  <i
                    id='update-checkin'
                    className={`fas ${
                      checkinOnChangeFlag
                        ? ' fa-check-circle text-success'
                        : ' fa-times-circle text-danger'
                    }`}
                    onClick={updateCheckInHandler}
                  />
                )}
              </div>
            </div>
          </div>

          <div className='user-dashboard-card'>
            <div className='user-dashboard-card-container'>
              <div className='clock-icon'>
                <i className='fas fa-history' />
              </div>
              <div className='hours-logged'>
                {dashboardLoading ? (
                  <div className='user-dashboard-card-loader' />
                ) : (
                  <h3
                    className={`${
                      !totalHoursLoggedThisWeek?.hours &&
                      !totalHoursLoggedThisWeek?.minutes
                        ? 'no-hours'
                        : ''
                    }`}
                  >
                    {formatLoggedTime(totalHoursLoggedThisWeek, 'short')}
                  </h3>
                )}

                <p>This week</p>
              </div>
            </div>
          </div>
          <div className='user-dashboard-card'>
            <div className='user-dashboard-card-container'>
              <div className='clock-icon'>
                <i className='fas fa-history' />
              </div>
              <div className='hours-logged'>
                {dashboardLoading ? (
                  <div className='user-dashboard-card-loader' />
                ) : (
                  <h3
                    className={`${
                      !totalHoursLoggedThisDay?.hours &&
                      !totalHoursLoggedThisDay?.minutes
                        ? 'no-hours'
                        : ''
                    }`}
                  >
                    {formatLoggedTime(totalHoursLoggedThisDay, 'short')}
                  </h3>
                )}
                <p>Today</p>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default User;
