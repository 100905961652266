import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import validator from 'validator';
import { validate } from 'email-validator';
import { validateCnic } from 'utils/helper-functions';

const FormGroupWithLabel = ({
  labelName,
  id,
  placeholder,
  type,
  setValue,
  value,
  disabled,
  icon,
  emailIndexArray,
  setEmailIndexArray,
  uid,
  setDeleteEmailUid,
  didSubmitButtonClick,
  invalidText,
  name,
  required,
  maxLength,
  selectBoxText,
  selectBoxArray,
  min,
  max,
}) => {
  const validateValue = () => {
    if (required) {
      return name?.includes('email')
        ? !validate(value)
        : name?.includes('contact')
        ? !validator.isMobilePhone(value)
        : name?.includes('cnic')
        ? !validateCnic(value)
        : !value;
    } else {
      return value
        ? name?.includes('email')
          ? !validate(value)
          : name?.includes('contact')
          ? !validator.isMobilePhone(value)
          : name?.includes('cnic')
          ? !validateCnic(value)
          : !value
        : false;
    }
  };
  return (
    <>
      <FormGroup
        className={`mb-1 ${
          didSubmitButtonClick && validateValue() ? 'invalid-form' : ''
        }`}
      >
        <div className='label-for-input'>
          <label className='form-control-label' htmlFor={id}>
            {labelName}
            {required ? <span> *</span> : ''}
          </label>
          {icon ? (
            <div>
              <i
                className='fa fa-trash'
                onClick={() => setDeleteEmailUid(uid)}
              />
              <i
                className={`fas fa-pencil-alt ${
                  emailIndexArray?.includes(uid) ? 'disabled' : ''
                }`}
                onClick={() => setEmailIndexArray([...emailIndexArray, uid])}
                disabled
              />
            </div>
          ) : (
            ''
          )}
        </div>

        <div className='input-with-label'>
          <Input
            id={id}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            disabled={icon ? !emailIndexArray?.includes(uid) : disabled}
            maxLength={maxLength}
            className={
              type !== 'select'
                ? 'input-not-select'
                : ` ${value && !disabled ? 'main-text-color' : ''}`
            }
            min={min ?? ''}
            max={max ?? ''}
          >
            {type === 'select' ? (
              <>
                {selectBoxText ? (
                  <option value={0} selected={!value} disabled={value}>
                    {selectBoxText}
                  </option>
                ) : (
                  ''
                )}
                {selectBoxArray?.map(({ uid, name }) => (
                  <option value={uid} key={uid} selected={uid === value}>
                    {name}
                  </option>
                ))}
              </>
            ) : (
              <></>
            )}
          </Input>
          <div
            className={`custom-invalid-feedback-text
           ${didSubmitButtonClick && validateValue() ? '' : ' hidden-class'}
          `}
          >
            <i className='fas fa-exclamation-circle' />{' '}
            <span>{invalidText}</span>
          </div>
        </div>
      </FormGroup>
    </>
  );
};

export default FormGroupWithLabel;
