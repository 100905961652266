const sideBarLinks = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'home',
    layout: `/client`,
  },
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'home',
    layout: `/admin`,
  },
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'home',
    layout: `/user`,
  },
  {
    path: '/manage',
    name: 'Manage',
    icon: 'manage',
    layout: `/user`,
  },
  {
    path: '/activity',
    name: 'Activity',
    icon: 'activity',
    layout: `/user`,
  },

  {
    collapse: true,
    name: 'Workspace',
    icon: 'settings',
    state: 'manageCollapse',
    views: [
      {
        path: '/users',
        name: 'Employees',
        layout: `/admin`,
        miniName: 'E',
      },
      {
        path: '/projects',
        name: 'Projects',
        layout: `/admin`,
        miniName: 'P',
      },
      {
        path: '/clients',
        name: 'Clients',
        layout: `/admin`,
        miniName: 'C',
      },
      {
        path: '/activity',
        name: 'Activity',
        layout: `/admin`,
        miniName: 'A',
      },
      {
        path: '/checkins',
        name: 'Checkin',
        layout: `/admin`,
        miniName: 'C',
      },
      // {
      //   path: '/leaves',
      //   name: 'Leaves',
      //   layout: `/admin`,
      //   miniName: 'C',
      // },
    ],
  },

  {
    collapse: true,

    name: 'Settings',
    label: 'Owner Settings',
    icon: 'manage',
    state: 'manageCollapse2',
    views: [
      {
        path: '/profile',
        miniName: 'P',
        name: 'Profile',
        layout: `/admin`,
      },
      {
        path: '/connect',
        miniName: 'I',
        name: 'Connect',
        layout: `/admin`,
      },
      {
        path: '/privacy',
        name: 'Privacy',
        layout: `/admin`,
        miniName: 'S',
      },
    ],
  },
  {
    collapse: true,
    name: 'Finances',
    label: 'Owner Finances',
    icon: 'invoice',
    state: 'manageCollapse4',
    views: [
      {
        path: '/invoices',
        name: 'Invoices',
        layout: `/admin`,
        miniName: 'N',
      },
    ],
  },

  {
    collapse: true,
    name: 'Settings',
    label: 'Admin Settings',
    icon: 'manage',
    state: 'manageCollapse2',
    views: [
      {
        path: '/profile',
        miniName: 'P',
        name: 'Profile',
        layout: `/admin`,
      },
      {
        path: '/privacy',
        name: 'Privacy',
        layout: `/admin`,
        miniName: 'S',
      },
    ],
  },
  {
    collapse: true,
    name: 'Settings',
    label: 'User Settings',
    icon: 'settings',
    state: 'manageCollapse3',
    views: [
      {
        path: '/profile',
        name: 'Profile',
        layout: `/user`,
        miniName: 'P',
      },
      {
        path: '/privacy',
        name: 'Privacy',
        layout: `/user`,
        miniName: 'S',
      },
      {
        path: '/checkins',
        name: 'Checkin',
        layout: `/user`,
        miniName: 'C',
      },
    ],
  },

  // {
  //   collapse: true,
  //   name: 'Leaves',
  //   label: 'Leaves Management',
  //   icon: 'leaves',
  //   state: 'manageCollapse4',
  //   views: [
  //     {
  //       path: '/leaves/index',
  //       name: 'My Leaves',
  //       layout: `/user`,
  //       miniName: 'L',
  //     },
  //     {
  //       path: '/leaves/manage',
  //       name: 'Team Leaves',
  //       layout: `/user`,
  //       miniName: 'L',
  //     },
  //   ],
  // },
  // {
  {
    path: '/login',
    name: 'Logout',
    icon: 'logout',
    layout: '/auth',
  },
];

export default sideBarLinks;
