import React, { useEffect, useState } from 'react';
import {
  getInvoices,
  billChecked,
  getArchivedInvoices,
} from 'utils/api/api-requests/admin';
import { Card, CardHeader, Col, Container, Row } from 'reactstrap';
import AdminHeader from 'Components/Headers/Admin/Admin';
import { toast } from 'react-toastify';
import DeleteModal from 'Components/Admin/Project/Modals/DeleteModal';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import ArchiveInvoicesTable from './Table/ArchiveTable';
import { useHistory } from 'react-router-dom';
import ExoprtIcon from 'assets/img/active-export.png';
import InvoicesTable from './Table/Table';
import './invoices.css';
import { DEFAULT_PAGE_SIZE } from 'constants/index';
import { isOwner } from '../../../utils/api/api-requests/owner';
import Auth from 'NotFound';

const InvoicesContainer = () => {
  const history = useHistory();
  const [areInvoicesBeingLoaded, setAreInvoicesBeingLoaded] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [archivedInvoices, setArchivedInvoices] = useState([]);
  const [isArchiveInvoicesModal, setIsArchiveInvoicesModal] = useState(false);
  const [unArchiveModal, setUnArchiveModal] = useState(false);
  const [selectedArchivedInvoices, setSelectedArchivedInvoices] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const [totalInvoices, setTotalInvoices] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const loadInvoices = () => {
    setAreInvoicesBeingLoaded(true);
    // Load regular invoices
    const resetPageNumber = 1;
    getInvoices(DEFAULT_PAGE_SIZE, resetPageNumber)
      .then(({ data }) => {
        // console.log(data);
        setInvoices(data.invoices);
        setTotalInvoices(data.invoices_count);
        setPageNumber(resetPageNumber);
        setAreInvoicesBeingLoaded(false);
      })
      .catch((error) => {
        setAreInvoicesBeingLoaded(false);
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast?.error('Unable to fetch invoice list.');
      });

    // Load archived invoices
    getArchivedInvoices()
      .then(({ data }) => {
        setArchivedInvoices(data);
        setAreInvoicesBeingLoaded(false);
      })
      .catch((error) => {
        setAreInvoicesBeingLoaded(false);
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast?.error('Unable to fetch invoice list.');
      });
  };
  const loadMoreInvoices = () => {
    setAreInvoicesBeingLoaded(true);
    const incrementedPageNumber = pageNumber + 1;
    getInvoices(DEFAULT_PAGE_SIZE, incrementedPageNumber)
      .then(({ data }) => {
        setInvoices((prevInvoices) => [...prevInvoices, ...data.invoices]);
        setTotalInvoices(data.invoices_count);
        setPageNumber(incrementedPageNumber);
        setAreInvoicesBeingLoaded(false);
      })
      .catch((error) => {
        setAreInvoicesBeingLoaded(false);
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast?.error('Unable to fetch invoice list.');
      });

    // Load archived invoices
    getArchivedInvoices()
      .then(({ data }) => {
        setArchivedInvoices(data);
        setAreInvoicesBeingLoaded(false);
      })
      .catch((error) => {
        setAreInvoicesBeingLoaded(false);
        if (error === 'token expired, please login again') {
          toast.error(error);
          localStorage.clear();
          history.push('/auth/login');
        } else toast?.error('Unable to fetch invoice list.');
      });
  };

  useEffect(() => {
    loadInvoices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isOwner) {
    return (
      <>
        <div className='main-content'>
          <AdminHeader />

          <Container className='mt--7' fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5' xl='12'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col d-flex align-items-center'>
                        <div className='workspace-heading'>
                          <h3 className='mb-0 text-dark'>Invoices</h3>
                        </div>
                        <div
                          className={`custom-action-button ${
                            selectedInvoices?.length <= 0 ? 'disabled' : ''
                          }`}
                          onClick={() => setArchiveModal(true)}
                        >
                          <img
                            className='export-icon'
                            alt='...'
                            src={ExoprtIcon}
                          />
                          <div>Archive</div>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <InvoicesTable
                    invoices={invoices}
                    areInvoicesBeingLoaded={areInvoicesBeingLoaded}
                    setSelectedInvoices={setSelectedInvoices}
                    selectedInvoices={selectedInvoices}
                    archiveModal={archiveModal}
                    setArchiveModal={setArchiveModal}
                    billChecked={billChecked}
                    setInvoices={setInvoices}
                    loadInvoices={loadInvoices}
                  />

                  <div className='d-flex justify-content-center mb-1'>
                    <button
                      className={`view-archived-button admin-theme-button ${
                        archivedInvoices?.length === 0 ? 'disabled' : ''
                      }`}
                      onClick={() => setIsArchiveInvoicesModal(true)}
                    >
                      View archived Invoices
                    </button>
                  </div>
                  {invoices.length < totalInvoices && invoices.length !== 0 && (
                    <div className='load-more-btn-container mb-3'>
                      <span
                        className={`load-more-btn`}
                        onClick={loadMoreInvoices}
                      >
                        Load more...
                      </span>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
            <DeleteModal />
          </Container>
        </div>
        <CustomModal
          isOpen={isArchiveInvoicesModal}
          toggle={() => {
            setIsArchiveInvoicesModal(!isArchiveInvoicesModal);
            setSelectedArchivedInvoices([]);
          }}
          headerText='Archived Invoices'
          noButtons={true}
          customSize='lg'
          customClass='archived-user-modal'
        >
          <ArchiveInvoicesTable
            archivedInvoices={archivedInvoices}
            areInvoicesBeingLoaded={areInvoicesBeingLoaded}
            selectedArchivedInvoices={selectedArchivedInvoices}
            setSelectedArchivedInvoices={setSelectedArchivedInvoices}
            setUnArchiveModal={setUnArchiveModal}
            unArchiveModal={unArchiveModal}
            setDeleteModal={setDeleteModal}
            deleteModal={deleteModal}
            loadInvoices={loadInvoices}
          />
          <div className='d-flex modal-action-button'>
            <div
              className={`custom-action-button ${
                selectedArchivedInvoices?.length <= 0 ? 'disabled' : ''
              }`}
              onClick={() => setUnArchiveModal(true)}
            >
              <img className='export-icon' alt='...' src={ExoprtIcon} />
              <div>Unarchive</div>
            </div>
            <div
              className={`custom-action-button align-items-baseline ${
                selectedArchivedInvoices?.length <= 0 ? 'disabled' : ''
              }`}
              onClick={() => setDeleteModal(true)}
            >
              <i className='fa fa-trash-alt delete-icon' />
              <div>Delete</div>
            </div>
          </div>
        </CustomModal>
      </>
    );
  } else {
    <Auth />;
  }
};

export default InvoicesContainer;
