import React, { useState, useEffect } from 'react';
import {
  getProjects,
  getClients,
  getUsers,
  addProject,
} from 'utils/api/api-requests/admin';
import CustomFormGroup from 'Components/Common/CustomFormGroup/CustomFormGroup';
import { Form, Label, Tooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from 'Components/Common/CustomModal/CustomModal';
import moment from 'moment';
import { setProjectAddModal } from 'redux/actions/modals';
import { setProjectsList, setSelectedProjectId } from 'redux/actions/projects';
import { setValidatedDateRange } from 'utils/helper-functions';

const AddModal = () => {
  const { projectAddModal } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [allClients, setAllClients] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [clientId, setClientId] = useState('');
  const [managerId, setManagerId] = useState('');
  const [userList, setUserList] = useState([]);
  const [projectStartDate, setProjectStartDate] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [maxHoursAllocated, setMaxHOursAllocated] = useState(null);

  const [projectEndDate, setProjectEndDate] = useState(
    moment().add(7, 'days').format('YYYY-MM-DD')
  );
  const [endDateFlag, setEndDateFlag] = useState(false);
  const [clientSpecificFlag, setClientSpecificFlag] = useState(false);
  const [didAddButtonClick, setDidAddButtonClick] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [retainerAmount, setRetainerAmount] = useState('');
  const [clients, setClients] = useState([]);

  useEffect(() => {
    getClients().then(({ data }) => setAllClients(data));
    getUsers().then(({ data }) => setUserList(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClients(allClients.filter(({ deleted_at }) => deleted_at === null));
  }, [allClients]);

  const setEndDateFlagHandler = (value) => {
    if (value)
      setProjectEndDate(
        moment(projectStartDate, 'YYYY-MM-DD')
          .add(7, 'days')
          .format('YYYY-MM-DD')
      );
    setEndDateFlag(value);
  };

  const toggleAdd = (e) => {
    e?.preventDefault();
    dispatch(setProjectAddModal(!projectAddModal));
    dispatch(setSelectedProjectId(null));
    setProjectName('');
    setClientId('');
    setManagerId('');
    setProjectStartDate(moment().format('YYYY-MM-DD'));
    setProjectEndDate(moment().add(7, 'days').format('YYYY-MM-DD'));
    setDidAddButtonClick(false);
    setEndDateFlag(false);
    setClientSpecificFlag(false);
  };
  const handleAPIRequest = (e) => {
    setDidAddButtonClick(true);
    e.preventDefault();
    if (projectName && managerId && (clientSpecificFlag ? clientId : true)) {
      const projectObject = {
        name: projectName,
        client_uid: clientSpecificFlag ? clientId : null,
        manager_uid: managerId,
        start_date: moment(projectStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        end_date: endDateFlag
          ? moment(projectEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null,
        max_hours_allocated: maxHoursAllocated,
        retainer_amount: retainerAmount || null,
      };
      setDidAddButtonClick(true);
      addProject(projectObject)
        .then(() => {
          toast.success('Project added successfully.');
          getProjects().then(({ data }) => {
            dispatch(setProjectsList(data));
          });
        })
        .catch(() => toast.error('Unable to add project.'));
      toggleAdd();
      dispatch(setProjectAddModal(false));
    }
  };

  return (
    <>
      <CustomModal
        isOpen={projectAddModal}
        toggle={toggleAdd}
        headerText='Add new project'
        cancelButtonText='Cancel'
        confirmButtonText='Add'
        confirmFunction={handleAPIRequest}
      >
        <Form
          onKeyPress={(e) => (e?.key === 'Enter' ? handleAPIRequest(e) : {})}
        >
          <Label className='custom-styled-label'>Details</Label>
          <CustomFormGroup
            type='select'
            name='selectManager'
            id='selectManger'
            value={managerId}
            setValue={setManagerId}
            iconClassName='far fa-address-card'
            invalidText='No Manager is selected'
            selectBoxText='Select manager'
            selectBoxArray={userList}
            didSubmitButtonClick={didAddButtonClick}
            layout='admin'
          />
          <CustomFormGroup
            type='text'
            name='projectName'
            id='name'
            placeholder='Project name'
            value={projectName}
            setValue={setProjectName}
            iconClassName='fas fa-project-diagram'
            invalidText='Project name is empty'
            didSubmitButtonClick={didAddButtonClick}
            layout='admin'
          />
          {clientSpecificFlag ? (
            <CustomFormGroup
              type='select'
              name='selectClient'
              id='selectClient'
              value={clientId}
              setValue={setClientId}
              iconClassName='fas fa-users'
              invalidText='No Client is selected'
              selectBoxText='Select client'
              selectBoxArray={clients}
              didSubmitButtonClick={didAddButtonClick}
              layout='admin'
            />
          ) : (
            ''
          )}
          <div className='d-flex flex-row justify-content-end align-item-center mb-2'>
            <label className='mr-3 mb-0 custom-styled-label'>
              Client specific project
            </label>
            <label className='mb-0 custom-toggle custom-parent-toggler'>
              <input
                type='checkbox'
                onClick={() => {
                  setClientSpecificFlag(!clientSpecificFlag);
                  setDidAddButtonClick(false);
                }}
              />
              <span className='custom-toggle-slider rounded-circle custom-child-toggler' />
            </label>
          </div>{' '}
          <Label className='custom-styled-label'>{`${
            endDateFlag ? 'Duration' : 'Commenced date'
          }`}</Label>
          <CustomFormGroup
            type='date'
            name='startDate'
            id='startDate'
            placeholder='Project name'
            value={projectStartDate}
            setValue={(value) => {
              setProjectStartDate(value);
              setValidatedDateRange({
                date1: value,
                date2: projectEndDate,
                setDate2: setProjectEndDate,
              });
            }}
            iconClassName='fas fa-calendar-day'
            invalidText='Project start date is empty'
            didSubmitButtonClick={didAddButtonClick}
            layout='admin'
          />
          {endDateFlag ? (
            <CustomFormGroup
              type='date'
              name='endDate'
              id='endDate'
              placeholder='Project name'
              value={projectEndDate}
              setValue={setProjectEndDate}
              iconClassName='fas fa-calendar-week'
              invalidText='Project end date is empty'
              didSubmitButtonClick={didAddButtonClick}
              min={projectStartDate}
              layout='admin'
            />
          ) : (
            ''
          )}
          <div className='d-flex flex-row justify-content-end align-item-center mb-2'>
            <label className='mr-3 mb-0 custom-styled-label'>
              Close ended project
            </label>
            <label className='mb-0 custom-toggle custom-parent-toggler'>
              <input
                type='checkbox'
                onClick={() => setEndDateFlagHandler(!endDateFlag)}
              />
              <span className='custom-toggle-slider rounded-circle custom-child-toggler' />
            </label>
          </div>
          <Label className='custom-styled-label'>
            Employee Working Hours{' '}
            <i
              id='hours-tooltip'
              class='fa fa-info-circle'
              aria-hidden='true'
            />
            <Tooltip
              placement='top'
              isOpen={tooltipOpen}
              target='hours-tooltip'
              toggle={() => setTooltipOpen((prevState) => !prevState)}
            >
              Set default logged monthly hours for project team
            </Tooltip>
          </Label>
          <CustomFormGroup
            required={false}
            min={0}
            type='number'
            name='maxHoursAllocated'
            id='name'
            placeholder='Defaults to 160'
            value={maxHoursAllocated}
            setValue={(value) => {
              if (value?.length <= 3) {
                setMaxHOursAllocated(parseInt(value));
              }
            }}
            iconClassName='fas fa-clock'
            didSubmitButtonClick={didAddButtonClick}
            layout='admin'
            appendedInfo={{ type: 'text', value: 'hrs' }}
          />
          <Label className='custom-styled-label' style={{ marginTop: '1rem' }}>
            Retainer Amount
          </Label>
          <CustomFormGroup
            required={false}
            min={0}
            type='number'
            name='retainerAmount'
            id='name'
            value={retainerAmount}
            setValue={(value) => {
              setRetainerAmount(parseInt(value));
            }}
            iconClassName='fas fa-dollar-sign'
            didSubmitButtonClick={didAddButtonClick}
            layout='admin'
          />
        </Form>
      </CustomModal>
    </>
  );
};

export default AddModal;
