import React, { useEffect } from 'react';
import Background from 'assets/img/brand/signup-bg.jpg';
import Logo from 'assets/svg/Logo.svg';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import routes from 'routes';
import { setMploUser } from 'redux/actions/general';
import { whoamiApiRequest } from 'utils/api/api-requests/admin';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const AuthLayout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem('jwt-token')) {
      whoamiApiRequest('datumbrain')
        .then(({ data: { name, roles, uid, email, image_url } }) => {
          const mploUser = {
            name,
            roles,
            uid,
            email,
            profileImage: image_url,
          };
          dispatch(setMploUser(mploUser));
          if (roles?.includes('ADMIN')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ) {
              localStorage.setItem('currentUserRole', 'admin');
              history.push(`/datumbrain/admin/index`);
            } else if (roles?.includes('EMPLOYEE')) {
              localStorage.setItem('currentUserRole', 'user');
              history.push(`/datumbrain/user/index`);
            }
          } else if (roles?.includes('EMPLOYEE')) {
            if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'user'
            ) {
              localStorage.setItem('currentUserRole', 'user');
              history.push(`/datumbrain/user/index`);
            } else if (
              localStorage.getItem('currentUserRole')?.toLowerCase() === 'admin'
            ) {
              localStorage.setItem('currentUserRole', 'admin');
              history.push(`/datumbrain/admin/index`);
            }
          }
        })
        .catch(() => {
          localStorage.clear();
          toast.error('Failed to get logged in user data Auth');
        });
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      if (prop.layout === `/auth`) {
        return (
          <Route
            path={`${prop.layout}/${prop.path}`}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <div className='landing-page-container'>
      <div className='signup-page'>
        <div className='signup-form'>
          <div className='logo-container'>
            <img src={Logo} alt='logo' width='150' />
          </div>
          <div>
            <Switch>
              {getRoutes(routes)}
              <Redirect from='*' to='' />
            </Switch>
          </div>
        </div>
        <div className='background-image'>
          <img src={Background} alt='background' width='130%' />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
