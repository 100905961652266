import React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import './styles.scss';
const ConfirmationPopover = ({
  isPopoverOpen,
  setIsPopoverOpen,
  icon,
  mainText,
  description,
  confirmHandler,
  cancelHandler,
}) => {
  return (
    <Popover
      className='confirmation-popover'
      placement='right'
      isOpen={isPopoverOpen}
      target='reset-all-popover'
      toggle={() => setIsPopoverOpen((prevState) => !prevState)}
    >
      <PopoverHeader>
        <img src={icon} alt='alert' /> <span>{mainText}</span>
      </PopoverHeader>
      <PopoverBody>
        <div className='description'>{description}</div>
        <div className='popover-buttons'>
          <button onClick={cancelHandler}>Cancel</button>
          <button onClick={confirmHandler}>Yes, I am sure.</button>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default ConfirmationPopover;
