import React, { useState } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { timePickerFormat } from '../../../constants';
import { updateOfficeTime } from '../../../utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import moment from 'moment';

const TimeInput = ({ officeTime, setOfficeTime }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [time, setTime] = useState(officeTime);

  const openTimePicker = () => setIsPickerOpen(true);
  const closeTimePicker = () => setIsPickerOpen(false);
  const onSave = () => {
    closeTimePicker();
    if (!moment(time).isSame(moment(officeTime))) {
      updateOfficeTime(moment(time).format('HH:mm'))
        .then(() => {
          setOfficeTime(time);
          toast.success('Office time updated.');
        })
        .catch(() => toast.error('Unable to update office time.'));
    }
  };
  return (
    <div className='office-time-picker form-control'>
      <TimePicker
        onClick={() => setIsPickerOpen(true)}
        onClose={onSave}
        open={isPickerOpen}
        showSecond={false}
        defaultValue={time}
        value={time}
        onChange={setTime}
        format={timePickerFormat}
        use12Hours
        inputReadOnly
        allowEmpty={false}
      />
      <i
        id='edit-office-time-icon'
        className='fas fa-pencil-alt fa-lg edit-btn client-edit-btn user-theme-color'
        onClick={openTimePicker}
      />
    </div>
  );
};

export default TimeInput;
