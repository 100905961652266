import React, { useState } from 'react';
import { PopoverBody, Table, UncontrolledPopover } from 'reactstrap';
import Parser from 'html-react-parser';
import { formatLoggedTime, getZonedTime } from 'utils/helper-functions';

import { updateStateArray } from 'utils/helper-functions';
import moment from 'moment';
import { parseMarkdown } from 'utils/helper-functions';
const LogsTable = ({ projectLogs, selectedLogs, setSelectedLogs }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [markDownTooglerFlag, setMarkDownTooglerFlag] = useState(false);
  const mouseEnterMarkDownHandler = (uid) => {
    if (!markDownTooglerFlag) setPopoverOpen(uid);
  };
  return (
    <>
      <Table
        className={`align-items-center table-flush client-logs-table`}
        responsive
      >
        <thead className='thead-light'>
          <tr className='project-log-table-row'>
            <th className='pl-pt-0'>
              <div className='custom-control custom-checkbox user'>
                <input
                  className='custom-control-input'
                  id='customCheck'
                  type='checkbox'
                  disabled={projectLogs?.length === 0}
                  checked={
                    projectLogs?.length > 0 &&
                    projectLogs?.length === selectedLogs?.length
                  }
                  onClick={(e) => {
                    setSelectedLogs(
                      e?.target?.checked
                        ? projectLogs?.map(({ uid }) => uid)
                        : []
                    );
                  }}
                />
                <label className='custom-control-label' htmlFor='customCheck' />
              </div>
            </th>
            {['admin', 'client']?.includes(
              localStorage.getItem('currentUserRole')?.toLowerCase()
            ) ? (
              <th>Logged By</th>
            ) : (
              ''
            )}
            <th>Date</th>
            <th>Day</th>
            <th>Duration</th>
            <th>Time Logged</th>
            <th>Details</th>
            <th>Off Hours</th>
          </tr>
        </thead>
        <tbody>
          {projectLogs?.map(
            ({
              uid,
              loggedBy,
              date,
              timeStart,
              timeEnd,
              hours,
              worked,
              lateSitting,
              archived,
              tracking,
            }) => (
              <tr
                className={`project-log-table-row ${
                  archived ? 'pink-row' : ''
                }`}
                key={uid}
              >
                <td className='pl-pt-0'>
                  <div className='custom-control custom-checkbox user'>
                    <input
                      className='custom-control-input'
                      id={uid}
                      type='checkbox'
                      checked={selectedLogs?.includes(uid)}
                      onClick={() =>
                        updateStateArray({
                          array: selectedLogs,
                          setArray: setSelectedLogs,
                          value: uid,
                        })
                      }
                    />
                    <label className='custom-control-label' htmlFor={uid} />
                  </div>
                </td>
                {['admin', 'client']?.includes(
                  localStorage.getItem('currentUserRole')?.toLowerCase()
                ) ? (
                  <td>{loggedBy}</td>
                ) : (
                  ''
                )}
                <td>{date}</td>
                <td>{moment(date, 'YYYY-MM-DD').format('dddd')}</td>
                <td>
                  {moment(timeStart, 'hh:mm A').format('hh:mm A')}
                  {` - `}
                  {tracking === false ? (
                    moment(timeEnd, 'hh:mm A').format('hh:mm A')
                  ) : (
                    <span className='in-progress'>in progress</span>
                  )}
                </td>
                <td>{`${
                  isNaN(hours)
                    ? '-'
                    : formatLoggedTime({
                        hours: getZonedTime({
                          type: 'hours',
                          value: hours,
                        }),
                        minutes: getZonedTime({
                          type: 'minutes',
                          value: hours,
                        }),
                      })
                }`}</td>
                <td>
                  <div>
                    <span
                      className='td-details'
                      onMouseEnter={() => mouseEnterMarkDownHandler(uid)}
                      id={`details-${uid}`}
                    >
                      {worked?.replace(/[^a-zA-Z ]/g, ' ')}
                    </span>
                    <UncontrolledPopover
                      trigger={markDownTooglerFlag === uid ? 'focus' : 'legacy'}
                      onMouseLeave={() => setPopoverOpen(false)}
                      placement='left'
                      isOpen={popoverOpen === uid}
                      toggle={() =>
                        setPopoverOpen(
                          markDownTooglerFlag ? true : !popoverOpen
                        )
                      }
                      target={`details-${uid}`}
                    >
                      <PopoverBody className='p-3'>
                        <div>
                          <div className='d-flex flex-row justify-content-end'>
                            <label className='mr-2 mb-0 custom-styled-label'>
                              Hold
                            </label>
                            <div>
                              <label className='mb-0 custom-toggle markdown-toogle custom-parent-toggler small'>
                                <input
                                  type='checkbox'
                                  onClick={() =>
                                    setMarkDownTooglerFlag(
                                      !markDownTooglerFlag ? uid : false
                                    )
                                  }
                                  checked={markDownTooglerFlag === uid}
                                />
                                <span
                                  className={`custom-toggle-slider markdown-toogle-slider rounded-circle custom-child-toggler ${
                                    markDownTooglerFlag === uid
                                      ? 'right'
                                      : 'left'
                                  }`}
                                />
                              </label>
                            </div>
                          </div>
                          <div>{Parser(parseMarkdown(worked))}</div>
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </td>
                <td>{lateSitting}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </>
  );
};

export default LogsTable;
