export const setProjectLogsList = (projectLogsList) => ({
  type: 'SET_PROJECT_LOGS_LIST',
  payload: projectLogsList,
});

export const setLogsViewMode = (viewMode) => ({
  type: 'SET_LOGS_VIEW_MODE',
  payload: viewMode,
});
export const setAreLogsBeingLoaded = (logs) => ({
  type: 'SET_ARE_LOGS_BEING_LOADED',
  payload: logs,
});

export const setLogAddModal = (addModal) => ({
  type: 'SET_LOG_ADD_MODAL',
  payload: addModal,
});

export const setLogEditModal = (editModal) => ({
  type: 'SET_LOG_EDIT_MODAL',
  payload: editModal,
});

export const setLogDeleteModal = (deleteModal) => ({
  type: 'SET_LOG_DELETE_MODAL',
  payload: deleteModal,
});

export const setLogID = (logID) => ({
  type: 'SET_LOG_ID',
  payload: logID,
});

export const setLogDate = (logDate) => ({
  type: 'SET_LOG_DATE',
  payload: logDate,
});
export const setLoggingProjects = (projects) => ({
  type: 'SET_LOGGING_PROJECTS',
  payload: projects,
});
export const setTrackingLogs = (logs) => ({
  type: 'SET_TRACKING_LOGS',
  payload: logs ?? [],
});
export const setLogsPageSize = (size) => ({
  type: 'SET_LOGS_PAGE_SIZE',
  payload: size,
});
export const setActiveView = (view) => ({
  type: 'SET_ACTIVE_VIEW',
  payload: view,
});
export const setIsEditingTrackingLog = (log) => ({
  type: 'SET_IS_EDITING_TRACKING_LOG',
  payload: log,
});
export const setUserIdForLogs = (uid) => ({
  type: 'SET_USER_ID_FOR_LOGS',
  payload: uid,
});
