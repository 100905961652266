import { API_URL } from '../api-constants';
export const PROJECT_URL = `${API_URL}/client/project`;
export const GET_PROJECT_INVOICE = (projectID) =>
  `${PROJECT_URL}/${projectID}/invoice`;

export const GET_TEAM_BY_CLIENT = (projectID) =>
  `${PROJECT_URL}/${projectID}/team`;
export const GET_LOGS_BY_CLIENT = (projectID) =>
  `${PROJECT_URL}/${projectID}/log`;
export const GET_USER_LOGS_BY_CLIENT = ({ projectId, userId }) =>
  `${PROJECT_URL}/${projectId}/user/${userId}/log`;
export const DOWNLOAD_INVOICE_CLIENT_URL = ({ projectId, from, to }) =>
  `${API_URL}/client/project/${projectId}/invoice/download?from=${from}&to=${to}`;
