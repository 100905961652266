import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isAdminLoggedIn, isUserLoggedIn } from 'utils/helper-functions';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';
import { setProjectLogsList } from 'redux/actions/logs';
import noUserImage from 'assets/img/theme/no-user-1.jpeg';

const AdminNavbar = ({ isClient, brandText }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mploUser = useSelector((state) => state.mploUser);

  return (
    <>
      <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
        <Container fluid style={{ justifyContent: 'flex-end' }}>
          <Link
            className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block'
            to='/'
          >
            {brandText}
          </Link>
          <Nav className='align-items-center d-none d-md-flex' navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className='pr-0 pl-0' nav>
                <Media className='align-items-center user-nav-image'>
                  <span className='avatar avatar-sm rounded-circle'>
                    <img
                      alt='...'
                      src={
                        isClient
                          ? localStorage?.getItem('clientImage')
                          : mploUser?.profileImage
                      }
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = noUserImage;
                      }}
                    />
                  </span>
                  <Media className='ml-2 d-none d-lg-block'>
                    <span className='user-name'>
                      {isClient
                        ? localStorage.getItem('client-name')
                        : mploUser?.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-arrow' right>
                {!localStorage?.getItem('client') && (
                  <DropdownItem
                    onClick={() => {
                      history.push(
                        `/datumbrain/${
                          isAdminLoggedIn()
                            ? 'admin'
                            : isUserLoggedIn()
                            ? 'user'
                            : ''
                        }/profile`
                      );
                    }}
                    className='dropdown-logout d-flex align-items-center'
                  >
                    <i className='fas fa-cog' />
                    <span>Profile Settings</span>
                  </DropdownItem>
                )}

                <DropdownItem
                  onClick={() => {
                    localStorage.clear();
                    dispatch(setProjectLogsList([]));
                    if (isClient) {
                      history.push(`/auth/client/login`);
                    } else {
                      history.push(`/logout`);
                    }
                  }}
                  className='dropdown-logout d-flex align-items-center'
                >
                  <i className='fas fa-power-off' />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
