import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from 'reactstrap';
import { setFromDateFilter, setToDateFilter } from 'redux/actions/projects';
import { setLogsViewMode, setUserIdForLogs } from 'redux/actions/logs';
import {
  setFilterDates,
  setValidatedDateRange,
  setFilterDatesInverse,
} from 'utils/helper-functions';
import moment from 'moment';

const ProjectLogTableFilter = ({ isClient }) => {
  const { fromDateFilter, toDateFilter } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setUserIdForLogs('all-users'));
    dispatch(setLogsViewMode('THIS_WEEK'));
    setFilterDates('THIS_WEEK', dispatch, setFromDateFilter, setToDateFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Form
        className={`needs-validation ${
          isClient ? 'd-flex align-items-center' : ''
        }`}
        noValidate
      >
        <div className='date-input-container'>
          <div className='date-text'>From</div>
          <Input
            className='user-select-none'
            type='date'
            max={moment().format('YYYY-MM-DD')}
            onChange={({ target: { value } }) => {
              dispatch(setFromDateFilter(value));
              setFilterDatesInverse(
                value,
                toDateFilter,
                dispatch,
                setLogsViewMode
              );
              setValidatedDateRange({
                date1: value,
                date2: toDateFilter,
                setDate2: (date) => dispatch(setToDateFilter(date)),
              });
            }}
            name='date'
            id='fromDate'
            value={fromDateFilter}
            placeholder='date placeholder'
          />
        </div>

        <div className='date-input-container'>
          <div className='date-text'>To</div>
          <Input
            className='user-select-none'
            onChange={({ target: { value } }) => {
              dispatch(setToDateFilter(value));

              setFilterDatesInverse(
                fromDateFilter,
                value,
                dispatch,
                setLogsViewMode
              );
            }}
            type='date'
            name='date'
            value={toDateFilter}
            min={fromDateFilter}
            max={moment().format('YYYY-MM-DD')}
            id='toDate'
            placeholder='date placeholder'
          />
        </div>
      </Form>
    </>
  );
};

export default ProjectLogTableFilter;
