import React, { useState, useEffect } from 'react';
import {
  UncontrolledTooltip,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { getTeam } from 'utils/api/api-requests/admin';
import { toast } from 'react-toastify';
import defaultImage from 'assets/img/theme/no-user-1.jpeg';
import { getImageUrl } from 'utils/helper-functions';
import { getTeamByClient } from '../../../../utils/api/api-requests/client';

const AssociatedEmployeeRow = ({ isClient, projectName, projectId }) => {
  const history = useHistory();
  const [employeeList, setEmployeeList] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  useEffect(() => {
    (isClient ? getTeamByClient : getTeam)(projectId)
      .then(({ data }) => setEmployeeList(data))
      .catch(() => toast.error('Failed to get Project Team'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => setPopoverOpen(!popoverOpen);
  const associateEmployeesHandler = () =>
    history?.push(`/datumbrain/admin/project/${projectId}`);

  return (
    <>
      {employeeList?.length > 0 ? (
        <div className='avatar-group d-flex align-items-center'>
          {employeeList
            ?.filter((_, index) => index <= 3)
            ?.map(({ name, image_url: imageUrl, uid }) => {
              const employeeImageSrc = getImageUrl(imageUrl);
              return (
                <>
                  <span
                    className='avatar avatar-sm rounded-circle'
                    id={`employee-${uid}`}
                  >
                    <img
                      alt={`-alt`}
                      src={employeeImageSrc}
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defaultImage;
                      }}
                      width='40'
                    />
                  </span>
                  <UncontrolledTooltip
                    delay={0}
                    target={`employee-${uid}`}
                    position='top'
                  >
                    {name}
                  </UncontrolledTooltip>
                </>
              );
            })}
          {employeeList?.length > 4 ? (
            <>
              <span
                className='avatar avatar-sm rounded-circle cursor-pointer'
                id='associate-employee-popover'
                onClick={(e) => e.preventDefault()}
                onMouseEnter={() => setPopoverOpen(true)}
              >
                {`+${employeeList?.length - 4}`}
              </span>
              <UncontrolledPopover
                trigger='legacy'
                onMouseLeave={() => setPopoverOpen(false)}
                placement='left'
                isOpen={popoverOpen}
                target='associate-employee-popover'
                toggle={toggle}
                fade={true}
                rootClose
              >
                <PopoverHeader>
                  <div className='d-flex flex-row align-items-baseline justify-content-between'>
                    {projectName} Team{' '}
                    <i
                      class='fas fa-times'
                      onClick={() => setPopoverOpen(false)}
                    />
                  </div>
                </PopoverHeader>
                <PopoverBody>
                  <div>
                    {employeeList?.map(
                      ({ name, image_url: imageUrl, uid }, index) => {
                        const employeeImageSrc = getImageUrl(imageUrl);
                        return (
                          <>
                            <span
                              className='avatar avatar-sm rounded-circle cursor-pointer'
                              id={`employee-${uid}-${index}`}
                            >
                              <img
                                alt={`${name}-img`}
                                src={employeeImageSrc}
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = defaultImage;
                                }}
                              />
                            </span>
                            <UncontrolledTooltip
                              delay={0}
                              target={`employee-${uid}-${index}`}
                            >
                              {name}
                            </UncontrolledTooltip>
                          </>
                        );
                      }
                    )}
                  </div>
                </PopoverBody>
                <div className='text-center p-2'>
                  <span
                    className='span-pointer user-theme-color cursor-pointer'
                    onClick={associateEmployeesHandler}
                  >
                    Associate employees
                  </span>
                </div>
              </UncontrolledPopover>
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        <span>-</span>
      )}
    </>
  );
};
export default AssociatedEmployeeRow;
