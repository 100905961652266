export const PAYMENT_TERMS = [
  {
    term: 'PIA',
    description: 'Payment in advance',
  },
  {
    term: 'Net 7',
    description: 'Payment seven days after invoice date',
  },
  {
    term: 'Net 10',
    description: 'Payment ten days after invoice date',
  },
  {
    term: 'Net 15',
    description: 'Payment fifteen days after invoice date',
  },
  {
    term: 'Net 30',
    description: 'Payment thirty days after invoice date',
  },
  {
    term: 'Net 60',
    description: 'Payment sixty days after invoice date',
  },
  {
    term: 'Net 90',
    description: 'Payment ninety days after invoice date',
  },
  {
    term: 'EOM',
    description: 'End of month',
  },
  {
    term: '21 MFI',
    description: '21st of the month following invoice date',
  },
  {
    term: '1% 10 Net 30',
    description:
      '1% discount if payment received within ten days otherwise payment 30 days after invoice date',
  },
  {
    term: 'COD',
    description: 'Cash on delivery',
  },
  {
    term: 'Cash account',
    description: 'Account conducted on a cash basis, no credit',
  },
  {
    term: 'Letter of credit',
    description:
      'A documentary credit confirmed by a bank, often used for export',
  },
  {
    term: 'Bill of exchange',
    description:
      'A promise to pay at a later date, usually supported by a bank',
  },
  {
    term: 'CND',
    description: 'Cash next delivery',
  },
  {
    term: 'CBS',
    description: 'Cash before shipment',
  },
  {
    term: 'CIA',
    description: 'Cash in advance',
  },
  {
    term: 'CWO',
    description: 'Cash with order',
  },
  {
    term: '1MD',
    description: `Monthly credit payment of a full month's supply`,
  },
  {
    term: '2MD',
    description: `Monthly credit payment of a full month's supply plus an extra calendar month`,
  },
  {
    term: 'Contra',
    description:
      'Payment from the customer offset against the value of supplies purchased from the customer    ',
  },
  {
    term: 'Stage payment',
    description: 'Payment of agreed amounts at stage',
  },
];
